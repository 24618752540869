import React, { useCallback } from "react";
import { Switch, Checkbox, Popper, Grow, MenuList, ClickAwayListener, Select, Menu, MenuItem, FormLabel, RadioGroup, FormControlLabel, Radio, Box, Chip, FormControl, Accordion, AppBar, Toolbar, IconButton, Button, Container, Grid, Paper, Typography, useTheme, useMediaQuery, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, List, TextField,CircularProgress, LinearProgress, Alert, Snackbar, TableContainer, TableCell, TableBody, TableSortLabel, Table, TableRow, TableHead, Card, CardContent, Tab, Tabs, TextareaAutosize, Divider, Skeleton } from '@mui/material';
import { useState, useEffect, useRef, useMemo } from 'react';
import { useParams } from "react-router-dom";
import axios from 'axios';
import InputAdornment from '@mui/material/InputAdornment';
import CloseIcon from '@mui/icons-material/Close';
import LinkIcon from '@mui/icons-material/Link';
import Add from "@mui/icons-material/Add";
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import TextSnippetIcon from '@mui/icons-material/TextSnippet';
import AlertTitle from '@mui/material/AlertTitle';
import Delete from '@mui/icons-material/Delete';
import ChatBubbleOutlineIcon from '@mui/icons-material/ChatBubbleOutline';
import * as loom from '@loomhq/loom-embed'

// needed for row & cell level scope DnD setup
import { useSortable } from '@dnd-kit/sortable'
import { CSS } from '@dnd-kit/utilities'
import { ArrowBack, Computer, Share, ExpandLess, NavigateBefore, NavigateNext, ExpandMore, Check, Clear, MailOutlineOutlined, ContentCopyOutlined, CloseFullscreen, OpenInFull, Edit } from "@mui/icons-material";
import EditIconSvg from './assets/svg/EditIcon.svg';
import DeleteIconSvg from './assets/svg/DeleteIcon.svg';
import EditIconNewSvg from './assets/svg/EditIconNew.svg';
import VideocamOffIcon from '@mui/icons-material/VideocamOff';
import PhoneIcon from '@mui/icons-material/Phone';
import ComputerIcon from '@mui/icons-material/Computer';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import ReactGA from "react-ga4";
import DragHandleIcon from "@mui/icons-material/DragHandle";
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';
import TranslateIcon from '@mui/icons-material/Translate';
import TooltipCustom from "./components/common/TooltipCustom";
import ChipTag from "./components/common/ChipTag";
import Flag from '@mui/icons-material/Flag';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import WayfasterLogo from "./wayfaster-logo-w.svg";

import Switcher from "./components/team/Switcher";
import { Sidebar } from "./components/nav/Sidebar";
import CandidateProfile from "./components/candidate/CandidateProfile";
import MessageDrawer from "./components/messaging/MessageDrawer";

import {
  useNavigate,
  useLocation,
  Link
} from 'react-router-dom'
import {
  useAuth0
} from '@auth0/auth0-react'
import { ArrowDropDown, CheckCircle, Circle, QueryStats, ThreeP } from "@mui/icons-material";
import FormField from "./components/common/FormField";
import api from "./api/api";

import { SECTIONS_OPTIONS, supportedLanguages, Language, SessionDecision } from "./util/constants";
import { buildUniqueSessionLink, normalizeScore, getColorFromScore, getColorBgFromScore } from "./util/interviews";
import WarningIcon from '@mui/icons-material/Warning';

ReactGA.initialize([
  {
    trackingId: "G-KEJZXKN80Q",
  },
]);
ReactGA.send({ hitType: "pageview", page: "/", title: "hit" });


var proxyEndpoint = 'https://lit-dusk-21105-d4d3c182eb10.herokuapp.com'
var wsEndpoint = 'wss://lit-dusk-21105-d4d3c182eb10.herokuapp.com'
// var proxyEndpoint = 'http://localhost:5555'
// var wsEndpoint = 'ws://localhost:5555'

var env = String(process.env.REACT_APP_ENV)
console.log('env', env)

if(env === 'staging') {
  proxyEndpoint = 'https://staging-proxy-6f8d86796ff2.herokuapp.com'
  wsEndpoint = 'wss://staging-proxy-6f8d86796ff2.herokuapp.com'
} else if (env === 'development') {
  proxyEndpoint = 'http://localhost:5555'
  wsEndpoint = 'ws://localhost:5555'
}

const LanguageDetect = require('languagedetect');
const langDetector = new LanguageDetect();

var stopInvoked = false;
const emptyInterviewObject = {
  state: 'none',
  messages: [],
  context: [],
  mute: false,
  metadata: {},
  scorecard: [],
  scoring_pending: false
}

// TODO: SWITCH BACK, only for staging testing
var interviewDuration = 600;
var globalInterviewType = 'system_design_event_ticketing'
var interviewerName = 'Steve'
const urlParams = new URLSearchParams(window.location.search);
const interviewTypeQueryParam = urlParams.get('interview_type');
if (interviewTypeQueryParam) {
  globalInterviewType = interviewTypeQueryParam
}
var allSysDesignParam = urlParams.get('all_sys_design');
if(!allSysDesignParam) {
  allSysDesignParam = 'false'
}

if(globalInterviewType === 'eve') {
  interviewerName = 'Isabelle'
}

const SessionStatus = {
  NOT_STARTED: 1,
  INCOMPLETE: 2,
  COMPLETED: 3,
  IN_PROGRESS: 4
}

const SessionStatusString = {
  [SessionStatus.NOT_STARTED]: 'Not Started',
  [SessionStatus.INCOMPLETE]: 'Incomplete',
  [SessionStatus.COMPLETED]: 'Completed',
  [SessionStatus.IN_PROGRESS]: 'In Progress'
}

const ONBOARDING_WHITELIST = [
  'tapesh@nimesa.io',
  'shreyas.jaganmohan@gmail.com',
  'viren@tourhero.com',
  'aubra.kidd@tourhero.com'
]

const RowDragHandleCell = ({ rowId }) => {
  const { attributes, listeners } = useSortable({
    id: rowId,
  })

  // console.log(rowId)

  return (
    <IconButton {...attributes} {...listeners} style={{ height: 'fit-content' }}>
      <DragHandleIcon />
    </IconButton>
  )
}

const detectLanguageMatch = (question, language) => {
  const detectedLanguage = langDetector.detect(question, 2);
  // shows as
  // [ [ 'english', 0.5969230769230769 ], [ 'hungarian', 0.407948717948718 ] ]
  if (detectedLanguage.length > 0) {
    return detectedLanguage[0][0] === language.toLowerCase();
  }
  return false;
}

const DraggableRow = ({ idx, row, selected, setSelectedPreview, stateAddQuestions, setAddQuestions, setShowAddQuestionInput, language }) => {
  const { transform, transition, setNodeRef, isDragging } = useSortable({
    id: row.id,
  })

  const style = {
    transform: CSS.Transform.toString(transform), //let dnd-kit do its thing
    transition: transition,
    opacity: isDragging ? 0.8 : 1,
    zIndex: isDragging ? 1 : 0,
    position: 'relative',
  }

  return (
    <TableRow
      ref={setNodeRef}
      sx={{
        transform: CSS.Transform.toString(transform),
        transition: transition,
        opacity: isDragging ? 0.8 : 1,
        zIndex: isDragging ? 1 : 0,
        position: 'relative',
        border: 'none', borderBottom: '1px solid lightgray', padding: '0px', margin: '0px',
        cursor: 'pointer',
        '&:hover': {
          backgroundColor: '#f5f5f5',
          transition: 'background-color 0.2s ease'
        }
      }}
      onClick={() => {
        setAddQuestions({
          ...stateAddQuestions,
          currentQuestion: {
            text: row.question,
            numFollowUps: row.follow_ups,
            id: row.id,
            editIndex: idx,
          },
        });
        setShowAddQuestionInput(true)
      }}
    >
      <TableCell sx={{ display: 'flex', flexDirection: 'row', border: 'none', padding: '0px', margin: '8px' }}>
        <RowDragHandleCell rowId={row.id}/>
        <div className="d-flex flex-row my-auto" style={{ marginRight: '8px', marginLeft: '8px' }}>
          {/* { !detectLanguageMatch(row.question, language ? language : Language.ENGLISH) && 
            <TooltipCustom title={
              <>
                <p className="m-auto">
                  We've detected that this question may not be in the selected language for this interview.
                </p>
              </>
            }>
              <ErrorIcon className="my-auto" sx={{ color: 'orange', fontSize: '14px', marginRight: '8px' }} />
            </TooltipCustom>
          } */}
          {row.question}
        </div>
      </TableCell>
      <TableCell sx={{ border: 'none', padding: '0px', margin: '0px' }}>{row.follow_ups}</TableCell>
        <TableCell sx={{ border: 'none', padding: '0px', margin: '0px', marginRight: '8px' }}>
          <div style={{ display: 'flex', flexDirection: 'row', marginTop: 'auto', marginBottom: 'auto' }}>
            {/* Preview */}
            <IconButton
              onClick={(e) => {
                e.stopPropagation();
                if (!selected) setSelectedPreview(row);
              }}
              style={{ color: selected ? 'var(--color-primary)' : 'var(--color-main)', marginRight: '4px' }}
            >
              <TextSnippetIcon style={{ fontSize: '20px' }} />
            </IconButton>
            {/* Edit */}
            <IconButton
              onClick={(e) => {
                e.stopPropagation();
                setAddQuestions({
                  ...stateAddQuestions,
                  currentQuestion: {
                    text: row.question,
                    numFollowUps: row.follow_ups,
                    id: row.id,
                    editIndex: idx,
                  },
                });
                setShowAddQuestionInput(true)
              }}
              style={{ color: '#656565', marginRight: '4px' }}
            >
              <img src={EditIconSvg} />
            </IconButton>
            {/* Delete */}
            <IconButton
              onClick={(e) => {
                e.stopPropagation();
                let newQuestions = stateAddQuestions.questions.filter((_, questionIdx) => questionIdx !== idx);
                setAddQuestions({ ...stateAddQuestions, questions: newQuestions });
              }}
              style={{ color: '#656565' }}
            >
              <img src={DeleteIconSvg} />
            </IconButton>
          </div>
        </TableCell>
    </TableRow>
  )
}

const getSession = (sessionId) => {
  return axios.post(proxyEndpoint + '/session', { id: sessionId }) // TODO: add session token auth
  .then(response => {
    return response.data;
  })
  .catch(err => {
    console.error(err);
  });
}

export const SessionDetail = ({ isPubliclyViewable }) => {
  const BASE_SCORE = 5;
  const DEFAULT_SCALE = 4; // based on 4 point scale before dynamic point scales implemented

  const location = useLocation();
  const { interview_id, session_id } = useParams();
  const { user, isAuthenticated, isLoading } = useAuth0();
  const [interview, setInterview] = useState({});
  const [session, setSession] = useState({});
  const [sessionList, setSessionList] = useState([]);
  const [previousSessionId, setPreviousSessionId] = useState('');
  const [nextSessionId, setNextSessionId] = useState('');
  const [videoRecordingLink, setVideoRecordingLink] = useState('')
  const [audioRecordingLink, setAudioRecordingLink] = useState('');

  const [currentSideTab, setCurrentSideTab] = useState(0);
  const [interviewAccordionOpen, setInterviewAccordionOpen] = useState(true);

  // misc
  const [interviews, setInterviews] = useState([])
  const [getInterviewsCalled, setGetInterviewsCalled] = useState(false)
  const [getInterviewCalled, setGetInterviewCalled] = useState(false);
  const [getSessionCalled, setGetSessionCalled] = useState(false);
  const [getSessionListCalled, setGetSessionListCalled] = useState(false);
  const [getVideoRecordingLinkCalled, setGetVideoRecordingLinkCalled] = useState(false)
  const [openSnackbar, setOpenSnackbar] = useState(false)
  const [snackbarMsg, setSnackbarMsg] = useState('')
  const [copied, setCopied] = useState(false)
  const [showMessages, setShowMessages] = useState(false)
  const [currentMessages, setCurrentMessages] = useState([])
  const [currentTranscriptLanguage, setCurrentTranscriptLanguage] = useState(Language.ENGLISH)
  const [gettingTranslation, setGettingTranslation] = useState(false)
  const [currentLanguageTab, setCurrentLanguageTab] = useState(0) 
  const [showScorecard, setShowScorecard] = useState(false)
  const [currentScorecard, setCurrentScorecard] = useState([])
  const [actionsOpen, setActionsOpen] = useState(false);
  const [shareOpen, setShareOpen] = useState(false);
  const [loomLinkReplaceCalled, setLoomLinkReplaced] = useState(false)
  const [team, setTeam] = useState({})
  const [teams, setTeams] = useState([])

  const [activeSidebar, setActiveSidebar] = useState(false);
  const [candidate, setCandidate] = useState({});
  
  const [showEditCandidate, setShowEditCandidate] = useState(false)
  const [showSessionDeletionConfirmation, setShowSessionDeletionConfirmation] = useState(false)

  const [showFlagCheatingDialog, setShowFlagCheatingDialog] = useState(false)
  const [flagCheatingReason, setFlagCheatingReason] = useState('')
  
  const [editScorecardValueIndex, setEditScorecardValueIndex] = useState(-1)
  const [newScoreValue, setNewScoreValue] = useState(1)
  const [editScoreReason, setEditScoreReason] = useState('')

  const [conversation, setConversation] = useState({})
  const [messages, setMessages] = useState([])
  const [showMessageDrawer, setShowMessageDrawer] = useState(false)
  const [selectedCandidate, setSelectedCandidate] = useState({})

  const autoFormatCandidate = () => {
    let profile = {
      first_name: session.candidate_metadata.first_name,
      given_name: session.candidate_metadata.last_name,
      interview: [
        {
          interview_title: interview.title,
          scorecard: currentScorecard,
          overall_score: sessionScore,
          interview_recording: session.videoRecordingUrl,
          audio_recording: session.audio_recording
        }
      ]
    }
    setCandidate(profile);
  }
  
  const thumbVDark = ({ style, ...props }) => (
    <div style={{ ...style, backgroundColor: 'rgba(255, 255, 255, 0.2)', borderRadius: '10px', cursor: 'pointer' }} {...props} />
  );

  const actionsRef = React.useRef(null);
  const shareRef = React.useRef(null);

  useEffect(() => {
    if(isPubliclyViewable && !getInterviewCalled) {
      api.getPublicInterview({ sessionId: session_id })
      .then((response) => {
        setInterview(response.data)
        setGetInterviewCalled(true);
        getSession(session_id)
        .then((response) => {
          setAudioRecordingLink(response.recording_url)
          setCurrentMessages(response.sentence_messages || response.messages)
          setCurrentTranscriptLanguage(response.language)
          setCurrentScorecard(response.scorecard)
          setSession(response)
        })
        .catch((err) => {
          console.error(err)
        })
  
        if(!loomLinkReplaceCalled && Object.keys(session).length > 0) {
          loom.linkReplace('.loom-video')
          setLoomLinkReplaced(true)
        }
      })
      .catch((err) => {
        console.error(err)
      })

      axios.post(`${proxyEndpoint}/generate_video_recording_link`, { id: session_id })
      .then((response) => {
        setVideoRecordingLink(response.data.url)
        setGetVideoRecordingLinkCalled(true)
        console.log(response.data.url)
      })
      .catch((error) => {
        console.error('Error generating video recording link:', error);
      });      
      return
    }

    var userId = ''
    var globalOverrideUser = ''
    if(user && !getInterviewCalled) {
      userId = user.email
      const teamId = localStorage.getItem('teamId')
      globalOverrideUser = localStorage.getItem('globalOverrideUser')
      if(globalOverrideUser != null) {
        userId = globalOverrideUser
      }
      api.getInterview(interview_id, userId, teamId)
      .then((response) => {
        setInterview(response.data)
        setGetInterviewCalled(true);
      })
      .catch((err) => {
        console.error(err)
      })
    }

    if(user && !getSessionCalled) {
      setGetSessionCalled(true);
      getSession(session_id)
      .then((response) => {
        setAudioRecordingLink(response.recording_url)
        setCurrentMessages(response.sentence_messages || response.messages)
        setCurrentTranscriptLanguage(response.language)
        setCurrentScorecard(response.scorecard)
        setSession(response)
      })
      .catch((err) => {
        console.error(err)
        setGetSessionCalled(false);
      })

      if(!loomLinkReplaceCalled && Object.keys(session).length > 0) {
        loom.linkReplace('.loom-video')
        setLoomLinkReplaced(true)
      }
    }

    if(user && !getVideoRecordingLinkCalled) {
      axios.post(`${proxyEndpoint}/generate_video_recording_link`, { id: session_id })
      .then((response) => {
        setVideoRecordingLink(response.data.url)
        setGetVideoRecordingLinkCalled(true)
      })
      .catch((error) => {
        console.error('Error generating video recording link:', error);
      });
    }
  }, [getVideoRecordingLinkCalled, getInterviewsCalled, getInterviewCalled, interview_id, interview, user, session_id, videoRecordingLink, loomLinkReplaceCalled]);

  const [accountOpen, setAccountOpen] = useState(false);
  const accountRef = useRef(null);

  const handleAccountToggle = () => {
    setAccountOpen((prevOpen) => !prevOpen);
  };
  const { logout } = useAuth0();
  const handleAccountClose = (event) => {
    if (accountRef.current && accountRef.current.contains(event.target)) {
      return;
    }
    setAccountOpen(false);
  };

  const teamClicked = (teamId) => {
    if (team._id.$oid != teamId) {
      const new_team = teams.find((t) => t._id.$oid == teamId);
      localStorage.setItem('teamId', new_team._id.$oid)
      setTeam(new_team)
      navigate('/interviews')
    }
  }

  const callFlagCheating = () => {
    const requestBody = {
      interview_session: {
        _id: session._id,
        potentialCheating: true,
        potentialCheatingReason: flagCheatingReason
      }
    }

    return axios.post(proxyEndpoint + '/interview_sessions/' + session_id, requestBody)
    .then(response => {
      setSession({
        ...session,
        potentialCheating: true,
        potentialCheatingReason: flagCheatingReason
      });
      setShowFlagCheatingDialog(false)
      setSnackbarMsg('Flagged cheating')
      setOpenSnackbar(true)
    })
    .catch(error => {
      console.error('Error flagging cheating:', error)
    })
  }

  const callEditScorecardValue = () => {
    const updatedScorecard = currentScorecard.map((item, index) => {
      if (index === editScorecardValueIndex) {
        return {
          ...item,
          score: newScoreValue,
          feedback: {
            subopinions: [
              {
                reasoning: editScoreReason,
                supporting_quotes_from_candidate: []
              }
            ]
          }
        }
      }
      return item
    })

    const requestBody = {
      scorecard: updatedScorecard
    }

    return axios.put(proxyEndpoint + '/sessions/' + session_id + '/scorecard', requestBody)
    .then(response => {
      setSession({
        ...session,
        scorecard: response.data.scorecard,
        overall_score: response.data.overall_score,
        overall_scale: response.data.overall_scale,
        overall_percentage: response.data.overall_percentage,
        overall_decision: response.data.overall_decision
      })
      setCurrentScorecard(updatedScorecard)
      setEditScorecardValueIndex(-1)
      setNewScoreValue(1)
      setEditScoreReason('')
      setSnackbarMsg('Score updated')
      setOpenSnackbar(true)
    })
    .catch(error => {
      console.error('Error editing scorecard value:', error)
    })
  }

  const callOverrideCandidateAction = (value) => {
    // value: 'reject' or 'accept'
    const requestBody = {
      interview_session: {
        _id: session._id,
        customerOverrideCandidateAction: value,
        overall_decision: value === 'accept' ? SessionDecision.PASS : SessionDecision.FAIL
      }
    };
        
    return axios.post(proxyEndpoint + '/interview_sessions/' + session_id, requestBody)
    .then(response => {
      setSession({
        ...session,
        customerOverrideCandidateAction: value,
        overall_decision: value === 'accept' ? SessionDecision.PASS : SessionDecision.FAIL
      });
      setSnackbarMsg(`Successfully set score to ${value} candidate`)
      setOpenSnackbar(true)
    })
    .catch(err => {
      console.error('Error in override action:', err);
    });
  }

  const navigate = useNavigate()

  const navigateToSession = (sessionId) => {
    setGetSessionCalled(false);
    setGetSessionListCalled(false);
    setGetVideoRecordingLinkCalled(false);
    setVideoRecordingLink('');
    setAudioRecordingLink('');

    navigate("/interviews/" + interview_id + "/sessions/" + sessionId);
  }

  const handleSideTabChange = (event, newValue) => {
    setCurrentSideTab(newValue);
  }

  const handleActionsToggle = () => {
    setActionsOpen((prevOpen) => !prevOpen);
  };

  const handleShareToggle = () => {
    setShareOpen((prevOpen) => !prevOpen);
  };
  
  const handleMessageToggle = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setSelectedCandidate(session.candidate_metadata);
    setShowMessageDrawer(true);

    fetchConversation(session.candidate_metadata.id);
  }

  const fetchConversation = (candidateId) => {
    api.getConversation({
      candidateId: candidateId
    }).then((response) => {
      console.log('conversation fetch', response.data);
      setConversation(response.data);
      setMessages(response.data.messages);
    }).catch((error) => {
      console.error('Error getting conversation:', error);
      setMessages([]);
      setConversation(null);
    });
  }

  const handleActionsClose = (event) => {
    if (
      actionsRef.current &&
      actionsRef.current.contains(event.target)
    ) {
      return;
    }
    setActionsOpen(false)
  }

  const handleShareClose = (event) => {
    if (
      shareRef.current &&
      shareRef.current.contains(event.target)
    ) {
      return;
    }
    setShareOpen(false)
  }

  const sessionScore = useMemo(() => {
    if (!currentScorecard || currentScorecard.length === 0) return -1;

    const totalWeightCheck = currentScorecard.reduce((sum, item) => sum + (item.weight || 0), 0);
    if (Math.abs(totalWeightCheck - 100) > 0.001) {
      const equalWeight = 25;
      currentScorecard.forEach(item => item.weight = equalWeight);
    }

    let weightedSum = 0;
    let totalWeight = 0;

    currentScorecard.forEach((scorecardItem) => {
      const configItem = currentScorecard.find(item => item.signal === scorecardItem.signal);
      if (configItem) {
        const weight = (configItem.weight / 100) || 0;
        weightedSum += scorecardItem.score * weight;
        totalWeight += weight;
      }
    });

    if (totalWeight === 0) return -1;

    const weightedAverage = weightedSum / totalWeight;
    return weightedAverage.toFixed(1);
  }, [currentScorecard, session])

	const renderOutputValue = (value) => {
		if(!Array.isArray(value)){
			return value;
		}

		if (value.length === 0) {
			return '[]';
		}


		const formattedItems = value.map(item => {
			if (typeof item === 'string') {
				return `'${item}'`;
			}
			return String(item);
		});

		const output = '[\n  ' + formattedItems.join(',\n  ') + '\n]';
		return output;
	}

  const renderStructuredOutputs = (structuredOutputs) => {
    if(!structuredOutputs) {
      return <>
              <div className="d-flex flex-col section">
                <p className="section-body color-gray" style={{ fontSize: '14px' }}>
                  No outputs found yet.
                </p>
              </div>
            </>
    }

    return structuredOutputs.map((output) => {
      return (
        <div className="d-flex flex-col section" style={{ padding: '12px', borderRadius: '12px', border: '1px solid lightgray' }}>
          {Object.entries(output).map(([key, value]) => (
            <>
              <div className="section-heading">
                <ChipTag variant="default" text={key} style={{ width: 'max-content' }} />
              </div>
              <p className="section-body" style={{ fontSize: '14px' }}>
                {renderOutputValue(value)}
              </p>
            </>
            )
          )}
        </div>
      )
    });
  }

  const handleSwitchChange = (event) => {
    // Update the session.shareable_candidate state based on the switch's checked value
    session.shareable_candidate = event.target.checked;
    api.updateShareableSession({ sessionId: session._id, shareable: event.target.checked }).then(response => {      
      if (response.status === 200) {
        session.shareable_candidate = response.data.shareable;
        if (response.data.candidate_id) {
          session.candidate_id = response.data.candidate_id;
        }
        setSnackbarMsg('Shareable link updated successfully')
        setOpenSnackbar(true)
      }
    })
  }

  const handleDeleteSession = () => {
    var userId = user.email
    const globalOverrideUser = localStorage.getItem('globalOverrideUser')
    if(globalOverrideUser != null) {
      userId = globalOverrideUser
    }
    const teamId = localStorage.getItem('teamId')
    const sessionId = session_id
    
    api.deleteSession({ userId, teamId, sessionId}).then((response) => {
      if (response.data.error) {
        console.error(`Failed to delete session: ${response.data.error}`);
        return;
      } else {
        setSnackbarMsg(response.data.message);
        setOpenSnackbar(true);

        setShowSessionDeletionConfirmation(false)
        navigate('/interviews/' + interview_id);
      }
    })
    .catch(error => {
      console.error('Failed to delete session:', error);
    });
  }

  const sessionEmpty = useMemo(() => {
    // return true;
    return !session || Object.keys(session).length === 0;
  }, [session]);

  const translateTranscript = (targetLanguage) => {
    if (session.language === Language.ENGLISH && targetLanguage === Language.ENGLISH) {
      setCurrentMessages(session.sentence_messages || session.messages)
      setCurrentTranscriptLanguage(Language.ENGLISH)
      return
    }

    if (session[`messages_${targetLanguage}`]) {
      setCurrentMessages(session[`messages_${targetLanguage}`])
      setCurrentTranscriptLanguage(targetLanguage)
      return
    } else {
      setGettingTranslation(true)
      api.getTranslatedTranscript({ interviewId: interview._id, sessionId: session._id, targetLanguage: targetLanguage })
      .then(response => {
        setCurrentMessages(response.data.translated_messages)
        setCurrentTranscriptLanguage(targetLanguage)
        setSession({...session, [`messages_${targetLanguage}`]: response.data.translated_messages})

        setGettingTranslation(false)
      })
      .catch(error => {
        console.error('Failed to get translated transcript:', error);
        setGettingTranslation(false)
      });
    }
  }

  const handleLanguageTabChange = (event, newValue) => {
    setCurrentLanguageTab(newValue);
  }

  // Add effect to set initial tab
  useEffect(() => {
    if (session.status === SessionStatus.NOT_STARTED) {
      setCurrentSideTab(4);
    }
  }, [session.status]);

  return (
    <Box sx={{display: 'flex'}}>
      {/* Side Nav (TODO REFACTOR: avoid unnecessary re-render of component) */}
      {/* {!isPubliclyViewable && <Sidebar user={user} isAuthenticated={isAuthenticated} isLoading={isLoading} onNavigate={() => {}}></Sidebar>} */}

      <Snackbar
        open={openSnackbar}
        autoHideDuration={6000}
        onClose={() => {setOpenSnackbar(false)}}
        message={snackbarMsg}
      />
      {/* Session */}
      <Box style={{ background: 'white', borderRadius: 6, width: '100%' }}>
          <Box container>
            {/* Session Header */}
            <Box style={{ width: '100%', padding: '16px 24px', borderBottom: '1px solid lightgray' }}>
              <Grid item xs={12}>
                <div className="d-flex flex-row">
                  {!isPubliclyViewable &&
                    <div className="d-flex flex-row" style={{ marginRight: 'auto' }}>
                      <button className="btn-main small d-flex gray outlined"
                        onClick={() => {navigate("/interviews/" + interview._id)}}>
                        <ArrowBack fontSize="14px" className="color-heading m-auto" />
                      </button>
                      <h5 style={{ margin: 'auto 8px', lineHeight: '20px', fontWeight: 600 }}>My Interviews</h5>

                      <h5 className="inactive" style={{ margin: 'auto', lineHeight: '20px' }}>/</h5>

                      {!sessionEmpty ? 
                        <h5 style={{ margin: 'auto 8px', lineHeight: '20px', fontWeight: 600 }}>{ interview.title }</h5> :
                        <Skeleton variant="text" width={140} height={28} style={{ margin: 'auto 8px' }}/>
                      }
                    </div>
                  }

                  {/* {!isPubliclyViewable &&
                    <div className="d-flex flex-row" style={{ marginLeft: 'auto' }}>
                      <button className={`btn-main small d-flex gray outlined ${previousSessionId ? "" : "inactive"}`}
                        disabled={!previousSessionId}
                        onClick={() => {navigateToSession(previousSessionId)}}>
                        <NavigateBefore fontSize="14px" className="color-heading m-auto" />
                      </button>
                      <h5 className={[ previousSessionId ? "" : "inactive" ]} style={{ margin: 'auto 8px', lineHeight: '20px' }}>Previous Session</h5>

                      <h5 className="inactive" style={{ margin: 'auto 4px', lineHeight: '20px' }}>|</h5>

                      <h5 className={[ nextSessionId ? "" : "inactive" ]} style={{ margin: 'auto 8px', lineHeight: '20px' }}>Next Session</h5>

                      <button className={`btn-main small d-flex gray outlined ${nextSessionId ? "" : "inactive"}`}
                        disabled={!nextSessionId}
                        onClick={() => {navigateToSession(nextSessionId)}}>
                        <NavigateNext fontSize="14px" className="color-heading m-auto" />
                      </button>
                    </div>
                  } */}
                </div>
              </Grid>
              <Box className="d-flex flex-row" style={{ width: '100%' }}>
                <div className="d-flex flex-row" style={{ width: '100%', marginTop: '16px' }}>
                  {!sessionEmpty ? <div className="d-flex flex-col" style={{ marginRight: 'auto' }}>
                    <div className="d-flex flex-row" style={{ marginRight: 'auto', gap: '8px' }}>
                      {session.candidate_metadata &&
                        <>
                          <h3 className="my-auto clickable" style={{ fontSize: '20px' }} onClick={() => {
                            autoFormatCandidate();
                            setActiveSidebar(true);
                          }}>
                            {session.candidate_metadata.first_name} {session.candidate_metadata.last_name}

                          </h3>
                        </>
                      }

                      {/* Status */}
                      {session.voicemail_detected ?
                        <ChipTag variant="default" text="Voicemail" />
                      :
                      <>
                        {session.status === SessionStatus.INCOMPLETE &&
                          <ChipTag variant="in-progress" text="Incomplete" />
                        }

                        {session.status === SessionStatus.IN_PROGRESS &&
                          <ChipTag variant="in-progress" text="In Progress" />
                        }

                        {(session.status === SessionStatus.NOT_STARTED && !session.sent_invite) && (
                          <ChipTag variant="not-started" text="Not Started" />
                        )}

                        {(session.status === SessionStatus.NOT_STARTED && session.sent_invite) && (
                          <ChipTag variant="invited" text="Invited" />
                        )}

                        {session.status === SessionStatus.COMPLETED &&
                          <ChipTag variant="completed" text="Completed" />
                        }

                        {/* Decision */}
                        {(() => {                          
                          if (session.overall_decision === SessionDecision.UNDECIDED) {
                            return <ChipTag variant="default" text="Pending" />;
                          }
                          
                          if (session.customerOverrideCandidateAction === 'accept' || 
                              session.overall_decision === SessionDecision.PASS) {
                            return <ChipTag variant="accept" text="Accept" />;
                          }
                          
                          if (session.customerOverrideCandidateAction === 'reject' || 
                              session.overall_decision === SessionDecision.FAIL) {
                            return <ChipTag variant="reject" text="Reject" />;
                          }
                          
                          return <ChipTag variant="default" text="Pending" />;
                        })()}

                        {/* Bullhorn Candidate ID */}
                        {session.candidate_metadata.bullhorn_candidate_id && (
                          <div style={{
                            backgroundColor: "var(--color-bullhorn-bg)",
                            borderRadius: "4px",
                            padding: "4px 8px",
                            fontSize: "14px",
                            fontFamily: "Inter",
                            fontWeight: 600,
                            color: "var(--color-bullhorn)",
                            display: "flex",
                            alignItems: "center",
                            gap: "4px"
                          }}>
                            <img 
                              src={require("./assets/bullhorn_logo_bull.png")} 
                              alt="Bullhorn" 
                              style={{ height: "14px", width: "auto" }} 
                            />
                            {session.candidate_metadata.bullhorn_candidate_id}
                          </div>
                        )}

                        {/* Suspicious Activity */}
                        {session.potentialCheating && (
                          <div style={{ 
                            display: 'inline-flex', 
                            alignItems: 'center',
                            backgroundColor: '#FF4D4D', 
                            color: 'white',
                            borderRadius: '6px',
                            padding: '0 8px',
                            height: '24px',
                            fontWeight: '600',
                            fontSize: '12px',
                          }}>
                            Suspicious
                            <CloseIcon 
                              style={{ 
                                fontSize: '16px', 
                                marginLeft: '4px',
                                cursor: 'pointer'
                              }} 
                              onClick={(e) => {
                                e.stopPropagation();
                                const requestBody = {
                                  interview_session: {
                                    _id: session._id,
                                    potentialCheating: false
                                  }
                                };
                                
                                axios.post(proxyEndpoint + '/interview_sessions/' + session_id, requestBody)
                                  .then(response => {
                                    setSession({
                                      ...session,
                                      potentialCheating: false,
                                      potentialCheatingReason: null
                                    });
                                    setSnackbarMsg('Removed suspicious flag');
                                    setOpenSnackbar(true);
                                  })
                                  .catch(error => {
                                    console.error('Error removing suspicious flag:', error);
                                  });
                              }}
                            />
                          </div>
                        )}
                      </>
                      }
                    </div>

                    <h5 className="color-gray" style={{ margin: '8px 0px', fontWeight: 500, fontSize: '14px' }}>
                      {session.created_at.$date ? (
                        new Date(session.created_at.$date).toLocaleDateString()
                      ) : (
                        new Date(session.created_at * 1000).toLocaleDateString()
                      )}
                    </h5>
                  </div> :
                  <div className="d-flex flex-col" style={{ marginRight: 'auto' }}>
                    <Skeleton variant="text" width={180} height={30} />
                    <Skeleton variant="text" width={100} height={18} />
                  </div>
                  }

                  {!sessionEmpty ? <div className="d-flex flex-row my-auto" style={{ marginLeft: 'auto' }}>
                    <div className="d-flex flex-col" style={{ marginRight: '16px' }}>
                      <h6 className="color-gray" style={{ margin: 0, fontWeight: 600 }}>Invited on</h6>
                      <p className="color-heading" style={{ fontSize: '12px', margin: '8px 0 0' }}>
                        {session.created_at.$date ?
                          <>
                            {new Date(session.created_at.$date).toLocaleDateString()} <br />
                            {new Date(session.created_at.$date).toLocaleTimeString()}
                          </> :
                          <>
                            {new Date(session.created_at * 1000).toLocaleDateString()} <br />
                            {new Date(session.created_at * 1000).toLocaleTimeString()}
                          </>
                        }
                      </p>
                    </div>
                    {session.status === SessionStatus.COMPLETED && (
                      <div className="d-flex flex-col" style={{ marginRight: '16px' }}>
                        <h6 className="color-gray" style={{ margin: 0, fontWeight: 600 }}>Conducted on</h6>
                        <p className="color-heading" style={{ fontSize: '12px', margin: '8px 0 0' }}>
                          {new Date(session.date_completed_unix_timestamp).toLocaleDateString()} <br />
                          {new Date(session.date_completed_unix_timestamp).toLocaleTimeString()}
                        </p>
                      </div>
                    )}
                    {(session.metadata && session.metadata.interview_config) && <div className="d-flex flex-col" style={{ marginRight: '16px' }}>
                      <h6 className="color-gray" style={{ margin: 0, fontWeight: 600 }}>Duration</h6>
                      <p className="color-heading" style={{ fontSize: '12px', margin: '8px 0 0' }}>
                        {session.messages && session.messages.length > 1 &&
                          <>
                          {Math.floor((session.messages[session.messages.length - 1].createdAt - session.messages[0].createdAt) / 60) > 0 && 
                            <>{Math.floor((session.messages[session.messages.length - 1].createdAt - session.messages[0].createdAt) / 60)} mins<br/></>
                          }
                          {Math.round((session.messages[session.messages.length - 1].createdAt - session.messages[0].createdAt) % 60)} secs
                          </>
                        }
                      </p>
                    </div>}
                    {session.client_type && <div className="d-flex flex-col" style={{ marginRight: '16px' }}>
                      <h6 className="color-gray" style={{ margin: 0, fontWeight: 600 }}>Call Type</h6>
                      <p className="color-heading" style={{ margin: '8px 0 0' }}>
                        {session.client_type === 'desktop' && <ComputerIcon fontSize="16px" />}
                        {(session.client_type === 'phone' || (session.client_type === 'mobile' && session.candidate_metadata?.call_type !== 'web')) && <PhoneIcon fontSize="16px" />}
                        {(session.client_type === 'mobile' && session.candidate_metadata?.call_type === 'web') && <ComputerIcon fontSize="16px" />}
                      </p>
                    </div>}

                    {!isPubliclyViewable &&
                    <button className="btn-main d-flex gray outlined my-auto"
                      ref={shareRef}
                      style={{ margin: 'auto', marginRight: '12px' }}
                      onClick={handleShareToggle}>
                        <Share fontSize="14px" className="color-heading m-auto" />
                      </button>
                    }

                    {!isPubliclyViewable && process.env.REACT_APP_INBOX_TAB_ENABLED === "true" &&
                    // Only show message button if candidate metadata has a phone number and id for messaging
                    (session.candidate_metadata && session.candidate_metadata.phone && session.candidate_metadata.id) &&
                    <button className="btn-main d-flex gray outlined my-auto"
                      style={{ margin: 'auto', marginRight: '12px' }}
                      onClick={handleMessageToggle}>
                        <ChatBubbleOutlineIcon fontSize="14px" className="color-heading m-auto" />
                      </button>
                    }

                    {!isPubliclyViewable &&
                    <>
                    <Popper
                      open={shareOpen}
                      anchorEl={shareRef.current}
                      role={undefined}
                      placement="bottom-end"
                      transition>
                      {({ TransitionProps, placement }) => (
                        <Grow
                          {...TransitionProps}
                          style={{
                            transformOrigin: 'right top',
                          }}
                        >
                          <Paper>
                            <ClickAwayListener onClickAway={handleShareClose}>
                              <MenuList
                                autoFocusItem={shareOpen}
                                id="composition-menu"
                                aria-labelledby="composition-button"
                                sx={{
                                  ['& .MuiMenuItem-root'] : {
                                    fontSize: '14px'
                                  },
                                  ['& .MuiTypography-root'] : {
                                    fontSize: '14px'
                                  }
                                }}
                              >
                                <MenuItem onClick={() => {
                                    navigator.clipboard.writeText(buildUniqueSessionLink(session.unique_session_id, 'web')).then((val) => {
                                      setSnackbarMsg('Web link copied to clipboard successfully')
                                      setOpenSnackbar(true)
                                      setCopied(true)
                                      handleShareClose()
                                    })
                                    .catch((err) => {console.error('could not copy')})
                                  }}>
                                  <ListItemIcon>
                                    <ComputerIcon fontSize="14px"/>
                                  </ListItemIcon>
                                  <ListItemText>
                                    Copy Web Link
                                  </ListItemText>
                                </MenuItem>
                                {session.client_type && (session.client_type === 'phone' || session.client_type === 'mobile') && <MenuItem onClick={() => {
                                    navigator.clipboard.writeText(buildUniqueSessionLink(session.unique_session_id, 'phone')).then((val) => {
                                      setSnackbarMsg('Phone link copied to clipboard successfully')
                                      setOpenSnackbar(true)
                                      setCopied(true)
                                      handleShareClose()
                                    })
                                    .catch((err) => {console.error('could not copy')})
                                  }}>
                                  <ListItemIcon>
                                    <PhoneIcon fontSize="14px"/>
                                  </ListItemIcon>
                                  <ListItemText>
                                    Copy Phone Link
                                  </ListItemText>
                                </MenuItem>}
                                <TooltipCustom
                                  title={
                                    <>
                                      <p className="m-auto">
                                        Generates a public profile to share, including candidate profile and session details.
                                      </p>
                                    </>
                                  }
                                >
                                <div className="d-flex flex-row" style={{ borderTop: '1px solid lightgray', paddingTop: '8px', marginBottom: '8px' }}>
                                  <Switch
                                      size="small"
                                      checked={session.shareable_candidate}
                                      onChange={handleSwitchChange}
                                      name="shareableCandidateSwitch"
                                      inputProps={{ 'aria-label': 'Shareable Candidate Switch' }}
                                    />
                                    <p className="my-auto" style={{ fontSize: '14px', fontFamily: 'Figtree', marginLeft: '12px'}}>
                                      Make Public
                                    </p>
                                  </div>
                                  {session.shareable_candidate && <MenuItem 
                                    onClick={() => {
                                      navigator.clipboard.writeText(window.location.origin + '/public/candidate/' + session.candidate_id).then((val) => {
                                        setSnackbarMsg('Public share link copied to clipboard successfully')
                                        setOpenSnackbar(true)
                                        handleShareClose()
                                      })
                                      .catch((err) => {console.error('could not copy')})
                                    }}>
                                    <ListItemIcon>
                                      <Share fontSize="14px"/>
                                    </ListItemIcon>
                                    <ListItemText>
                                      Share Public Link
                                    </ListItemText>
                                  </MenuItem>}
                                  {/* TODO: add edit candidate back in */}
                                  {/* {session.shareable_candidate && <MenuItem 
                                    onClick={() => {
                                      setShowEditCandidate(true)
                                    }}>
                                    <ListItemIcon>
                                      <Edit fontSize="14px"/>
                                    </ListItemIcon>
                                    <ListItemText>
                                      Edit Candidate
                                    </ListItemText>
                                  </MenuItem>} */}
                                </TooltipCustom>
                              </MenuList>
                            </ClickAwayListener>
                          </Paper>
                        </Grow>
                      )}
                    </Popper>

                    {/* Edit Candidate Dialog */}
                    <Dialog open={showEditCandidate} onClose={() => setShowEditCandidate(false)}>
                      <DialogTitle>Edit Candidate Details</DialogTitle>
                      <DialogContent>
                        <Grid container spacing={2} style={{ marginTop: '8px' }}>
                          <Grid item xs={6}>
                            <FormField
                              label="First Name"
                              value={session.candidate_metadata?.first_name || ''}
                              onChange={(e) => {
                                setSession({
                                  ...session,
                                  candidate_metadata: {
                                    ...session.candidate_metadata,
                                    first_name: e.target.value
                                  }
                                })
                              }}
                            />
                          </Grid>
                          <Grid item xs={6}>
                            <FormField
                              label="Last Name"
                              value={session.candidate_metadata?.last_name || ''}
                              onChange={(e) => {
                                setSession({
                                  ...session,
                                  candidate_metadata: {
                                    ...session.candidate_metadata,
                                    last_name: e.target.value
                                  }
                                })
                              }}
                            />
                          </Grid>
                        </Grid>
                      </DialogContent>
                      <DialogActions>
                        <Button className="btn outlined" onClick={() => setShowEditCandidate(false)}>
                          Cancel
                        </Button>
                        <Button 
                          className="btn-main"
                          onClick={() => {
                            // Save candidate details
                            let candidate = {
                              _id: session.candidate_id,
                              first_name: session.candidate_metadata?.first_name,
                              last_name: session.candidate_metadata?.last_name
                            }
                            api.updateCandidate({ candidateId: session.candidate_id, candidate: candidate })
                            .then(response => {
                              setOpenSnackbar(true);
                              setSnackbarMsg('Candidate details updated successfully');
                              setShowEditCandidate(false);
                            })
                            .catch(error => {
                              console.error('Failed to update candidate details:', error);
                            });
                          }}
                        >
                          Save
                        </Button>
                      </DialogActions>
                    </Dialog>

                    {/* TODO: handle click for Session Actions here (web / phone share? edit interview copy?) */}
                    <button className="btn-main d-flex gray outlined my-auto"
                        ref={actionsRef}
                        id="actions-button"
                        aria-controls={actionsOpen ? 'composition-menu' : undefined}
                        aria-expanded={actionsOpen ? 'true' : undefined}
                        aria-haspopup="true"
                        onClick={handleActionsToggle}>
                      Actions
                      <ExpandMore fontSize="14px" className="color-heading m-auto"
                        style={{ marginLeft: '8px' }}/>
                    </button>

                    {/* Actions */}
                    <Menu
                      id="actions-menu"
                      anchorEl={actionsRef.current}
                      open={actionsOpen}
                      onClose={handleActionsClose}
                      sx={{
                        marginTop: "8px",
                        "& .MuiPaper-root": {
                          borderRadius: "8px",
                          boxShadow: "0 2px 4px rgba(0,0,0,0.1) !important",
                          border: "1px solid var(--color-midgray)",
                          minWidth: "200px" // Added minimum width
                        },
                        "& .MuiMenuItem-root": {
                          fontSize: "14px",
                          fontWeight: "500",
                          padding: "8px 16px",
                          width: "200px" // Set fixed width for menu items
                        }
                      }}
                    >
                      <MenuItem className="d-flex flex-row" onClick={() => {
                          callOverrideCandidateAction('accept')
                        }} style={{ width: '100%'}}
                        selected={session.customerOverrideCandidateAction === 'accept' || session.overall_decision === SessionDecision.PASS}>
                        Accept
                        <Check className="color-accept" style={{ fontSize: '14px', marginLeft: 'auto' }} />
                      </MenuItem>
                      <MenuItem onClick={() => {
                          callOverrideCandidateAction('reject')
                        }} style={{ width: '100%'}}
                        selected={!(session.customerOverrideCandidateAction === 'accept' || session.overall_decision === SessionDecision.PASS)}>
                        Reject
                        <Clear className="color-reject" style={{ fontSize: '14px', marginLeft: 'auto' }} />
                      </MenuItem>
                      <MenuItem
                        onClick={(e) => {
                          e.preventDefault();
                          e.stopPropagation();
                          setShowSessionDeletionConfirmation(true)
                        }}
                        style={{ width: '100%' }}
                      >
                        Delete
                        <Delete style={{ fontSize: '14px', marginLeft: 'auto' }} />
                      </MenuItem>
                      {(localStorage.getItem('teamId') === '66f4c9db6a7d88f9cbcdca09' || process.env.REACT_APP_ENV === 'development') && ( // only show flag cheating for Wayfaster team
                        <MenuItem
                          onClick={(e) => {
                            e.preventDefault();
                            e.stopPropagation();
                            setShowFlagCheatingDialog(true)
                          }}
                          style={{ width: '100%' }}
                        >
                          Flag Cheating
                          <Flag style={{ fontSize: '14px', marginLeft: 'auto' }} />
                        </MenuItem>
                      )}
                    </Menu>

                    {/* FLAG CHEATING DIALOG */}
                    <Dialog
                      open={showFlagCheatingDialog}
                      onClose={() => setShowFlagCheatingDialog(false)}
                      aria-labelledby="flag-cheating-dialog-title"
                      aria-describedby="flag-cheating-dialog-description"
                    >
                      <div style={{padding: "24px", width: "400px"}}>
                        <h2 style={{fontSize: "18px", fontWeight: "600", marginBottom: "12px", marginTop: "0px"}}>
                          Flag cheating
                        </h2>
                        
                        <FormField
                          label="Why do you think this candidate cheated?"
                          multiline
                          rows={8}
                          value={flagCheatingReason}
                          onChange={(e) => setFlagCheatingReason(e.target.value)}
                          required
                        />

                        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end', gap: '12px', marginTop: '8px' }}>
                          <button
                            onClick={() => setShowFlagCheatingDialog(false)}
                            className="btn-main outlined gray"
                            style={{ width: 'fit-content' }}
                          >
                            Cancel
                          </button>
                          <button
                            onClick={callFlagCheating}
                            className="btn-main"
                            style={{ width: 'fit-content' }}
                          >
                            Flag cheating
                          </button>
                        </div>
                      </div>
                    </Dialog>
                    
                    {/* SELF SESSION DELETION */}
                    <Dialog
                      open={showSessionDeletionConfirmation}
                      onClose={() => setShowSessionDeletionConfirmation(false)}
                      aria-labelledby="alert-dialog-title"
                      aria-describedby="alert-dialog-description"
                    >
                      <DialogTitle id="alert-dialog-title">
                        {"Confirm Session Deletion"}
                      </DialogTitle>
                      <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                          Are you sure you want to delete this session? 
                          You and the candidate will not be able to access this session anymore.
                          {session &&
                            <p style={{ fontSize: 16, padding: '24px', border: '0.5px solid darkgray', borderRadius: '10px' }}>
                              <b>Candidate Name:</b> {session.candidate_metadata.first_name} {session.candidate_metadata.last_name}
                              <br/><br/>
                              <b style={{ marginRight: 8 }}>Status:</b> 
                                  {session.voicemail_detected ?
                                    <ChipTag variant="default" text="Voicemail" />
                                  :
                                  <>
                                    {(session.status === SessionStatus.NOT_STARTED && session.sent_invite) && (
                                      <ChipTag variant="invited" text="Invited" />
                                    )}
                                    {session.status === SessionStatus.NOT_STARTED && !session.sent_invite && (
                                      <ChipTag variant="not-started" text="Not Started" />
                                    )}
                                    {session.status === SessionStatus.INCOMPLETE &&
                                      <ChipTag variant="in-progress" text="Incomplete" />
                                    }
                                    {session.status === SessionStatus.INVITED &&
                                      <ChipTag variant="invited" text="Invited" />
                                    }
                                    {session.status === SessionStatus.IN_PROGRESS &&
                                      <ChipTag variant="in-progress" text="In Progress" />
                                    }
                                    {session.status === SessionStatus.COMPLETED &&
                                      <ChipTag variant="completed" text="Completed" />
                                    }                                
                                  </>
                                  }
                              <br/><br/>
                              <b style={{ marginRight: 8 }}>Score:</b> 
                                  {!session.voicemail_detected && session.status === SessionStatus.COMPLETED ?
                                      (!session.scorecard || session.scorecard.length === 0 ? 
                                          <ChipTag variant="pending" text="N/A" /> :
                                          ((session.customerOverrideCandidateAction === 'accept' || session.overall_decision === SessionDecision.PASS) ?
                                              <ChipTag variant="accept" text="Accept" /> : 
                                              <ChipTag variant="reject" text="Reject" />))
                                      :
                                      <ChipTag variant="pending" text="Pending" />
                                  }
                                  {session.potentialCheating &&
                                    <ChipTag variant="danger" text="Suspicious" style={{ marginLeft: '8px', backgroundColor: '#FF4D4D', color: 'white' }} />
                                  }             
                            </p>
                          }
                        </DialogContentText>
                      </DialogContent>
                      <DialogActions>
                        <Button className="btn outlined" onClick={() => setShowSessionDeletionConfirmation(false)} color="primary">
                          Close
                        </Button>
                        <Button className="btn outlined danger" onClick={() => {
                          handleDeleteSession();
                          setShowSessionDeletionConfirmation(false);
                        }} color="primary" autoFocus>
                          Delete
                        </Button>
                      </DialogActions>
                    </Dialog>
                    </>
                    }
                  </div> :
                  <div className="d-flex flex-row" style={{ marginLeft: 'auto' }}>
                    <Skeleton variant="text" width={60} height={60} style={{ margin: '0 16px' }}/>
                    <Skeleton variant="text" width={60} height={60} style={{ margin: '0 16px' }} />
                    <Skeleton variant="text" width={60} height={60} style={{ margin: '0 16px' }} />
                  </div>}
                </div>
              </Box>
            </Box>

            {/* Session Details */}
            <Box style={{ position: 'relative', height: 'calc(100vh - 140px)' }}>
              {session.status === SessionStatus.NOT_STARTED && (
                <div style={{ background: '#FFF3E0', padding: '12px 24px', borderBottom: '1px solid #FFE0B2' }}>
                  <div className="d-flex flex-row align-items-center">
                    <WarningIcon style={{ color: '#F57C00', marginRight: '12px' }} />
                    <div>
                      <h4 style={{ margin: '0', color: '#E65100', fontSize: '14px', fontWeight: '600' }}>This interview isn't completed yet</h4>
                      <p style={{ margin: '4px 0 0', fontSize: '14px', color: '#E65100' }}>
                        The candidate hasn't started the interview. Some information may not be available until they complete it.
                      </p>
                    </div>
                  </div>
                </div>
              )}
              <Box className="d-flex flex-row" style={{ height: '100%' }}>
                {/* Main Details */}
                {!sessionEmpty ? <Box className="d-flex flex-col" style={{ flex: 3, padding: '24px', overflow: 'auto' }}>
                  {/* Video / Audio */}
                  <Box className="section">
                    {/* <p style={{ fontWeight: 600 }}>Video Recording</p> */}
                    <div className="section" style={{ borderRadius: '6px', overflow: 'hidden' }}>
                      {(session && session.videoRecordingUrl && session.videoRecordingUrl.includes('loom')) &&
                        <a className="loom-video" href={session.videoRecordingUrl}></a>
                      }
                      {(session && (!session.videoRecordingUrl || (session.videoRecordingUrl && !session.videoRecordingUrl.includes('loom'))) ) &&
                        <>
                          {(videoRecordingLink.length > 0 && (session.client_type === 'desktop' || session.candidate_metadata && session.candidate_metadata.call_type === 'web')) ?
                            <video
                              width="100%"
                              type="video/mp4"
                              controls
                              preload="auto"
                            >
                              <source src={videoRecordingLink} type="video/mp4" />
                            </video>
                            :
                            <div className="d-flex color-empty-bg" style={{ width: '100%', aspectRatio: '16 / 9' }}>
                              <VideocamOffIcon className="m-auto" style={{ color: '#ACACAC' }}/>
                            </div>
                          }
                        </>
                      }
                    </div>
                    {audioRecordingLink && <div className="d-flex flex-col">
                      <h4 className="section-heading">Audio Recording</h4>
                      <audio controls
                        className="audio-player"
                        style={{ background: 'transparent', padding: '4px', width: '50%' }}>
                          <source src={audioRecordingLink} type="audio/mpeg" />
                      </audio>
                    </div>}
                  </Box>

                  {/* Summary */}
                  <Box className="section">
                    <h4 className="section-heading">Summary</h4>
                    {(session.summary === undefined && !session.internalOverrideReject) ? (
                      <p style={{ fontSize: '14px', margin: 0 }}>N/A</p>
                    ) : (
                      typeof session.summary === 'string' ? (
                        <p style={{ fontSize: '14px', margin: 0 }}>{session.summary}</p>
                      ) : (
                        <div>
                          {Object.entries(session.summary).map(([key, points], sectionIndex) => {
                            if (Array.isArray(points)) {
                              return (
                                <div key={sectionIndex} style={{ marginBottom: '16px' }}>
                                  <p style={{ fontSize: '14px', fontWeight: 600, marginBottom: '8px' }}>
                                    {key.charAt(0).toUpperCase() + key.slice(1)}
                                  </p>
                                  <ul style={{ fontSize: '14px', margin: 0, paddingLeft: '20px' }}>
                                    {points.map((point, i) => (
                                      <li key={i}>{point}</li>
                                    ))}
                                  </ul>
                                </div>
                              );
                            }
                            return null;
                          })}
                        </div>
                      )
                    )}
                  </Box>
                  {(session.session_specific_questions && session.session_specific_questions.questions && session.session_specific_questions.questions.length > 0) &&
                    <Box className="section">
                    <h4 className="section-heading">Session-Specific Questions</h4>
                      <ol className="font-inter" style={{ marginLeft: '18px', padding: 0 }}>
                      {session.session_specific_questions.questions.map((session_specific_question, index) => {
                        return (
                          <li key={index} style={{ marginBottom: '10px', fontSize: '14px' }}>{session_specific_question.question}</li>
                        )
                      })}
                      </ol>
                    </Box>
                  }
                </Box> :
                <Box className="d-flex flex-col" style={{ flex: 3, padding: '24px', overflow: 'auto', height: '100%' }}>
                  <Skeleton variant="rectangular" width="100%" height={300} style={{ borderRadius: '12px' }}/>
                  <Skeleton variant="text" width={120} height={24} style={{ marginTop: '12px' }} />
                </Box>
                }

                {/* Side Bar (Transcript, Scorecard, Outputs) */}
                <Box className="d-flex flex-col" style={{ flex: 2, padding: '24px', borderLeft: '1px solid lightgray', overflow: 'hidden'}}>
                  {!sessionEmpty ? <>
                  <Tabs value={currentSideTab} onChange={handleSideTabChange}
                    style={{ background: 'white', marginBottom: '16px' }}
                    sx={{
                      ['& .MuiTabs-indicator']: {
                        backgroundColor: 'transparent',
                      },
                      ['& .MuiTab-root']: {
                        color: "var(--color-main-light)",
                        fontSize: '14px',
                        borderRadius: '8px',
                        padding: '4px 8px',
                        marginRight: '4px',
                        fontFamily: 'Inter',
                        textTransform: 'none',
                        minHeight: '40px'
                      },
                      ['& .MuiTab-root.Mui-selected']: {
                        color: 'var(--color-main)',
                        backgroundColor: 'var(--color-lightgray)',
                        fontWeight: 600
                      },
                    }}>
                    <Tab label="Transcript" 
                      onClick={() => setCurrentMessages(session.sentence_messages || session.messages)} 
                      disabled={session.status === SessionStatus.NOT_STARTED}
                      disableRipple />
                    <Tab label="Scorecard" 
                      onClick={() => setCurrentScorecard(session.scorecard)} 
                      disabled={session.status === SessionStatus.NOT_STARTED}
                      disableRipple />  
                    <Tab label="Outputs" 
                      disabled={session.status === SessionStatus.NOT_STARTED}
                      disableRipple />
                    <Tab label="Questions Asked" 
                      disabled={session.status === SessionStatus.NOT_STARTED}
                      disableRipple />
                    {
                      session.potentialCheating ? 
                        <Tab label="Cheating" 
                          disabled={session.status === SessionStatus.NOT_STARTED}
                          disableRipple />
                      :
                        <Tab label="Details" disableRipple />
                    }
                  </Tabs>
                  <Box style={{ maxHeight: '70vh'}}>
                    {/* Transcript */}
                    { currentSideTab == 0 &&
                      <Box className="section" style={{ overflowY: 'auto', maxHeight: '100%', paddingRight: '30px', scrollbarColor: '#d6d6d6 white' }}>
                        {/* When interview was not conducted in English, add translation tabs */}
                        {session.language !== Language.ENGLISH && currentMessages && currentMessages.length > 0 &&
                        <div className="d-flex flex-row">
                          <TranslateIcon style={{ fontSize: '16px', lineHeight: '24px', marginTop: '5px', marginRight: '8px', color: 'var(--color-main-light)' }} />
                          <Tabs value={currentLanguageTab} onChange={handleLanguageTabChange}
                            style={{ background: 'white'}}
                            sx={{
                              '& .MuiTabs-root': {
                                padding: '4px',
                                height: '24px',
                                minHeight: '24px',
                              },
                              '& .MuiTabs-flexContainer': {
                                border: '1px solid var(--color-midgray)',
                                borderRadius: '8px',
                                width: 'fit-content',
                              },
                              '& .MuiTabs-indicator': {
                                display: 'none',
                              },
                              '& .MuiTab-root': {
                                color: "var(--color-main-light)",
                                fontSize: '12px',
                                borderRadius: '8px',
                                padding: '4px 8px',
                                fontFamily: 'Inter',
                                textTransform: 'none',
                                height: '24px',
                                minHeight: '24px',
                              },
                              '& .MuiTab-root.Mui-selected': {
                                color: 'var(--color-main)',
                                backgroundColor: 'var(--color-primary-bg)',
                                fontWeight: 600
                              },
                            }}>
                            <Tab label={`${session.language} (Original)`} onClick={() => setCurrentMessages(session.sentence_messages || session.messages)} disableRipple />
                            <Tab label={<>
                              <div className="d-flex flex-row">
                              {gettingTranslation ? 'Translating...' : Language.ENGLISH} 
                              {gettingTranslation && <CircularProgress className="my-auto" size={10} style={{ marginLeft: '6px', color: 'var(--color-main)' }} />}
                              </div>
                            </>} onClick={() => translateTranscript(Language.ENGLISH)} disabled={gettingTranslation} />  
                          </Tabs>
                        </div>}
                        {/* Transcript */}
                        {(currentMessages && currentMessages.length > 0) ? 
                          (() => {
                            // Group consecutive messages from the same speaker
                            const groupedMessages = [];
                            let currentGroup = null;
                            
                            currentMessages.forEach((msg) => {
                              if (!currentGroup || currentGroup.role !== msg.message.role) {
                                // Start a new group
                                currentGroup = {
                                  role: msg.message.role,
                                  messages: [msg],
                                  firstTimestamp: msg.createdAt
                                };
                                groupedMessages.push(currentGroup);
                              } else {
                                // Add to existing group
                                currentGroup.messages.push(msg);
                              }
                            });
                            
                            return groupedMessages.map((group, groupIndex) => {
                              const isCandidate = group.role !== 'assistant';
                              const speakerName = group.role === 'assistant' ? 
                                <b>Wayfaster</b> : 
                                <b>{session.candidate_metadata ?
                                  <>
                                  <span style={{ lineHeight: '24px' }}>
                                    {session.candidate_metadata.first_name + ' ' + session.candidate_metadata.last_name}
                                      <ChipTag className="m-auto" variant="default" text="Candidate" style={{ marginLeft: '12px', marginTop: '-2px' }} />
                                    </span>
                                  </>
                                  : 'Candidate' }</b>;
                              
                              return (
                                <div className="d-flex flex-col section" key={groupIndex}>
                                  <h5 className="section-heading">
                                    {speakerName}
                                  </h5>
                                  {group.messages.map((msg, msgIndex) => (
                                    <p className="section-body" style={{ fontSize: '14px', marginBottom: '8px' }} key={msgIndex}>
                                      <span className="color-gray" style={{ fontSize: '12px', fontWeight: 400 }}>
                                        [{new Date((msg.createdAt - currentMessages[0].createdAt)*1000).toISOString().substr(11, 8)}]
                                      </span>{' '}
                                      {msg.message.content}
                                    </p>
                                  ))}
                                </div>
                              );
                            });
                          })() :
                          <div className="d-flex flex-col section">
                            <p className="section-body color-gray" style={{ fontSize: '14px' }}>
                              No transcript found yet.
                            </p>
                          </div>
                        }
                      </Box>
                    }

                    {/* Scorecard */}
                    { currentSideTab == 1 &&
                      <Box className="section" style={{ overflowY: 'auto', height: '100%' }}>
                        {(currentScorecard && currentScorecard.length > 0) ?
                          <>
                            <div className="d-flex flex-row section" style={{ backgroundColor: getColorBgFromScore(sessionScore, DEFAULT_SCALE), width: 'auto', padding: '20px', borderRadius: "20px" }}>
                              <div className="d-flex" style={{ backgroundColor: getColorFromScore(sessionScore, DEFAULT_SCALE), height: '48px', width: '48px', borderRadius: "12px", marginRight: '16px' }}>
                                <h2 className="m-auto" style={{ fontSize: '20px', color: 'white' }}>
                                  { sessionScore }
                                </h2>
                              </div>
                              <h5 className="color-main-light my-auto" style={{ fontWeight: 600 }}>Overall Score</h5>
                            </div>

                            {currentScorecard.map((scorecardItem, index) => (
                              <div className="d-flex flex-col section" key={index}>
                                <div className="d-flex flex-row" style={{ gap: '4px' }}>
                                  <h5 className="section-heading" style={{ marginBottom: '8px', fontWeight: 500 }}>{scorecardItem.signal}</h5>
                                  <h5 className="section-heading" style={{ marginBottom: '8px', display: 'flex', alignItems: 'center', gap: '4px' }}>
                                    <TooltipCustom title={scorecardItem.feedback.overall_sentiment}>
                                      <div style={{ display: 'flex', alignItems: 'center', gap: '4px', fontWeight: 400, fontSize: '13px',color: 'var(--color-subheading-light)' }}>
                                        <InfoOutlinedIcon style={{ fontSize: '14px' }} />
                                        {scorecardItem.score <= 1 ? "Failing" :
                                         scorecardItem.score === 2 ? "Needs Improvement" :
                                         scorecardItem.score === 3 ? "Meets Expectations" :
                                         "Exceeds Expectations"}
                                      </div>
                                    </TooltipCustom>
                                  </h5>
                                </div>
                                <div className="d-flex flex-row" style={{ alignItems: 'center', gap: '16px' }}>
                                  <h5 style={{ margin: '0px 0px', color: getColorFromScore(scorecardItem.score, scorecardItem.scale ? scorecardItem.scale : DEFAULT_SCALE) }}>
                                    {scorecardItem.score}/{scorecardItem.scale ? scorecardItem.scale : DEFAULT_SCALE
                                  }</h5>
                                  <LinearProgress className="my-auto" variant="determinate" value={normalizeScore(scorecardItem.score, scorecardItem.scale ? scorecardItem.scale : DEFAULT_SCALE)}
                                    style={{ backgroundColor: "#DADADA", height: '4px', borderRadius: '4px', width: '128px' }}
                                    sx={{
                                      [`& .MuiLinearProgress-bar`]: {
                                        backgroundColor: getColorFromScore(scorecardItem.score, scorecardItem.scale ? scorecardItem.scale : DEFAULT_SCALE),
                                      },
                                    }} />
                                  <IconButton
                                    style={{ 
                                      margin: '0px',
                                      color: 'black',
                                      fontSize: '14px', 
                                      fontWeight: 500,
                                      padding: '6px 9px 6px 9px',
                                      borderRadius: '8px',
                                    }}
                                    onClick={() => {
                                      setEditScorecardValueIndex(index)
                                      setNewScoreValue(scorecardItem.score)
                                      setEditScoreReason('')
                                    }}
                                  >
                                    <img src={EditIconNewSvg} />
                                  </IconButton>
                                </div>
                                {/* EDIT SCORECARD VALUE DIALOG */}
                                <Dialog
                                  open={editScorecardValueIndex > -1}
                                  onClose={() => {
                                    setEditScorecardValueIndex(-1)
                                    setNewScoreValue(1)
                                    setEditScoreReason('')
                                  }}
                                  aria-labelledby="edit-scorecard-dialog-title"
                                  aria-describedby="edit-scorecard-dialog-description"
                                >
                                  <div style={{padding: "24px", width: "400px"}}>
                                    <h2 style={{fontSize: "18px", fontWeight: "600", marginBottom: "12px", marginTop: "0px"}}>
                                      Edit "{currentScorecard[editScorecardValueIndex]?.signal}"
                                    </h2>

                                    <FormField
                                      label="New score"
                                      select
                                      value={newScoreValue}
                                      onChange={(e) => setNewScoreValue(e.target.value)}
                                      style={{marginBottom: "16px"}}
                                      required
                                    >
                                      <MenuItem value={1}>1</MenuItem>
                                      <MenuItem value={2}>2</MenuItem>
                                      <MenuItem value={3}>3</MenuItem>
                                      <MenuItem value={4}>4</MenuItem>
                                    </FormField>
                                    
                                    <FormField
                                      label="Reason for changing score"
                                      multiline
                                      rows={8}
                                      value={editScoreReason}
                                      onChange={(e) => setEditScoreReason(e.target.value)}
                                      required
                                    />

                                    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end', gap: '12px', marginTop: '8px' }}>
                                      <button
                                        onClick={() => {
                                          setEditScorecardValueIndex(-1)
                                          setNewScoreValue(1)
                                          setEditScoreReason('')
                                        }}
                                        className="btn-main outlined gray"
                                        style={{ width: 'fit-content' }}
                                      >
                                        Cancel
                                      </button>
                                      <button
                                        onClick={() => callEditScorecardValue()}
                                        className="btn-main"
                                        style={{ width: 'fit-content' }}
                                        disabled={!newScoreValue || !editScoreReason}
                                      >
                                        Submit
                                      </button>
                                    </div>
                                  </div>
                                </Dialog>
                                {session.scorecard_version === 2 && scorecardItem.feedback && typeof scorecardItem.feedback === 'object' ? (
                                  <div>
                                    {scorecardItem.feedback.subopinions && scorecardItem.feedback.subopinions.map((subopinion, subIndex) => (
                                      <div key={subIndex} style={{
                                        paddingBottom: '16px',
                                        borderBottom: '1px solid var(--color-midgray)',
                                      }}>
                                        <p style={{
                                          fontSize: '12px', 
                                          marginBottom: '12px', 
                                          fontWeight: 500, 
                                          color: 'var(--color-subheading-light)',
                                          lineHeight: '20px'
                                        }}>{subopinion.reasoning}</p>
                                        <div style={{
                                          borderRadius: '8px',
                                          backgroundColor: "#F3F4F6",
                                          padding: '12px',
                                        }}>
                                          <div style={{
                                            fontSize: '12px',
                                            fontWeight: 500,
                                            margin: subopinion.supporting_quotes_from_candidate?.length ? '0 0 12px 0' : 0,
                                            color: 'var(--color-subheading)'
                                          }}>{subopinion.supporting_quotes_from_candidate?.length ? 'Quotes' : 'No supporting quotes'}</div>
                                          {subopinion.supporting_quotes_from_candidate.map((quote, quoteIndex) => (
                                            <div key={quoteIndex}>
                                              <p style={{fontSize: '12px', fontWeight: 400, margin: '0px', lineHeight: '20px'}}>
                                                "{quote.quote}"
                                                <span style={{fontSize: '12px', color: '#666', fontWeight: 400, lineHeight: '20px'}}> - <a href="#" onClick={(e) => {e.preventDefault(); document.querySelector('.audio-player').currentTime = parseInt(quote.timestamp.includes('T') ? quote.timestamp.split('T')[1].split('.')[0] : quote.timestamp)}} style={{color: '#999', textDecoration: 'underline'}}>{Math.floor(parseInt(quote.timestamp.includes('T') ? quote.timestamp.split('T')[1].split('.')[0] : quote.timestamp)/60)}:{(parseInt(quote.timestamp.includes('T') ? quote.timestamp.split('T')[1].split('.')[0] : quote.timestamp)%60).toString().padStart(2,'0')}</a></span>
                                              </p>
                                              {quoteIndex < subopinion.supporting_quotes_from_candidate.length - 1 && (
                                                <div style={{
                                                  height: '1px',
                                                  backgroundColor: 'var(--color-midgray)',
                                                  margin: '12px 0'
                                                }} />
                                              )}
                                            </div>
                                          ))}
                                        </div>
                                      </div>
                                    ))}
                                  </div>
                                ) : (
                                  <p className="section-body color-gray" style={{ fontWeight: 500, fontSize: '12px' }}>
                                    {scorecardItem.feedback}
                                  </p>
                                )}
                              </div>
                            ))}
                          </>
                          :
                          <div className="d-flex flex-col section">
                            {session.internalOverrideReject && session.internalOverrideRejectReason ? (
                              <div className="automatic-rejection-alert">
                                <Alert severity="error" icon={<ErrorOutlineIcon fontSize="inherit" />}>
                                  <AlertTitle>Automatic Rejection</AlertTitle>
                                  <strong>{session.internalOverrideRejectReason}</strong>
                                </Alert>
                              </div>
                            ) : (
                              <p className="section-body color-gray" style={{ fontSize: '14px' }}>
                                No scorecard found yet.
                              </p>
                            )}
                          </div>

                        }
                      </Box>
                    }
                    {/* Questions Completed */}
                    { currentSideTab == 3 &&
                      <Box className="section" style={{ overflowY: 'auto', height: '100%' }}>
                        {(session.metadata && session.metadata.interview_config && session.metadata.interview_config.questions) ? (
                          <>
                            <div className="d-flex flex-row section" style={{ 
                              backgroundColor: getColorBgFromScore(
                                Math.round((session.parrot_text_index + 1) / session.metadata.interview_config.questions.length * 100), 
                                100
                              ),
                              width: 'auto', 
                              padding: '20px', 
                              borderRadius: "20px" 
                            }}>
                              <div className="d-flex" style={{ 
                                backgroundColor: getColorFromScore(
                                  Math.round((session.parrot_text_index + 1) / session.metadata.interview_config.questions.length * 100), 
                                  100
                                ), 
                                height: '48px', 
                                width: 'auto', 
                                minWidth: '48px', 
                                borderRadius: "12px", 
                                marginRight: '16px', 
                                padding: '0 8px' 
                              }}>
                                <h2 className="m-auto" style={{ fontSize: '16px', color: 'white', whiteSpace: 'nowrap' }}>
                                  {session.parrot_text_index + 1} / {session.metadata.interview_config.questions.length}
                                </h2>
                              </div>
                              <h5 className="color-main-light my-auto">Questions Asked</h5>
                            </div>

                            {session.metadata.interview_config.questions.map((question, index) => (
                              <div className="d-flex flex-col section" key={index}>
                                <div className="d-flex flex-row align-items-center">
                                  <Checkbox 
                                    checked={index <= session.parrot_text_index} 
                                    disabled 
                                    style={{padding: '0px', marginRight: '12px'}}
                                  />
                                  <h5 className={index <= session.parrot_text_index ? 'section-heading color-main' : 'section-heading color-gray'} style={{ margin: 0 }}>
                                    {question.question}
                                  </h5>
                                </div>
                              </div>
                            ))}
                          </>
                        ) : (
                          <div className="d-flex flex-col section">
                            <p className="section-body color-gray" style={{ fontSize: '14px' }}>
                              No questions found.
                            </p>
                          </div>
                        )}
                      </Box>
                    }

                    {/* Outputs */}
                    { currentSideTab == 2 &&
                      <Box className="section" style={{ overflowY: 'auto', height: '100%' }}>
                        {(session.metadata && session.metadata.interview_config && (session.structured_outputs || session.metadata.interview_config.found_structured_outputs)) ?
                          renderStructuredOutputs(session.structured_outputs || session.metadata.interview_config.found_structured_outputs)
                          :
                          renderStructuredOutputs(null)
                        }
                      </Box>
                    }

                    {/* Form Details */}
                    { !session.potentialCheating && currentSideTab == 4 &&
                      <Box className="section" style={{ overflowY: 'auto', height: '100%' }}>
                        {session.candidate_metadata ? (
                          <>
                            {/* Basic Fields */}
                            {Object.entries(session.candidate_metadata).map(([key, value]) => {
                              // Skip additional_fields since it's handled separately
                              if (key === 'additional_fields') return null;
                              
                              // Skip id since it's internal
                              if (key === 'id') return null;

                              if (key === 'first_name' || key === 'last_name') {
                                return null;
                              }

                              const label = key.split('_')
                                .map(word => word.charAt(0).toUpperCase() + word.slice(1))
                                .join(' ');

                              return (
                                <div key={key} className="d-flex flex-col section">
                                  <h5 className="section-heading color-main-light">{label}</h5>
                                  <p className="section-body">{value}</p>
                                </div>
                              );
                            })}

                            {/* Additional Fields */}
                            {session.candidate_metadata.additional_fields && Object.entries(session.candidate_metadata.additional_fields).map(([key, value]) => {
                              console.log("key", key, "value", value);
                              if (key === 'resume') {
                                return (
                                  <div key={key} className="d-flex flex-col section">
                                    <button
                                      className="btn-main"
                                      style={{ textAlign: 'left', width: 'fit-content' }}
                                      onClick={() => {
                                        fetch(`${proxyEndpoint}/generate_resume_link`, {
                                          method: 'POST',
                                          headers: {
                                            'Content-Type': 'application/json'
                                          },
                                          body: JSON.stringify({
                                            file_key: value
                                          })
                                        })
                                        .then(response => response.json())
                                        .then(data => {
                                          if (data.url) {
                                            window.open(data.url, '_blank');
                                          }
                                        })
                                        .catch(error => {
                                          console.error('Error generating resume link:', error);
                                        });
                                      }}
                                    >
                                      View Resume
                                    </button>
                                  </div>
                                );
                              }

                              const label = key.split('_')
                                .map(word => word.charAt(0).toUpperCase() + word.slice(1))
                                .join(' ');

                              return (
                                <div key={key} className="d-flex flex-col section">
                                  <h5 className="section-heading color-main-light">{label}</h5>
                                  <p className="section-body">{value}</p>
                                </div>
                              );
                            })}
                          </>
                        ) : (
                          <div className="d-flex flex-col section">
                            <p className="section-body color-gray" style={{ fontSize: '14px' }}>
                              No form details found.
                            </p>
                          </div>
                        )}
                      </Box>
                    }

                    {/* Cheating */}
                    { session.potentialCheating && currentSideTab == 4 &&
                       <Box className="section" style={{ overflowY: 'auto', height: '100%' }}>
                         <div className="section-body color-gray" style={{ fontSize: '14px', whiteSpace: 'pre-wrap' }}>
                           {session.potentialCheatingReason}
                         </div>
                       </Box>
                     }
                  </Box>
                  </> :
                  <Box className="d-flex flex-row" style={{ marginTop: '12px', gap: '12px' }}>
                    <Skeleton variant="text" width={90} height={40} />
                    <Skeleton variant="text" width={90} height={40} />
                    <Skeleton variant="text" width={90} height={40} />
                    <Skeleton variant="text" width={90} height={40} />
                  </Box>
                  } 
                </Box>
              </Box>
            </Box>
          </Box>
      </Box>

      
      {/* Sidebar */}
      {activeSidebar && <div style={{ height: '100vh', width: '100vw', opacity: 0.7, background: 'black', zIndex: 5000, position: 'fixed' }}></div>}
      <div
        className={`d-flex flex-col sidebar-right ${
          activeSidebar ? "sidebar-right-show" : ""
        }`}
        style={{ padding: "12px", zIndex: 5005 }}
      >
        <CloseIcon
          className="color-gray clickable"
          style={{
            marginLeft: "auto",
            fontSize: "24px",
            position: "absolute",
            top: "12px",
            right: "12px",
          }}
          onClick={() => {
            setActiveSidebar(false);
          }}
        />
        <CandidateProfile candidate={candidate} compact={true} sessionId={session_id}></CandidateProfile>
      </div>
      {/* Message Drawer */}
      {process.env.REACT_APP_INBOX_TAB_ENABLED === "true" && 
        <MessageDrawer
          candidateId={selectedCandidate.id}
          candidateName={selectedCandidate.first_name + " " + selectedCandidate.last_name}
          candidatePhone={selectedCandidate.phone}
          messages={messages}
          open={showMessageDrawer}
          onClose={() => setShowMessageDrawer(false)}
          platform={selectedCandidate.platform}
          interviewConfigId={interview_id}
          conversation={conversation}
          refetchConversation={() => fetchConversation(selectedCandidate.id)}
        />
      }
    </Box>
  )
}


export const Settings = () => {
  const { user, isAuthenticated, isLoading } = useAuth0();
  const navigate = useNavigate();
  const location = useLocation();
  const [interviews, setInterviews] = useState([])
  const [interviewerUser, setInterviewerUser] = useState(null);
  const [showApiKey, setShowApiKey] = useState(false);
  const [showAddTeamMemberModal, setShowAddTeamMemberModal] = useState(false);
  const [refreshTeam, setRefreshTeam] = useState(false);
  const [currentTeam, setCurrentTeam] = useState({});
  const [teamMembers, setTeamMembers] = useState([]);
  const [addTeamEmailCount, setAddTeamEmailCount] = useState(1);
  const [addTeamEmail, setAddTeamEmail] = useState('');
  const [addTeamAccess, setAddTeamAccess] = useState('full');
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMsg, setSnackbarMsg] = useState('');
  const [inviteSent, setInviteSent] = useState(false)

  const [getInterviewsCalled, setGetInterviewsCalled] = useState(false);

  const [teams, setTeams] = useState([]);

  const [accountOpen, setAccountOpen] = useState(false);
  const [interviewAccordionOpen, setInterviewAccordionOpen] = useState(true);
  const knitRef = useRef(null);
  // Backend call to generate & get the authSessionToken
  const newSessionFn = (e) => {
    e?.preventDefault();
    // Hit the backend to generate authSessionToken
    if(currentTeam && currentTeam._id && user) {
      fetch(proxyEndpoint + '/knit-create-session', {
        method: "POST",
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          originOrgId: currentTeam._id.$oid,
          originOrgName: currentTeam.name,
          originUserEmail: user.email,
          originUserName: user.name
        })
      })
      .then(res => {
        if (!res.ok) {
          throw new Error('Network response was not ok');
        }
        return res.json();
      })
      .then((r) => {
        // UI Auth component won't open with an empty session token
        if (knitRef?.current && r.msg && r.msg.token) {
          knitRef.current.setAttribute("authsessiontoken", r.msg.token);
          // Set skipIntro attribute to disable the introduction screen
          // knitRef.current.setAttribute("skipIntro",'');
        } else {
          console.error('Invalid response or missing token');
        }
      })
      .catch((err) => {
        console.error('Error in knit-create-session:', err);
      });
    }
  };  

  // Upon finishing the integration flow
  const onFinishFn = (e) => {
    e.preventDefault();
    console.log( e["detail"]["integrationDetails"]);
    if (currentTeam && currentTeam._id && e?.detail?.integrationDetails?.integrationId) {
      fetch(proxyEndpoint + '/update-knit-integration-id', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          teamId: currentTeam._id.$oid,
          integrationId: e.detail.integrationDetails.integrationId
        })
      })
      .then(res => {
        if (!res.ok) {
          throw new Error('Failed to update Knit integration ID');
        }
        setCurrentTeam({...currentTeam, knit_integration_id: e.detail.integrationDetails.integrationId})
        return res.json();
      })
      .catch(err => {
        console.error('Error updating Knit integration ID:', err);
      });
    }
  };

  // Upon deactivate of integration
  const onDeactivateFn = (e) => {
    e.preventDefault();
    console.log( e["detail"]["integrationDetails"]);
  };

  // Upon closing of the knit-auth component
  const onKnitCloseFn = (e) => {
    e.preventDefault();
    console.log( e["detail"]["knitClosed"]);
  };

  useEffect(() => {
    if(!currentTeam || !user) {
      return
    }
    
    console.log('knitRef', knitRef)
     // Assign functions to event listeners for onNewSession and onFinish events.
    knitRef.current?.addEventListener("onNewSession",newSessionFn);
    knitRef.current?.addEventListener("onFinish",onFinishFn);
    knitRef.current?.addEventListener("onDeactivate",onDeactivateFn);
    knitRef.current?.addEventListener("onKnitClose", onKnitCloseFn);
    // Set the token once the component has mounted
    newSessionFn();
    return () => {
    // Remove events listeners on unmount
      knitRef.current?.removeEventListener("onNewSession", newSessionFn);
      knitRef.current?.removeEventListener("onFinish",onFinishFn);
      knitRef.current?.removeEventListener("onDeactivate", onDeactivateFn);
      knitRef.current?.removeEventListener("onKnitClose", onKnitCloseFn);
    };
  }, [currentTeam, user]);
  
  const [showBullhornModal, setShowBullhornModal] = useState(false);
  const [bullhornClientId, setBullhornClientId] = useState('');
  const [bullhornClientSecret, setBullhornClientSecret] = useState('');
  const [bullhornUsername, setBullhornUsername] = useState('');
  const [bullhornPassword, setBullhornPassword] = useState('');
  const [bullhornInterviewLinkCustomField, setBullhornInterviewLinkCustomField] = useState('customText1');
  const [bullhornConnectionError, setBullhornConnectionError] = useState('');
  const accountRef = useRef(null);
  
  const thumbVDark = ({ style, ...props }) => (
    <div style={{ ...style, backgroundColor: 'rgba(255, 255, 255, 0.2)', borderRadius: '10px', cursor: 'pointer' }} {...props} />
  );

  const handleAccountToggle = () => {
    setAccountOpen((prevOpen) => !prevOpen);
  };
  const { logout } = useAuth0();
  const handleAccountClose = (event) => {
    if (accountRef.current && accountRef.current.contains(event.target)) {
      return;
    }
    setAccountOpen(false);
  };
  
  function interviewSort(a, b) {
    // top_val comes first
    if (a.type && a.type == 'template') return 1;
    if (b.type && b.type == 'template') return -1;
    // neither matches `value` - compare them normally
    else return 0;
  }

  const ACCESS_OPTIONS = [
    {name: 'Full access', val: 'full'},
    // {name: 'Read only', val: 'read'}
  ]

  useEffect(() => {
    if(user && !isLoading) {
      var userId = user.email
      const overrideUserLocal = localStorage.getItem('globalOverrideUser')
      if(overrideUserLocal != null) {
        userId = overrideUserLocal
      }
      axios.post(proxyEndpoint + '/me', { userId: userId })
      .then(response => {
        setInterviewerUser(response.data);
      })
      .catch(error => {
        console.error(error);
      });

      const teamId = localStorage.getItem('teamId')
      api.getTeam({ userId, teamId }).then((response) => {
        let team = response.data;
        setCurrentTeam(team)
        setTeamMembers(team.members)
        setRefreshTeam(false);
      })
      .catch(error => {
        console.error(error);
      });
    }
  }, [user, isLoading, refreshTeam]);

  useEffect(() => {
    if(!user || !isAuthenticated) {
      return
    }

    if(Object.keys(currentTeam).length > 0 && teams.length > 0) {
      return
    }

    const teamId = localStorage.getItem('teamId')
    console.log('teamId', teamId)
    if(!teamId) {
      navigate('/')
      return
    } else {
      if(Object.keys(currentTeam).length > 0) {
        return
      }
      var userId = user.email
      const overrideUser = localStorage.getItem('globalOverrideUser')
      if(overrideUser) {
        userId = overrideUser
      }

      api.getTeams({ userId: userId }).then((res) => {
        console.log('teams', res.data)
        setTeams(res.data)
      })
    }

    if(user && !getInterviewsCalled) {
      var userId = user.email
      var overrideUserLocal = localStorage.getItem('globalOverrideUser')
      if(overrideUserLocal != null) {
        userId = overrideUserLocal
      }
      const teamId = localStorage.getItem('teamId')
      if(!teamId) {
        navigate('/')
        return
      }
      api.getInterviews(userId, teamId)
      .then((response) => {
        // setInterviews(response.reverse())
        setInterviews(response.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt)))        
        setGetInterviewsCalled(true)
      })
    }
  }, [user, isAuthenticated, currentTeam, teams, setCurrentTeam, setTeams])
  
  const toggleShowApiKey = () => {
    setShowApiKey(!showApiKey);
  };

  const generateApiKey = () => {
    console.log('generateApiKey')
    var userId = user.email
    const overrideUserLocal = localStorage.getItem('globalOverrideUser')
    if(overrideUserLocal != null) {
      userId = overrideUserLocal
    }
    axios.post(proxyEndpoint + '/generate_api_key', { teamId: currentTeam._id.$oid })
      .then(response => {
        if(response.data.api_key) {
          setCurrentTeam({...currentTeam, api_key: response.data.api_key, public_team_id: response.data.public_team_id});
          setSnackbarMsg('API Key generated successfully');
          setOpenSnackbar(true);
        }
      })
      .catch(error => {
        console.error(error);
      });
  }

  const saveWebhookUrl = () => {
    console.log('saveWebhookUrl')
    var userId = user.email
    const overrideUserLocal = localStorage.getItem('globalOverrideUser')
    if(overrideUserLocal != null) {
      userId = overrideUserLocal
    }
    axios.post(proxyEndpoint + '/update_webhook_url', { teamId: currentTeam._id.$oid, webhookUrl: currentTeam.webhook_url })
    .then(response => {
      console.log('Webhook URL saved successfully');
      if(response.data.webhook_url) {
        setCurrentTeam({...currentTeam, webhook_url: response.data.webhook_url});
        setSnackbarMsg('Team Webhook URL saved successfully');
        setOpenSnackbar(true);
      }
    })
    .catch(error => {
      console.error(error);
    });
  }

  const callDeleteTeamMember = (memberId) => {
    let teamId = currentTeam._id.$oid;
    let userId = user.email;
    const overrideUserLocal = localStorage.getItem('globalOverrideUser')
    if(overrideUserLocal != null) {
      userId = overrideUserLocal
    }
    api.deleteTeamMember({ memberId, teamId, userId }).then((response) => {
      setSnackbarMsg(`Successfully deleted team member.`);
      setOpenSnackbar(true);
      setRefreshTeam(true);
    })
    .catch(error => {
      console.error(error);
      setSnackbarMsg(error.response.data.error);
      setOpenSnackbar(true);
    });

  }

  const callInviteTeamMember = (member_id, access) => {
    let memberId = member_id;
    let teamId = currentTeam._id.$oid;
    let userId = user.email;
    const overrideUserLocal = localStorage.getItem('globalOverrideUser')
    if(overrideUserLocal != null) {
      userId = overrideUserLocal
    }
    let role = access;
    api.inviteTeamMember({ memberId, teamId, userId, role }).then((response) => {
      setSnackbarMsg(`Successfully invited team member.`);
      setOpenSnackbar(true);
      setRefreshTeam(true);
      setInviteSent(true)
    })
    .catch(error => {
      console.error(error);
      setSnackbarMsg(error.response.data.error);
      setOpenSnackbar(true);
    });
  }

  const teamClicked = (teamId) => {
    if (currentTeam._id.$oid != teamId) {
      const new_team = teams.find((t) => t._id.$oid == teamId);
      localStorage.setItem('teamId', new_team._id.$oid)
      setCurrentTeam(new_team)
      navigate('/interviews')
    }
  }

  return (<>
    {/* Side Nav (TODO REFACTOR: avoid unnecessary re-render of component) */}
    {/* <Sidebar user={user} isAuthenticated={isAuthenticated} isLoading={isLoading} onNavigate={() => {}}></Sidebar> */}

    <div style={{ padding: '24px 32px', height: 'fit-content', width: '100%', background: 'white' }}>
      <h3 className="section" style={{ marginTop: 0 }}>Settings</h3>
      <Snackbar
        open={openSnackbar}
        autoHideDuration={6000}
        onClose={() => {setOpenSnackbar(false)}}
        message={snackbarMsg}
      />

      {/* Teams */}

      <div className="d-flex flex-col mx-auto" style={{ paddingTop: '24px', paddingBottom: '80px' }}>
          {/* Interview Questions Display */}
            <div className="d-flex flex-row section">
              <div className="d-flex flex-col">
                <h3 className="section-heading" style={{ marginTop: 0 }}>Your Team</h3>
                <p className="color-gray section" style={{ fontSize: 14, marginTop: 0 }}>Manage your existing team.</p>
              </div>
              <button className="btn-main d-flex my-auto" style={{ marginLeft: 'auto', marginRight: '16px' }}
                  onClick={() => setShowAddTeamMemberModal(true)}>
                  <Add className="my-auto" fontSize="24px"/>
                  Add Team Member
                </button>
            </div>

            <Paper style={{ margin: 'auto', width: '50%', boxShadow: 'none', borderRadius: '9px', border: '1px solid #D9D9D9' }}>
              <Grid container>
                <TableContainer style={{ width: '100%', margin: 0 }}>
                  <Table style={{ margin: 0}}>
                    <TableHead style={{ margin: 0}}>
                      <TableRow sx={{
                              '& .MuiTableCell-root': {
                                lineHeight: '20px',
                                backgroundColor: '#FAFAFA',
                                color: 'var(--color-heading)',
                                padding: '12px 16px'
                              }
                            }}>
                        {/* <TableCell>
                          Name
                        </TableCell> */}
                        <TableCell style={{ borderRadius: '9px 0 0 0' }}>
                          Email
                        </TableCell>
                        <TableCell style={{ borderRadius: '0 9px 0 0' }}>
                          {/* Actions */}
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody className="table-rounded-bottom">
                    {teamMembers && teamMembers.map((member, idx) => {
                      return (
                        <TableRow key={idx} sx={{ border: 'none', borderBottom: idx == teamMembers.length - 1 ? 'none' : '1px solid lightgray', padding: '0px', margin: '0px' }}>
                          {/* <TableCell style={{ margin: 0, fontSize: 14, color: '#656565', border: 'none', fontWeight: 500}}>
                            {member.name}
                          </TableCell>                           */}
                          <TableCell style={{  fontSize: 14, color: '#656565', border: 'none', fontWeight: 500}}>{member.user_id}
                              {
                                member.status == 'invited' && <>
                                  <ChipTag variant="in-progress" text="Invited" style={{ width: 'max-content', marginLeft: '12px', marginTop: 'auto', marginBottom: 'auto' }} />
                                </>
                              }
                          </TableCell>
                          <TableCell style={{ fontSize: 14, color: '#656565', border: 'none', fontWeight: 500}}>
                            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end', marginTop: 'auto', marginBottom: 'auto' }}>
                              {(user && member.user_id !== user.email) ?
                                <Button className="btn-main btn-text my-auto"
                                  style={{ border: 'none !important' }}
                                  onClick={() => callDeleteTeamMember(member.user_id)}>
                                    <b style={{ color: "var(--color-reject)" }}>Delete</b>
                                </Button>
                                :
                                <>
                                </>
                              }
                              {/* <Button className="btn-main btn-text my-auto"
                                onClick={() => console.log('pressed edit')}>
                                  <b style={{ color: "var(--color-progress)" }}>Edit</b>
                              </Button> */}
                            </div>
                          </TableCell>
                        </TableRow>
                      )
                    })}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Grid>
            </Paper>
            {/* Add Team Dialog */}

            <Dialog open={showAddTeamMemberModal} maxWidth="md" onClose={() => {
              setAddTeamEmail('')
              setInviteSent(false)
            }}>
              <DialogContent style={{ padding: '20px 24px 0px' }}>
                <h5 className="section-heading">Invite team members
                  <TooltipCustom
                    title={<>
                        <p className="m-auto">
                          Share interviews, sessions, and other team resources with people you collaborate with.
                        </p>
                      </>}
                    style={{ fontSize: '14px', marginLeft: '8px', marginTop: 'auto', marginBottom: 'auto' }}>
                  </TooltipCustom>
                </h5>
                <IconButton
                  aria-label="close"
                  onClick={() => setShowAddTeamMemberModal(false)}
                  sx={{
                    position: 'absolute',
                    right: 8,
                    top: 8,
                  }}
                >
                  <CloseIcon style={{ fontSize: '16px' }} />
                </IconButton>
                <p className="color-gray" style={{ fontSize: 12, margin: 0 }}>Get your projects up and running faster by inviting your team to collaborate.</p>
                <div className="d-flex flex-row" style={{ marginTop: '16px' }}>
                  <div className="d-flex" style={{ flex: '10', marginRight: '16px' }}>
                    <TextField
                      variant="outlined"
                      fullWidth={true}
                      InputProps={{
                        startAdornment: <InputAdornment position="start"><MailOutlineOutlined></MailOutlineOutlined></InputAdornment>,
                      }}
                      value={addTeamEmail}
                      onChange={(e) => {setAddTeamEmail(e.target.value)}}
                    />
                    {/* <p style={{ fontSize: 14, marginLeft: 10, marginTop: 20, fontWeight: '500px' }}>What are follow up questions? Wayfaster assessments are fully dynamic, meaning questions are generated based on what the candidate says. <span style={{ color: '#20C58A'}}>Watch a small demo here to see how it works.</span></p> */}
                  </div>
                  <div className="d-flex" style={{ flex: '2' }}>
                    <TextField
                      select
                      variant="outlined"
                      defaultValue={'full'}
                      value={addTeamAccess}
                      onChange={(e) => {setAddTeamAccess(e.target.value)}}
                    >
                      {ACCESS_OPTIONS.map((access) =>
                        <MenuItem key={access.name} value={access.val}>
                          {access.name}
                        </MenuItem>)}
                    </TextField>
                  </div>
                </div>
              </DialogContent>
              <DialogActions>
                  <div style={{ display : 'flex', width: '100%', padding: '16px'}}>
                    {/* {addTeamEmailCount < 5 && <Button className="btn-main gray btn-text"
                      style={{ marginRight: 'auto', marginTop: 'auto', marginBottom: 'auto', border: 'none !important' }}
                      onClick={() => addTeamMemberInput()}>
                        <Add style={{ fontSize: '16px' }}></Add>
                        Add another
                    </Button>} */}
                    <Button className="btn-main" style={{ height: '40px', padding: '10px 14px', marginLeft: 'auto' }}
                      onClick={() => callInviteTeamMember(addTeamEmail, addTeamAccess)} disabled={inviteSent}>
                        {inviteSent ?
                          <>
                            <MailOutlineOutlined style={{ marginLeft: 'auto', fontSize: '18px', marginRight: '8px' }}></MailOutlineOutlined>
                            Invite Sent
                          </>
                          :
                          <>
                          <MailOutlineOutlined style={{ marginLeft: 'auto', fontSize: '18px', marginRight: '8px' }}></MailOutlineOutlined>
                          Send Invite
                          </>
                        }
                    </Button>
                  </div>
              </DialogActions>
            </Dialog>
          </div>
            <Divider/>
            <h3 className="font-inter" style={{ fontWeight: 'bold' }}>Connect your ATS</h3>
            <p className="font-inter color-gray" style={{ fontSize: '14px' }}>Connect your Applicant Tracking System to streamline your hiring process</p>
            {process.env.REACT_APP_BULLHORN_ENABLED === "true" ? (
              <Typography className="font-inter color-gray" variant="body2" color="textSecondary" sx={{ marginTop: 2, marginBottom: 2, fontSize: '14px' }}>
                Note: If your team is using Bullhorn, please use the "Connect Bullhorn" button below. For all other ATS integrations, please use the "Connect other ATS" button and ensure the interview title in Wayfaster exactly matches the job name in your ATS. 
              </Typography>
            ) : (
              <Typography className="font-inter color-gray" variant="body2" color="textSecondary" sx={{ marginTop: 2, marginBottom: 2, fontSize: '14px' }}>
                Note: For your integrations, ensure the interview title in Wayfaster exactly matches the job name in your ATS. 
              </Typography>
            )}
            {/* <Button
              variant="contained"
              color="primary"
              disabled={!isReady || (currentTeam && currentTeam.merge_account_token)}
              onClick={() => {
                
              }}
              startIcon={<LinkIcon />}
              sx={{
                fontFamily: 'Inter',
                fontSize: '14px',
                backgroundColor: 'black',
                color: 'white',
                '&:hover': {
                  backgroundColor: '#333',
                },
                '&:disabled': {
                  backgroundColor: '#444',
                  color: '#888',
                },
                padding: '10px 20px',
                borderRadius: '8px',
                fontWeight: 'bold',
                textTransform: 'none',
              }}
            >
              {currentTeam && currentTeam.merge_account_token ? 'ATS is Connected' : 'Connect your ATS'}
            </Button> */}
            <div style={{ display: 'flex', gap: '10px' }}>
              <knit-auth ref={knitRef}>
                <button 
                className="btn-main"
                variant="contained"
                color="primary"
                disabled={currentTeam?.knit_integration_id}
                sx={{
                  fontFamily: 'Inter',
                  fontSize: '14px',
                  backgroundColor: 'black',
                  color: 'white',
                  '&:hover': {
                    backgroundColor: '#333',
                  },
                  '&:disabled': {
                    backgroundColor: '#444',
                    color: '#888',
                  },
                  padding: '10px 20px',
                  borderRadius: '8px',
                  fontWeight: 'bold',
                  textTransform: 'none',
                }}              
                slot="trigger">
                  <LinkIcon style={{ marginRight: '6px' }}/>
                  {currentTeam?.knit_integration_id ? 'ATS Connected' : 'Connect to ATS'}
                </button>
              </knit-auth>
              {process.env.REACT_APP_BULLHORN_ENABLED === "true" && (
                <button 
                  className="btn-main"
                  variant="contained"
                  color="primary"
                  disabled={currentTeam?.bullhorn_client_id}
                  onClick={() => {
                    setShowBullhornModal(true);
                  }}
                  sx={{
                    fontFamily: 'Inter',
                    fontSize: '14px',
                    backgroundColor: '#ff6b00',
                    color: 'white',
                    '&:hover': {
                      backgroundColor: '#cc5500',
                    },
                    '&:disabled': {
                      backgroundColor: '#ff6b0066',
                      color: '#ff6b0099',
                    },
                    padding: '10px 20px',
                    borderRadius: '8px',
                    fontWeight: 'bold',
                    textTransform: 'none'
                  }}
                >
                  <LinkIcon style={{ marginRight: '6px' }}/>
                  {currentTeam?.bullhorn_client_id ? 'Bullhorn Connected' : 'Connect to Bullhorn'}
                </button>
              )}
              <Dialog 
                open={showBullhornModal}
                onClose={() => setShowBullhornModal(false)}
                maxWidth="sm"
                fullWidth
              >
                <DialogContent style={{ padding: '20px 24px' }}>
                  <Typography variant="h5" sx={{ fontFamily: 'Inter', fontSize: '24px', fontWeight: 'bold', marginBottom: '16px' }}>
                    Connect to Bullhorn
                  </Typography>
                  <IconButton
                    aria-label="close"
                    onClick={() => setShowBullhornModal(false)}
                    sx={{
                      position: 'absolute',
                      right: 8,
                      top: 8,
                    }}
                  >
                    <CloseIcon style={{ fontSize: '16px' }} />
                  </IconButton>
                  <TextField
                    required
                    fullWidth
                    label="Client ID"
                    margin="normal"
                    value={bullhornClientId}
                    onChange={(e) => setBullhornClientId(e.target.value)}
                    InputProps={{
                      style: { fontFamily: 'Inter' }
                    }}
                    InputLabelProps={{
                      style: { fontFamily: 'Inter' }
                    }}
                  />
                  <TextField
                    required
                    fullWidth
                    label="Client Secret" 
                    margin="normal"
                    value={bullhornClientSecret}
                    onChange={(e) => setBullhornClientSecret(e.target.value)}
                    InputProps={{
                      style: { fontFamily: 'Inter' }
                    }}
                    InputLabelProps={{
                      style: { fontFamily: 'Inter' }
                    }}
                  />
                  <TextField
                    required
                    fullWidth
                    label="API Username"
                    margin="normal" 
                    value={bullhornUsername}
                    onChange={(e) => setBullhornUsername(e.target.value)}
                    InputProps={{
                      style: { fontFamily: 'Inter' }
                    }}
                    InputLabelProps={{
                      style: { fontFamily: 'Inter' }
                    }}
                  />
                  <TextField
                    required
                    fullWidth
                    label="API Password"
                    type="password"
                    margin="normal"
                    value={bullhornPassword}
                    onChange={(e) => setBullhornPassword(e.target.value)}
                    InputProps={{
                      style: { fontFamily: 'Inter' }
                    }}
                    InputLabelProps={{
                      style: { fontFamily: 'Inter' }
                    }}
                  />
                  <hr style={{ margin: '24px 0', border: 'none', borderTop: '1px solid #EAECF0' }} />
                  
                  <p style={{ 
                    color: '#6B7280', 
                    fontSize: '14px', 
                    marginBottom: '16px',
                    fontFamily: 'Inter'
                  }}>
                    Optionally, you can customize which field we use to store Wayfaster interview links on Candidate entities below (the default is customText1).
                  </p>

                  <TextField
                    select
                    fullWidth
                    label="Interview Link Custom Field"
                    margin="normal"
                    value={bullhornInterviewLinkCustomField || 'customText1'}
                    onChange={(e) => setBullhornInterviewLinkCustomField(e.target.value)}
                    InputProps={{
                      style: { fontFamily: 'Inter' }
                    }}
                    InputLabelProps={{
                      style: { fontFamily: 'Inter' }
                    }}
                  >
                    {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map(num => (
                      <MenuItem key={num} value={`customText${num}`}>customText{num}</MenuItem>
                    ))}
                    {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map(num => (
                      <MenuItem key={num+10} value={`customTextBlock${num}`}>customTextBlock{num}</MenuItem>
                    ))}
                  </TextField>
                  {bullhornConnectionError && <p style={{ color: 'red', fontFamily: 'Inter', fontSize: '14px', marginTop: '16px' }}>{bullhornConnectionError}</p>}
                </DialogContent>
                <DialogActions>
                  <div style={{ display: 'flex', width: '100%', padding: '16px'}}>
                    <Button 
                      className="btn-main"
                      style={{ fontSize: '14px', fontWeight: 'bold', padding: '20px 20px', borderRadius: '8px', marginLeft: 'auto' }}
                      onClick={() => {
                        // Check if any required fields are missing
                        if (!bullhornClientId || !bullhornClientSecret || !bullhornUsername || !bullhornPassword) {
                          setBullhornConnectionError('Please fill in all required fields.');
                          return;
                        }

                        api.connectBullhorn({
                          teamId: currentTeam._id.$oid,
                          apiUsername: bullhornUsername,
                          apiPassword: bullhornPassword,
                          clientId: bullhornClientId,
                          clientSecret: bullhornClientSecret,
                          interviewLinkCustomField: bullhornInterviewLinkCustomField
                        })
                        .then(response => {
                          if (response.status === 200) {
                            setCurrentTeam({
                              ...currentTeam,
                              bullhorn_client_id: bullhornClientId,
                              bullhorn_client_secret: bullhornClientSecret,
                              bullhorn_username: bullhornUsername,
                              bullhorn_password: bullhornPassword
                            });
                            setShowBullhornModal(false);

                            setBullhornConnectionError('');
                            setBullhornClientId('');
                            setBullhornClientSecret('');
                            setBullhornUsername('');
                            setBullhornPassword('');
                            
                            setSnackbarMsg('Successfully connected to Bullhorn');
                            setOpenSnackbar(true);
                          }
                        })
                        .catch(error => {
                          setBullhornConnectionError(error.response?.data?.error || "Failed to connect to Bullhorn. Please check your credentials and try again.");
                        });
                      }}
                    >
                      Connect
                    </Button>
                  </div>
                </DialogActions>
              </Dialog>
            </div>
            {currentTeam && currentTeam.merge_account_token && (
              <div style={{ marginTop: '20px' }}>
                <p style={{ fontFamily: 'Inter', fontSize: '14px' }}>You've connected your ATS. Set the stage name in your ATS that corresponds to the Wayfaster interview stage.</p>
                <FormField
                  label="Wayfaster Stage Name"
                  tooltip
                  tooltipTitle="Enter the name of the stage in your ATS that corresponds to Wayfaster interviews."
                  variant="outlined"
                  value={currentTeam.wayfaster_stage_name || ""}
                  onChange={(e) => {
                    setCurrentTeam({
                      ...currentTeam,
                      wayfaster_stage_name: e.target.value
                    });
                  }}
                  inputProps={{ style: { fontFamily: 'Inter', fontSize: '14px' } }}
                />
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => {
                    api.updateWayfasterStageName({
                      userId: user.email,
                      teamId: currentTeam._id.$oid,
                      wayfasterStageName: currentTeam.wayfaster_stage_name
                    })
                    .then((res) => {
                      console.log("Wayfaster stage name updated successfully:", res);
                      setCurrentTeam({
                        ...currentTeam,
                        wayfaster_stage_name: currentTeam.wayfaster_stage_name
                      });
                      setSnackbarMsg("Wayfaster stage name updated successfully");
                      setOpenSnackbar(true);
                    })
                    .catch((err) => {
                      console.error("Failed to update Wayfaster stage name:", err);
                      setSnackbarMsg("Failed to update Wayfaster stage name");
                      setOpenSnackbar(true);
                    });
                  }}
                  sx={{
                    fontFamily: 'Inter',
                    fontSize: '14px',
                    backgroundColor: 'black',
                    color: 'white',
                    '&:hover': {
                      backgroundColor: '#333',
                    },
                    padding: '10px 20px',
                    borderRadius: '8px',
                    fontWeight: 'bold',
                    textTransform: 'none',
                    marginTop: '10px',
                  }}
                >
                  Save Stage Name
                </Button>
              </div>
            )}
            <Divider sx={{ marginTop: '24px' }}/>
            <h3>Developer</h3>
            <p style={{ fontFamily: 'Inter', fontSize: '14px' }}>Settings for interacting with our service via our public API and webhooks.</p>
            <p style={{ fontFamily: 'Inter', fontSize: '14px' }}>Check out our <a href="https://wayfaster.mintlify.app" target="_blank" rel="noopener noreferrer" style={{ color: 'var(--color-main)', textDecoration: 'underline' }}>docs here</a> to see the endpoints and webhook events we support.</p>
            {currentTeam && currentTeam.public_team_id &&
              <>
                <h4>Your Team ID</h4>
                <p style={{ fontFamily: 'Inter', fontSize: '14px' }}>This is your username for interacting with our service via our public API. This never changes and it is specific to your team.</p>            
                <div style={{ display: 'flex', alignItems: 'center' }}>
                <FormField
                  value={currentTeam && currentTeam.public_team_id ? currentTeam.public_team_id : ""}
                  disabled
                  variant="outlined"
                  style={{ flex: 1 }}
                />
                </div>
              </>
            }

            <Divider sx={{ marginTop: '24px' }}/>
            <h3>Your API Key</h3>
            {currentTeam && currentTeam.api_key &&
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <FormField
                  type={showApiKey ? "text" : "password"}
                  value={currentTeam && currentTeam.api_key ? currentTeam.api_key : ""}
                  disabled
                  variant="outlined"
                  style={{ flex: 1 }}
                />
                <button className="btn-main outlined d-flex"
                  style={{ height: '46px', marginLeft: '8px', marginBottom: '16px' }}
                  onClick={toggleShowApiKey}>
                  {showApiKey ? <VisibilityOffIcon className="m-auto"/> : <VisibilityIcon className="m-auto"/>}
                </button>
              </div>
            }
            {(!currentTeam || !currentTeam.api_key) &&
              <p style={{ fontSize: '14px' }}>No API Key found. Generate one.</p>
            }
            <button className="btn-main" onClick={generateApiKey}>
              {currentTeam && currentTeam.api_key ? "Regenerate API Key" : "Generate API Key"}
            </button>

            <Divider sx={{ marginTop: '24px' }}/>
            <h3>Webhook Configuration</h3>
            <FormField
              label="Webhook URL"
              tooltip
              tooltipTitle="Enter the endpoint where we should send interview session start and completion events to. This is set for the entire team."
              variant="outlined"
              value={currentTeam && currentTeam.webhook_url ? currentTeam.webhook_url : ""}
              onChange={(e) => setCurrentTeam({...currentTeam, webhook_url: e.target.value})}
            />
            <button className="btn-main" onClick={() => saveWebhookUrl()}>
              Save
            </button>
    </div>
    
  </>
)
}

export const GettingStarted = () => {
  const navigate = useNavigate();

  return (
    <>
      {/* Side Nav (TODO REFACTOR: avoid unnecessary re-render of component) */}
      {/* <Sidebar user={user} isAuthenticated={isAuthenticated} isLoading={isLoading} onNavigate={() => {}}></Sidebar> */}

      <div style={{ padding: '24px 32px', height: 'fit-content', width: '100%', background: 'white' }}>

        <div className="d-flex flex-col mx-auto" style={{ paddingTop: '24px', paddingBottom: '80px' }}>
          <div className="d-flex flex-row section justify-content-between align-items-center">
            <div className="d-flex flex-col" style={{ flex: 1 }}>
              <h3 className="section-heading" style={{ marginTop: 0, fontSize: '18px', fontWeight: 600, marginBottom: '4px' }}>Discover how Wayfaster works</h3>
              <p className="color-gray section" style={{ fontSize: 14, marginTop: 0, fontWeight: 500 }}>Take a few minutes to discover how the product works.</p>
            </div>
            <div style={{ marginLeft: 'auto' }}>
              <button
                className="btn-main d-flex"
                onClick={() => navigate('/create-interview')}
                style={{
                }}
              >                
                <Add sx={{ color: '#fff', fontSize: '18px' }} />
                <span style={{ color: '#fff', fontSize: '14px', fontWeight: 600, marginRight: '8px' }}>Add Interview</span>
              </button>
            </div>
          </div>

          <div className="video-grid" style={{ display: 'grid', gridTemplateColumns: 'repeat(auto-fill, minmax(250px, 1fr))', gap: '20px' }}>
            {[
              { id: 1, title: "Getting Started: Creating an Interview", url: "https://www.loom.com/embed/461af952d4bd4228946c43c1238767db" },
              { id: 2, title: "Adding People to a Team", url: "https://www.loom.com/embed/a9c921960e114612be30f22268e0bc61" },
              { id: 3, title: "Creating Topics for The Interview", url: "https://www.loom.com/embed/c622212a13dd45e1a346a96199bf83ef" },
              { id: 4, title: "Creating Topics from Scratch", url: "https://www.loom.com/embed/d48a3b390adf4023a3849ba0e730a07c" },
              { id: 5, title: "Creating Scoring Criteria from Scratch", url: "https://www.loom.com/embed/13ad85032afd4740abe719017ec8ae50" },
              { id: 6, title: "Structured Outputs and How to Use Them", url: "https://www.loom.com/embed/cf98ea4b370d40349ce06680a9c1baba" },
              { id: 7, title: "Creating and Sending Interview Links", url: "https://www.loom.com/embed/6f16cb930acb4ad0a9ca5aaca8d8ff9d" },
              { id: 8, title: "Viewing and Sharing Interview Results", url: "https://www.loom.com/embed/8a68e9a2fe6e4d47adb819afbe703fc5" },
              { id: 9, title: "Modifying an Existing Interview", url: "https://www.loom.com/embed/ea15388f56114f08b4e49982dfa93ef9" },
              { id: 10, title: "Improving Your Scoring Model", url: "https://www.loom.com/embed/ead87aa28d154fa6a564de42b5a80b47" },
              { id: 11, title: "Custom Prompting/Interaction With the AI", url: "https://www.loom.com/embed/27942de0bb6c4c638b88de3491633a78" }
            ].map((video) => (
              <div key={video.id} style={{ display: 'flex', flexDirection: 'column' }}>
                <div style={{ position: 'relative', width: '100%', paddingBottom: '56.25%' }}>
                  <iframe
                    src={video.url}
                    frameBorder="0"
                    webkitallowfullscreen="true"
                    mozallowfullscreen="true"
                    allowFullScreen
                    style={{
                      position: 'absolute',
                      top: 0,
                      left: 0,
                      width: '100%',
                      height: '100%',
                      borderRadius: '8px'
                    }}
                  ></iframe>
                </div>
                <p style={{ 
                  marginTop: '10px', 
                  fontSize: '14px', 
                  fontWeight: '500', 
                  textAlign: 'left',
                }}>{video.title}</p>
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
};


export const Logout = () => {
  const { isAuthenticated, logout, isLoading, error } = useAuth0();
  const navigate = useNavigate();

  React.useEffect(() => {
    async function checkUser() {
      if (!isLoading) {
        if(isAuthenticated) {
          localStorage.removeItem('teamId')
          localStorage.removeItem('globalOverrideUser')

          let returnTo = 'https://admin.techinterviewer.ai'
          if (env === 'staging') {
            returnTo = 'https://staging-admin.techinterviewer.ai'
          } else if (env === 'development') {
            returnTo = 'http://localhost:3000'
          }

          logout({
            logoutParams: {
              returnTo: returnTo
            }
          });
        } else {
          navigate('/interviews')
        }
      }
    }

    checkUser();
  }, [isAuthenticated, logout, isLoading, error, navigate]);

  return (
    <div>
      <CircularProgress sx={{ color: 'var(--color-primary)', margin: 'auto' }}/>
    </div>
  )
}

export const Login = () => {
  const { user, isAuthenticated, loginWithRedirect, isLoading, error } = useAuth0();
  const navigate = useNavigate();

  React.useEffect(() => {
    async function checkUser() {
      if (!isLoading) {
        if(isAuthenticated) {
          api.getTeams({ userId: user.email }).then((response) => {
            const teams = response.data
            
            // If no teams, redirect to your-team page
            if (teams.length === 0) {
              navigate('/your-team')
              return
            }

            // Check if onboarding is needed and enabled
            const hasCompletedOnboarding = teams.some(team => team.onboarding?.interview_config_id);
            if (!hasCompletedOnboarding && process.env.REACT_APP_ONBOARDING_ENABLED === "true" && ONBOARDING_WHITELIST.includes(user.email)) {
              navigate('/onboarding')
              return
            }

            let invited_team_id = null
            teams.map((team) => {
              team.members.map((member) => {
                if(member.user_id === user.email && member.status === 'invited') {
                  invited_team_id = team._id.$oid
                  return
                }
              })
              return
            })

            if(invited_team_id) {
              localStorage.setItem('teamId', invited_team_id)
              navigate('/your-team')
              return
            }

            // no invites found. check if active membership on any
            let active_team_id = null
            teams.map((team) => {
              team.members.map((member) => {
                if(member.user_id === user.email && member.status === 'active') {
                  active_team_id = team._id.$oid
                  return
                }
              })
            })

            if(active_team_id) {
              localStorage.setItem('teamId', active_team_id)
              if (process.env.REACT_APP_HOME_TAB_ENABLED === "true") {
                navigate('/home')
              } else {
                navigate('/interviews')
              }
              return
            }
          })
          .catch(error => {
            console.log(error)
            // no teams found with user
            if (error.response?.status === 400) {
              navigate('/your-team')
              return
            }
          })

          const teamId = localStorage.getItem('teamId')
          if(teamId) {
            if (process.env.REACT_APP_HOME_TAB_ENABLED === "true") {
              navigate('/home') 
            } else {
              navigate('/interviews')
            }
            return
          }
        } else {
          loginWithRedirect();
        }
      }
    }

    checkUser();
  }, [isAuthenticated, loginWithRedirect, isLoading, error, navigate]);

  return (
    <div>
      <CircularProgress sx={{ color: 'var(--color-primary)', margin: 'auto' }}/>
    </div>
  )
}

export const HomeRedirect = () => {
  const { user, isAuthenticated, isLoading } = useAuth0();
  const navigate = useNavigate();
  const location = useLocation();

  React.useEffect(() => {
    async function handleRedirects() {
      if (isLoading) {
        return; // Wait until loading is complete
      }
      
      if (!isAuthenticated) {
        navigate('/login'); // Redirect to login if not authenticated
        return;
      }
      
      try {
        const response = await api.getTeams({ userId: user.email });
        const teams = response.data;
        
        // If no teams, redirect to your-team page
        if (teams.length === 0) {
          navigate('/your-team');
          return;
        }

        // Check if onboarding is needed and enabled
        const hasCompletedOnboarding = teams.some(team => team.onboarding?.interview_config_id);
        if (!hasCompletedOnboarding && process.env.REACT_APP_ONBOARDING_ENABLED === "true" && ONBOARDING_WHITELIST.includes(user.email)) {
          navigate('/onboarding');
          return;
        }

        let invited_team_id = null;
        teams.forEach(team => {
          team.members.forEach(member => {
            if (member.user_id === user.email && member.status === 'invited') {
              invited_team_id = team._id.$oid;
            }
          });
        });

        if (invited_team_id) {
          localStorage.setItem('teamId', invited_team_id);
          navigate('/your-team');
          return;
        }

        // no invites found. check if active membership on any
        let active_team_id = localStorage.getItem('teamId');
        if(active_team_id) {
          console.log('team already set in local storage', active_team_id)
          if (process.env.REACT_APP_HOME_TAB_ENABLED === "true") {
            navigate('/home')
          } else {
            navigate('/interviews')
          }
          return
        }

        teams.forEach(team => {
          team.members.forEach(member => {
            if (member.user_id === user.email && member.status === 'active') {
              console.log('setting active team id', team._id.$oid)
              active_team_id = team._id.$oid;
            }
          });
        });

        if (active_team_id) {
          localStorage.setItem('teamId', active_team_id);
        }
        
        const currentPath = location.pathname;
        if(currentPath === '/home') {
          return
        }

        // IMPORTANT: Don't redirect to /home if we're already there to avoid loops
        if (active_team_id && process.env.REACT_APP_HOME_TAB_ENABLED === "true") {
          navigate('/home');
        } else {
          navigate('/interviews');
        }

        // Check if there's a team ID in localStorage as a fallback
        const teamId = localStorage.getItem('teamId');
        if (teamId) {
          if (process.env.REACT_APP_HOME_TAB_ENABLED === "true") {
            navigate('/home');
          } else {
            navigate('/interviews');
          }

          return;
        }
        
        // If we've reached this point, we have no team information, go to team creation
        navigate('/your-team');
        
      } catch (error) {
        console.log(error);
        // no teams found with user
        if (error.response?.status === 400) {
          navigate('/your-team');
        }
      }
    }

    handleRedirects();
  }, [isAuthenticated, isLoading, navigate, user, location.pathname]);

  // Show loading spinner while authentication status is being determined
  return (
    <div className="d-flex" style={{ height: '100vh', width: '100%' }}>
      <CircularProgress sx={{ color: 'var(--color-primary)', margin: 'auto' }}/>
    </div>
  );
}
