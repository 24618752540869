import React, { useState } from 'react';
import { Drawer, Grid, Checkbox, Divider } from '@mui/material';
import ChatBubbleOutlineIcon from '@mui/icons-material/ChatBubbleOutline';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import FormField from '../common/FormField';
import MessageBubble from './MessageBubble';
import MessageVariable from './MessageVariable';
import TooltipCustom from '../common/TooltipCustom';
import EyeIcon from '@mui/icons-material/Visibility';
import MessageEvent from './MessageEvent';
import api from '../../api/api';

const MessageDrawer = React.memo(({ 
  candidateId,
  candidateName,
  candidatePhone,
  open, 
  onClose, 
  messages,
  platform,
  conversation,
  interviewConfigId,
  refetchConversation
}) => {
  const [startingConversation, setStartingConversation] = useState(false);
  const [invitedBy, setInvitedBy] = useState('Wayfaster');
  const [consentChecked, setConsentChecked] = useState(false);
  const [sendMessage, setSendMessage] = useState('');
  const [sendingMessage, setSendingMessage] = useState(false);

  return (
    <Drawer
      anchor="right"
      open={open}
      onClose={onClose}
      sx={{
        '& .MuiDrawer-paper': {
          width: '450px',
          padding: '0px',
          backgroundColor: '#FFFFFF',
          margin: '24px',
          height: 'calc(100% - 48px)',
          borderRadius: '16px',
          boxShadow: '0px 4px 6px -2px rgba(0, 0, 0, 0.05), 0px 10px 15px -3px rgba(0, 0, 0, 0.1)'
        },
        '& .MuiBackdrop-root': {
          backgroundColor: 'rgba(0, 0, 0, 0.3)'
        }
      }}
    >
      <div className="d-flex flex-col" style={{ padding: '16px', borderBottom: '1px solid #E0E0E0' }}>
        <h4 className='m-0 color-gray font-inter' style={{ fontWeight: 400 }}>
          {platform === "WhatsApp" ? <WhatsAppIcon style={{ fontSize: '18px', marginRight: '8px', marginBottom: '-4px' }} /> : <ChatBubbleOutlineIcon style={{ fontSize: '18px', marginRight: '8px', marginBottom: '-4px' }} />} 
          {platform === 'WhatsApp' ? 'WhatsApp' : 'Text'} to <span className='color-main font-inter' style={{ fontWeight: 600 }}>{candidateName}</span> 
          <span className='color-gray font-inter' style={{ marginLeft: '8px', fontSize: '14px', fontWeight: 400 }}>{candidatePhone}</span>
        </h4>
      </div>
      <div className="d-flex flex-col" style={{ padding: '16px', height: '100%', overflowY: 'auto' }}>
        {messages && messages.length > 0 && messages.map((message, index) => (
          message && <MessageBubble
            key={index}
            message={message.body}
            role={message.direction.includes('outbound') ? 'sent' : 'received'} 
            speaker={message.direction.includes('outbound') ? 'Wayfaster' : candidateName}
            status={message.status}
            time={message.date_created.$date ? message.date_created.$date : message.date_created}
          />
        ))}
        {messages && messages.length === 0 && (
          <div className="d-flex flex-col" style={{ padding: '16px' }}>
            <h4 className='m-0 color-gray font-inter' style={{ fontWeight: 400 }}>No messages found.</h4>
          </div>
        )}
      </div>

      {/* Conversation not started */}
      {conversation == null && !startingConversation && <div className="d-flex flex-col" style={{ marginTop: 'auto', padding: '16px' }}>
        <button className="btn-main" variant="contained" color="primary" style={{ marginLeft: 'auto', width: 'fit-content' }} onClick={() => {
          setStartingConversation(true);

        }}>Start conversation <ArrowForwardIcon style={{ fontSize: '18px', marginLeft: '8px' }} /></button>
      </div>}
      {
        startingConversation && <div className="d-flex flex-col" style={{ marginTop: 'auto', padding: '16px' }}>
          <Grid item xs={12}>
                  <FormField
                    required
                    label="Invited by"
                    tooltip
                    tooltipTitle={
                      <>
                        <p className="m-auto">
                          "You've been invited by <MessageVariable variable="invited_by" /> to take an interview..." <br/>(Default is <b style={{ fontWeight: 500, color: 'var(--color-message-variable)' }}>Wayfaster</b>)
                        </p>
                      </>
                    }
                    labelColor="black"
                    placeholder="Wayfaster"
                    value={invitedBy}
                    onChange={(e) => {
                      setInvitedBy(e.target.value);
                    }}
                />
              </Grid>
              <div className="d-flex flex-row" style={{ padding: '0px 8px' }}>
                <Checkbox
                className="my-auto"
                style={{
                  height: 'fit-content',
                }}
                checked={consentChecked}
                onClick={() => {
                  setConsentChecked(!consentChecked);
                }}
                sx={{
                  '&.Mui-checked': {
                    color: 'var(--color-main)',
                  },
                }}
                />
                <p className="color-gray" style={{ padding: '0px 8px', fontSize: 12, marginTop: 0 }}>
                  I agree to have obtained consent from the candidate for receiving SMS notifications & alerts from Wayfaster. Message frequency varies. Message & data rates may apply. Candidates can text HELP to +18445436533 for assistance, or reply STOP to unsubscribe at any time.
                  &nbsp; <a href="https://sessions.wayfaster.com/privacy" target="_blank" rel="noopener noreferrer">Privacy Policy</a>
                </p>
              </div>
              <div className="d-flex flex-row">
                <TooltipCustom title={
                  <>
                    <p className="m-auto" style={{ lineHeight: 1.65 }}>
                      Hi, <b style={{ fontWeight: candidateName ? 600 : 400, color: 'var(--color-message-variable)' }}>{candidateName ? candidateName : <MessageVariable variable="candidate_name" />}</b>! 
                      You've been invited by <b style={{ fontWeight: 600, color: 'var(--color-message-variable)' }}>{invitedBy}</b> to take an interview for a <MessageVariable variable="job_title" /> role: <MessageVariable variable="interview_link" />.
                    </p>
                  </>}>
                  <p className="color-gray d-flex my-auto" style={{ padding: '0px 8px', fontSize: 12, height: 'fit-content', marginLeft: 'auto' }}>
                    <EyeIcon style={{ fontSize: 16, marginRight: 4 }} /> <b>Preview message</b>
                  </p>
                </TooltipCustom>
                <button className="btn-main gray outlined" variant="contained" color="primary" style={{ width: 'fit-content' }} onClick={() => {
                  setStartingConversation(false);
                }}>Cancel</button>
                <button className="btn-main" variant="contained" color="primary" style={{ marginLeft: '16px', width: 'fit-content' }} disabled={!consentChecked} onClick={() => {
                  api.sendTextInviteTemplated({
                    candidateId: candidateId,
                    interviewConfigId: interviewConfigId,
                    invitedBy: invitedBy,
                  }).then((response) => {
                    console.log(response);
                    setStartingConversation(false);
                    refetchConversation();
                  }).catch((err) => {
                    console.log(err);
                    refetchConversation();
                  });
                }}>Send text invitation <ArrowForwardIcon style={{ fontSize: '18px', marginLeft: '8px' }} /></button>
              </div>
        </div>
      }

      {/* Conversation started */}
      {conversation != null && conversation._id && <div className="d-flex flex-col" style={{ marginTop: 'auto', padding: '16px' }}>
        <Divider sx={{ margin: '16px -16px', }} />
        <FormField
          label=""
          multiline
          sx={{
            '& .MuiInputBase-root': {
              height: 'fit-content',
              paddingRight: '40px',
            },
          }}
          placeholder="Type a message..."
          value={sendMessage}
          onChange={(e) => {
            setSendMessage(e.target.value);
          }}
        />
        <button className="btn-main" variant="contained" color="primary" style={{ marginLeft: 'auto', width: 'fit-content' }} 
          disabled={sendMessage.length === 0 || sendingMessage}
          onClick={() => {
            setSendingMessage(true);
            api.sendTextMessage({
              message: sendMessage,
              conversationId: conversation._id,
            }).then((response) => {
              console.log(response);
              setSendMessage('');
              refetchConversation();
            }).catch((err) => {
              console.log(err);
            }).finally(() => {
              setSendingMessage(false);
            });
          }}>{sendingMessage ? 'Sending...' : 'Send text'} <ArrowForwardIcon style={{ fontSize: '18px', marginLeft: '8px' }} /></button>
      </div>}
    </Drawer>
  );
});

export default MessageDrawer;