import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import {
  SessionDetail,
  Login,
  Logout,
  Settings,
  GettingStarted,
  HomeRedirect,
} from "./App";
import { AuthenticationGuard } from "./components/nav/AuthenticationGuard";
import { Auth0ProviderWithNavigate } from "./util/Auth0ProviderWithNavigate";
import CreateInterviewV2 from "./components/interview/CreateInterviewV2";
import Home from "./components/home/Home";
import Analytics from "./components/analytics/Analytics";
import InterviewDetail from "./components/interview/InterviewDetail";
import InterviewDetailList from "./components/interview/InterviewDetailList";
import CandidateDetailPublic from "./pages/CandidateDetailPublic";
import CallToApply from "./pages/CallToApply";
import { Auth0Provider } from "@auth0/auth0-react";
import { createTheme, ThemeProvider } from "@mui/material";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import * as Sentry from "@sentry/react";
import posthog from "posthog-js";
import CandidateProfileFull from "./pages/CandidateProfileFull";
import CandidatesPreVetted from "./pages/CandidatesPreVetted";
import Inbox from "./pages/Inbox";
import YourTeam from "./pages/YourTeam";
import BaseLayout from "./layouts/BaseLayout";
import Onboarding from "./components/onboarding/Onboarding";
posthog.init("phc_WcTIhKTBtZfgo4URTcSni32bkl8oACPOtBZ5t6sYmW9", {
  api_host: "https://us.i.posthog.com",
  person_profiles: "identified_only",
  disable_session_recording: process.env.REACT_APP_ENV === "production" ? false : true
});

Sentry.init({
  dsn: "https://b1c31689814476854f723725e3636880@o4507392418775040.ingest.us.sentry.io/4507392429326336",
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration(),
  ],
  // Performance Monitoring
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

// Override Material UI Theme Font
const customTheme = createTheme({
  typography: {
    fontFamily: "Inter",
  },
});

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <ThemeProvider theme={customTheme}>
      <Router>
        <Auth0ProviderWithNavigate>
          <BaseLayout>
            <Routes>
              <Route path="/" element={<HomeRedirect />} />
              {process.env.REACT_APP_HOME_TAB_ENABLED === "true" && <Route path="/home" element={<AuthenticationGuard component={Home} />} />}
              {process.env.REACT_APP_ANALYTICS_TAB_ENABLED === "true" && <Route path="/analytics" element={<AuthenticationGuard component={Analytics} />} />}
              {process.env.REACT_APP_INBOX_TAB_ENABLED === "true" && <Route path="/inbox" element={<AuthenticationGuard component={Inbox} />} />}
              <Route
                path="/candidate/:id"
                element={<AuthenticationGuard component={CandidateProfileFull} />}
              />
              {process.env.REACT_APP_DIRECT_HIRING_ENABLED === "true" && <Route
                path="/candidates"
                element={<AuthenticationGuard component={CandidatesPreVetted} />}
              />}
              <Route
                path="/your-team"
                element={<AuthenticationGuard component={YourTeam} />}
              />
              <Route
                path="/getting-started"
                element={<AuthenticationGuard component={GettingStarted} />}
              />              
              <Route path="/interviews" element={<AuthenticationGuard component={InterviewDetailList} />} />
              <Route path="/public/sessions/:session_id" element={<SessionDetail isPubliclyViewable={true} />} />
              <Route path="/public/candidate/:candidate_id" element={<CandidateDetailPublic />} />
              <Route path="/interviews/:interview_id" element={<AuthenticationGuard component={InterviewDetail} />} />
              <Route
                path="/interviews/:interview_id/sessions/:session_id"
                element={<AuthenticationGuard component={SessionDetail} />}
              />
              <Route path="/call-to-apply/:interview_id" element={<CallToApply />} />
              <Route path="/logout" element={<Logout />} />
              <Route path="/settings" element={<AuthenticationGuard component={Settings} />} />
              <Route path="/login" element={<Login />} />

              {/* v2 interview creation routes */}
              <Route path="/create-interview" element={<AuthenticationGuard component={CreateInterviewV2} />} />
              <Route path="/onboarding" element={<AuthenticationGuard component={Onboarding} />} />
            </Routes>
          </BaseLayout>
        </Auth0ProviderWithNavigate>
      </Router>
    </ThemeProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
