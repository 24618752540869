import { Drawer, Typography, Box, Switch, Slider, TextField, IconButton, Button, FormControl, Select, MenuItem } from '@mui/material';
import { Clear, Add } from '@mui/icons-material';
import { useState, useRef, useEffect } from 'react';

const AdvancedDrawer = ({ 
    showAdvancedDrawer, 
    onClose, 
    currentQuestion,
    onToggleAdvanced,
    onChangeDepth,
    onChangeHelpfulness,
    onEditStoppingPoint,
    onRemoveStoppingPoint,
    onAddStoppingPoint,
    onChangeQuestionType,
    onChangeRoleplayContext
}) => {
    // Track input values locally
    const [inputValues, setInputValues] = useState(currentQuestion.stopping_points || []);
    // Add local state for roleplay context with interviewer and candidate roles
    const [roleplayContextValue, setRoleplayContextValue] = useState({
        interviewer_role: currentQuestion.roleplay_context?.interviewer_role || '',
        candidate_role: currentQuestion.roleplay_context?.candidate_role || '',
        difficulty: currentQuestion.roleplay_context?.difficulty || 'medium'
    });

    const difficultyToIntMap = {
        "easy": 1,
        "medium": 2,
        "hard": 3
    };
    const intToDifficultyMap = {
        1: "easy",
        2: "medium",
        3: "hard"
    };
    
    // Create a single debounce function
    const debounceTimeout = useRef(null);
    // Create a separate debounce function for roleplay context
    const roleplayDebounceTimeout = useRef(null);
    
    // Function to handle debounced updates
    const handleDebouncedUpdate = (value, index) => {
        // Clear any existing timeout
        if (debounceTimeout.current) {
            clearTimeout(debounceTimeout.current);
        }
        
        // Set a new timeout
        debounceTimeout.current = setTimeout(() => {
            onEditStoppingPoint(value, index);
        }, 500);
    };
    
    // Function to handle debounced roleplay context updates
    const handleDebouncedRoleplayUpdate = (updatedContext) => {
        // Clear any existing timeout
        if (roleplayDebounceTimeout.current) {
            clearTimeout(roleplayDebounceTimeout.current);
        }
        
        // Set a new timeout
        roleplayDebounceTimeout.current = setTimeout(() => {
            onChangeRoleplayContext(updatedContext);
        }, 500);
    };
    
    // Update local state when currentQuestion changes
    useEffect(() => {
        setInputValues(currentQuestion.stopping_points || []);
        setRoleplayContextValue({
            interviewer_role: currentQuestion.roleplay_context?.interviewer_role || '',
            candidate_role: currentQuestion.roleplay_context?.candidate_role || '',
            difficulty: currentQuestion.roleplay_context?.difficulty || 'medium'
        });
    }, [currentQuestion.stopping_points, currentQuestion.roleplay_context]);

    return (
    <Drawer
        anchor="right"
        open={showAdvancedDrawer}
        onClose={onClose}
        sx={{
        '& .MuiDrawer-paper': {
            width: '450px',
            padding: '24px',
            backgroundColor: '#FFFFFF',
            margin: '24px',
            height: 'calc(100% - 48px)',
            borderRadius: '16px',
            boxShadow: '0px 4px 6px -2px rgba(0, 0, 0, 0.05), 0px 10px 15px -3px rgba(0, 0, 0, 0.1)'
        },
        '& .MuiBackdrop-root': {
            backgroundColor: 'rgba(0, 0, 0, 0.3)'
        }
        }}
    >
        <div className="advanced-section">
        <Typography variant="h6" sx={{ fontWeight: 600, fontSize: '18px', color: '#111827', mb: 1 }}>
            Advanced Settings
        </Typography>
        <Box sx={{ display: 'flex', alignItems: 'center', mb: 3 }}>
            <Typography variant="subtitle2" sx={{ color: '#6B7280', fontSize: '14px', flex: 1 }}>
                Manual configuration of advanced settings is currently <span style={{ fontWeight: 600, color: currentQuestion.advanced_enabled ? '#22C55E' : '#EF4444' }}>{currentQuestion.advanced_enabled ? 'enabled' : 'disabled'}</span>
            </Typography>
            <Switch 
                checked={currentQuestion.advanced_enabled || false}
                onChange={(e) => {
                    onToggleAdvanced(e.target.checked);
                }}
                sx={{
                    '& .MuiSwitch-switchBase.Mui-checked': {
                        color: '#258462',
                        '&:hover': {
                            backgroundColor: 'rgba(37, 132, 98, 0.08)',
                        },
                    },
                    '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
                        backgroundColor: '#258462',
                    },
                    '& .MuiSwitch-thumb': {
                        width: 16,
                        height: 16,
                        margin: '2px'
                    }
                }}
            />
        </Box>
        
        <Box sx={{
            bgcolor: '#F9FAFB',
            p: 2,
            borderRadius: '8px',
            mb: 3,
            border: '1px solid #E5E7EB'
        }}>
            <Typography variant="subtitle1" sx={{ 
            color: '#374151',
            fontWeight: 500,
            }}>
            Question
            </Typography>
            <Typography sx={{
            color: '#6B7280',
            fontSize: '14px',
            lineHeight: 1.5
            }}>
            {currentQuestion.question}
            </Typography>
        </Box>

        <Typography variant="h6" sx={{ fontWeight: 600, fontSize: '16px', color: '#111827', mb: 1 }}>
            Breadth vs Depth
        </Typography>
        <Typography variant="subtitle2" sx={{ color: '#6B7280', fontSize: '14px', mb: 2 }}>
            Controls whether follow-up questions explore topics at a surface level (1) or dive deep into specifics (5).
        </Typography>
        <Slider
            value={currentQuestion.depth || 2} 
            min={1}
            max={5}
            step={1}
            marks={[
            { value: 1, label: '1' },
            { value: 2, label: '2' },
            { value: 3, label: '3' },
            { value: 4, label: '4' },
            { value: 5, label: '5' }
            ]}
            disabled={!currentQuestion.advanced_enabled}
            onChange={(e, value) => {
                onChangeDepth(value);
            }}
            sx={{ 
            mb: 4,
            color: '#258462',
            height: 8,
            '& .MuiSlider-thumb': {
                height: 14,
                width: 14,
                backgroundColor: '#fff',
                border: '2px solid currentColor',
                '&:focus, &:hover, &.Mui-active, &.Mui-focusVisible': {
                boxShadow: 'inherit',
                }
            },
            '& .MuiSlider-track': {
                color: '#258462',
                height: 8,
                border: 'none'
            },
            '& .MuiSlider-rail': {
                color: '#258462',
                height: 8
            }
            }}
        />

        <Typography variant="h6" sx={{ fontWeight: 600, fontSize: '16px', color: '#111827', mb: 1 }}>
            Helpfulness
        </Typography>
        <Typography variant="subtitle2" sx={{ color: '#6B7280', fontSize: '14px', mb: 2 }}>
            Adjusts how little (1) or much (5) guidance Wayfaster provides to help candidates score higher.
        </Typography>
        <Slider
            value={currentQuestion.helpfulness || 2}
            min={1}
            max={5}
            step={1}
            marks={[
            { value: 1, label: '1' },
            { value: 2, label: '2' },
            { value: 3, label: '3' },
            { value: 4, label: '4' },
            { value: 5, label: '5' }
            ]}
            disabled={!currentQuestion.advanced_enabled}
            onChange={(e, value) => {
                onChangeHelpfulness(value);
            }}
            sx={{ 
            mb: 2,
            color: '#258462',
            height: 8,
            '& .MuiSlider-thumb': {
                height: 14,
                width: 14,
                backgroundColor: '#fff',
                border: '2px solid currentColor',
                '&:focus, &:hover, &.Mui-active, &.Mui-focusVisible': {
                boxShadow: 'inherit',
                }
            },
            '& .MuiSlider-track': {
                color: '#258462',
                height: 8,
                border: 'none'
            },
            '& .MuiSlider-rail': {
                color: '#258462',
                height: 8
            }
            }}
        />

        <Typography variant="h6" sx={{ fontWeight: 600, fontSize: '16px', color: '#111827', mb: 1, mt: 2 }}>
            Stopping Points
        </Typography>
        <Typography variant="subtitle2" sx={{ color: '#6B7280', fontSize: '14px', mb: 2 }}>
            Adds explicit boundaries to stop asking follow-ups and move on to the next question.
        </Typography>

        {(currentQuestion.stopping_points || []).map((point, pointIdx) => (
            <Box 
            key={pointIdx}
            sx={{
                display: 'flex',
                alignItems: 'center',
                mb: 2,
                position: 'relative'
            }}
            >
            <TextField
                value={inputValues[pointIdx] || point}
                placeholder="Enter a stopping point..."
                disabled={!currentQuestion.advanced_enabled}
                onChange={(e) => {
                    // Update local state immediately for responsive UI
                    const newInputValues = [...inputValues];
                    newInputValues[pointIdx] = e.target.value;
                    setInputValues(newInputValues);
                    
                    // Call the debounced update function
                    handleDebouncedUpdate(e.target.value, pointIdx);
                }}
                sx={{
                width: 'calc(100% - 40px)', // Leave space for icon button
                '& .MuiOutlinedInput-root': {
                    fontSize: '14px',
                    color: '#111827',
                    backgroundColor: 'white',
                    borderRadius: '8px',
                    height: '40px', // Reduced height
                    '& fieldset': {
                    borderColor: '#E5E7EB',
                    transition: 'border-color 0.2s ease-in-out'
                    },
                    '&:hover fieldset': {
                    borderColor: '#D1D5DB'
                    },
                    '&.Mui-focused fieldset': {
                    borderColor: '#2563EB'
                    },
                    '& input': {
                        padding: '8px 14px' // Reduced padding
                    }
                }
                }}
            />
            <IconButton
                disabled={!currentQuestion.advanced_enabled}
                onClick={() => {
                    onRemoveStoppingPoint(pointIdx);
                }}
                sx={{
                ml: 1,
                color: '#9CA3AF',
                padding: '4px',
                '&:hover': {
                    color: '#EF4444',
                    backgroundColor: 'transparent'
                }
                }}
            >
                <Clear sx={{ fontSize: 16 }} />
            </IconButton>
            </Box>
        ))}

        <Button
            disabled={!currentQuestion.advanced_enabled}
            onClick={() => onAddStoppingPoint()}
            startIcon={<Add />}
            sx={{
            color: '#6B7280',
            bgcolor: '#F9FAFB',
            border: '1px dashed #E5E7EB',
            textTransform: 'none',
            borderRadius: '6px',
            '&:hover': {
                bgcolor: '#F3F4F6'
            }
            }}
        >
            Add new
        </Button>

        <Box sx={{ mt: 2 }}>
          <Typography variant="subtitle1" sx={{ mb: 1, fontWeight: 600, color: '#111827' }}>
            Question Type
          </Typography>
          <FormControl
            disabled={!currentQuestion.advanced_enabled}
            fullWidth
            size="small"
            sx={{
              '& .MuiOutlinedInput-root': {
                borderRadius: '6px',
                backgroundColor: '#F9FAFB',
                '& fieldset': {
                  borderColor: '#E5E7EB',
                  transition: 'border-color 0.2s ease-in-out'
                },
                '&:hover fieldset': {
                  borderColor: '#D1D5DB'
                },
                '&.Mui-focused fieldset': {
                  borderColor: '#2563EB'
                }
              }
            }}
          >
            <Select
              value={currentQuestion.type || 'standard'}
              onChange={(e) => onChangeQuestionType(e.target.value)}
            sx={{
              '& .MuiSelect-select': {
                fontSize: '14px',
                fontWeight: 500
              }
            }}
            >
              <MenuItem sx={{ fontSize: '14px', fontWeight: 500 }} value="standard">Standard</MenuItem>
              <MenuItem sx={{ fontSize: '14px', fontWeight: 500 }} value="roleplay">Role Play</MenuItem>
            </Select>
          </FormControl>
        </Box>

        {currentQuestion.type === 'roleplay' && (
          <>
          <Box sx={{ mt: 2 }}>
            <Typography variant="subtitle1" sx={{ mb: 1, fontWeight: 600, color: '#111827' }}>
              Difficulty
            </Typography>
            <Slider
              disabled={!currentQuestion.advanced_enabled}
              value={difficultyToIntMap[roleplayContextValue.difficulty] || 2}
              onChange={(e, newValue) => {
                const updatedContext = {
                  ...roleplayContextValue,
                  difficulty: intToDifficultyMap[newValue]
                };
                setRoleplayContextValue(updatedContext);
                onChangeRoleplayContext(updatedContext);
              }}
              step={1}
              marks={[
                { value: 1, label: 'Easy' },
                { value: 2, label: 'Medium' },
                { value: 3, label: 'Hard' }
              ]}
              min={1}
              max={3}
              sx={{ 
                mb: 2,
                color: '#258462',
                height: 8,
                '& .MuiSlider-thumb': {
                  height: 14,
                  width: 14,
                  backgroundColor: '#fff',
                  border: '2px solid currentColor',
                  '&:focus, &:hover, &.Mui-active, &.Mui-focusVisible': {
                    boxShadow: 'inherit',
                  }
                },
                '& .MuiSlider-track': {
                  color: '#258462',
                  height: 8,
                  border: 'none'
                },
                '& .MuiSlider-rail': {
                  color: '#258462',
                  height: 8
                },
                '& .MuiSlider-markLabel': {
                  fontSize: '14px',
                  fontWeight: 500,
                  '&[data-index="0"]': { // Target "Easy" label
                    transform: 'translateX(0px)'
                  },
                  '&[data-index="2"]': { // Target "Hard" label
                    transform: 'translateX(-30px)'
                  }
                }
              }}
            />
          </Box>


          <Box sx={{ mt: 2 }}>
            <Typography variant="subtitle1" sx={{ mb: 1, fontWeight: 600, color: '#111827' }}>
              Interviewer Role
            </Typography>
            <TextField
              disabled={!currentQuestion.advanced_enabled}
              fullWidth
              size="small"
              multiline
              rows={6}
              placeholder="Ex: You are a VP of Engineering at a mid-sized B2B SaaS company called Viva. You're responsible for increasing developer productivity to overcome recent delays in shipping new features."
              value={roleplayContextValue.interviewer_role}
              onChange={(e) => {
                // Update local state immediately for responsive UI
                const updatedContext = {
                  ...roleplayContextValue,
                  interviewer_role: e.target.value
                };
                setRoleplayContextValue(updatedContext);
                
                // Call the debounced update function
                handleDebouncedRoleplayUpdate(updatedContext);
              }}
              sx={{
                '& .MuiOutlinedInput-root': {
                  borderRadius: '6px',
                  fontSize: '14px',
                  '& fieldset': {
                    borderColor: '#E5E7EB',
                    transition: 'border-color 0.2s ease-in-out'
                  },
                  '&:hover fieldset': {
                    borderColor: '#D1D5DB'
                  },
                  '&.Mui-focused fieldset': {
                    borderColor: '#2563EB'
                  }
                }
              }}
            />
          </Box>
          <Box sx={{ mt: 2 }}>
          <Typography variant="subtitle1" sx={{ mb: 1, fontWeight: 600, color: '#111827' }}>
            Candidate Role
          </Typography>
          <TextField
            disabled={!currentQuestion.advanced_enabled}
            fullWidth
            size="small"
            multiline
            rows={6}
            placeholder="Ex: You are a BDR at Monday.com. Through background research, you've learned that Viva is struggling to ship new features efficiently. You're cold-calling the VP of Engineering at Viva to pitch Monday.com's AI-powered project management tool."
            value={roleplayContextValue.candidate_role}
            onChange={(e) => {
              // Update local state immediately for responsive UI
              const updatedContext = {
                ...roleplayContextValue,
                candidate_role: e.target.value
              };
              setRoleplayContextValue(updatedContext);
              
              // Call the debounced update function
              handleDebouncedRoleplayUpdate(updatedContext);
            }}
            sx={{
              '& .MuiOutlinedInput-root': {
                borderRadius: '6px',
                fontSize: '14px',
                '& fieldset': {
                  borderColor: '#E5E7EB',
                  transition: 'border-color 0.2s ease-in-out'
                },
                '&:hover fieldset': {
                  borderColor: '#D1D5DB'
                },
                '&.Mui-focused fieldset': {
                  borderColor: '#2563EB'
                }
              }
            }}
          />
          </Box>
          </>
        )}

        <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 4 }}>
            <Button
            variant="contained"
            onClick={onClose}
            sx={{ 
                bgcolor: 'black',
                textTransform: 'none',
                borderRadius: '6px',
                boxShadow: 'none',
                '&:hover': {
                bgcolor: '#1D4ED8',
                boxShadow: 'none'
                }
            }}
            >
            Save Changes
            </Button>
        </Box>
        </div>
    </Drawer>
    )
}

export default AdvancedDrawer;