import React, { useState, useEffect } from 'react';
import { Box, Typography, Button, Grid, CircularProgress, Tabs, Tab, Alert, Container, IconButton, Snackbar, TextField   } from '@mui/material';
import { TabPanel, TabContext } from '@mui/lab';
import CheckIcon from '@mui/icons-material/Check';
import WayfasterLogo from "../../wayfaster-logo.svg";
import UploadFileIcon from '../../assets/svg/UploadFileIcon.svg';
import InterviewDetailsGraphic from '../../assets/svg/InterviewDetailsGraphic.svg';
import FormField from '../common/FormField';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { VoiceSelector, VoiceDisplay } from "../interview/VoiceSelector";
import LanguageSelector from "../interview/LanguageSelector";
import { supportedLanguages } from "../../util/constants";
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import TooltipCustom from '../common/TooltipCustom';
import { styled } from '@mui/material/styles';
import pdfToText from "react-pdftotext";
import api from "../../api/api";
import { useNavigate } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import CloseIcon from '@mui/icons-material/Close';
import AddIcon from '@mui/icons-material/Add';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { DndContext, closestCenter } from '@dnd-kit/core';
import { arrayMove, SortableContext, verticalListSortingStrategy } from '@dnd-kit/sortable';
import { restrictToVerticalAxis } from '@dnd-kit/modifiers';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import IndustryIcon from '../../assets/OnboardingSidebar/step1_industry.svg';
import RoleIcon from '../../assets/OnboardingSidebar/step2_role.svg';
import JdIcon from '../../assets/OnboardingSidebar/step3_jd.svg';
import ShareTeamIcon from '../../assets/OnboardingSidebar/step4_share_team.svg';
import TestTeamIcon from '../../assets/OnboardingSidebar/step5_test_team.svg';
import UploadOrWriteJDIcon from '../../assets/svg/UploadOrWriteJDIcon.svg';
import ShareWithTeamIcon from '../../assets/OnboardingShareWithTeam.svg';
import industries from './industries';
import { SessionDecision } from '../../util/constants';
const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});

const steps = [
  ['What industry do you work in?', IndustryIcon],
  ['Select the role you are hiring for', RoleIcon],
  ['Upload your job description', JdIcon],
  ['Share with your team', ShareTeamIcon],
  ['Test with your team', TestTeamIcon]
];

const DraggableQuestionRow = ({ question, index, handleEditQuestion, handleDeleteQuestion, validationErrors }) => {
  const { attributes, listeners, setNodeRef, transform, transition, isDragging } = useSortable({
    id: question.id,
  });

  return (
    <Box
      ref={setNodeRef}
      sx={{
        transform: CSS.Transform.toString(transform),
        transition,
        opacity: isDragging ? 0.8 : 1,
        zIndex: isDragging ? 1 : 0,
        position: 'relative',
      }}
    >
      <Box sx={{ display: 'flex', alignItems: 'flex-start', gap: 2 }}>
        <Box sx={{ display: 'flex', alignItems: 'center', width: '100%' }}>
          <DragIndicatorIcon 
            {...attributes} 
            {...listeners}
            sx={{ 
              cursor: 'grab',
              color: '#9CA3AF',
              mb: 2,
              display: 'flex',
              alignItems: 'center', 
              justifyContent: 'center',
              padding: '8px',
              borderRadius: '8px',
              fontSize: '32px',
              transition: 'all 0.2s ease-in-out',
              '&:hover': {
                color: '#6B7280',
                backgroundColor: 'rgba(0, 0, 0, 0.04)', 
                transform: 'scale(1.15)'
              },
              '&:focus': {
                outline: 'none'
              }
            }} 
          />
          <Box sx={{ display: 'flex', alignItems: 'center', flex: 1, gap: 2 }}>
            <TextField
              fullWidth
              value={question.question}
              onChange={(e) => handleEditQuestion(index, e.target.value)}
              error={!question.question.trim() && validationErrors.length > 0}
              helperText={!question.question.trim() && validationErrors.length > 0 ? "Question cannot be empty" : ""}
              variant="outlined"
              sx={{
                flex: 1,
                mb: 2,
                backgroundColor: 'white',
                '& .MuiInputBase-input': {
                  fontSize: '14px',
                  fontWeight: 500,
                },
                '& .MuiOutlinedInput-root': {
                  height: '55px',
                  '& fieldset': {
                    borderColor: '#CDCDCD',
                  },
                  '&:hover fieldset': {
                    borderColor: '#CDCDCD',
                  },
                  '&.Mui-focused fieldset': {
                    borderColor: '#CDCDCD',
                  },
                },
              }}
            />
            <Select
              value={question.time_limit}
              onChange={(e) => handleEditQuestion(index, undefined, e.target.value)}
              sx={{
                width: '120px',
                height: '55px',
                backgroundColor: '#FFFFFF',
                mb: 2,
                '& .MuiOutlinedInput-notchedOutline': {
                  borderColor: '#E5E7EB',
                },
                '&:hover .MuiOutlinedInput-notchedOutline': {
                  borderColor: '#0066FF',
                },
                '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                  borderColor: '#0066FF',
                },
                '& .MuiSelect-select': {
                  padding: '12px 16px',
                  fontSize: '14px',
                  color: '#374151',
                },
              }}
            >
              <MenuItem value={15}>15 sec</MenuItem>
              <MenuItem value={60}>1 min</MenuItem>
              <MenuItem value={120}>2 min</MenuItem>
              <MenuItem value={300}>5 min</MenuItem>
            </Select>
          </Box>
          <IconButton 
            onClick={() => handleDeleteQuestion(index)}
            sx={{ 
              p: 1,
              ml: 1,
              mb: 2,
              '&:hover': {
                color: '#F33D3D',
                backgroundColor: '#FFF8F8'
              }
            }}
          >
            <CloseIcon />
          </IconButton>
        </Box>
      </Box>
    </Box>
  );
};

const interviewStatusTexts = [
  "Analyzing responses...",
  "Evaluating communication skills...",
  "Scoring technical knowledge...",
  "Assessing problem-solving ability...",
  "Calculating overall performance...",
  "Processing interview data...",
  "Generating insights...",
  "Finalizing scorecard..."
];

export default function Onboarding() {
  const navigate = useNavigate();
  const { user } = useAuth0();
  const [currentStep, setCurrentStep] = useState(0);
  const [selectedIndustry, setSelectedIndustry] = useState(null);
  const [selectedRole, setSelectedRole] = useState(null);
  const [interviewConfigId, setInterviewConfigId] = useState(null);
  const [inviteEmail, setInviteEmail] = useState('');
  
  // JD Upload states
  const [jobDescriptionText, setJobDescriptionText] = useState("");
  const [file, setFile] = useState("");
  const [fileText, setFileText] = useState("");
  const [maxInterviewLength, setMaxInterviewLength] = useState(10);
  const [submitting, setSubmitting] = useState(false);
  const [showUpload, setShowUpload] = useState(false);
  const [showTemplates, setShowTemplates] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [templateTitle, setTemplateTitle] = useState("");
  const [selectedTemplate, setSelectedTemplate] = useState(null);
  const [submittingIndex, setSubmittingIndex] = useState(0);  

  // Interview configuration states
  const [roleBackground, setRoleBackground] = useState({
    interviewTitle: '',
    jobTitle: '',
    assessmentLanguage: supportedLanguages.English.language_name,
    aiVoice: '11labs-Jason'
  });
  const [addQuestions, setAddQuestions] = useState({
    questions: [],
    currentQuestion: {
      question: '',
      time_limit: 30,
      editIndex: -1,
    },
  });
  const [addScoring, setAddScoring] = useState({
    currentSignal: {
      signal: '',
      scale: 4,
      '1PointCriteria': '',
      '2PointCriteria': '', 
      '3PointCriteria': '',
      '4PointCriteria': '',
      editIndex: -1
    },
    signals: []
  });
  const [addOutputs, setAddOutputs] = useState({
    currentOutput: { extraction_instructions: '', data_type: '', key: '' },
    editIndex: -1,          
    outputs: []
  });

  // Interview creation step
  const [interviewStep, setInterviewStep] = useState(0);
  const [reviewTest, setReviewTest] = useState({
    prompt: '',
    recordingEnabled: true,
    antiCheatingEnabled: true,
    recordingType: 'video'
  });
  const [voices, setVoices] = useState([]);
  const [supportedVoiceIds, setSupportedVoiceIds] = useState([]);
  const [currentVoice, setCurrentVoice] = useState(null);
  const [isCreatingInterview, setIsCreatingInterview] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMsg, setSnackbarMsg] = useState('');
  const [invitedEmails, setInvitedEmails] = useState([]);
  const [currentInviteEmail, setCurrentInviteEmail] = useState('');
  const [inviteSending, setInviteSending] = useState(false);

  // Add this state near the top with other state declarations
  const [completedInterview, setCompletedInterview] = useState(null);
  const [interviewStatusText, setInterviewStatusText] = useState("Waiting for interview to complete");
  const [interviewStatusTextIndex, setInterviewStatusTextIndex] = useState(0);
  const [signalTabValues, setSignalTabValues] = useState({});
  useEffect(() => {
    // Get supported voices
    api.getVoices().then((voice_res) => {
        let voices = voice_res.data.voices;
        setVoices(voices);
        setRoleBackground(prev => ({ ...prev, aiVoice: voices.find((voice) => voice.voice_id == "11labs-Jason") }));

        let current_voice = roleBackground.aiVoice
          ? roleBackground.aiVoice
          : voices.find((voice) => voice.voice_id == "11labs-Jason");
        setCurrentVoice(current_voice);
      });

    api.getSupportedVoices({ language: roleBackground.assessmentLanguage }).then((voice_res) => {
        let supported_voice_ids = voice_res.data.supported_voice_ids;
        setSupportedVoiceIds(supported_voice_ids);
    });
  }, []);  

  useEffect(() => {
    // Get team object to check onboarding status
    const teamId = localStorage.getItem('teamId');
    if (teamId && user) {
      console.log("getting team onboarding status");
      api.getTeam({ userId: user.email, teamId: teamId })
        .then(response => {
          console.log("response", response);
          const onboarding = response.data.onboarding;
          if (onboarding && onboarding.interview_config_id) {
            // Fetch interview details to populate role background
            api.getInterview(onboarding.interview_config_id, user.email, teamId)
              .then(interviewResponse => {
                console.log("interview details", interviewResponse);
                if (interviewResponse.data && interviewResponse.data.title) {
                  setRoleBackground(prev => ({
                    ...prev,
                    interviewTitle: interviewResponse.data.title,
                    jobTitle: interviewResponse.data.jobTitle || prev.jobTitle
                  }));
                  setCurrentStep(3);
                  setInterviewConfigId(onboarding.interview_config_id);
                }
              })
              .catch(error => {
                console.error('Failed to get interview details:', error);
              });
          }
        })
        .catch(error => {
          console.error('Failed to get team onboarding status:', error);
        });
    }
  }, [user]);

  // Add this effect to check interview status
  useEffect(() => {
    if (currentStep === 4 && interviewConfigId) {
      const interval = setInterval(() => {
        // Replace this with actual API call when ready
        console.log("checking interview status");
        api.interviewAttempted({ interviewConfigId })
          .then(response => {
            console.log("interview completed response", response);
            if (response.data.has_attempted_interview) {
              if(response.data.postcall_analysis_complete) {
                setCompletedInterview(response.data.attempted_interview_id);
                clearInterval(interval);
              } else {
              // Update the text immediately based on current index
                setInterviewStatusText(interviewStatusTexts[interviewStatusTextIndex]);
                setInterviewStatusTextIndex(prevIndex => (prevIndex + 1) % interviewStatusTexts.length);              
              }
            }
          })
          .catch(error => {
            console.error('Failed to check interview status:', error);
          });
      }, 3000);

      return () => clearInterval(interval);
    }
  }, [currentStep, interviewConfigId, interviewStatusTexts, interviewStatusTextIndex]);

  // Add new state for validation errors
  const [validationErrors, setValidationErrors] = useState([]);

  const messages = [
    'Coming up with good questions...',
    'Generating a strong scorecard...',
    'Calibrating difficulty levels...',
    'Optimizing interview flow...',
    'Crafting follow-up questions...',
    'Fine-tuning time allocations...',
    'Building evaluation criteria...',
    'Personalizing for your role...',
    'Polishing question phrasing...',
    'Setting up scoring rubrics...',
    'Almost done...',
    'Creating your interview...'
  ];

  useEffect(() => {
    const interval = setInterval(() => {
      setSubmittingIndex((prevIndex) => (prevIndex + 1) % messages.length);
    }, 1500);

    return () => clearInterval(interval);
  }, []);

  const handleNext = () => {
    const errorMsgs = [];

    if (currentStep === 0 && !selectedIndustry) {
      errorMsgs.push("Please select an industry before continuing");
    }
    else if (currentStep === 1 && !selectedRole) {
      errorMsgs.push("Please select a role before continuing"); 
    }

    if (errorMsgs.length > 0) {
      setValidationErrors(errorMsgs);
      return;
    }

    if (currentStep === 2) {
      handleCreateInterview();
    } else {
      if(currentStep === 0) {
        setSelectedRole(null)
      }
      setCurrentStep(currentStep + 1);
    }
  };

  const handleBack = () => {
    setCurrentStep(currentStep - 1);
  };

  const handleFileUpload = (e) => {
    if (e.target.files && e.target.files[0]) {
      const file = e.target.files[0];
      
      // Clear any existing job description text when uploading a file
      setJobDescriptionText("");
      setFile(file);
      processFile(file);
    }
  };

  const processFile = (file) => {
    if (file.type === "application/pdf") {
      pdfToText(file)
        .then((text) => {
          setFileText(text);
          setErrorMessage(""); // Clear any existing error messages
        })
        .catch((error) => {
          console.error("Failed to extract text from pdf:", error);
          setErrorMessage("Failed to extract text from PDF. Please try again or paste the text directly.");
          clearFile();
        });
    } else {
      // text file
      const reader = new FileReader();
      
      if (file.type.match(/text.*/)) {
        reader.onload = function (event) {
          setFileText(event.target.result);
          setErrorMessage(""); // Clear any existing error messages
        };
        reader.onerror = function (error) {
          console.error("Failed to read text file:", error);
          setErrorMessage("Failed to read text file. Please try again or paste the text directly.");
          clearFile();
        };
        reader.readAsText(file);
      } else {
        setErrorMessage("Unsupported file type. Please upload a PDF or text file.");
        clearFile();
      }
    }
  };

  const clearFile = () => {
    setFile("");
    setFileText("");
  };

  const handleSubmitJD = () => {
    // Clear any existing error messages
    setErrorMessage("");

    // Check if both inputs are being used
    if (jobDescriptionText && fileText && !selectedTemplate) {
      setErrorMessage("Please use either the text input or file upload, not both. Clear one before proceeding.");
      return;
    }

    // Check if neither input is provided
    if (!jobDescriptionText && !fileText && !selectedTemplate) {
      setErrorMessage("Job description is required. Either write the job description, upload a file, or select a template.");
      return;
    }

    const finalText = jobDescriptionText || fileText;

    const payload = {
      context: finalText,
      context_type: "job_description",
      total_time_length: maxInterviewLength * 60 // convert to seconds
    };
    
    setSubmitting(true);
    api
      .generateInterviewQuestions(payload)
      .then((response) => {
        setSubmitting(false);
        console.log("generated interview questions");
        console.log(response);

        // Update interview configuration
        setRoleBackground({
          ...roleBackground,
          interviewTitle: response.data.details.interview_title,
          jobTitle: response.data.details.job_title,
        });

        // Update questions
        const questionsWithId = response.data.questions.map((question) => {
          return { 
            ...question, 
            question: question.question, 
            id: crypto.randomUUID()
          };
        });
        
        // Add the two default questions at the beginning
        const defaultQuestions = [
          {
            question: `We will be asking you questions about the ${response.data.details.job_title} role. Is that ok?`,
            time_limit: 15,
            id: crypto.randomUUID(),
            isOpening: true
          },
          {
            question: "Tell me a little bit about yourself.",
            time_limit: 60,
            id: crypto.randomUUID(),
            isDefault: true
          }
        ];

        setAddQuestions({
          questions: [...defaultQuestions, ...questionsWithId],
          currentQuestion: {
            question: "What has been the highlight of your role?",
            time_limit: 30,
            editIndex: -1,
          },
        });

        // Update scoring
        const signals = response.data.scorecard.map((signal) => {
          return {
            signal: signal.signal,
            scale: 4,
            '1PointCriteria': signal.point_criteria_1,
            '2PointCriteria': signal.point_criteria_2, 
            '3PointCriteria': signal.point_criteria_3,
            '4PointCriteria': signal.point_criteria_4
          };
        });
        
        setAddScoring({
          currentSignal: {
            signal: "",
            scale: 4,
            '1PointCriteria': "",
            '2PointCriteria': "", 
            '3PointCriteria': "",
            '4PointCriteria': "",
            editIndex: -1
          },
          signals: signals,
        });
        
        // Initialize tab values for each signal
        const initialTabValues = {};
        signals.forEach((_, idx) => {
          initialTabValues[idx] = "1";
        });
        setSignalTabValues(initialTabValues);

        // Update outputs
        setAddOutputs({
          currentOutput: { extraction_instructions: '', data_type: '', key: '' },
          editIndex: -1,          
          outputs: response.data.structured_outputs.map((output) => {
            return {
              key: output.key,
              extraction_instructions: output.extraction_instructions,
              data_type: output.data_type
            };
          }),
        });

        setInterviewStep(1); // Show review section
      })
      .catch((error) => {
        setSubmitting(false);
        setErrorMessage("Failed to generate interview questions. Please try again.");
        console.error("Failed to generate interview questions:", error);
      });
  };

  const handleCreateInterview = () => {
    setIsCreatingInterview(true);
    // Validate questions
    const errors = [];
    if (addQuestions.questions.length === 0) {
      errors.push("Please add at least one question to create an interview");
    } else {
      addQuestions.questions.forEach((q, idx) => {
        if (!q.question.trim()) {
          errors.push(`Question ${idx + 1} cannot be empty`);
        }
      });
    }

    if (errors.length > 0) {
      setValidationErrors(errors);
      return;
    }

    setValidationErrors([]); // Clear any previous errors
    const interviewConfig = {
      version: 2,
      title: roleBackground.interviewTitle,
      language: roleBackground.assessmentLanguage,
      jobTitle: roleBackground.jobTitle,
      questions: addQuestions.questions,
      scorecard: addScoring.signals,
      prompt: reviewTest.prompt,
      structuredOutputs: addOutputs.outputs,
    };

    const teamId = localStorage.getItem('teamId');

    const payload = {
      userId: user.email,
      teamId: teamId,
      interviewConfig: interviewConfig,
    };

    api.createInterview(payload).then((response) => {
      console.log("created interview");
      console.log(response);
      setInterviewConfigId(response.data.id);
      api.completeOnboarding({ userId: user.email, interviewConfigId: response.data.id, teamId: teamId }).then((complete_onboarding_res) => {
        console.log("completed onboarding");
        console.log(complete_onboarding_res);

        // Fetch interview details to populate role background
        api.getInterview(response.data.id, user.email, teamId)
          .then(interviewResponse => {
            console.log("interview details", interviewResponse);
            if (interviewResponse.data && interviewResponse.data.title) {
              setRoleBackground(prev => ({
                ...prev,
                interviewTitle: interviewResponse.data.title,
                jobTitle: interviewResponse.data.jobTitle || prev.jobTitle
              }));
              setCurrentStep(3);
              setInterviewConfigId(response.data.id);
              setIsCreatingInterview(false);              
            }
          })
          .catch(error => {
            console.error('Failed to get interview details:', error);
          });
      }).catch((error) => {
        console.error("Failed to complete onboarding:", error);
      });
    }).catch((error) => {
      console.error("Failed to create interview:", error);
      setIsCreatingInterview(false);
    });
  };

  const handleDeleteQuestion = (index) => {
    setAddQuestions(prev => ({
      ...prev,
      questions: prev.questions.filter((_, i) => i !== index)
    }));
  };

  const handleEditQuestion = (index, newText, newTimeLimit) => {
    setAddQuestions(prev => ({
      ...prev,
      questions: prev.questions.map((q, i) => 
        i === index ? { 
          ...q, 
          question: newText !== undefined ? newText : q.question,
          time_limit: newTimeLimit !== undefined ? newTimeLimit : q.time_limit
        } : q
      )
    }));
  };

  const handleAddQuestion = () => {
    const newId = crypto.randomUUID();
    setAddQuestions(prev => ({
      ...prev,
      questions: [...prev.questions, {
        id: newId,
        question: '',
        time_limit: 30
      }]
    }));
  };

  const isNextDisabled = () => {
    switch (currentStep) {
      case 0:
        return !selectedIndustry;
      case 1:
        return !selectedRole;
      case 2:
        if (interviewStep === 0) {
          return !jobDescriptionText && !fileText;
        } 
        return isCreatingInterview;
      case 3:
        return false; // Allow next step when sharing
      default:
        return false;
    }
  };

  const getNextButtonText = () => {
    if (currentStep === 2) {
      if (interviewStep === 0) {
        return submitting ? (
          <>
            <CircularProgress size={20} sx={{color: "white", mr: 2}} />
            <span>{messages[submittingIndex]}</span>
          </>
        ) : (
          <>
            {showTemplates ? 'Use Template' : 'Generate'}
            <span style={{fontSize: '12px', opacity: 0.7, marginLeft: '4px'}}>
              {!showUpload && !showTemplates && `(${navigator.platform.includes('Mac') ? '⌘' : 'Ctrl'} + Enter)`}
            </span>
          </>
        );
      }
      if (isCreatingInterview) {
        return 'Creating Interview...';
      }
      return 'Create Interview';
    }
    if (currentStep === 3) {
      return 'Next Step';
    }
    return 'Next Step';
  };

  const handleDragEnd = (event) => {
    const { active, over } = event;

    if (active.id !== over.id) {
      setAddQuestions(prev => {
        const oldIndex = prev.questions.findIndex(q => q.id === active.id);
        const newIndex = prev.questions.findIndex(q => q.id === over.id);
        return {
          ...prev,
          questions: arrayMove(prev.questions, oldIndex, newIndex)
        };
      });
    }
  };

  const renderInterviewCreation = () => {
    return (
      <Box sx={{ 
        marginBottom: '25px',
        display: 'flex',
        width: '100%',
        height: 'calc(100vh - 200px)',
        position: 'relative',
      }}>
        <Box sx={{ 
          width: '100%',
          height: '100%',
          display: 'flex',
          flexDirection: 'column'
        }}>
          {/* Show validation errors if any */}
          {validationErrors.length > 0 && (
            <Alert 
              severity="error"
              sx={{
                mb: 3,
                '& .MuiAlert-message': {
                  fontSize: '14px',
                  fontWeight: 500
                }
              }}
            >
              <Box component="div" sx={{ mb: 1 }}>Please fix the following errors:</Box>
              <Box component="ul" sx={{ m: 0, pl: 2 }}>
                {validationErrors.map((error, index) => (
                  <li key={index}>{error}</li>
                ))}
              </Box>
            </Alert>
          )}

          {/* Fixed Header Section */}
          <Box sx={{ mb: 4 }}>
            <Box sx={{ mb: 2, display: 'flex', justifyContent: 'space-between', alignItems: 'flex-start' }}>
              <Box>
                <Typography variant="h6" sx={{ fontSize: '18px', fontWeight: 600 }}>
                  {roleBackground.interviewTitle}
                </Typography>
                <Typography sx={{ fontSize: '14px', color: '#6C757D', mt: 0.5 }}>
                  {roleBackground.jobTitle}
                </Typography>
              </Box>
              <Box sx={{ 
                backgroundColor: '#F0F7FF', 
                px: 2, 
                py: 1, 
                borderRadius: '6px',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center'
              }}>
                <Typography sx={{ fontSize: '12px', color: '#0066CC', fontWeight: 500 }}>
                  Total Duration
                </Typography>
                <Typography sx={{ fontSize: '16px', fontWeight: 600, color: '#0066CC' }}>
                  {Math.round(addQuestions.questions.reduce((total, q) => total + (q.time_limit || 0), 0) / 60)} min
                </Typography>
              </Box>
            </Box>            
          </Box>

          {/* Scrollable Content */}
          <Box sx={{ 
            flex: 1,
            overflowY: 'auto',
            pr: 2,
            pb: '80px',
            '&::-webkit-scrollbar': {
              width: '4px',
            },
            '&::-webkit-scrollbar-track': {
              background: 'transparent',
            },
            '&::-webkit-scrollbar-thumb': {
              background: '#DDE1E6',
              borderRadius: '10px',
              '&:hover': {
                background: '#C1C7CD',
              },
            },
          }}>
            {/* Questions Section */}
            <Box sx={{ mb: 4, backgroundColor: '#FAFAFA', p: 3, borderRadius: '8px' }}>
              <Typography variant="h6" sx={{ mb: 2, fontSize: '16px', fontWeight: 500 }}>
                Review interview questions ({addQuestions.questions.length})
              </Typography>
              <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1.5 }}>
                <DndContext
                  collisionDetection={closestCenter}
                  modifiers={[restrictToVerticalAxis]}
                  onDragEnd={handleDragEnd}
                >
                  <SortableContext
                    items={addQuestions.questions.map(q => q.id)}
                    strategy={verticalListSortingStrategy}
                  >
                    {addQuestions.questions.map((question, index) => (
                      <DraggableQuestionRow
                        key={question.id}
                        question={question}
                        index={index}
                        handleEditQuestion={handleEditQuestion}
                        handleDeleteQuestion={handleDeleteQuestion}
                        validationErrors={validationErrors}
                      />
                    ))}
                  </SortableContext>
                </DndContext>
                <Button
                  startIcon={<AddIcon />}
                  onClick={handleAddQuestion}
                  sx={{
                    width: 'fit-content',
                    color: 'black',
                    borderColor: '#0066FF',
                    fontSize: '14px',
                    textTransform: 'none',
                    '&:hover': {
                      backgroundColor: '#EBF5FF',
                      borderColor: '#0052CC'
                    }
                  }}
                >
                  Add question
                </Button>
              </Box>
            </Box>
            {/* Basic Settings */}
            <Box sx={{ mb: 4 }}>
              <Typography variant="h6" sx={{ mb: 2, fontSize: '18px', fontWeight: 600 }}>
                Basic settings
              </Typography>
              <Box sx={{ display: 'flex', gap: 3 }}>
                {/* Voice Selection */}
                <Box>
                  <h5 className="input-label d-flex">Interviewer Voice</h5>
                  <Box sx={{ width: '280px' }}>
                    <VoiceSelector
                      currentVoice={roleBackground.aiVoice}
                      voices={voices}
                      supportedVoiceIds={supportedVoiceIds}
                      clicked={(voice) => setRoleBackground(prev => ({ ...prev, aiVoice: voice }))}
                      language={roleBackground.assessmentLanguage}
                    />                  
                  </Box>
                </Box>

                {/* Language Selection */}
                <Box>
                  <h5 className="input-label d-flex">Language</h5>
                  <Box sx={{ width: '280px' }}>
                    <LanguageSelector
                      currentLanguage={roleBackground.assessmentLanguage}
                      languages={supportedLanguages}
                      clicked={(lang) => setRoleBackground(prev => ({ ...prev, assessmentLanguage: lang }))}
                    />
                  </Box>
                </Box>
              </Box>
            </Box>

            {/* Scoring Section */}
            <Box sx={{ mb: 4 }}>
              <Typography variant="h6" sx={{ mb: 2, fontSize: '18px', fontWeight: 600 }}>
                Scoring Criteria
              </Typography>
              <Grid container spacing={2}>
                {addScoring.signals.map((signal, index) => (
                  <Grid item xs={12} md={6} key={index}>
                    <Box sx={{ 
                      p: 3, 
                      border: '1px solid #E5E7EB',
                      borderRadius: '8px',
                      height: '100%',
                      boxShadow: '0 1px 3px rgba(0,0,0,0.05)',
                      backgroundColor: '#FFFFFF',
                      transition: 'all 0.15s ease',
                      '&:hover': {
                        boxShadow: '0 3px 10px rgba(0,0,0,0.06)',
                        borderColor: '#D1D5DB'
                      }
                    }}>
                      <Typography sx={{ 
                        fontWeight: 600, 
                        mb: 2, 
                        color: '#111827', 
                        fontSize: '16px',
                        position: 'relative',
                        paddingBottom: '10px',
                        borderBottom: '1px solid #F3F4F6'
                      }}>
                        {signal.signal}
                      </Typography>
                      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
                        <TabContext value={signalTabValues[index] || "1"}>
                          <Tabs 
                            variant="fullWidth" 
                            sx={{ 
                              mb: 2,
                              '& .MuiTab-root': {
                                textTransform: 'none',
                                fontWeight: 500,
                                fontSize: '14px',
                                color: '#6B7280',
                                borderRadius: '4px',
                                '&.Mui-selected': {
                                  color: '#111827',
                                  fontWeight: 600,
                                  backgroundColor: 'rgba(0, 0, 0, 0.04)'
                                }
                              },
                              '& .MuiTabs-indicator': {
                                backgroundColor: '#000000',
                                height: '3px',
                                borderRadius: '2px'
                              }
                            }}
                            TabIndicatorProps={{
                              sx: {
                                transition: 'none'
                              }
                            }}
                            value={signalTabValues[index] || "1"}
                            onChange={(e, newValue) => {
                              setSignalTabValues(prev => ({
                                ...prev,
                                [index]: newValue
                              }));
                            }}
                          >
                            <Tab value="1" label="1 Point" />
                            <Tab value="2" label="2 Points" />
                            <Tab value="3" label="3 Points" />
                            <Tab value="4" label="4 Points" />
                          </Tabs>
                          <TabPanel value="1">
                            <Box sx={{ 
                              backgroundColor: '#F3F4F6', 
                              borderRadius: '6px',
                              p: 2,
                              border: '1px solid #E5E7EB'
                            }}>
                              <Typography sx={{ 
                                color: '#111827', 
                                fontWeight: 600, 
                                mb: 1, 
                                fontSize: '14px',
                                display: 'flex',
                                alignItems: 'center'
                              }}>
                                Poor (1 point)
                              </Typography>
                              <Typography sx={{ 
                                color: '#111827', 
                                fontSize: '13px', 
                                whiteSpace: 'pre-line', 
                                lineHeight: '1.5',
                                fontWeight: 400
                              }}>
                                {signal['1PointCriteria'].split('•').join('\n•')}
                              </Typography>
                            </Box>
                          </TabPanel>
                          <TabPanel value="2">
                            <Box sx={{ 
                              backgroundColor: '#F3F4F6', 
                              borderRadius: '6px',
                              p: 2,
                              border: '1px solid #E5E7EB'
                            }}>
                              <Typography sx={{ 
                                color: '#111827', 
                                fontWeight: 600, 
                                mb: 1, 
                                fontSize: '14px',
                                display: 'flex',
                                alignItems: 'center'
                              }}>
                                Fair (2 points)
                              </Typography>
                              <Typography sx={{ 
                                color: '#111827', 
                                fontSize: '13px', 
                                whiteSpace: 'pre-line', 
                                lineHeight: '1.5',
                                fontWeight: 400
                              }}>
                                {signal['2PointCriteria'].split('•').join('\n•')}
                              </Typography>
                            </Box>
                          </TabPanel>
                          <TabPanel value="3">
                            <Box sx={{ 
                              backgroundColor: '#F3F4F6', 
                              borderRadius: '6px',
                              p: 2,
                              border: '1px solid #E5E7EB'
                            }}>
                              <Typography sx={{ 
                                color: '#111827', 
                                fontWeight: 600, 
                                mb: 1, 
                                fontSize: '14px',
                                display: 'flex',
                                alignItems: 'center'
                              }}>
                                Good (3 points)
                              </Typography>
                              <Typography sx={{ 
                                color: '#111827', 
                                fontSize: '13px', 
                                whiteSpace: 'pre-line', 
                                lineHeight: '1.5',
                                fontWeight: 400
                              }}>
                                {signal['3PointCriteria'].split('•').join('\n•')}
                              </Typography>
                            </Box>
                          </TabPanel>
                          <TabPanel value="4">
                            <Box sx={{ 
                              backgroundColor: '#F3F4F6', 
                              borderRadius: '6px',
                              p: 2,
                              border: '1px solid #E5E7EB'
                            }}>
                              <Typography sx={{ 
                                color: '#111827', 
                                fontWeight: 600, 
                                mb: 1, 
                                fontSize: '14px',
                                display: 'flex',
                                alignItems: 'center'
                              }}>
                                Excellent (4 points)
                              </Typography>
                              <Typography sx={{ 
                                color: '#111827', 
                                fontSize: '13px', 
                                whiteSpace: 'pre-line', 
                                lineHeight: '1.5',
                                fontWeight: 400
                              }}>
                                {signal['4PointCriteria'].split('•').join('\n•')}
                              </Typography>
                            </Box>
                          </TabPanel>
                        </TabContext>
                      </Box>
                    </Box>
                  </Grid>
                ))}
              </Grid>
            </Box>
          </Box>
        </Box>
      </Box>
    );
  };

  const renderStepContent = () => {
    switch (currentStep) {
      case 0:
        return (
          <>
            <Typography variant="h4" sx={{ mb: 2, fontWeight: 600 }}>
              What industry do you work in?
            </Typography>
            <Typography variant="body1" sx={{ mb: 4, color: '#6C757D' }}>
              This will help us tailor the interview to your industry.
            </Typography>
            <Box sx={{ 
              position: 'absolute', 
              top: 50, 
              right: 50, 
              display: 'flex', 
              alignItems: 'center', 
              backgroundColor: '#E6F4EA', 
              borderRadius: '8px', 
              padding: '6px 12px' 
            }}>
              <AccessTimeIcon sx={{ fontSize: 18, color: '#34A853', mr: 1 }} />
              <Typography sx={{ fontSize: '14px', fontWeight: 500, color: '#34A853' }}>
                5 minutes
              </Typography>
            </Box>

            <Grid container spacing={3}>
              {industries.map((industry) => {
                const isSelected = selectedIndustry === industry.id;

                return (
                  <Grid item xs={4} key={industry.id}>
                    <Button
                      fullWidth
                      onClick={() => setSelectedIndustry(industry.id)}
                      sx={{
                        height: '80px',
                        border: '1px solid',
                        borderColor: isSelected ? '#000000' : '#E9ECEF',
                        borderRadius: '8px',
                        backgroundColor: '#FFFFFF',
                        display: 'flex',
                        flexDirection: 'row',
                        pl: 2,
                        alignItems: 'center',
                        justifyContent: 'left',
                        gap: 2,
                        '&:hover': {
                          backgroundColor: '#F8F9FA',
                        },
                      }}
                    >
                      {industry.icon && <img src={industry.icon} style={{ width: 48, height: 48 }} />}
                      <Typography
                        sx={{
                          color: isSelected ? '#000000' : '#212529',
                          fontWeight: isSelected ? 600 : 500,
                          textTransform: 'none',
                          fontSize: '14px'
                        }}
                      >
                        {industry.label}
                      </Typography>
                    </Button>
                  </Grid>
                );
              })}
            </Grid>
          </>
        );
      case 1:
        return (
          <>
            <Typography variant="h4" sx={{ mb: 2, fontWeight: 600 }}>
              What role are you hiring for?
            </Typography>
            <Typography variant="body1" sx={{ mb: 4, color: '#6C757D' }}>
              This will help us tailor the interview to the role.
            </Typography>
            <Box sx={{ 
              position: 'absolute', 
              top: 50, 
              right: 50, 
              display: 'flex', 
              alignItems: 'center', 
              backgroundColor: '#E6F4EA', 
              borderRadius: '8px', 
              padding: '6px 12px' 
            }}>
              <AccessTimeIcon sx={{ fontSize: 18, color: '#34A853', mr: 1 }} />
              <Typography sx={{ fontSize: '14px', fontWeight: 500, color: '#34A853' }}>
                5 minutes
              </Typography>
            </Box>            
            {validationErrors.map((error) => (
              <Typography variant="body1" sx={{ mb: 4, color: '#6C757D' }}>
                {error}
              </Typography>
            ))}            

            <Grid container spacing={3}>
              {industries.find(industry => industry.id === selectedIndustry)?.roles.map((role) => {
                const isSelected = selectedRole === role.id;

                return (
                  <Grid item xs={4} key={role.id}>
                    <Button
                      fullWidth
                      onClick={() => setSelectedRole(role.id)}
                      sx={{
                        height: '60px',
                        border: '1px solid',
                        borderColor: isSelected ? '#000000' : '#E9ECEF',
                        borderRadius: '8px',
                        backgroundColor: '#FFFFFF',
                        display: 'flex',
                        flexDirection: 'row',
                        pl: 2,
                        alignItems: 'center',
                        justifyContent: 'left',
                        '&:hover': {
                          backgroundColor: '#F8F9FA',
                        },
                      }}
                    >
                      <Typography
                        sx={{
                          color: isSelected ? '#000000' : '#212529',
                          fontWeight: isSelected ? 600 : 500,
                          textTransform: 'none',
                          fontSize: '14px'
                        }}
                      >
                        {role.label}
                      </Typography>
                    </Button>
                  </Grid>
                );
              })}
            </Grid>
          </>
        );
      case 2:
        return interviewStep === 0 ? (
          <Box sx={{ 
            display: 'flex', 
            width: '80%',
            margin: '0 auto'
          }}>
            <Box sx={{ 
              position: 'absolute', 
              top: 50, 
              right: 50, 
              display: 'flex', 
              alignItems: 'center', 
              backgroundColor: '#E6F4EA', 
              borderRadius: '8px', 
              padding: '6px 12px' 
            }}>
              <AccessTimeIcon sx={{ fontSize: 18, color: '#34A853', mr: 1 }} />
              <Typography sx={{ fontSize: '14px', fontWeight: 500, color: '#34A853' }}>
                5 minutes
              </Typography>
            </Box>            
            <Box sx={{ width: '100%', pr: 5, pb: 5, height: '100%' }}>
              <img src={UploadOrWriteJDIcon} alt="Upload or write JD" style={{ width: '40px', height: '40px', marginBottom: '10px' }} />              
              <Typography variant="h4" sx={{ mb: 3, fontSize: '24px', fontWeight: 600 }}>
                Upload or write candidate job description
              </Typography>
              {/* Toggle Buttons */}
              <Box sx={{ mb: 3, display: 'inline-block' }}>
                <Tabs
                  value={showUpload ? 2 : showTemplates ? 1 : 0}
                  onChange={handleTabChange}
                  sx={{
                    '& .MuiTabs-indicator': {
                      display: 'none'
                    },
                    backgroundColor: '#f5f5f5',
                    padding: '4px',
                    borderRadius: '8px',
                    width: 'fit-content'
                  }}
                >
                  <Tab 
                    label="Write job description"
                    sx={{
                      textTransform: 'none',
                      borderRadius: '6px',
                      color: '#000',
                      '&.Mui-selected': {
                        color: '#000',
                        backgroundColor: '#fff',
                        border: '1px solid #9BAECC',
                        boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)',
                        borderRadius: '8px'
                      },
                      minHeight: '36px'
                    }}
                  />
                  <Tab
                    label="Select a template" 
                    sx={{
                      textTransform: 'none',
                      borderRadius: '6px', 
                      color: '#000',
                      '&.Mui-selected': {
                        color: '#000',
                        backgroundColor: '#fff',
                        border: '1px solid #9BAECC',
                        boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)',
                        borderRadius: '8px'
                      },
                      minHeight: '36px'
                    }}
                  />
                  <Tab
                    label="Upload job description" 
                    sx={{
                      textTransform: 'none',
                      borderRadius: '6px',
                      color: '#000', 
                      '&.Mui-selected': {
                        color: '#000',
                        backgroundColor: '#fff',
                        border: '1px solid #9BAECC',
                        boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)',
                        borderRadius: '8px'
                      },
                      minHeight: '36px'
                    }}
                  />
                </Tabs>
              </Box>

              {/* Write or Upload Content */}
              {(!showUpload && !showTemplates) ? (
                <FormField
                  label="Job Description"
                  placeholder="Paste job description here..."
                  value={jobDescriptionText}
                  multiline
                  minRows={6}
                  disabled={!!fileText} // Disable when file is uploaded
                  onChange={(e) => {
                    setJobDescriptionText(e.target.value);
                    setFileText(""); // Clear any uploaded file text when typing
                    setFile(""); // Clear any uploaded file when typing
                  }}
                  onKeyDown={(e) => {
                    if ((e.metaKey || e.ctrlKey) && e.key === 'Enter') {
                      handleSubmitJD();
                    }
                  }}
                  style={{
                    overflow: 'auto',
                    maxHeight: '400px'
                  }}
                />
              ) : showTemplates ? (
                <Box>
                  <Typography sx={{ fontSize: '14px', fontWeight: 500, mb: 2 }}>
                    Select a template of popular roles in today's market. All the template interviews are 15-20 minutes.
                  </Typography>                    
                  <Box sx={{
                    display: 'grid',
                    gridTemplateColumns: 'repeat(auto-fill, minmax(300px, 1fr))',
                    gap: '24px',
                    width: '100%'
                  }}>
                    {selectedIndustry && selectedRole && 
                      industries
                        .find(i => i.id === selectedIndustry)
                        ?.roles.find(r => r.id === selectedRole)
                        ?.templates?.map((template) => (
                          <Button
                            key={template.id}
                            onClick={() => {
                              if (selectedTemplate?.id === template.id) {
                                // If clicking the same template again, deselect it
                                setSelectedTemplate(null);
                                setTemplateTitle("");
                                setFileText("");
                              } else {
                                // Select the template
                                setSelectedTemplate(template);
                                setTemplateTitle(template.label);
                                // Pre-fill the job description with the template content
                                setFileText(template.template);
                              }
                              // Clear any error message
                              setErrorMessage("");
                            }}
                            sx={{
                              height: '156px',
                              width: '100%',
                              display: 'flex',
                              flexDirection: 'column',
                              alignItems: 'flex-start',
                              justifyContent: 'space-between',
                              padding: '15px',
                              backgroundColor: '#fff',
                              border: selectedTemplate?.id === template.id ? '2px solid #000' : '1px solid #E0E0E0',
                              borderRadius: '8px',
                              textAlign: 'left',
                              transition: 'all 0.2s ease-in-out',
                              textTransform: 'none',
                              color: '#000',
                              margin: '0',
                              '&:hover': {
                                backgroundColor: '#F8F8F8',
                                transform: 'translateY(-2px)',
                                boxShadow: '0 4px 6px rgba(0, 0, 0, 0.05)'
                              }
                            }}
                          >
                            {template.icon ? (
                              <>
                                <Box sx={{ alignSelf: 'flex-start' }}>
                                  <img src={template.icon} alt={template.label} style={{ height: '25px' }} />
                                </Box>
                                <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
                                  <Typography sx={{ fontSize: '14px', fontWeight: selectedTemplate?.id === template.id ? 600 : 400, pb: 0, mb: 0 }}>{template.company}</Typography>
                                  <Typography sx={{ 
                                    fontWeight: selectedTemplate?.id === template.id ? 700 : 500,
                                    alignSelf: 'flex-start',
                                    fontSize: '18px',
                                    mt: 0,
                                    pt: 0
                                  }}>{template.title}</Typography>
                                </Box>
                              </>
                            ) : (
                              <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
                                <Typography sx={{ fontSize: '14px', fontWeight: selectedTemplate?.id === template.id ? 600 : 400, pb: 0, mb: 0 }}>{template.company}</Typography>
                                <Typography sx={{ 
                                  fontWeight: selectedTemplate?.id === template.id ? 700 : 500,
                                  alignSelf: 'flex-start',
                                  fontSize: '18px',
                                  mt: 0,
                                  pt: 0
                                }}>{template.title}</Typography>
                              </Box>
                            )}
                          </Button>
                        ))
                    }
                    {(!selectedIndustry || !selectedRole || 
                      !industries.find(i => i.id === selectedIndustry)?.roles.find(r => r.id === selectedRole)?.templates ||
                      industries.find(i => i.id === selectedIndustry)?.roles.find(r => r.id === selectedRole)?.templates.length === 0) && (
                      <Typography sx={{ gridColumn: '1/-1', color: '#6C757D', textAlign: 'center', padding: '40px 0' }}>
                        No templates available for the selected industry and role. Please select a different role or use the job description input.
                      </Typography>
                    )}
                  </Box>
                </Box>
              ) : (
                <TooltipCustom
                  title={
                    <>
                      <p className="m-auto">Supports .pdf, .txt files.</p>
                    </>
                  }
                >
                  <Button
                    className="btn-upload d-flex flex-col"
                    sx={{
                      height: "fit-content", 
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      justifyContent: "center",
                      padding: "32px",
                      backgroundColor: "#F8F8F8 !important",
                      boxShadow: "none",
                      border: "0.5px solid #E0E0E0 !important",
                      textAlign: "center",
                      minHeight: "240px",
                      gap: "16px"
                    }}
                    component="label"
                    role={undefined}
                    variant="contained"
                    tabIndex={-1}
                  >
                    <img src={UploadFileIcon} style={{ width: '33px', height: '32px' }} />

                    <Typography sx={{ maxWidth: "400px", fontWeight: 500 }}>
                      Upload job description or any past interview notes/information
                      on the role
                    </Typography>

                    <VisuallyHiddenInput
                      type="file"
                      id="file-upload-input"
                      onChange={handleFileUpload}
                      accept="application/pdf,.txt"
                    />
                    
                    <Button 
                      variant="contained" 
                      onClick={() => document.getElementById('file-upload-input').click()}
                      sx={{ 
                        bgcolor: '#000000',
                        color: '#FFFFFF',
                        padding: '10px 24px',
                        fontSize: '12px',
                        textTransform: 'none',
                        fontWeight: 500,
                        borderRadius: '8px',
                        boxShadow: '0 1px 2px rgba(0, 0, 0, 0.05)',
                        transition: 'all 0.2s ease-in-out',
                        '&:hover': {
                          bgcolor: '#333333',
                          transform: 'translateY(-1px)',
                          boxShadow: '0 4px 6px rgba(0, 0, 0, 0.05)',
                        },
                        '&:active': {
                          transform: 'translateY(0)',
                        }
                      }}
                    >
                      Upload file
                    </Button>
                    {file && (
                      <Button variant="outlined" onClick={clearFile}>
                        Clear file
                      </Button>
                    )}
                  </Button>
                </TooltipCustom>
              )}

              {!showTemplates && (
                <Box sx={{ mt: 4, position: 'relative' }}>
                  <FormField
                    required
                    label="How long do you want the interview to be?"
                    type="number"
                    value={maxInterviewLength}
                  onChange={(e) => {setMaxInterviewLength(e.target.value)}}
                  onKeyDown={(e) => {
                    if ((e.metaKey || e.ctrlKey) && e.key === 'Enter') {
                      handleSubmitJD();
                    }
                  }}
                />
                <Typography 
                  sx={{
                    position: 'absolute',
                    right: '12px',
                    top: '54%',
                    transform: 'translateY(-50%)',
                    color: '#999',
                    pointerEvents: 'none'
                  }}
                >
                  minutes
                  </Typography>
                </Box>
              )}

              <Button
                fullWidth
                variant="contained"
                disabled={submitting || (showTemplates && !selectedTemplate)}
                onClick={handleSubmitJD}
                sx={{
                  mt: 3,
                  bgcolor: submitting ? '#333333' : '#000000',
                  color: 'white',
                  padding: '12px',
                  fontSize: '16px',
                  fontWeight: 600,
                  height: '50px',
                  '&:hover': {
                    bgcolor: '#0052CC'
                  },
                  textTransform: 'none'
                }}
              >
                {submitting ? (
                  <>
                    <CircularProgress size={20} sx={{color: "white", mr: 2}} />
                    <span>{messages[submittingIndex]}</span>
                  </>
                ) : (
                  <>
                    {showTemplates ? 'Use Template' : 'Generate'}
                    <span style={{fontSize: '12px', opacity: 0.7, marginLeft: '4px'}}>
                      {!showUpload && !showTemplates && `(${navigator.platform.includes('Mac') ? '⌘' : 'Ctrl'} + Enter)`}
                    </span>
                  </>
                )}
              </Button>

              {errorMessage && (
                <Alert 
                  severity="error"
                  sx={{
                    mt: 2,
                    '& .MuiAlert-message': {
                      fontSize: '14px',
                      fontWeight: 500
                    }
                  }}
                >
                  {errorMessage}
                </Alert>
              )}
            </Box>

            {/* Right Column */}
            {/* <Box sx={{ flex: 1, display: 'flex', justifyContent: 'center', alignItems: 'center', overflow: 'hidden', mt: 10 }}>
              <img 
                src={InterviewDetailsGraphic} 
                alt="Interview Setup" 
                style={{ 
                  width: '100%',
                  height: '100%',
                  objectFit: 'none',
                  objectPosition: 'left top'
                }} 
              />
            </Box> */}
          </Box>
        ) : renderInterviewCreation();
      case 3:
        return (
          <Box sx={{ 
            display: 'flex',
            flexDirection: 'column',
            width: '80%',
            margin: '0 auto'
          }}>
            <img src={ShareWithTeamIcon} alt="Share with team" style={{ width: '40px', height: '40px', marginBottom: '10px' }} />
            <Typography variant="h4" sx={{ 
              fontSize: '28px', 
              fontWeight: 600,
              mb: 2
            }}>
              Share with your team
            </Typography>
            <Box sx={{ 
              position: 'absolute', 
              top: 50, 
              right: 50, 
              display: 'flex', 
              alignItems: 'center', 
              backgroundColor: '#E6F4EA', 
              borderRadius: '8px', 
              padding: '6px 12px' 
            }}>
              <AccessTimeIcon sx={{ fontSize: 18, color: '#34A853', mr: 1 }} />
              <Typography sx={{ fontSize: '14px', fontWeight: 500, color: '#34A853' }}>
                3 minutes
              </Typography>
            </Box>            
            
            <Typography sx={{ 
              color: '#6B7280',
              mb: 6
            }}>
              We've got an interview all set up and ready to take for you. Before you send to candidates, make sure you send it to one other person at your company to try
            </Typography>

            {/* Share with Team Section */}
            <Box sx={{ mb: 2 }}>
              <Box sx={{ display: 'flex' }}>
                <FormField
                  placeholder="Enter teammate's email"
                  size="small"
                  sx={{ flex: 1 }}
                  value={currentInviteEmail}
                  onChange={(e) => {
                    setCurrentInviteEmail(e.target.value);
                  }}
                  onKeyDown={(e) => {
                    if (e.key === 'Enter') {
                      if (currentInviteEmail.trim()) {
                        e.preventDefault();
                        document.getElementById('send-invite-button').click();
                      }
                    }
                  }}
                />
                <Button
                  id="send-invite-button"
                  variant="contained"
                  sx={{
                    bgcolor: '#000000',
                    color: '#FFFFFF',
                    '&:hover': {
                      bgcolor: '#333333'
                    },
                    textTransform: 'none',
                    fontSize: '14px',
                    borderRadius: '8px',
                    width: invitedEmails.length >= 1 ? '240px' : '180px',
                    height: '40px',
                    ml: 2,
                    display: 'flex',
                    alignItems: 'center'
                  }}
                  onClick={async () => {
                    // Validate email format
                    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
                    if (!currentInviteEmail || !emailRegex.test(currentInviteEmail.trim())) {
                      setSnackbarMsg("Please enter a valid email address");
                      setSnackbarOpen(true);
                      return;
                    }

                    // Check if email has already been invited
                    const normalizedEmail = currentInviteEmail.trim().toLowerCase();
                    if (invitedEmails.some(email => email.toLowerCase() === normalizedEmail)) {
                      setSnackbarMsg("This email has already been invited");
                      setSnackbarOpen(true);
                      return;
                    }

                    setInviteSending(true);
                    
                    try {
                      await api.sendInterviewInvite({
                        recipientEmail: normalizedEmail,
                        interviewConfigId: interviewConfigId,
                        senderEmail: user.email,
                        senderName: user.name || user.email,
                        interviewTitle: roleBackground.interviewTitle || "Interview"
                      });
                      
                      setInvitedEmails([...invitedEmails, normalizedEmail]);
                      setCurrentInviteEmail('');
                      setSnackbarMsg(`Invitation email sent to ${normalizedEmail}`);
                      setSnackbarOpen(true);
                    } catch (error) {
                      console.error("Failed to send invite:", error);
                      setSnackbarMsg("Failed to send invitation email. Please try again.");
                      setSnackbarOpen(true);
                    } finally {
                      setInviteSending(false);
                    }
                  }}
                  disabled={inviteSending || !currentInviteEmail.trim()}
                >
                  {inviteSending ? (
                    <CircularProgress size={20} sx={{color: "white"}} />
                  ) : (
                    invitedEmails.length >= 1 ? 'Send another invite' : 'Send invite'
                  )}
                </Button>
              </Box>
            </Box>

            {invitedEmails.length > 0 && (
              <Box>
                <Typography sx={{ fontWeight: 500, mb: 2, fontSize: '16px' }}>
                  Invitations sent
                </Typography>
                {invitedEmails.map((email, index) => (
                  <Typography 
                    key={index} 
                    sx={{ 
                      fontSize: '14px', 
                      mb: 1,
                      bgcolor: '#F5F5F5',
                      p: 1.5,
                      borderRadius: '6px',
                      color: '#4A4A4A'
                    }}
                  >
                    {email}
                  </Typography>
                ))}
              </Box>
            )}
            <br/><br/><br/>

            {/* Interview Link Section */}
            <Box sx={{
              width: '100%',
              mb: 6,
              backgroundColor: '#F8F8F8',
              borderRadius: '12px',
              padding: '24px',
              border: '1px solid #E5E7EB'
            }}>
              <Typography sx={{ fontWeight: 500, mb: 2, fontSize: '16px' }}>
                Here's your invitation link 🔗
              </Typography>
              <Box sx={{
                display: 'flex',
                alignItems: 'center', 
                gap: 1,
                bgcolor: '#FFFFFF',
                border: '1px solid #E0E0E0',
                borderRadius: '6px',
                p: 1.5,
                '&:hover': {
                  border: '1px solid #9BAECC',
                }
              }}>
                <Typography sx={{
                  flex: 1,
                  color: '#1A1A1A', 
                  fontSize: '14px',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  whiteSpace: 'nowrap'
                }}>
                  {`https://sessions.wayfaster.com/i/${interviewConfigId}`}
                </Typography>
                <Button
                  variant="contained"
                  startIcon={<ContentCopyIcon sx={{ fontSize: 14 }} />}
                  onClick={() => {
                    navigator.clipboard.writeText(`https://sessions.wayfaster.com/i/${interviewConfigId}`);
                    setSnackbarMsg("Link copied to clipboard")
                    setSnackbarOpen(true)
                  }}
                  sx={{
                    bgcolor: '#FFFFFF',
                    color: '#1A1A1A',
                    border: '1px solid #E0E0E0', 
                    boxShadow: 'none',
                    '&:hover': {
                      bgcolor: '#F8F8F8',
                      border: '1px solid #9BAECC',
                    },
                    textTransform: 'none',
                    fontSize: '12px',
                    fontWeight: 500,
                    padding: '4px 8px',
                    minWidth: '80px'
                  }}
                >
                  Copy
                </Button>
              </Box>
            </Box>

            {/* <Box sx={{ mt: 'auto' }}>
              <Button
                variant="contained"
                onClick={() => setCurrentStep(4)}
                sx={{
                  bgcolor: '#000000',
                  color: '#FFFFFF',
                  '&:hover': {
                    bgcolor: '#333333'
                  },
                  textTransform: 'none',
                  fontSize: '14px',
                  height: '38px',
                  borderRadius: '8px',
                  width: 'fit-content',
                }}
              >
                Next step
              </Button>
            </Box> */}
          </Box>
        );
      case 4:
        return (
          <Box sx={{ width: '100%', height: '100%' }}>
            <Box sx={{ mb: 4, display: 'flex', justifyContent: 'space-between', alignItems: 'flex-start' }}>
              <Box>
                <Typography variant="h6" sx={{ 
                  fontWeight: 600, 
                  fontSize: '18px',
                  lineHeight: '28px',
                  color: '#1A1A1A',
                  mb: 1
                }}>
                  Take the interview here or wait until your colleague has taken it
                </Typography>
                <Typography sx={{
                  fontSize: '14px',
                  color: '#6C757D',
                  mb: 3
                }}>
                  Once you've done it, you can go to your scorecard to see the data
                </Typography>                
              </Box>
              {!completedInterview ? (
                <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                  <CircularProgress size={20} />
                  <Typography sx={{ fontSize: '14px', color: '#6C757D' }}>
                    {interviewStatusText}
                  </Typography>
                </Box>
              ) : (
                <Button
                  variant="contained"
                  disabled={!completedInterview}
                  onClick={() => {
                    navigate(`/interviews/${interviewConfigId}/sessions/${completedInterview}`);
                  }}
                  sx={{
                    bgcolor: '#000000',
                    color: '#FFFFFF',
                    '&:hover': {
                      bgcolor: '#333333'
                    },
                    textTransform: 'none', 
                    fontSize: '14px',
                    height: '38px',
                    borderRadius: '8px',
                    width: 'fit-content',
                  }}
                >
                  View your Results
                </Button>
              )}
            </Box>
            <iframe 
              src={`https://sessions.wayfaster.com/i/${interviewConfigId}`}
              style={{
                width: '100%',
                height: '90%',
                border: '1px solid #E0E0E0',
                borderRadius: '8px',
                flex: 1,
                minHeight: 0, // Prevents iframe from growing beyond container,
                zoom: '80%'
              }}
              title="Interview Preview"
              allow="camera;microphone"
            />
          </Box>
        );
      default:
        return null;
    }
  };

  // Clear the form inputs when switching tabs
  const handleTabChange = (event, newValue) => {
    setShowUpload(newValue === 2);
    setShowTemplates(newValue === 1);
    
    // Clear inputs when switching tabs
    if (newValue === 0) {
      // Switching to Write Job Description
      setFileText("");
      setFile("");
      setSelectedTemplate(null);
    } else if (newValue === 1) {
      // Switching to Select a template
      setJobDescriptionText("");
      setFileText("");
      setFile("");
    } else if (newValue === 2) {
      // Switching to Upload file
      setJobDescriptionText("");
      setSelectedTemplate(null);
    }
    
    // Clear error messages when switching tabs
    setErrorMessage("");
  };

  return (
    <Box sx={{ display: 'flex', width: '100%', height: '100vh', backgroundColor: 'white' }}>
      {/* Left Sidebar */}
      <Box sx={{ 
        display: 'flex', 
        p: 4, 
        height: '100vh',
        backgroundColor: 'white'
      }}>
        <Box
          sx={{
            width: '420px',
            backgroundColor: '#FAFAFA',
            borderRadius: '12px',
            p: 4,
            display: 'flex',
            flexDirection: 'column'
          }}
        >
          <img src={WayfasterLogo} style={{ width: 140, marginBottom: 40 }} />
          <Typography sx={{ mb: 3, fontWeight: 400, fontSize: '18px', lineHeight: '28px' }}>
            Send your first free interview in a few short steps
          </Typography>
          
          <Box sx={{ mt: 4 }}>
            {steps.map((step, index) => (
              <Box
                key={index}
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  mb: 2,
                  p: 2,
                  borderRadius: '8px',
                  border: index === currentStep ? '2px solid #E9ECEF' : 'none',
                  backgroundColor: index === currentStep ? '#FFFFFF' : 'transparent',
                }}
              >
                <Box
                  sx={{
                    width: 24,
                    height: 24,
                    borderRadius: '50%',
                    backgroundColor: 'transparent',
                    borderColor: index === currentStep ? '#000000' : index < currentStep ? '#000000' : '#E9ECEF',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    mr: 2,
                  }}
                >
                  {index < currentStep ? (
                    <Box sx={{ backgroundColor: 'green', borderRadius: '50%', display: 'flex', alignItems: 'center', justifyContent: 'center', width: 20, height: 20 }}>
                      <CheckIcon sx={{ color: 'white', width: 16, height: 16 }} />
                    </Box>
                  ) : (
                    <img 
                      src={step[1]} 
                      style={{ 
                        width: 20, 
                        height: 20,
                        filter: index === currentStep || index < currentStep ? 'brightness(0)' : 'brightness(0.8)'
                      }} 
                    />
                  )}
                </Box>
                <Typography
                  sx={{
                    color: index === currentStep ? '#000000' : '#6C757D',
                    fontSize: '14px',
                    fontWeight: index === currentStep ? 600 : 400,
                  }}
                >
                  {step[0]}
                </Typography>
              </Box>
            ))}
          </Box>
        </Box>
      </Box>

      {/* Main Content */}
      <Box sx={{
            backgroundColor: '#FFFFFF',
            borderRadius: '12px',
            border: '1px solid #E5E7EB',
            p: 4,
            minHeight: 'calc(100vh - 200px)',
            flex: 1,
            p: 6,
            margin: '25px'
        }}>      

        {renderStepContent()}

        <Box sx={{ mt: 4, display: 'flex', justifyContent: 'space-between' }}>
          {currentStep > 0 && currentStep < 2 && (
            <Button
              variant="outlined"
              onClick={handleBack}
              startIcon={<ArrowBackIcon />}
              sx={{
                color: '#6C757D',
                borderColor: '#E0E0E0',
                backgroundColor: '#FFFFFF',
                fontSize: '14px',
                fontWeight: 500,
                padding: '10px 24px',
                borderRadius: '8px',
                boxShadow: '0 1px 2px rgba(0, 0, 0, 0.05)',
                transition: 'all 0.2s ease-in-out',
                '&:hover': {
                  backgroundColor: '#F8F9FA',
                  borderColor: '#6C757D',
                  transform: 'translateY(-1px)',
                  boxShadow: '0 4px 6px rgba(0, 0, 0, 0.05)',
                },
                '&:active': {
                  transform: 'translateY(0)',
                }
              }}
            >
              Previous Step
            </Button>
          )}
          <Box sx={{ flex: 1 }} /> {/* Spacer */}
          {!(currentStep === 2 && interviewStep === 0) && currentStep !== 4 && (
            <Button
              variant="contained"
              disabled={isNextDisabled()}
              onClick={handleNext}
              endIcon={<ArrowForwardIcon />}
              sx={{
                backgroundColor: 'black',
                fontSize: '14px',
                fontWeight: 600,
                padding: '10px 24px',
                borderRadius: '8px',
                boxShadow: '0 1px 2px rgba(0, 102, 255, 0.2)',
                transition: 'all 0.2s ease-in-out',
                '&:hover': {
                  backgroundColor: '#0052CC',
                  transform: 'translateY(-1px)',
                  boxShadow: '0 4px 6px rgba(0, 102, 255, 0.2)',
                },
                '&:active': {
                  transform: 'translateY(0)',
                },
                '&.Mui-disabled': {
                  backgroundColor: '#F1F3F5',
                  color: '#ADB5BD',
                  boxShadow: 'none',
                }
              }}
            >
              {getNextButtonText()}
            </Button>
          )}
        </Box>

        <Snackbar
            open={snackbarOpen}
            autoHideDuration={3000}
            onClose={() => setSnackbarOpen(false)}
            message={snackbarMsg}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        />        
      </Box>
    </Box>
  );
} 