import { Drawer, Typography, Box, Button, Divider, Checkbox, FormControlLabel } from "@mui/material";
import FormField from "../common/FormField";
import { CheckCircle } from "@mui/icons-material";
import { useState } from "react";

const GenerateSignal = ({ 
    open, 
    onClose, 
    scoringGenerating, 
    onGenerate, 
    setDescription, 
    description, 
    predefinedSignals, 
    selectedPredefinedSignal, 
    setSelectedPredefinedSignal,
    questions,
    selectedQuestions,
    setSelectedQuestions,
    onAddSignal,
  }) => {
  
  const [currentStep, setCurrentStep] = useState(1);
  const [generatedSignal, setGeneratedSignal] = useState(null);

  const handleNextStep = () => {
    if (currentStep === 1) {
      setCurrentStep(2);
    } else if (currentStep === 2) {
      onGenerate().then((signal) => {
        setGeneratedSignal(signal);
        setCurrentStep(3);
      });
    }
  };

  const handlePreviousStep = () => {
    setCurrentStep(currentStep - 1);
  };

  return (
    <Drawer
      anchor="right"
      open={open}
      onClose={onClose}
      sx={{
        '& .MuiDrawer-paper': {
          width: '450px',
          padding: '24px',
          backgroundColor: '#FFFFFF',
          margin: '24px',
          height: 'calc(100% - 48px)',
          borderRadius: '16px',
          boxShadow: '0px 4px 6px -2px rgba(0, 0, 0, 0.05), 0px 10px 15px -3px rgba(0, 0, 0, 0.1)'
        },
        '& .MuiBackdrop-root': {
          backgroundColor: 'rgba(0, 0, 0, 0.3)'
        }
      }}
    >
      <div style={{ display: 'flex', flexDirection: 'column', gap: '16px', height: '100%' }}>
        {currentStep === 1 && (
          <>
            <Typography variant="p" sx={{ fontWeight: 600, fontSize: '18px', color: '#111827', marginBottom: '-8px' }}>
              Generate signal
            </Typography>
            <Typography variant="p" sx={{ color: '#6B7280', fontSize: '14px', fontWeight: 400 }}>
              Let us help you generate a signal to evaluate your candidates!
            </Typography>
            <Typography variant="p" sx={{ color: 'black', fontSize: '14px', fontWeight: 500, marginBottom: '-8px'}}>
                Choose one of our most frequently used scoring signals as a starting point
            </Typography>

            <Box sx={{ 
              display: 'flex', 
              flexWrap: 'wrap', 
              gap: '8px', 
              marginBottom: '0px'
            }}>
              {predefinedSignals.map((signal, index) => (
                <Button
                  key={index}
                  variant="outlined"
                  onClick={() => setSelectedPredefinedSignal(selectedPredefinedSignal === signal ? null : signal)}
                  sx={{
                    color: selectedPredefinedSignal === signal ? '#111827' : '#6B7280',
                    borderColor: selectedPredefinedSignal === signal ? '#111827' : '#E5E7EB',
                    textTransform: 'none',
                    borderRadius: '6px',
                    backgroundColor: selectedPredefinedSignal === signal ? '#F9FAFB' : 'transparent',
                    '&:hover': {
                      borderColor: '#D1D5DB',
                      backgroundColor: '#F9FAFB'
                    },
                    display: 'flex',
                    alignItems: 'center',
                    gap: '4px'
                  }}
                >
                  {signal}
                  {selectedPredefinedSignal === signal && (
                    <CheckCircle style={{ marginLeft: '4px', fontSize: '16px', color: '#111827' }} />
                  )}
                </Button>
              ))}
            </Box>

            <Box sx={{ 
              display: 'flex', 
              alignItems: 'center', 
              width: '100%', 
              margin: '0px'
            }}>
              <Divider sx={{ flexGrow: 1, borderColor: '#D1D5DB' }} />
              <Typography sx={{ margin: '0 8px', color: '#6B7280', fontWeight: 500, fontSize: '14px' }}>or</Typography>
              <Divider sx={{ flexGrow: 1, borderColor: '#D1D5DB' }} />
            </Box>

            <div className="d-flex flex-col">
              <FormField
                labelColor="black"
                label="Tell us what you're looking for"
                fullWidth
                multiline
                rows={8}
                placeholder="e.g. 'A candidate who is a great fit for the role will have a strong understanding of React and JavaScript'"
                value={description}
                onChange={(e) => setDescription(e.target.value)}
              />
            </div>
          </>
        )}

        {currentStep === 2 && (
          <>
            <Typography variant="p" sx={{ fontWeight: 600, fontSize: '18px', color: '#111827', marginBottom: '-8px' }}>
              Associated questions
            </Typography>
            <Typography variant="p" sx={{ color: '#6B7280', fontSize: '14px', fontWeight: 400 }}>
              Select which interview questions are related to this scoring signal
            </Typography>
            
            <Box sx={{ 
              display: 'flex', 
              flexDirection: 'column',
              gap: '12px',
              marginTop: '16px',
              overflowY: 'auto',
              maxHeight: 'calc(100vh - 260px)', // Add max height to prevent overflow
              paddingBottom: '24px' // Add padding to prevent content from being hidden under buttons
            }}>
              {questions.map((question, index) => (
                <Box 
                  key={index}
                  sx={{
                    backgroundColor: '#F9FAFB',
                    padding: '12px 16px',
                    borderRadius: '8px',
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center'
                  }}
                >
                  <Typography sx={{ color: '#111827', fontSize: '14px' }}>
                    {question.question}
                  </Typography>
                  <Checkbox
                    checked={selectedQuestions.includes(question)}
                    onChange={(e) => {
                      if (e.target.checked) {
                        setSelectedQuestions([...selectedQuestions, question]);
                      } else {
                        setSelectedQuestions(selectedQuestions.filter(q => q !== question));
                      }
                    }}
                    size="small"
                    sx={{
                      color: '#000000',
                      '&.Mui-checked': {
                        color: '#000000'
                      }
                    }}
                  />
                </Box>
              ))}
            </Box>
          </>
        )}

        {currentStep === 3 && (
          <>
            <Typography variant="p" sx={{ fontWeight: 600, fontSize: '18px', color: '#111827', marginBottom: '-8px' }}>
              Review signal
            </Typography>
            <Typography variant="p" sx={{ color: '#6B7280', fontSize: '14px', fontWeight: 400, marginBottom: '8px' }}>
              Review the signal and criteria we generated for you and make any adjustments you'd like
            </Typography>
            
            <div className="d-flex flex-col">
              <FormField
                labelColor="black"
                label="What is your signal called?"
                required
                fullWidth
                value={generatedSignal.signal}
                onChange={(e) => setGeneratedSignal({ ...generatedSignal, signal: e.target.value })}
              />
            </div>

            <div className="d-flex flex-col">
            <FormField
              labelColor="black"
              required
              label={`Criteria for "Meets Expectations" score (3)`}
              tooltip
              tooltipTitle="Candidates are evaluated against signal criteria on a scale of 1 to 4. This is the criteria for the 'Meets Expectations' level of the signal or a passing score of 3 out of 4."
              fullWidth
              multiline
              rows={12}
              value={generatedSignal.criteria}
                onChange={(e) => setGeneratedSignal({ ...generatedSignal, criteria: e.target.value })}
              />
            </div>
          </>
        )}

        <Box sx={{ 
          display: 'flex', 
          justifyContent: 'flex-end', 
          gap: 2,
          position: 'absolute',
          bottom: 0,
          left: 0,
          right: 0,
          padding: '24px',
        }}>
          {currentStep > 1 ? (
            <Button
              variant="outlined"
              onClick={handlePreviousStep}
              sx={{
                color: '#6B7280',
                borderColor: '#E5E7EB', 
                textTransform: 'none',
                borderRadius: '6px',
                '&:hover': {
                  borderColor: '#D1D5DB',
                  backgroundColor: '#F9FAFB'
                }
              }}
            >
              Previous step
            </Button>
          ) : (
            <Button
              variant="outlined"
              onClick={onClose}
              sx={{
                color: '#6B7280',
                borderColor: '#E5E7EB', 
                textTransform: 'none',
                borderRadius: '6px',
                '&:hover': {
                  borderColor: '#D1D5DB',
                  backgroundColor: '#F9FAFB'
                }
              }}
            >
              Cancel
            </Button>
          )}
          
          {currentStep < 3 ? (
            <Button
              sx={{
                bgcolor: 'black',
                color: 'white', 
                textTransform: 'none',
                borderRadius: '6px',
                boxShadow: 'none',
                '&:hover': {
                  bgcolor: '#333333',
                  color: 'white',
                  boxShadow: 'none'
                },
                '&.Mui-disabled': {
                  bgcolor: '#E5E7EB',
                  color: '#9CA3AF'
                }
              }}
              variant="contained"
              onClick={handleNextStep}
              disabled={
                (currentStep === 1 && (!description.trim() && !selectedPredefinedSignal)) ||
                (currentStep === 2 && selectedQuestions.length === 0)
              }
            >
              {scoringGenerating ? "Loading..." : "Next step"}
            </Button>
          ) : (
            <Button
              sx={{
                bgcolor: 'black',
                color: 'white', 
                textTransform: 'none',
                borderRadius: '6px',
                boxShadow: 'none',
                '&:hover': {
                  bgcolor: '#333333',
                  color: 'white',
                  boxShadow: 'none'
                },
                '&.Mui-disabled': {
                  bgcolor: '#E5E7EB',
                  color: '#9CA3AF'
                }
              }}
              variant="contained"
              onClick={() => onAddSignal(generatedSignal)}
              disabled={!generatedSignal?.signal?.trim() || !generatedSignal?.criteria?.trim()}
            >
              Add signal
            </Button>
          )}
        </Box>
      </div>
    </Drawer>
  );
};

export default GenerateSignal;