import React from "react";
import { Switch, Select, Menu, MenuItem, FormControlLabel, Box, IconButton, Button, Grid, Paper, Typography, Dialog, DialogTitle, DialogContent, DialogActions, TextField,CircularProgress, Snackbar, TableContainer, TableCell, TableBody, Table, TableRow, Tab, Tabs } from '@mui/material';
import { useState, useEffect } from 'react';
import Add from "@mui/icons-material/Add";
import AutoAwesomeOutlinedIcon from '@mui/icons-material/AutoAwesomeOutlined';
import DriveFileRenameOutlineIcon from '@mui/icons-material/DriveFileRenameOutline';
import { Scrollbars } from 'react-custom-scrollbars';
import FormField from "../common/FormField";
import { SECTIONS_OPTIONS, supportedLanguages, Language, humanReadableOutputTypes } from "../../util/constants";
import { secondsToTimeLimitReadable } from "../../util/interviews";
import QuestionDrawer from "./QuestionDrawer";
import ScoringDrawer from "./ScoringDrawer";
import AdvancedDrawer from "./AdvancedDrawer";
import OutputDrawer from "./OutputDrawer";
import GenerateSignal from "./GenerateSignal";
import api from "../../api/api";
import RadioBtn from "../common/RadioBtn";
import {
  useNavigate,
} from 'react-router-dom'
import {
  useAuth0
} from '@auth0/auth0-react'
import CreateInterviewJD from "../../pages/CreateInterviewJD";
import CreateInterviewTemplate from "../../pages/CreateInterviewTemplate";
import CreateInterviewHome from "../../pages/CreateInterviewHome";
import LanguageSelector from "../interview/LanguageSelector";
import {
  DndContext,
  KeyboardSensor,
  MouseSensor,
  TouchSensor,
  closestCenter,
  useSensor,
  useSensors,
} from '@dnd-kit/core'
import { restrictToVerticalAxis } from '@dnd-kit/modifiers'
import {
  arrayMove,
  SortableContext,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable'

// needed for row & cell level scope DnD setup
import { useSortable } from '@dnd-kit/sortable'
import { CSS } from '@dnd-kit/utilities'
import { CheckCircle } from "@mui/icons-material";
import EditIconNewSvg from '../../assets/svg/EditIconNew.svg';
import DeleteIconSvg from '../../assets/svg/DeleteIcon.svg';
import DeleteIconNewSvg from '../../assets/svg/DeleteIconNew.svg';
import DetailsSidebarIcon from '../../assets/svg/CreateInterviewSidebarIcons/DetailsSidebarIcon.svg';
import QuestionsSidebarIcon from '../../assets/svg/CreateInterviewSidebarIcons/QuestionsSidebarIcon.svg';
import ScoringSidebarIcon from '../../assets/svg/CreateInterviewSidebarIcons/ScoringSidebarIcon.svg';
import OutputsSidebarIcon from '../../assets/svg/CreateInterviewSidebarIcons/OutputsSidebarIcon.svg';
import ReviewSidebarIcon from '../../assets/svg/CreateInterviewSidebarIcons/ReviewSidebarIcon.svg';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import Drawer from '@mui/material/Drawer';
import DragIndicatorIcon from "@mui/icons-material/DragIndicator";
import MoreVertIcon from '@mui/icons-material/MoreVert';
import WayfasterLogo from "../../wayfaster-logo.svg";
import InterviewDetailsGraphic from "../../assets/svg/InterviewDetailsGraphic.svg";
import TuneIcon from '@mui/icons-material/Tune';

const LanguageDetect = require('languagedetect');
const langDetector = new LanguageDetect();

const RowDragHandleCell = ({ rowId }) => {
  const { attributes, listeners } = useSortable({
    id: rowId,
  })

  return (
    <IconButton {...attributes} {...listeners} style={{ height: 'fit-content' }}>
      <DragIndicatorIcon sx={{fontSize: '18px'}} />
    </IconButton>
  )
}

const detectLanguageMatch = (question, language) => {
  const detectedLanguage = langDetector.detect(question, 2);
  // shows as
  // [ [ 'english', 0.5969230769230769 ], [ 'hungarian', 0.407948717948718 ] ]
  if (detectedLanguage.length > 0) {
    return detectedLanguage[0][0] === language.toLowerCase();
  }
  return false;
}

const DraggableRow = ({ idx, row, stateAddQuestions, setAddQuestions, setShowDrawer, addScoring, setAddScoring }) => {
  const [showAdvancedDrawer, setShowAdvancedDrawer] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const { transform, transition, setNodeRef, isDragging } = useSortable({
    id: row.id,
  });

  const handleSaveQuestion = (updatedQuestion) => {
    let newQuestions = [...stateAddQuestions.questions];
    newQuestions[idx] = {
      ...row,
      ...updatedQuestion
    };
    setAddQuestions({
      ...stateAddQuestions,
      questions: newQuestions
    });
  };

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
    opacity: isDragging ? 0.8 : 1,
    backgroundColor: 'white',
    margin: '8px 0', 
    width: '100%',
    position: isDragging ? 'relative' : 'static',
    zIndex: isDragging ? 1000 : 'auto',
    border: '1px solid black !important',
    borderRadius: '8px',
    cursor: 'pointer',
    height: '58px',
  };

  return (
    <>
      <TableRow
        ref={setNodeRef}
        style={style}
        onClick={(e) => {
          // Only open drawer if not clicking advanced button or inside advanced drawer
          if (!e.target.closest('.advanced-section') && !showAdvancedDrawer && e.target.className.startsWith("MuiTableCell-root") || e.target.className === 'd-flex flex-row') {
            setAddQuestions({
              ...stateAddQuestions,
              currentQuestion: {
                question: row.question,
                time_limit: row.time_limit,
                editIndex: idx
              }
            });
            setShowDrawer(true);
          }
        }}
        sx={{
          '&:hover': {
            backgroundColor: '#F9FAFB !important',
            transition: 'background-color 0.2s ease'
          }
        }}
      >
        <TableCell sx={{ 
          display: 'flex', 
          flexDirection: 'row', 
          alignItems: 'center',
          borderLeft: '1px solid #E5E7EB', 
          borderTop: '1px solid #E5E7EB', 
          borderBottom: '1px solid #E5E7EB', 
          padding: '12px', 
          margin: '0', 
          borderTopLeftRadius: '8px', 
          borderBottomLeftRadius: '8px',
          width: '100%'
        }}>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <RowDragHandleCell rowId={row.id} size="small"/>
          </div>
          <div className="d-flex flex-row" style={{ marginRight: '8px', marginLeft: '8px', flex: 1, fontSize: '14px', fontWeight: '500' }}>
            <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
            {row.question}
              {row.isOpening && (
                <div style={{ 
                  backgroundColor: 'var(--color-progress-bg)',
                  color: 'var(--color-progress)',
                  padding: '4px 8px',
                  borderRadius: '8px',
                  fontSize: '14px',
                  fontWeight: 500
                }}>
                  Opening
                </div>
              )}
              {row.isDefault && (
                <div style={{ 
                  backgroundColor: 'var(--color-offwhite-bg)',
                  color: 'var(--color-gray)',
                  padding: '4px 8px',
                  borderRadius: '8px',
                  fontSize: '14px',
                  fontWeight: 500
                }}>
                  Default
                </div>
              )}
            </div>
          </div>
        </TableCell>
        <TableCell sx={{ 
          paddingLeft: '5px', 
          margin: '0', 
          borderTop: '1px solid #E5E7EB', // light gray
          borderBottom: '1px solid #E5E7EB', // light gray
          width: '120px',
          paddingTop: '0px',
          paddingBottom: '0px'
        }}>
          <TextField
            select
            variant="outlined"
            size="small"
            value={row.time_limit}
            onClick={(e) => e.stopPropagation()}
            onChange={(e) => {
              const newQuestions = [...stateAddQuestions.questions];
              newQuestions[idx] = {...row, time_limit: e.target.value};
              setAddQuestions({...stateAddQuestions, questions: newQuestions});
            }}
            sx={{ 
              width: '130px',
              '& .MuiOutlinedInput-root': {
                borderRadius: '6px',
                borderWidth: '1px',
                height: '32px',
                backgroundColor: '#FFFFFF',
                fontWeight: 500,
                fontSize: '14px',
                '&:hover': {
                  backgroundColor: '#F9FAFB',
                },
                '& fieldset': {
                  borderColor: '#E5E7EB', // light gray
                },
                '&:hover fieldset': {
                  borderColor: '#E5E7EB', // light gray
                },
                '&.Mui-focused fieldset': {
                  borderColor: '#E5E7EB', // light gray
                  borderWidth: '1px',
                }
              },
              '& .MuiSelect-icon': {
                color: '#374151', // dark gray
                transform: 'rotate(0deg)',
              }
            }}
            SelectProps={{
              IconComponent: (props) => (
                <svg {...props} width="20" height="16" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M5 7.5L10 12.5L15 7.5" stroke="#374151" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                </svg>
              ),
              MenuProps: {
                PaperProps: {
                  sx: {
                    '& .MuiMenuItem-root': {
                      fontSize: '14px',
                      '&:hover': {
                        backgroundColor: 'var(--color-primary-bg-hover) !important'
                      },
                      '&.Mui-selected': {
                        backgroundColor: 'var(--color-primary-bg-hover) !important'
                      }
                    }
                  }
                }
              }
            }}
          >
            <MenuItem value={15}>15 seconds</MenuItem>
            <MenuItem value={60}>1 minute</MenuItem>
            <MenuItem value={120}>2 minutes</MenuItem>
            <MenuItem value={300}>5 minutes</MenuItem>
          </TextField>
        </TableCell>
        <TableCell sx={{ 
          paddingRight: '0px',
          paddingLeft: '0px', 
          margin: '0', 
          borderTop: '1px solid #E5E7EB', // light gray
          borderBottom: '1px solid #E5E7EB', // light gray
          width: '90px',
          padding: '0px'
        }}>
          <>
            <div className="advanced-section" style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
              <button
                onClick={(e) => {
                  e.stopPropagation(); // Prevent row click when clicking button
                  setShowAdvancedDrawer(true);
                }}
                style={{
                  padding: '6px 12px',
                  borderRadius: '6px',
                  border: `0.5px solid ${row.advanced_enabled ? '#22C55E' : '#E5E7EB'}`,
                  background: row.advanced_enabled ? '#DCFCE7' : '#F9FAFB', // Made greener
                  color: row.advanced_enabled ? '#166534' : '#374151', // Darker green text if enabled
                  cursor: 'pointer',
                  display: 'flex',
                  alignItems: 'center',
                  gap: '4px',
                  fontSize: '14px',
                  fontWeight: 500,
                  boxShadow: row.advanced_enabled ? '0 0 0 1px #22C55E' : 'none' // Green outline if enabled
                }}
              >
                <TuneIcon sx={{ fontSize: 14 }} />
                Advanced
              </button>
            </div>

            <AdvancedDrawer
              showAdvancedDrawer={showAdvancedDrawer}
              onClose={() => setShowAdvancedDrawer(false)}
              currentQuestion={row}
              onToggleAdvanced={(val) => {
                const newQuestions = [...stateAddQuestions.questions];
                const updatedQuestion = {...row, 
                  advanced_enabled: val,
                  depth: row.depth || 2,
                  helpfulness: row.helpfulness || 2,
                  stopping_points: row.stopping_points || []
                };
                newQuestions[idx] = updatedQuestion;
                setAddQuestions({...stateAddQuestions, questions: newQuestions});
              }}
              onChangeDepth={(value) => {
                const newQuestions = [...stateAddQuestions.questions];
                newQuestions[idx] = {...row, depth: value};
                setAddQuestions({...stateAddQuestions, questions: newQuestions});
              }}
              onChangeHelpfulness={(value) => {
                const newQuestions = [...stateAddQuestions.questions];
                newQuestions[idx] = {...row, helpfulness: value};
                setAddQuestions({...stateAddQuestions, questions: newQuestions});
              }}
              onEditStoppingPoint={(val, pointIdx) => {
                const newQuestions = [...stateAddQuestions.questions];
                const newStoppingPoints = [...(row.stopping_points || [])];
                newStoppingPoints[pointIdx] = val;
                newQuestions[idx] = {...row, stopping_points: newStoppingPoints};
                setAddQuestions({...stateAddQuestions, questions: newQuestions});
              }}
              onRemoveStoppingPoint={(pointIdx) => {
                const newQuestions = [...stateAddQuestions.questions];
                const newStoppingPoints = [...(row.stopping_points || [])];
                newStoppingPoints.splice(pointIdx, 1);
                newQuestions[idx] = {...row, stopping_points: newStoppingPoints};
                setAddQuestions({...stateAddQuestions, questions: newQuestions});
              }}
              onAddStoppingPoint={() => {
                const newQuestions = [...stateAddQuestions.questions];
                const newStoppingPoints = [...(row.stopping_points || []), ''];
                newQuestions[idx] = {...row, stopping_points: newStoppingPoints};
                setAddQuestions({...stateAddQuestions, questions: newQuestions});
              }}
              onChangeQuestionType={(val) => {
                const newQuestions = [...stateAddQuestions.questions];
                newQuestions[idx] = {...row, type: val};
                setAddQuestions({...stateAddQuestions, questions: newQuestions});
              }}
              onChangeRoleplayContext={(val) => {
                const newQuestions = [...stateAddQuestions.questions];
                newQuestions[idx] = {...row, roleplay_context: val};
                setAddQuestions({...stateAddQuestions, questions: newQuestions});
              }}
            />
          </>
        </TableCell>
        <TableCell sx={{ 
          borderRight: '1px solid #E5E7EB', 
          borderTop: '1px solid #E5E7EB', 
          borderBottom: '1px solid #E5E7EB', 
          padding: '0px', 
          paddingLeft: '10px',
          paddingRight: '10px',
          borderTopRightRadius: '8px', 
          borderBottomRightRadius: '8px',
          width: '10px'
        }}>
          <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
            <Box sx={{ position: 'relative' }}>
              <IconButton
                onClick={(e) => {
                  e.stopPropagation(); // Prevent row click when clicking menu
                  setAnchorEl(e.currentTarget);
                }}
                sx={{
                  color: '#656565',
                  '&:hover': {
                    backgroundColor: '#F3F4F6'
                  }
                }}
              >
                <MoreVertIcon style={{ fontSize: '20px' }}/>
              </IconButton>
              <Menu
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={() => {
                  setAnchorEl(null);
                  setShowDrawer(false);
                }}
                PaperProps={{
                  elevation: 0,
                  sx: {
                    overflow: 'visible',
                    filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.1))',
                    mt: 1,
                    borderRadius: '8px',
                    '& .MuiMenuItem-root': {
                      px: 2,
                      py: 1,
                      fontSize: '14px',
                      '&:hover': {
                        backgroundColor: '#F3F4F6'
                      }
                    }
                  }
                }}
                transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
              >
                <MenuItem onClick={() => {
                  setAddQuestions({
                    ...stateAddQuestions,
                    currentQuestion: {
                      question: row.question,
                      time_limit: row.time_limit,
                      editIndex: idx
                    }
                  });
                  setShowDrawer(true);
                  setAnchorEl(null);
                }}>
                  <img src={EditIconNewSvg} style={{ marginRight: '12px', width: '18px', height: '18px' }} />
                  Edit
                </MenuItem>
                <MenuItem 
                  onClick={() => {
                    const filteredQuestions = stateAddQuestions.questions.filter(question => question.id !== row.id);
                    setAddQuestions({
                      questions: filteredQuestions,
                      currentQuestion: {
                        question: '',
                        time_limit: 60,
                        editIndex: -1
                      }
                    });

                    // Remove deleted question from any signals that reference it
                    if (addScoring.signals) {
                      const updatedSignals = addScoring.signals.map(signal => ({
                        ...signal,
                        associated_questions: signal.associated_questions?.filter(q => q.id !== row.id) || []
                      }));
                      setAddScoring({
                        ...addScoring,
                        signals: updatedSignals
                      });
                    }

                    setShowDrawer(false);
                    setAnchorEl(null);
                  }}
                  sx={{ color: '#DC2626' }}
                >
                  <img src={DeleteIconSvg} style={{ marginRight: '12px', width: '18px', height: '18px' }} />
                  <span style={{ color: '#DC2626' }}>Delete</span>
                </MenuItem>
              </Menu>
            </Box>
          </div>
        </TableCell>
      </TableRow>
    </>
  );
};

export default function CreateInterviewV2() {
    const { user, isAuthenticated, isLoading } = useAuth0();
    const [activeStep, setActiveStep] = useState(-1)
    const [skipped, setSkipped] = useState(new Set())
    const [errMsgs, setErrMsgs] = useState('')
    const [questionDrawerErrMsgs, setQuestionDrawerErrMsgs] = useState([])
    const [outputDrawerErrMsgs, setOutputDrawerErrMsgs] = useState([])
    const [creationFlow, setCreationFlow] = useState('');
    const [loadingPreview, setLoadingPreview] = useState(false);
    const [currentPreviewQuestion, setCurrentPreviewQuestion] = useState({});
    const [interviewPreview, setInterviewPreview] = useState([])
  
    // "Role Background"
    const [roleBackground, setRoleBackground] = useState({
      interviewTitle: '',
      assessmentLanguage: Language.ENGLISH,
      jobTitle: '',
      interviewLength: 10
    })
  
    // "Add Questions"
    const [addQuestions, setAddQuestions] = useState({
      questions: [],
      currentQuestion: {
        question: 'What has been the highlight of your past role?',
        time_limit: 60,
        editIndex: -1,
      },
      edit: false
    })
    const [showAddQuestionInput, setShowAddQuestionInput] = useState(false)

    const getTranslatedOpening = (jobTitle, language) => {
      switch (language) {
        case 'English':
          return "We will be asking you questions about the " + jobTitle + " role. Is that ok?"
        case 'Chinese':
          return "我们将向您询问有关 " + jobTitle + " 角色的问题。 没事吧？"
        case 'French':
          return "Nous vous poserons des questions sur le rôle de " + jobTitle + ". C'est bon?"
        case 'German':
          return "Wir werden Ihnen Fragen zur " + jobTitle + "-Rolle stellen. Ist das okay?"
        case 'Hindi':
          return "हम आपको " + jobTitle + " के अभियान के बारे में सवाल पूछेंगे। ठीक है?"
        case 'Spanish':
          return "Le haremos preguntas sobre el papel de " + jobTitle + ". ¿Está bien?"
        default:
          return "We will be asking you questions about the " + jobTitle + " role. Is that ok?"
      }
    }

    const getTranslatedDefault = (language) => {
      switch (language) {
        case 'English':
          return "Tell me a little bit about yourself."
        case 'Chinese':
          return "告诉我一些关于你自己的事情。"
        case 'French':
          return "Parlez-moi un peu de vous."
        case 'German':
          return "Sprechen Sie ein wenig über sich selbst."
        case 'Hindi':
          return "आपको आपके बारे में थोड़ा बात करने दें।"
        case 'Spanish':
          return "Habla un poco sobre ti."
        default:
          return "Tell me a little bit about yourself."
      }
    }

    // Add useEffect to handle greeting questions
    useEffect(() => {
      if (roleBackground.jobTitle) {
        setAddQuestions(prevState => {
          // Remove any existing greeting questions
          const nonGreetingQuestions = prevState.questions.filter(q => !(q.isOpening || q.isDefault));
          
          // Prepend new greeting questions
          const greetingQuestions = [
            {
              question: getTranslatedOpening(roleBackground.jobTitle, roleBackground.assessmentLanguage),
              time_limit: 15,
              editIndex: 0,
              isOpening: true,
              id: 'opening'
            },
            {
              question: getTranslatedDefault(roleBackground.assessmentLanguage),
              time_limit: 120,
              editIndex: 1,
              isDefault: true,
              advanced_enabled: true,
              helpfulness: 1,
              depth: 2,
              id: 'default'
            }
          ];

          return {
            ...prevState,
            questions: [...greetingQuestions, ...nonGreetingQuestions]
          };
        });
      }
    }, [roleBackground.jobTitle, roleBackground.assessmentLanguage]);
  
    // "Add Outputs"
    const [addOutputs, setAddOutputs] = useState({
      outputs: [{ key: 'Past Employers', data_type: 'list', extraction_instructions: 'get a list of past employers the candidate has worked for'}],
      currentOutput: {
        output: '',
        data_type: 'List',
        extraction_instructions: ''
      },
      editIndex: -1
    })
    const [showAddOutputInput, setShowAddOutputInput] = useState(false)
  
    // "Add Scoring"
    const [scoringErrorMsgs, setScoringErrorMsgs] = useState([]);

    const [addScoring, setAddScoring] = useState({
      signals: [
        {
          "signal": "English Proficiency",
          "criteria": "\t•\tClarity: Speaks clearly with minor accents or speech issues that do not significantly hinder understanding.\n\t•\tVocabulary: Uses a good range of vocabulary, including some construction-specific terms, with occasional minor errors.\n\t•\tComprehension: Understands most questions and instructions with little need for repetition or clarification.\n\t•\tResponsiveness: Responds appropriately to questions and demonstrates a good understanding of the conversation context.",
          "scale": 4,
          "weight": 0,
          "associated_questions": []
        }
      ],
      currentSignal: {
        'signal': '',
        'scale': 4,
        'criteria': '',
        'weight': 0, // percentage,
        'editIndex': -1,
        'associated_questions': []
      }
    })
  
    // "Review Test"
    const [reviewTest, setReviewTest] = useState({
      antiCheating: true,
      videoRecording: true,
      audioRecording: true,
      prompt: ''
    })
  
    const [validations, setValidations] = useState({
      roleBackground: {
        valid: false,
        msgs: []
      },
      addQuestions: {
        valid: false,
        msgs: []
      },
      addScoring: {
        valid: false,
        msgs: []
      },
      addOutputs: {
        valid: false,
        msgs: []
      },
      reviewTest: {
        valid: false,
        msgs: []
      }
    })

    useEffect(() => {
      setValidations(prev => ({
        ...prev,
        roleBackground: {
          valid: roleBackground.interviewTitle.length > 0 && roleBackground.jobTitle.length > 0,
          msgs: []
        }
      }))
    }, [roleBackground])

    useEffect(() => {
      setValidations(prev => ({
        ...prev,
        addQuestions: {
          valid: addQuestions.questions.length > 0,
          msgs: []
        }
      }))
    }, [addQuestions])

    useEffect(() => {
      setValidations(prev => ({
        ...prev,
        addScoring: {
          valid: true,
          msgs: []
        }
      }))
    }, [addScoring])

    useEffect(() => {
      setValidations(prev => ({
        ...prev,
        addOutputs: {
          valid: true,
          msgs: []
        }
      }))
    }, [addOutputs])

    useEffect(() => {
      setValidations(prev => ({
        ...prev,
        reviewTest: {
          valid: prev.roleBackground.valid && prev.addQuestions.valid && prev.addScoring.valid && prev.addOutputs.valid,
          msgs: []
        }
      }))
    }, [reviewTest, roleBackground, addQuestions, addScoring, addOutputs])

    const [showAddSignalInput, setShowAddSignalInput] = useState(false)
    const [showAddSignalAssistInput, setShowAddSignalAssistInput] = useState(false);
    const [signalAssistantDescription, setSignalAssistantDescription] = useState("");
    const [signalAssistantSelectedQuestions, setSignalAssistantSelectedQuestions] = useState([]);
    const [selectedPredefinedSignal, setSelectedPredefinedSignal] = useState("");
    const [showScoringDrawer, setShowScoringDrawer] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);
    const [scoringGenerating, setScoringGenerating] = useState(false);
    const [snackbarMsg, setSnackbarMsg] = useState('');
    const [expanded, setExpanded] = useState(false)
  
    const [fetched, setFetched] = useState(false)
    useEffect(() => {
      const urlParams = new URLSearchParams(window.location.search);
      const copyFrom = urlParams.get('copy_from');
      if(!isLoading && isAuthenticated && copyFrom !== undefined && !fetched) {
        setFetched(true)
        var userId = user.email
        const teamId = localStorage.getItem('teamId')
        const overrideUserLocal = localStorage.getItem('globalOverrideUser')
        if(overrideUserLocal != null) {
          userId = overrideUserLocal
        }
        api.getInterview(copyFrom, userId, teamId)
        .then((response) => {
          let questions = response.data.questions
          questions.shift()
  
          setRoleBackground({
            assessmentLanguage: Language.ENGLISH,
            interviewTitle: response.data.title,
            jobTitle: '',
            interviewLength: 10
          })
  
          setAddQuestions({
            ...addQuestions,
            questions: questions,
          })
  
          setAddScoring({
            ...addScoring,
            signals: response.data.scorecard
          })
  
          setAddOutputs({
            ...addOutputs,
            outputs: response.data.structuredOutputs
          })
  
          setReviewTest({
            prompt: response.data.prompt
          })
  
          // redirect to self-create flow if questions populated from copy
          setCreationFlow('self');
          setActiveStep(0);
        })
        .catch((err) => {
          console.log(err)
        })
      }
    }, [isLoading, isAuthenticated, user, fetched, setFetched,
    addQuestions, setAddQuestions, addScoring, setAddScoring, addOutputs, setAddOutputs, setReviewTest]);
  
    const steps = [
      {
        'label': 'Details',
        'icon': DetailsSidebarIcon,
        'valid': validations.roleBackground.valid
      },
      {
        'label': 'Questions',
        'icon': QuestionsSidebarIcon,
        'valid': validations.addQuestions.valid
      },
      {
        'label': 'Scoring',
        'icon': ScoringSidebarIcon,
        'valid': validations.addScoring.valid
      },
      {
        'label': 'Outputs',
        'icon': OutputsSidebarIcon,
        'valid': validations.addOutputs.valid
      },
      {
        'label': 'Review',
        'icon': ReviewSidebarIcon,
        'valid': validations.reviewTest.valid
      }
    ]
    const navigate = useNavigate();
    const sensors = useSensors(
      useSensor(MouseSensor, {}),
      useSensor(TouchSensor, {}),
      useSensor(KeyboardSensor, {})
    )
  
  
    const createInterview = () => {
      if (user) {
        const interviewConfig = {
          version: 2,
          title: roleBackground.interviewTitle,
          language: roleBackground.assessmentLanguage,
          jobTitle: roleBackground.jobTitle,
          questions: addQuestions.questions,
          scorecard: addScoring.signals,
          prompt: reviewTest.prompt,
          structuredOutputs: addOutputs.outputs,
        };
  
        var userId = user.email
        const overrideUserLocal = localStorage.getItem('globalOverrideUser')
        if(overrideUserLocal != null) {
          userId = overrideUserLocal
        }
        const payload = {
          userId: userId,
          teamId: localStorage.getItem('teamId'),
          interviewConfig: interviewConfig,
        };
        api
          .createInterview(payload)
          .then((response) => {
            navigate("/interviews/" + response.data.id);
          })
          .catch((error) => {
            console.log(error);
          });
      } else {
        console.log('User not logged in');
      }
    };
  
    const isStepOptional = (step) => {
      return false;
    };
  
    const isStepSkipped = (step) => {
      return skipped.has(step);
    };
  
    const interviewDetailsValid = () => {
      let valid = true
      let msgs = []
  
      if(roleBackground.assessmentLanguage.length === 0 || roleBackground.interviewTitle.length === 0 || roleBackground.jobTitle.length === 0) {
        valid = false
        msgs.push('Please fill in all fields')
      }
  
      return {
        isValid: valid,
        msgs: msgs
      }
    }
  
    const questionDetailsValid = () => {
      let valid = true
      let msgs = []
  
      if(addQuestions.questions.length === 0) {
        valid = false
        msgs.push('You must add at least one question before proceeding.')
      }
  
      return {
        isValid: valid,
        msgs: msgs
      }
    }
  
    const scoringDetailsValid = () => {
      let msgs = []
      let valid = true
  
      return {
        isValid: valid,
        msgs: msgs
      }
    }
  
    const outputsDetailsValid = () => {
      let msgs = []
      let valid = true
  
      return {
        isValid: valid,
        msgs: msgs
      }
    }
  
    const reviewValid = () => {
      let valid = true
      let msgs = []
  
      return {
        isValid: valid,
        msgs: msgs
      }
    }

    const saveScoring = (isEdit) => {
      // add validations
      if (addScoring.currentSignal.signal === '') {
        setScoringErrorMsgs(['Signal name cannot be empty']  );
        return false;
      }
  
      // no criteria must be missing (1-4)
      if (addScoring.currentSignal.criteria === '') {
        setScoringErrorMsgs(['Criteria for signal is required']);
        return false;
      }
      if (isEdit) {
        const newSignals = [...addScoring.signals];
        newSignals[addScoring.currentSignal.editIndex] = {
          ...addScoring.currentSignal,
          editIndex: undefined
        };
        // Check if any changes were made by comparing with original signal
        const originalSignal = addScoring.signals[addScoring.currentSignal.editIndex];
        const hasChanges = originalSignal.signal !== addScoring.currentSignal.signal || 
          originalSignal.criteria !== addScoring.currentSignal.criteria || 
          originalSignal.associated_questions !== addScoring.currentSignal.associated_questions;
  
        if (!hasChanges) {
          setSnackbarMsg('No changes made');
        } else {
          setAddScoring({
            ...addScoring,
            signals: newSignals,
            currentSignal: {
              'signal': '',
              'scale': 4,
              'criteria': '',
              'weight': 0,
              'associated_questions': [],
              'editIndex': -1
            }
          });
          setSnackbarMsg('Signal updated successfully');
        }
      } else {
        setAddScoring({
          ...addScoring,
          signals: [...addScoring.signals, {
            ...addScoring.currentSignal,
            editIndex: undefined
          }],
          currentSignal: {
            'signal': '',
            'scale': 4,
            'criteria': '',
            'weight': 0,
            'associated_questions': [],
            'editIndex': -1
          }
        });
        setSnackbarMsg('Signal added successfully');
      } 
      setScoringErrorMsgs([]);
      return true
    }
  
    const handleNext = () => {
      let newSkipped = skipped;
      if (isStepSkipped(activeStep)) {
        newSkipped = new Set(newSkipped.values());
        newSkipped.delete(activeStep);
      }
  
      // form validation
      if(activeStep === 0) {
        // validate Interview Details
        const { isValid, msgs } = interviewDetailsValid()
        if(!isValid) {
          setErrMsgs(msgs)
          return
        }
      } else if (activeStep === 1) {
        // validate question details
        const { isValid, msgs } = questionDetailsValid()
        if(!isValid) {
          setErrMsgs(msgs)
          return
        }
      } else if (activeStep === 2) {
        // validate scoring details
        const { isValid, msgs } = scoringDetailsValid()
        if(!isValid) {
          setErrMsgs(msgs)
          return
        }
      } else if (activeStep === 3) {
        // validate outputs details
        const { isValid, msgs } = outputsDetailsValid()
        if(!isValid) {
          setErrMsgs(msgs)
          return
        }
      } else if (activeStep === 4) {
        // validate review test details
        const { isValid, msgs } = reviewValid()
        if(!isValid) {
          setErrMsgs(msgs)
          return
        }
        createInterview();
      }
  
      setErrMsgs([])
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
      setSkipped(newSkipped);
    };
  
    const handleBack = () => {
      if (activeStep == 0) {
        setCreationFlow('')
      }
      setErrMsgs([])
      setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };
  
    const handleSkip = () => {
      if (!isStepOptional(activeStep)) {
        // You probably want to guard against something like this,
        // it should never occur unless someone's actively trying to break something.
        throw new Error("You can't skip a step that isn't optional.");
      }
  
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
      setSkipped((prevSkipped) => {
        const newSkipped = new Set(prevSkipped.values());
        newSkipped.add(activeStep);
        return newSkipped;
      });
    };
  
    const handleDragEnd = (event) => {
      const { active, over } = event;
  
      if (active.id !== over.id) {
        setAddQuestions((prevState) => {
          const oldIndex = prevState.questions.findIndex((question) => question.id === active.id);
          const newIndex = prevState.questions.findIndex((question) => question.id === over.id);
          return {
            ...prevState,
            questions: arrayMove(prevState.questions, oldIndex, newIndex),
          };
        });
      }
    };
  
    const renderFlowPrompt = () => {
      return <CreateInterviewHome setCreationFlow={setCreationFlow}/>
    }
  
    const renderJDUpload = () => {
      return <CreateInterviewJD
        setCreationFlow={setCreationFlow}
        setActiveStep={setActiveStep}
        setAddQuestions={setAddQuestions}
        setAddScoring={setAddScoring}
        setAddOutputs={setAddOutputs}
        setShowAddQuestionInput={setShowAddQuestionInput}
        setRoleBackground={setRoleBackground}
        roleBackground={roleBackground}
        showInterviewSetup={true}
        sx={{ width: '800px' }}
      />
    }
  
    const renderTemplateFlow = () => {
      return <CreateInterviewTemplate
        setCreationFlow={setCreationFlow}
        setActiveStep={setActiveStep}
        setAddQuestions={setAddQuestions}
        setAddScoring={setAddScoring}
        setAddOutputs={setAddOutputs}
        setShowAddQuestionInput={setShowAddQuestionInput}
        setRoleBackground={setRoleBackground}
        roleBackground={roleBackground}
        showInterviewSetup={true}
        sx={{ width: '800px' }}
      />
    }
  
    const renderRoleBackground = () => {
      return (
        <Box container className="mx-auto d-flex flex-col" style={{ 
          flex: 1,
          maxHeight: '95%',
          height: '95%',
          backgroundColor: 'white',
          maxWidth: '1200px',
          border: '1px solid lightgray',
          padding: '24px',
          borderRadius: '6px',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center'
        }}>
          <div className="d-flex flex-row" style={{ width: '100%', gap: '48px', height: '100%' }}>
            {/* Left Column */}
            <div className="d-flex flex-col" style={{ flex: 1 }}>
              <h3 className="section" style={{ marginTop: 0, fontSize: '20px', fontWeight: '600' }}>Interview details</h3>

              <FormField
                required
                label="Interview Title"
                tooltip
                tooltipTitle={<>
                  <p className="m-auto">
                    This is the title of the interview that only you will see.
                  </p>
                </>}
                placeholder="Retail Sales Associate Case Study"
                value={roleBackground.interviewTitle}
                onChange={(e) => {setRoleBackground({ ...roleBackground, interviewTitle: e.target.value })}}
                onKeyDown={(e) => {
                  if ((e.metaKey || e.ctrlKey) && e.key === 'Enter') {
                    setActiveStep(1);
                  }
                }}
              />

              <FormField
                required
                label="Job Title"
                tooltip
                tooltipTitle={<>
                  <p className="m-auto">
                    This is the title of the job that will be displayed to the candidate.
                  </p>
                </>}
                placeholder="Retail Sales Associate"
                value={roleBackground.jobTitle}
                onChange={(e) => {setRoleBackground({ ...roleBackground, jobTitle: e.target.value })}}
                onKeyDown={(e) => {
                  if ((e.metaKey || e.ctrlKey) && e.key === 'Enter') {
                    setActiveStep(1);
                  }
                }}
              />

              <div className="d-flex flex-col" style={{ marginBottom: '16px' }}>
                <h5 className="input-label" style={{ fontSize: '14px', fontWeight: 500, color: 'var(--color-gray)' }}>Language</h5>
                <LanguageSelector currentLanguage={roleBackground.assessmentLanguage} languages={supportedLanguages} 
                  clicked={(e) => {setRoleBackground({ ...roleBackground, assessmentLanguage: e })}} />
              </div>

              <Button
                onClick={() => setActiveStep(1)}
                disabled={roleBackground.interviewTitle.length === 0 || roleBackground.jobTitle.length === 0}
                onSubmit={(e) => {
                  e.preventDefault();
                  if ((e.metaKey || e.ctrlKey) && e.key === 'Enter') {
                    setActiveStep(1);
                  }
                }}
                variant="contained"
                sx={{
                  // width: 'fit-content',
                  backgroundColor: '#000000',
                  color: '#FFFFFF',
                  textTransform: 'none',
                  fontWeight: 600,
                  fontSize: '14px',
                  '&:hover': {
                    backgroundColor: '#333333'
                  },
                  px: 2,
                  py: 1,
                  borderRadius: '6px',
                  // marginLeft: 'auto',
                  marginTop: '16px',
                  // display: 'block'
                }}
              >
                Next step
              </Button>
            </div>

            {/* Right Column */}
            <div className="d-flex flex-col" style={{ flex: 1, justifyContent: 'center', alignItems: 'center', overflow: 'hidden' }}>
              <img src={InterviewDetailsGraphic} alt="Interview Setup" style={{ 
                width: '100%', 
                height: '100%', 
                objectFit: 'none',
                objectPosition: 'left top'
              }} />
            </div>
          </div>
        </Box>
      )
    }
  
    function uuidv4() {
      return "10000000-1000-4000-8000-100000000000".replace(/[018]/g, c =>
        (+c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> +c / 4).toString(16)
      );
    }
  
    const triggerShowAddModal = (type) => {
      setErrMsgs([]);
      switch (type) {
        case 'question':
          return setShowAddQuestionInput(true);
        case 'signal':
          return setShowAddSignalInput(true);
        case 'signal-assist':
          return setShowAddSignalAssistInput(true);
        case 'output':
          return setShowAddOutputInput(true)
        default:
          return;
      }
    }
  
    const cancelShowAddModal = (type) => {
      setErrMsgs([]);
      switch (type) {
        case 'question':
          return setShowAddQuestionInput(false);
        case 'signal':
          return setShowScoringDrawer(false);
        case 'signal-assist':
          return setShowAddSignalAssistInput(false);
        case 'output':
          return setShowAddOutputInput(false)
        default:
          return;
      }
    }
  
    const saveAddQuestion = (currentQuestion) => {
      // Validate question
      if (!currentQuestion.question || currentQuestion.question.trim() === '') {
        setQuestionDrawerErrMsgs(['Question cannot be empty']);
        return false;
      }

      let newQuestions = [...addQuestions.questions];

      if (currentQuestion.editIndex >= 0) {
        if (currentQuestion.question !== newQuestions[currentQuestion.editIndex].question || 
          currentQuestion.time_limit !== newQuestions[currentQuestion.editIndex].time_limit) {
          setSnackbarMsg('Question updated!');
          
          // Update associated questions in signals when question text changes
          const oldQuestion = newQuestions[currentQuestion.editIndex].question;
          const newSignals = addScoring.signals.map(signal => {
            if (signal.associated_questions.includes(oldQuestion)) {
              return {
                ...signal,
                associated_questions: signal.associated_questions.map(q => 
                  q === oldQuestion ? currentQuestion.question : q
                )
              };
            }
            return signal;
          });

          setAddScoring({
            ...addScoring,
            signals: newSignals
          });

        } else {
          setSnackbarMsg('No changes made');
        }
        // Modify existing question
        newQuestions[currentQuestion.editIndex] = {
          ...newQuestions[currentQuestion.editIndex],
          question: currentQuestion.question,
          time_limit: currentQuestion.time_limit
        };
      } else {
        // Add new question
        const randomId = uuidv4();
        newQuestions.push({
          question: currentQuestion.question,
          time_limit: currentQuestion.time_limit,
          id: randomId
        });
        setSnackbarMsg('Question added!');
      }

      setAddQuestions({
        ...addQuestions,
        questions: newQuestions,
        currentQuestion: {
          question: '',
          time_limit: 60,
          editIndex: -1
        }
      });

      setQuestionDrawerErrMsgs([]);
      setShowAddQuestionInput(false)
      
      return true;
    }
  
    function groupBy(arr, property) {
      return arr.reduce((acc, cur) => {
        acc[cur[property]] = [...acc[cur[property]] || [], cur];
        return acc;
      }, {});
    }
  
    function sectionSort(a, b) {
      const top_val = 'Introduction'
      const null_val = 'undefined'
  
      // top_val comes first
      if (a == top_val) return -1;
      if (b == top_val) return 1;
  
  
      // nulls sort after anything else
      if (a === null_val) {
        return 1;
      }
      if (b === null_val) {
          return -1;
      }
  
      // neither matches `value` - compare them normally
      return a.localeCompare(b);
    }
  
    const setSelectedPreview = async (question) => {
      setCurrentPreviewQuestion({
        topic: question.question,
        time_limit: question.time_limit
      })
  
      if (question.sample) {
        setInterviewPreview(question.sample);
      } else {
        let sample = await callGenerateConversationPreview({
          topic: question.question,
          time_limit: String(question.time_limit),
          context_type: 'question_preview',
          job_title: roleBackground.jobTitle, 
          title: roleBackground.interviewTitle
        })
        if (sample) setInterviewPreview(sample);
        else setCurrentPreviewQuestion({});
        question = {...question, sample}
        updateInAddQuestions(question.id, question)
      }
    }
  
    const updateInAddQuestions = async (rowId, updatedRow) => {
      let updatedQuestions = [...addQuestions.questions];
      let updatedIndex = updatedQuestions.findIndex((question) => question.id == rowId)
      if (updatedIndex >= 0) updatedQuestions[updatedIndex] = updatedRow
      else updatedQuestions = [...updatedQuestions, updatedRow]
      setAddQuestions({ questions: updatedQuestions, currentQuestion: { question: '', time_limit: 60 }})
    }
  
    const callGenerateConversationPreview = ({topic, time_limit, context_type, job_title, title }) => {
      setLoadingPreview(true);
      return api.generateConversationPreview({ topic, time_limit, context_type, job_title, title })
      .then((response) => {
        setLoadingPreview(false);
        return(response.data.sample)
      })
      .catch((error) => {
        console.log(error)
        setLoadingPreview(false);
        return null;
      })
    }
    
    const thumbVLight = ({ style, ...props }) => (
      <div style={{ ...style, backgroundColor: '#d6d6d6', borderRadius: '10px', cursor: 'pointer' }} {...props} />
    );
  
    const renderAddQuestions = () => {
      // Calculate total interview time
      const totalInterviewTime = addQuestions.questions.reduce((total, question) => {
        return total + (question.time_limit || 0);
      }, 0);

      return (
        <DndContext
          collisionDetection={closestCenter}
          modifiers={[restrictToVerticalAxis]}
          onDragEnd={handleDragEnd}
          sensors={sensors}
        >
          <div className="d-flex flex-col mx-auto" style={{ flex: 3, paddingTop: '40px', paddingBottom: '80px', width: '100%', minHeight: '100vh', marginTop: '-40px' }}>
            {/* Interview Questions Display */}
            <Paper className="d-flex flex-col" style={{ flex: 1, minHeight: '400px', padding: '25px', margin: 'auto', width: '100%', boxShadow: 'none', borderRadius: '6px', border: '1px solid #D9D9D9', maxWidth: '1200px' }}>
              <div className="d-flex flex-col">
                <div className="d-flex flex-row align-items-center">
                  <p className="my-auto" style={{ fontSize: '20px', fontWeight: '600' }}>Questions</p>
                  <div style={{
                    margin: '0 auto',
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    justifyContent: 'center',
                    gap: '12px'
                  }}>
                    <div style={{
                      display: 'flex',
                      alignItems: 'center',
                      backgroundColor: "#F2DA02", 
                      padding: '12px 18px',
                      borderRadius: '8px',
                      width: 'fit-content',
                      height: '42px'
                    }}>
                      <Typography style={{
                        fontSize: '14px',
                        fontWeight: '600',
                        color: "black",
                      }}>
                        Questions: {addQuestions.questions.length} 
                      </Typography>
                    </div>                    
                    <div
                      style={{
                        backgroundColor: "#F2DA02",
                        borderRadius: "8px",
                        fontSize: "14px", 
                        fontWeight: "600",
                        padding: "12px 18px",
                        color: "black",
                        display: "flex",
                        alignItems: "center",
                        height: '42px'
                      }}
                    >
                    <AccessTimeIcon style={{fontSize: "14px", marginRight: "6px"}} />
                      <Typography style={{ fontSize: '14px', fontWeight: '600' }}>
                        Interview Length: {(totalInterviewTime / 60).toFixed(0)} {(totalInterviewTime / 60).toFixed(0) === '1' ? 'minute' : 'minutes'}
                      </Typography>
                    </div>
                  </div>
              
                  <button className="btn-main" onClick={() => {
                      setAddQuestions({
                        ...addQuestions,
                        currentQuestion: {
                          question: '',
                          time_limit: 60,
                          editIndex: -1
                        }
                      });
                      triggerShowAddModal('question')
                    }}>
                    Add Question
                      <Add className="my-auto" fontSize="16px" style={{ marginLeft: '8px' }}/>
                </button>
              </div>
              </div>

              <Scrollbars 
                renderThumbVertical={({ style, ...props }) => (
                  <div {...props} style={{ 
                    ...style, 
                    backgroundColor: 'rgba(0,0,0,0.1)', // Very subtle gray
                    borderRadius: '4px',
                    width: '4px',
                    cursor: 'pointer'
                  }}/>
                )}
                style={{ 
                  transition: 'all .3s ease',
                  '& .track-vertical': {
                    backgroundColor: 'transparent',
                    width: '4px',
                    right: '2px',
                    bottom: '2px', 
                    top: '2px',
                    borderRadius: '3px'
                  },
                  '& .thumb-vertical:hover': {
                    backgroundColor: 'rgba(0,0,0,0.15)' // Slightly darker on hover
                  }
                }}>
                {addQuestions.questions.length === 0 && (
                  <div style={{
                    marginTop: '16px',
                    width: '100%',
                    padding: '24px', 
                    border: '1px solid #E5E7EB',
                    borderRadius: '8px',
                    textAlign: 'center',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    color: '#6B7280',
                    marginBottom: '16px',
                    height: '95%'
                  }}>
                    No questions defined.
                  </div>
                )}
                <Grid container>
                  <TableContainer style={{ padding: 0, width: '100%', marginTop: '16px' }}>
                    <Table style={{ padding: 0, margin: 0, width: '100%', borderCollapse: 'separate', borderSpacing: '0 8px' }}>
                      <TableBody>
                        <SortableContext
                          items={addQuestions.questions.map((question) => {return question.id })}
                          strategy={verticalListSortingStrategy}
                        >
                        {addQuestions.questions.map((question, idx) => (
                          <DraggableRow
                            idx={idx}
                            row={question}
                            setAddQuestions={setAddQuestions}
                            stateAddQuestions={addQuestions}
                            setShowDrawer={setShowAddQuestionInput}
                            addScoring={addScoring}
                            setAddScoring={setAddScoring}
                          />
                        ))}
                        </SortableContext>
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Grid>
              </Scrollbars>

              <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '24px', gap: '12px' }}>
                <button
                  onClick={handleBack}
                  className="btn-main outlined-gray"
                >
                  Previous step
                </button>
                <button
                  className="btn-main"
                  onClick={handleNext}
                >
                  Next step
                </button>
              </div>
            </Paper>
          </div>
          <QuestionDrawer
            open={showAddQuestionInput}
            onClose={() => {
              setShowAddQuestionInput(false);
              setQuestionDrawerErrMsgs([]);
            }}
            question={addQuestions.currentQuestion}
            onSave={saveAddQuestion}
            isEdit={addQuestions.currentQuestion.editIndex !== undefined && addQuestions.currentQuestion.editIndex >= 0}
            errMsgs={questionDrawerErrMsgs}
          />
        </DndContext>
      )
    }
  
    const saveAddStructuredOutput = () => {
      if (!addOutputs.currentOutput.extraction_instructions || !addOutputs.currentOutput.data_type || !addOutputs.currentOutput.key) {
        setOutputDrawerErrMsgs(['All fields must be filled in']);
        return false;
      }
      const isDuplicate = addOutputs.outputs.some((output, index) => 
        index !== addOutputs.currentOutput.editIndex && 
        output.key === addOutputs.currentOutput.key && 
        output.data_type === addOutputs.currentOutput.data_type
      );
      if (isDuplicate) {
        setOutputDrawerErrMsgs(['This structured output already exists']);
        return false;
      }
  
      if(addOutputs.currentOutput.editIndex > -1) {
        // Check if anything has changed
        const originalOutput = addOutputs.outputs[addOutputs.currentOutput.editIndex];
        const hasChanges = originalOutput.extraction_instructions !== addOutputs.currentOutput.extraction_instructions ||
          originalOutput.data_type !== addOutputs.currentOutput.data_type ||
          originalOutput.key !== addOutputs.currentOutput.key;

        if (!hasChanges) {
          setSnackbarMsg('No changes made');
        } else {
        let tmpOutputs = JSON.parse(JSON.stringify(addOutputs.outputs))
          tmpOutputs[addOutputs.currentOutput.editIndex] = {
          extraction_instructions: addOutputs.currentOutput.extraction_instructions,
          data_type: addOutputs.currentOutput.data_type,
          key: addOutputs.currentOutput.key
        }
        setAddOutputs({
          ...addOutputs,
          outputs: tmpOutputs,
            currentOutput: { extraction_instructions: '', data_type: '', key: '', editIndex: -1 },
        })
          setSnackbarMsg('Output updated successfully');
        }
      } else {
        setAddOutputs({ ...addOutputs, outputs: [...addOutputs.outputs, {
          extraction_instructions: addOutputs.currentOutput.extraction_instructions,
          data_type: addOutputs.currentOutput.data_type,
          key: addOutputs.currentOutput.key
        }], currentOutput: { extraction_instructions: '', data_type: '', key: '', editIndex: -1 }})
        setSnackbarMsg('Output added successfully');
      }
      setShowAddOutputInput(false)
      setOutputDrawerErrMsgs([]);
      return true;
    }
  
    const renderAddOutputs = () => {
      return (
        <div className="d-flex flex-col mx-auto" style={{ paddingTop: '40px', paddingBottom: '80px', width: '100%', minHeight: '100vh', marginTop: '-40px' }}>
          {/* Outputs Display */}
          <Paper className="d-flex flex-col mx-auto" style={{ padding: '25px', marginTop: '0px', width: '100%', boxShadow: 'none', borderRadius: '6px', border: '1px solid #D9D9D9', maxWidth: '1200px' }}>
            <div className="d-flex flex-row">
              <h3 style={{ fontSize: '20px', fontWeight: '600' }} className="my-auto">Outputs</h3>
            
              <div className="d-flex flex-row" style={{ marginLeft: 'auto', width: 'fit-content' }}>
                <button className="btn-main d-flex"
                  style={{
                    padding: '8px 16px',
                    backgroundColor: '#000000',
                    color: '#FFFFFF',
                    border: 'none', 
                    borderRadius: '8px',
                    cursor: 'pointer',
                    fontSize: '14px',
                    display: 'flex',
                    alignItems: 'center'
                  }}
                  onClick={() => {
                    setAddOutputs({
                      ...addOutputs,
                      currentOutput: { key: '', data_type: '', extraction_instructions: '' },
                      editIndex: -1
                    });
                    setShowAddOutputInput(true);
                  }}>
                  Add Output
                  <Add className="my-auto" fontSize="24px" style={{ marginLeft: '8px' }}/>
                </button>
              </div>
            </div>
            <p className="color-gray" style={{ fontSize: 14, margin: '0px', marginBottom: 16 }}>Extract some set of structured information out of the interview.</p>
            {addOutputs.outputs.length === 0 && (
              <p style={{
                width: '100%',
                padding: '24px',
                border: '1px solid #E5E7EB',
                borderRadius: '12px',
                textAlign: 'center',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                color: '#6B7280',
                marginTop: 0,
                marginBottom: 16,
                height: '200px',
                fontSize: '16px'
              }}>
                No outputs defined.
              </p>
            )}
            <Grid container>
              <TableContainer style={{ 
                padding: 0, 
                width: '100%', 
                margin: 0, 
                marginBottom: '24px',
                tableLayout: 'fixed' // Add this to enforce column widths
              }}>
                <Table style={{ 
                  padding: 0, 
                  margin: 0, 
                  width: '100%', 
                  borderCollapse: 'separate',
                  borderSpacing: '0 8px',
                  tableLayout: 'fixed' // Add this to enforce column widths
                }}>
                  <colgroup>
                    <col style={{ width: '25%' }} />
                    <col style={{ width: '40%' }} />
                    <col style={{ width: '15%' }} />
                    <col style={{ width: '20%' }} />
                  </colgroup>
                  <TableBody>
                    {addOutputs.outputs.map((output, idx) => (
                        <TableRow 
                          key={idx}
                          style={{
                            opacity: 1,
                            backgroundColor: 'white',
                            width: '100%',
                            position: 'static',
                            zIndex: 'auto',
                            cursor: 'pointer',
                            display: 'table-row',
                          }}                          
                          sx={{
                            '&:hover': {
                              backgroundColor: '#F9FAFB !important',
                              transition: 'background-color 0.2s ease'
                          },
                          }}      
                          onClick={() => {
                            setAddOutputs({
                              ...addOutputs,
                              currentOutput: { ...output, editIndex: idx }
                            });
                            setShowAddOutputInput(true);
                          }}
                        >
                          <TableCell sx={{
                            borderLeft: '1px solid #E5E7EB',
                            borderTop: '1px solid #E5E7EB', 
                            borderBottom: '1px solid #E5E7EB',
                            borderTopLeftRadius: '8px',
                            borderBottomLeftRadius: '8px',
                            padding: '12px',
                          fontWeight: '500',
                          fontSize: '14px',
                          whiteSpace: 'nowrap',
                          overflow: 'hidden',
                          textOverflow: 'ellipsis'
                          }}>{output.key}</TableCell>
                          <TableCell sx={{
                            borderTop: '1px solid #E5E7EB',
                            borderBottom: '1px solid #E5E7EB',
                            padding: '12px',
                          fontSize: '14px',
                          fontWeight: '400',
                          whiteSpace: 'nowrap',
                          overflow: 'hidden',
                          textOverflow: 'ellipsis'
                          }}>{output.extraction_instructions}</TableCell>
                          <TableCell sx={{
                            borderTop: '1px solid #E5E7EB',
                            borderBottom: '1px solid #E5E7EB',
                          padding: '12px',
                          fontSize: '14px',
                          fontWeight: '500',
                          whiteSpace: 'nowrap',
                          overflow: 'hidden',
                          textOverflow: 'ellipsis'
                        }}>
                          <div style={{
                            backgroundColor: '#F3F4F6',
                            padding: '4px 8px',
                            borderRadius: '8px',
                            width: 'fit-content'
                          }}>
                            {humanReadableOutputTypes[output.data_type]}
                          </div>
                        </TableCell>
                        <TableCell sx={{
                          borderRight: '1px solid #E5E7EB', 
                          borderTop: '1px solid #E5E7EB',
                          borderBottom: '1px solid #E5E7EB',
                          borderTopRightRadius: '8px',
                          borderBottomRightRadius: '8px',
                          padding: '12px',
                        }}>
                          <div style={{ display: 'flex', justifyContent: 'flex-end', gap: '8px' }}>
                            <IconButton
                              onClick={
                                (e) => {
                                  e.stopPropagation();
                                  setAnchorEl(null);
                                  setAddOutputs({
                                    ...addOutputs,
                                    currentOutput: { ...output, editIndex: idx }
                                  });
                                  setShowAddOutputInput(true);
                                }
                              }
                              className="drop-shadow-btn"
                              style={{ 
                                margin: '0px',
                                color: 'black',
                                fontSize: '14px', 
                                fontWeight: 500,
                                borderRadius: '9px',
                                border: '0.5px solid lightgray',
                                padding: '6px 9px 6px 8px'
                              }}
                            >
                              <img src={EditIconNewSvg} /> Edit
                            </IconButton>
                            <IconButton
                              onClick={
                                (e) => {
                                  e.stopPropagation();
                                  setAnchorEl(null);
                                  let newOutputs = addOutputs.outputs.filter((_, i) => i !== idx);
                                  setAddOutputs({
                                    ...addOutputs,
                                    outputs: newOutputs
                                  });
                                }
                              }
                              className="drop-shadow-btn"
                              style={{ 
                                background: '#FFF8F8',
                                color: '#F33D3D',
                                fontSize: '14px',
                                fontWeight: 500,
                                marginRight: '4px',
                                borderRadius: '9px', 
                                border: '0.5px solid lightgray',
                                padding: '6px 9px 6px 8px'
                              }}
                            >
                              <img src={DeleteIconNewSvg} /> Delete
                            </IconButton>
                          </div>
                        </TableCell>
                        </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
            <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: 'auto', gap: '12px' }}>
              <button
                onClick={handleBack}
                className="btn-main outlined-gray"
              >
                Previous step
              </button>
              <button
                className="btn-main"
                onClick={handleNext}
              >
                Next step
              </button>
            </div>            
          </Paper>

          {/* Add Output Drawer */}
          <OutputDrawer
            open={showAddOutputInput}
            onClose={() => {
              setShowAddOutputInput(false)
              setOutputDrawerErrMsgs([])
            }}
            onKeyDown={(e) => {
              if ((e.metaKey || e.ctrlKey) && e.key === 'Enter') {
                const saved = saveAddStructuredOutput();
                if (saved) {
                  setShowAddOutputInput(false);
                  setOutputDrawerErrMsgs([]);
                }
              }
            }}
            onChangeKey={(key) => setAddOutputs({ ...addOutputs, currentOutput: { ...addOutputs.currentOutput, key } })}
            onChangeInstructions={(instructions) => setAddOutputs({ ...addOutputs, currentOutput: { ...addOutputs.currentOutput, extraction_instructions: instructions } })}
            onChangeType={(type) => setAddOutputs({ ...addOutputs, currentOutput: { ...addOutputs.currentOutput, data_type: type } })}
            onSave={() => {
              const saved = saveAddStructuredOutput();
              if (saved) {
                setShowAddOutputInput(false);
                setOutputDrawerErrMsgs([]);
              }
            }}
            isEdit={addOutputs.currentOutput.editIndex > -1}
            errMsgs={outputDrawerErrMsgs}
            output={addOutputs.currentOutput}
          />
        </div>
      )
    }
  
    const handleDragEndScoring = (event) => {
      const { active, over } = event;
      if (active.id !== over.id) {
        setAddScoring(prevState => {
          const oldIndex = prevState.signals.findIndex(sig => (sig.id || '').toString() === active.id);
          const newIndex = prevState.signals.findIndex(sig => (sig.id || '').toString() === over.id);
          return {
            ...prevState,
            signals: arrayMove(prevState.signals, oldIndex, newIndex)
          };
        });
      }
    };
  
    const DraggableScoringRow = ({ idx, signal, stateAddScoring, setAddScoring, setShowScoringDrawer }) => {
      const { attributes, listeners, setNodeRef, transform, transition, isDragging } = useSortable({
        id: signal.id ? signal.id : idx.toString(),
      });
      const [anchorEl, setAnchorEl] = useState(null);
      const style = {
        transform: CSS.Transform.toString(transform),
        transition,
        opacity: isDragging ? 0.8 : 1,
        backgroundColor: 'white',
        margin: '8px 0', 
        width: '100%',
        position: isDragging ? 'relative' : 'static',
        zIndex: isDragging ? 1000 : 'auto',
        border: '1px solid black !important',
        borderRadius: '8px',
        cursor: 'pointer'
      };
      
      const handleEdit = () => {
        setAddScoring(prev => ({
          ...prev,
          currentSignal: { ...signal, criteria: signal.criteria, editIndex: idx }
        }));
        setShowScoringDrawer(true);
        setAnchorEl(null);
      };
      
      const handleDelete = (e) => {
        e.stopPropagation();
        setAddScoring(prev => ({
          ...prev,
          signals: prev.signals.filter((_, i) => i !== idx)
        }));
        setAnchorEl(null);
        setShowScoringDrawer(false);
      };
      
      return (
        <>
          <TableRow 
            ref={setNodeRef} 
            onClick={handleEdit}
            style={{
              ...style,
              border: '1px solid #E5E7EB', 
              borderRadius: '8px',
              marginBottom: '12px',
              display: 'table-row',
              marginTop: '12px',
              borderSpacing: '0 12px',
              cursor: 'pointer'
            }}
            sx={{
              '&:hover': {
                backgroundColor: '#F9FAFB !important',
                transition: 'background-color 0.2s ease'
              }
            }}
          >
            <TableCell sx={{
              borderLeft: '1px solid #E5E7EB',
              borderTop: '1px solid #E5E7EB', 
              borderBottom: '1px solid #E5E7EB',
              borderTopLeftRadius: '8px',
              borderBottomLeftRadius: '8px',
              padding: '12px',
              fontWeight: 500,
              fontSize: '14px'
            }}>{signal.signal}</TableCell>
            <TableCell sx={{
              borderRight: '1px solid #E5E7EB', 
              borderTop: '1px solid #E5E7EB',
              borderBottom: '1px solid #E5E7EB',
              borderTopRightRadius: '8px',
              borderBottomRightRadius: '8px',
              padding: '12px'
            }}>
              <div style={{ display: 'flex', justifyContent: 'flex-end', gap: '8px' }}>
                <IconButton
                  onClick={handleEdit}
                  className="drop-shadow-btn"
                  style={{ 
                    margin: '0px',
                    color: 'black',
                    fontSize: '14px', 
                    fontWeight: 500,
                    borderRadius: '9px',
                    border: '0.5px solid lightgray',
                    padding: '6px 9px 6px 8px'
                  }}
                >
                  <img src={EditIconNewSvg} /> Edit
                </IconButton>
                <IconButton
                  onClick={(e) => handleDelete(e)}
                  className="drop-shadow-btn"
                  style={{ 
                    background: '#FFF8F8',
                    color: '#F33D3D',
                        fontSize: '14px',
                    fontWeight: 500,
                    marginRight: '4px',
                    borderRadius: '9px', 
                    border: '0.5px solid lightgray',
                    padding: '6px 9px 6px 8px'
                  }}
                >
                  <img src={DeleteIconNewSvg} /> Delete
                </IconButton>
              </div>
            </TableCell>
          </TableRow>             
        </>
      );
    };
  
    const renderAddScoring = () => {
      return (
        <div className="d-flex flex-col mx-auto" style={{ 
          width: '100%', 
          marginTop: '-40px',
          padding: '40px 0 80px 0'
        }}>
          <Paper className="d-flex flex-col mx-auto" style={{ 
            width: '100%',
            marginTop: '0px',
            padding: '25px',
            boxShadow: 'none', 
            borderRadius: '6px',
            border: '1px solid #D9D9D9',
            maxWidth: '1200px'
          }}>
            <div className="d-flex flex-row">
              <h3 style={{ fontSize: '20px', fontWeight: '600' }} className="my-auto">Scoring</h3>
              <div className="d-flex flex-row" style={{ marginLeft: 'auto', width: 'fit-content' }}>
                <button 
                  className="btn-main"
                  onClick={() => {                     
                    setSignalAssistantDescription('');
                    setShowAddSignalAssistInput(true); 
                  }}
                  style={{
                    color: 'black',
                    marginRight: '16px',
                    padding: '8px 16px',
                    backgroundColor: '#EEDA49',
                    border: 'none',
                    borderRadius: '8px',
                    cursor: 'pointer',
                    display: 'flex',
                    alignItems: 'center',
                    height: '40px',
                    fontSize: '14px'
                  }}
                >
                  <span style={{ display: 'flex', alignItems: 'center' }}>Generate signal</span>
                  <AutoAwesomeOutlinedIcon style={{ marginLeft: '6px', fontSize: '18px' }} />
                </button>
                {/* <button
                  className="btn-main"
                  onClick={() => {
                    setAddScoring({ ...addScoring,
                      currentSignal: { signal: '', weight: 0, scale: 4, criteria: '' }
                    });
                    setShowScoringDrawer(true);
                  }}
                >
                  Add signal
                  <Add fontSize="24px" style={{ marginLeft: '8px' }}/>
                </button> */}
              </div>
            </div>
            <p className="color-gray" style={{ fontSize: 14, marginTop: 0 }}>Determine what the candidate should be graded on in the interview.</p>
            <DndContext
              collisionDetection={closestCenter}
              onDragEnd={handleDragEndScoring}
              sensors={sensors}
            >
              {addScoring.signals.length === 0 && (
                <div style={{
                  fontSize: '16px',
                  width: '100%',
                  padding: '24px',
                  border: '1px solid #E5E7EB',
                  borderRadius: '8px',
                  textAlign: 'center',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  color: '#6B7280',
                  marginBottom: '16px',
                  height: '200px',
                }}>
                  No signals defined.
                </div>
              )}
              <TableContainer style={{ padding: 0, width: '100%', margin: 0, marginBottom: '24px' }}>
                <Table style={{ padding: 0, margin: 0, width: '100%', borderCollapse: 'separate', borderSpacing: '0 8px' }}>
                  <TableBody>
                    <SortableContext items={addScoring.signals.map((sig, idx) => (sig.id ? sig.id : idx.toString()))} strategy={verticalListSortingStrategy}>
                      {addScoring.signals.map((signal, idx) => (
                        <DraggableScoringRow
                          key={signal.id ? signal.id : idx}
                          idx={idx}
                          signal={signal}
                          stateAddScoring={addScoring}
                          setAddScoring={setAddScoring}
                          setShowScoringDrawer={setShowScoringDrawer}
                          setCurrentScoringSignal={(data) => setAddScoring(prev => ({...prev, currentSignal: data}))}
                        />
                      ))}
                    </SortableContext>
                  </TableBody>
                </Table>
              </TableContainer>
            </DndContext>

            <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: 'auto', gap: '12px' }}>
              <button
                onClick={handleBack}
                className="btn-main outlined-gray"
              >
                Previous step
              </button>
              <button
                className="btn-main"
                onClick={handleNext}
              >
                Next step
              </button>
            </div>
          </Paper>
          <ScoringDrawer
            open={showScoringDrawer}
            signalName={addScoring.currentSignal.signal}
            onSignalNameChange={(e) => {
              setAddScoring({ ...addScoring, currentSignal: { ...addScoring.currentSignal, signal: e } });
            }}
            criteria={addScoring.currentSignal.criteria}
            onCriteriaChange={(e) => {
              setAddScoring({ ...addScoring, currentSignal: { 
                ...addScoring.currentSignal, 
                  criteria: e,
              }});
            }}
            onClose={() => {
              setShowScoringDrawer(false);
              setScoringErrorMsgs([]);
            }}
            isEdit={addScoring.currentSignal.editIndex !== undefined && addScoring.currentSignal.editIndex >= 0}
            errorMsgs={scoringErrorMsgs}
            saveScoring={saveScoring}
            questions={addQuestions.questions.slice(1)}
            selectedQuestions={addScoring.currentSignal.associated_questions ? addScoring.currentSignal.associated_questions.map(q => addQuestions.questions.find(q2 => q2.id === q.id)) : []}
            setSelectedQuestions={(questions) => setAddScoring({ ...addScoring, currentSignal: { ...addScoring.currentSignal, associated_questions: questions.map(q => ({ id: q.id, question: q.question })) }})}
          />
          { showAddSignalAssistInput && (
            <GenerateSignal
              open={showAddSignalAssistInput} 
              onClose={() => {
                setShowAddSignalAssistInput(false);
                setSignalAssistantDescription("");
                setSelectedPredefinedSignal("");
                setSignalAssistantSelectedQuestions([]);
              }}
              scoringGenerating={scoringGenerating}
              onGenerate={() => handleAssistantNext()}
              setDescription={setSignalAssistantDescription}
              description={signalAssistantDescription}
              predefinedSignals={["Teamwork", "Communication", "English Proficiency", "Leadership", "Resilience"]}
              selectedPredefinedSignal={selectedPredefinedSignal}
              setSelectedPredefinedSignal={setSelectedPredefinedSignal}
              questions={addQuestions.questions.slice(1)}
              selectedQuestions={signalAssistantSelectedQuestions}
              setSelectedQuestions={setSignalAssistantSelectedQuestions}
              onAddSignal={(generatedSignal) => {
                generatedSignal.associated_questions = signalAssistantSelectedQuestions.map(q => ({ id: q.id, question: q.question }));
                setAddScoring(prev => ({
                  ...prev,
                  signals: [...prev.signals, generatedSignal]
                }));
                setSignalAssistantSelectedQuestions([]);
                setSignalAssistantDescription("");
                setSelectedPredefinedSignal("");
                setShowAddSignalAssistInput(false);
              }}
            />
          )}
        </div>
      );
    };
  
    const renderReviewTest = () => {
      const question_sections = groupBy(addQuestions.questions, 'section')
      const showSections = Object.keys(question_sections).length > 1;
  
      return (
        <Scrollbars 
          style={{ 
            width: '100%',
            height: 'calc(100vh - 64px)', // Adjust for header height
          }}
          renderThumbVertical={({ style, ...props }) => (
            <div {...props} style={{ 
              ...style, 
              backgroundColor: 'rgba(0,0,0,0.1)', // Very subtle gray
              borderRadius: '4px',
              width: '4px',
              cursor: 'pointer'
            }}/>
          )}
        >
          <div className="d-flex flex-col mx-auto" style={{ 
            marginTop: '-40px',
            padding: '40px 0 80px 0',
            minHeight: 'min-content'
          }}>
            <Paper className="d-flex flex-col mx-auto" style={{ 
              width: '100%',
              marginTop: '0px',
              padding: '25px',
              boxShadow: 'none', 
              borderRadius: '6px',
              border: '1px solid #D9D9D9',
              maxWidth: '1200px',
              minHeight: 'min-content'
            }}>
              <div style={{ 
                width: "100%", 
                margin: '0 auto'
              }}>
                <div className="d-flex flex-row">
                  <h3 className="my-auto" style={{ fontSize: '20px', fontWeight: '600', marginTop: '8px' }}>Review</h3>
                </div>
                <p className="color-gray" style={{ fontSize: 14, marginTop: '4px', marginBottom: '24px' }}>Before finalizing changes, look to see if you have completed everything correctly.</p>

                {/* Interview Details */}
                <Paper className="d-flex flex-col" style={{ padding: '24px', marginBottom: '24px', boxShadow: 'none', borderRadius: '8px', position: 'relative', backgroundColor: '#FAFAFA' }}>
                  <div className="d-flex flex-row align-items-center">
                    <h4 style={{ margin: 0, fontSize: '16px', fontWeight: '600' }}>Interview Details</h4>
                    <IconButton 
                      onClick={() => setActiveStep(0)}
                      style={{ position: 'absolute', right: '24px', top: '24px' }}
                    >
                      <DriveFileRenameOutlineIcon style={{ fontSize: '18px', color: '#656565' }} />
                    </IconButton>
                  </div>
                  <div style={{ marginTop: '16px' }}>
                    <div className="d-flex flex-row" style={{ justifyContent: 'space-between', borderBottom: '1px solid #D9D9D9', padding: '12px 0px' }}>
                      <span style={{ width: '150px', color: '#656565', fontSize: '14px', fontWeight: 500 }}>Interview Title</span>
                      <span style={{ fontSize: '14px', fontWeight: 500 }}>{roleBackground.interviewTitle}</span>
                    </div>
                    <div className="d-flex flex-row" style={{ justifyContent: 'space-between', borderBottom: '1px solid #D9D9D9', padding: '12px 0px' }}>
                      <span style={{ width: '150px', color: '#656565', fontSize: '14px', fontWeight: 500 }}>Job Title</span>
                      <span style={{ fontSize: '14px', fontWeight: 500 }}>{roleBackground.jobTitle}</span>
                    </div>
                    <div className="d-flex flex-row" style={{ justifyContent: 'space-between', borderBottom: '1px solid #D9D9D9', padding: '12px 0px' }}>
                      <span style={{ width: '150px', color: '#656565', fontSize: '14px', fontWeight: 500 }}>Language</span>
                      <span style={{ fontSize: '14px', fontWeight: 500 }}>{roleBackground.assessmentLanguage}</span>
                    </div>
                    <div className="d-flex flex-row" style={{ justifyContent: 'space-between', borderBottom: '1px solid #D9D9D9', padding: '12px 0px' }}>
                      <span style={{ width: '150px', color: '#656565', fontSize: '14px', fontWeight: 500 }}>Interview Length</span>
                      <span style={{ fontSize: '14px', fontWeight: 500 }}>{addQuestions.questions.reduce((sum, q) => sum + q.time_limit, 0) / 60} minutes</span>
                    </div>
                  </div>
                </Paper>

                {/* Questions Overview */}
                <Paper className="d-flex flex-col" style={{ padding: '24px', marginBottom: '24px', boxShadow: 'none', borderRadius: '8px', position: 'relative', backgroundColor: '#FAFAFA' }}>
                  <div className="d-flex flex-row align-items-center">
                    <h4 style={{ margin: 0, fontSize: '16px', fontWeight: '600' }}>Questions Overview</h4>
                    <IconButton 
                      onClick={() => setActiveStep(1)}
                      style={{ position: 'absolute', right: '24px', top: '24px' }}
                    >
                      <DriveFileRenameOutlineIcon style={{ fontSize: '18px', color: '#656565' }} />
                    </IconButton>
                  </div>
                  <TableContainer
                    style={{ marginTop: '16px' }}
                  >
                    <Table>
                      <TableBody>
                        {addQuestions.questions.map((question, idx) => (
                          <TableRow key={idx} style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', borderBottom: '1px solid #D9D9D9', padding: '12px 0px' }}>
                            <TableCell style={{ border: 'none', fontWeight: 500, fontSize: '14px', padding: '0px', borderBottom: 'none' }}>{question.question}</TableCell>
                            <TableCell style={{ border: 'none', fontWeight: 500, fontSize: '14px', padding: '0px', borderBottom: 'none' }}>{secondsToTimeLimitReadable(question.time_limit)}</TableCell>
                              </TableRow>
                          ))}
                    </TableBody>
                  </Table>
                </TableContainer>
                </Paper>

                {/* Scoring Overview */}
                <Paper className="d-flex flex-col" style={{ padding: '24px', marginBottom: '24px', boxShadow: 'none', borderRadius: '8px', position: 'relative', backgroundColor: '#FAFAFA' }}>
                  <div className="d-flex flex-row align-items-center">
                    <h4 style={{ margin: 0, fontSize: '16px', fontWeight: '600' }}>Scoring Overview</h4>
                    <IconButton 
                      onClick={() => setActiveStep(2)}
                      style={{ position: 'absolute', right: '24px', top: '24px' }}
                    >
                      <DriveFileRenameOutlineIcon style={{ fontSize: '18px', color: '#656565' }} />
                    </IconButton>
                  </div>
                  <TableContainer style={{ marginTop: '16px' }}>
                    <Table>
                    <TableBody>
                      {addScoring.signals.map((signal, idx) => (
                          <TableRow key={idx} style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', borderBottom: '1px solid #D9D9D9', padding: '12px 0px' }}>
                            <TableCell style={{ border: 'none', fontWeight: 500, fontSize: '14px', padding: '0px', borderBottom: 'none' }}>{signal.signal}</TableCell>
                          </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
                </Paper>

                {/* Outputs Overview */}
                <Paper className="d-flex flex-col" style={{ padding: '24px', marginBottom: '24px', boxShadow: 'none', borderRadius: '8px', position: 'relative', backgroundColor: '#FAFAFA' }}>
                  <div className="d-flex flex-row align-items-center">
                    <h4 style={{ margin: 0, fontSize: '16px', fontWeight: '600' }}>Outputs Overview</h4>
                    <IconButton 
                      onClick={() => setActiveStep(3)}
                      style={{ position: 'absolute', right: '24px', top: '24px' }}
                    >
                      <DriveFileRenameOutlineIcon style={{ fontSize: '18px', color: '#656565' }} />
                    </IconButton>
                          </div>
                  <TableContainer style={{ marginTop: '16px' }}>
                    <Table>
                      <TableBody>
                        {addOutputs.outputs.map((output, idx) => (
                          <TableRow key={idx} style={{ 
                            display: 'flex', 
                            flexDirection: 'row', 
                            borderBottom: '1px solid #D9D9D9', 
                            padding: '12px 0px',
                            width: '100%'
                          }}>
                            <TableCell style={{ 
                              border: 'none', 
                              fontWeight: 500, 
                              fontSize: '14px', 
                              padding: '0px', 
                              borderBottom: 'none',
                              width: '25%',
                              minWidth: '25%',
                              maxWidth: '25%',
                              whiteSpace: 'nowrap',
                              overflow: 'hidden',
                              textOverflow: 'ellipsis'
                            }}>
                              {output.key}
                            </TableCell>
                            <TableCell style={{ 
                              border: 'none', 
                              fontWeight: 500, 
                              fontSize: '14px', 
                              padding: '0px 0px 0px 16px', 
                              borderBottom: 'none',
                              width: '70%',
                              minWidth: '70%',
                              maxWidth: '70%',
                              whiteSpace: 'nowrap',
                              overflow: 'hidden',
                              textOverflow: 'ellipsis'
                            }}>
                              {output.extraction_instructions}
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Paper>

                <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: 'auto', gap: '12px', paddingBottom: '24px' }}>
                  <button
                    onClick={handleBack}
                    className="btn-main outlined-gray"
                  >
                    Previous step
                  </button>
                    <button
                      className="btn-main"
                      onClick={handleNext}
                      style={{
                        backgroundColor: '#F2DA02',
                        color: '#000000',
                        textTransform: 'none',
                        boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.08)',
                      }}
                      disabled={!validations.reviewTest.valid}
                    >
                      Create interview
                  </button>
                          </div>
                    </div>
          </Paper>
        </div>
        </Scrollbars>
      )
    }
  
    const renderActiveStepComponent = () => {
      switch (activeStep) {
        case -1:
          switch (creationFlow) {
            case '':
              return renderFlowPrompt();
            case 'jd':
              return renderJDUpload();
            case 'template':
              return renderTemplateFlow();
            case 'self':
            default:
              return setActiveStep(0);
          }
        case 0:
          return renderRoleBackground();
        case 1:
          return renderAddQuestions();
        case 2:
          return renderAddScoring();
        case 3:
          return renderAddOutputs();
        case 4:
          return renderReviewTest();
        default:
          return null;
      }
    }
  
    const handleAssistantNext = () => {
      setScoringGenerating(true);
      
      return api.generateScoringCriteria({ 
        context: signalAssistantDescription || selectedPredefinedSignal, 
        title: roleBackground.interviewTitle, 
        job_title: roleBackground.jobTitle,
        questions: signalAssistantSelectedQuestions.map(q => q.question)
      })
      .then((response) => {
        setScoringGenerating(false);
        
        return {
          signal: response.data.signal,
          criteria: response.data.criteria,
        };
      })
      .catch((error) => {
        console.error('Error generating scoring criteria:', error);
        setScoringGenerating(false);
        throw error;
      });
    };
  
    return (
      <div className="d-flex flex-col" style={{ backgroundColor: '#FAFAFA', height: '100%', width: '100%' }}>
        {/* Header */}
        <div className="d-flex flex-row" style={{ 
          width: '100%', 
          height: '64px',
          padding: '16px',
          backgroundColor: '#FAFAFA',
          zIndex: 100
        }}>
          <img 
            src={WayfasterLogo} 
            alt="Logo"
            style={{
              height: '32px',
              width: 'auto',
              marginRight: '16px',
              cursor: 'pointer'
            }}
            onClick={() => navigate('/home')}
          />
        </div>
        <div className="d-flex flex-row" style={{ flex: 1, overflow: 'hidden' }}>
          {activeStep > -1 && (
            <div className="d-flex flex-col" style={{ 
              width: '240px',
              flexShrink: 0  // Add this to prevent shrinking
            }}>              
              <div className="d-flex flex-col" style={{ padding: '16px' }}>
                <div className="d-flex flex-col">
                  {steps.map((label, index) => {
                    const stepProps = {};
                    const labelProps = {};
                    if (isStepOptional(index)) {
                      labelProps.optional = (
                        <Typography variant="caption">Optional</Typography>
                      );
                    }
                    if (isStepSkipped(index)) {
                      stepProps.completed = false;
                    }
                    return (
                      <div 
                        key={label}
                        className="d-flex flex-row align-items-center"
                        style={{ 
                          padding: '10px',
                          borderRadius: '8px',
                          backgroundColor: activeStep === index ? '#E4E4E7' : 'transparent',
                          cursor: 'pointer',
                        }}
                        onClick={() => setActiveStep(index)}
                      >
                        <img src={label.icon} alt={label.label} style={{ width: '16px', height: '16px', marginRight: '12px', color: 'black' }} />
                        <p style={{ 
                          fontSize: '13px',
                          fontWeight: 500,
                          margin: '0px',
                          color: 'black'
                        }}>
                          {label.label}
                        </p>
                        {/* <div style={{marginLeft: 'auto'}}> */}
                          {label.valid && <CheckCircle style={{ marginLeft: 'auto', fontSize: '16px', lineHeight: '14px' }} />}
                        {/* </div> */}
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          )}

          {/* Right Content Column */}
          <div className="d-flex flex-col" style={{ flex: 1, marginRight: '25px' }}>
            <div className="d-flex flex-row" style={{ 
              flex: 1, 
              width: '100%', 
              marginTop: '20px',
              minHeight: 0  // Add this to allow content to scroll
            }}>
              {renderActiveStepComponent()}
            </div>
          </div>
          {snackbarMsg && (
            <Snackbar
              open={!!snackbarMsg}
              autoHideDuration={3000}
              onClose={() => setSnackbarMsg('')}
              message={snackbarMsg} 
              anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
            />
          )}
        </div>
      </div>
    )
  }