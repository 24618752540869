import React from 'react'
import { styled } from '@mui/material/styles';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import TooltipCustom from './TooltipCustom';

const FormField = styled(({ className, label, noLabel, noBorder, required, tooltip, tooltipTitle, height, icon, description,...props }) => (
  <>
    {!noLabel && <h5 className="input-label d-flex" style={{ fontSize: '14px', fontWeight: 500, color: props.labelColor ? props.labelColor : 'var(--color-gray)' }}>
      {label}{required ? <span className="color-required"> *</span> : ''}
      {tooltip ? <>
        <TooltipCustom 
          title={tooltipTitle}
          style={{ color: "var(--color-gray)", marginLeft: '8px', marginTop: 'auto', marginBottom: 'auto', fontSize: '14px' }}>
        </TooltipCustom>
      </> : ''}
    </h5>}
    {description && <p className="color-gray" style={{ fontSize: '12px', marginTop: '0' }}>{description}</p>}
    <TextField
      {...props}
      variant="standard"
      fullWidth={true}
      InputProps={{
        disableUnderline: true,
        startAdornment: icon ? <InputAdornment position="start">{icon}</InputAdornment> : '',
        style: { padding: '8px 12px', marginBottom: props.error ? 4 : 16, height: height ? `${height}` : '',
          border: noBorder ? 'none' : '1px solid lightgray', borderRadius: '6px', fontSize: '14px' }
      }}
      sx={{
        ['& .MuiInput-input'] : {
          padding: 0,
        }
      }}
    />
  </>
))({});

export default FormField