import React from 'react';
import { formatTimeSince } from '../../util/interviews';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';

const Role = {
    Sent: 'sent',
    Received: 'received'
}

const MessageBubble = React.memo(({ message, role, speaker, status, time }) => {

    return (
        <div className="d-flex flex-col" style={{ marginBottom: '8px' }}>
            <div className="d-flex flex-row">
                <div className={`message-bubble d-flex ${role === Role.Sent ? 'sent' : 'received'}`} style={{ padding: '16px', borderRadius: '16px', width: '90%' }}>
                    <p className="font-inter" style={{ fontSize: '14px' }}>{message}</p>
                </div>
                {status && status == 'undelivered' && 
                    <ErrorOutlineIcon className="color-danger my-auto" style={{ fontSize: '24px', marginLeft: '8px' }} />
                }
            </div>
            {speaker !== 'CONTINUED' && <h5 className={`font-inter color-gray ${role === Role.Sent ? 'text-right' : 'text-left'}`} style={{ marginBottom: '8px', marginTop: '8px' }}>
              {speaker} 
              
              {status && status == 'undelivered' ? 
                <span className="color-danger" style={{ marginLeft: '4px', fontWeight: 600 }}>Not Delivered</span>
              : <span className="color-gray" style={{ marginLeft: '4px', fontWeight: 400 }}>{time ? formatTimeSince(time) : 'N/A'}</span>}
              {status && status == 'delivered' && <span className="color-gray" style={{ fontStyle: 'italic', marginLeft: '4px', fontWeight: 600 }}>Delivered</span>}
            </h5>}
        </div>
    );
});

export default MessageBubble; 