import React from 'react';
import { Box } from '@mui/material';

const SessionCounts = ({ title, sessionCounts }) => {
    return (
        <Box
            style={{
                display: 'flex',
                flex: 1,
                flexDirection: 'column',
                alignItems: 'start',
                backgroundColor: 'white',
                borderRadius: '16px',
                padding: '24px',
                height: '170px',
                gap: '16px',
            }}
        >
            <Box
                style={{
                    fontSize: '14px',
                    color: 'var(--color-gray)',
                    fontWeight: '500',
                }}
            >
                {title}
            </Box>

            {/* Bar chart */}
            <Box style={{ width: '100%', height: '24px', display: 'flex', flexDirection: 'row', position: 'relative' }}>
                {sessionCounts.data.map((count, index) => {
                    const total = sessionCounts.data.reduce((a, b) => a + b, 0);
                    const width = total > 0 ? Math.ceil((count / total) * 100) || 1 : 0;

                    var style = {
                        width: `${width}%`,
                        height: '24px',
                        backgroundColor: sessionCounts.colors[index],
                        transition: 'opacity 0.2s ease',
                        position: 'relative',
                        cursor: 'pointer'
                    }

                    // Find first visible box from left
                    const firstVisibleIndex = sessionCounts.data.findIndex(count => count > 0);
                    
                    // Find last visible box from right
                    const lastVisibleIndex = sessionCounts.data.length - 1 - [...sessionCounts.data].reverse().findIndex(count => count > 0);

                    if (index === firstVisibleIndex) {
                        style = {
                            ...style,
                            borderTopLeftRadius: '8px',
                            borderBottomLeftRadius: '8px'
                        }
                    }
                    if (index === lastVisibleIndex) {
                        style = {
                            ...style,
                            borderTopRightRadius: '8px', 
                            borderBottomRightRadius: '8px'
                        }
                    }
                    
                    return (
                        <Box
                            key={index}
                            style={style}
                            onMouseLeave={(e) => {
                                // Hide tooltip
                                const tooltip = document.getElementById('session-tooltip');
                                if (tooltip) tooltip.style.display = 'none';
                            }}
                            onMouseMove={(e) => {
                                var tooltip = document.getElementById('session-tooltip');
                                if (!tooltip) {
                                    // Create tooltip if it doesn't exist
                                    var newTooltip = document.createElement('div');
                                    newTooltip.id = 'session-tooltip';
                                    document.body.appendChild(newTooltip);
                                }
                                
                                var tooltip = document.getElementById('session-tooltip');
                                tooltip.style.position = 'fixed';
                                tooltip.style.display = 'flex';
                                tooltip.style.flexDirection = 'column';
                                tooltip.style.alignItems = 'flex-start';
                                tooltip.style.gap = '8px';
                                tooltip.style.backgroundColor = 'white';
                                tooltip.style.boxShadow = '0 2px 10px rgba(0,0,0,0.1)';
                                tooltip.style.padding = '8px';
                                tooltip.style.borderRadius = '4px';
                                tooltip.style.zIndex = '1000';
                                tooltip.style.left = `${e.clientX - (tooltip.offsetWidth / 2)}px`;
                                tooltip.style.top = `${e.clientY - tooltip.offsetHeight - 10}px`;
                                
                                tooltip.innerHTML = `
                                    <div style="
                                        display: flex;
                                        align-items: center;
                                        gap: 8px;
                                        font-size: 14px;
                                        color: var(--color-gray);
                                        font-weight: 500;
                                    ">
                                        <div style="
                                            width: 12px;
                                            height: 12px;
                                            background-color: ${sessionCounts.colors[index]};
                                            border-radius: 3px;
                                        "></div>
                                        <span style="white-space: nowrap">${sessionCounts.labels[index]}</span>
                                    </div>
                                    <div style="
                                        font-size: 24px;
                                        font-weight: bold;
                                        color: var(--color-heading);
                                    ">${count}</div>
                                `;
                            }}
                        />
                    );
                })}
            </Box>

            {/* Legend */}
            <Box style={{ 
                display: 'flex', 
                flexDirection: 'row', 
                flexWrap: 'wrap',
                gap: '16px',
            }}>
                {sessionCounts.labels.map((label, index) => {
                    return (
                        <Box
                            key={index}
                            style={{
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'flex-start',
                                gap: '8px',
                                fontSize: '14px',
                                color: 'var(--color-gray)',
                                fontWeight: '500',
                                opacity: sessionCounts.data[index] === 0 ? 0.5 : 1
                            }}
                        >
                            <Box style={{
                                display: 'flex',
                                alignItems: 'center',
                                gap: '8px'
                            }}>
                                <Box
                                    style={{
                                        width: '12px',
                                        height: '12px',
                                        backgroundColor: sessionCounts.colors[index],
                                        borderRadius: '3px'
                                    }}
                                />
                                <span>{label}</span>
                            </Box>
                            <Box style={{
                                fontSize: '24px',
                                fontWeight: 'bold',
                                color: 'var(--color-heading)',
                            }}>
                                {sessionCounts.data[index]}
                            </Box>
                        </Box>
                    );
                })}
            </Box>
        </Box>
    );
};

export default SessionCounts;