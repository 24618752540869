import { useState, useEffect } from 'react';
import { Dialog, Typography, Box } from '@mui/material';
import FormField from '../common/FormField';
import api from "../../api/api";

const RenameFolder = ({ onClose, isOpen, currentTeam, folder }) => {
  const [folderName, setFolderName] = useState('');
  useEffect(() => {
    if (folder) setFolderName(folder.name);
  }, [folder]);

  const [errMsgs, setErrMsgs] = useState([]);

  const handleSubmit = async () => {
    // Validate folder name
    if (!folderName || folderName.trim().length === 0) {
      setErrMsgs(["Folder name is required"]);
      return;
    }

    try {
      const res = await api.updateFolder({ team_id: currentTeam._id.$oid, folder_id: folder._id, name: folderName });
      
      if (res.status === 200) {
        onClose(res.data);
      } else {
        setErrMsgs(["Failed to rename folder"]);
      }
    } catch (error) {
      console.error("Error renaming folder:", error);
      setErrMsgs(["Failed to rename folder"]);
    }
  };

  // Reset error messages when dialog closes
  const handleClose = () => {
    setErrMsgs([]);
    onClose();
  };

  return (
    <Dialog 
      open={isOpen}
      onClose={handleClose}
      aria-labelledby="rename-folder-dialog-title"
      aria-describedby="rename-folder-dialog-description"
    >
      <div style={{padding: "24px", width: "400px"}}>
        <h2 style={{fontSize: "18px", fontWeight: "600", marginBottom: "12px", marginTop: "0px"}}>Rename folder</h2>
        
        <FormField
          label="Folder name"
          value={folderName}
          onChange={(e) => setFolderName(e.target.value)}
          required
        />

        {errMsgs && errMsgs.length > 0 && (
            <Box
              sx={{
                marginBottom: '16px',
                padding: '12px',
                backgroundColor: '#FEF2F2',
                borderRadius: '8px',
                border: '1px solid #FEE2E2'
              }}
            >
              {errMsgs.map((msg, idx) => (
                <Typography
                  key={idx}
                  variant="caption"
                  sx={{
                    color: '#DC2626',
                    display: 'block',
                    fontSize: '14px',
                    '&:not(:last-child)': {
                      marginBottom: '4px'
                    }
                  }}
                >
                  {msg}
                </Typography>
              ))}
            </Box>
        )} 

        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end', gap: '12px', marginTop: '8px' }}>
          <button
            onClick={handleClose}
            className="btn-main outlined gray"
            style={{ width: 'fit-content' }}
          >
            Cancel
          </button>
          <button
            onClick={handleSubmit}
            className="btn-main"
            style={{ width: 'fit-content' }}
            disabled={folder && folderName === folder.name}
          >
            Rename folder
          </button>
        </div>
      </div>
    </Dialog>
  );
};

export default RenameFolder;
