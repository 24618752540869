import { Drawer, Typography, Box, Button, Checkbox } from "@mui/material";
import FormField from "../common/FormField";

const ScoringDrawer = ({ 
    open, 
    onClose, 
    signalName,
    onSignalNameChange,
    criteria,
    onCriteriaChange,
    errorMsgs,
    saveScoring,
    isEdit = false,
    questions,
    selectedQuestions,
    setSelectedQuestions,
  }) => {
    return (
      <Drawer
        anchor="right"
        open={open}
        onKeyDown={(e) => {
          if ((e.metaKey || e.ctrlKey) && e.key === 'Enter') {
            const saved = saveScoring(isEdit);
            if (saved) {
              onClose();
            }
          }
        }}
        onClose={onClose}
        sx={{
          '& .MuiDrawer-paper': {
            width: '450px',
            padding: '24px',
            backgroundColor: '#FFFFFF',
            margin: '24px',
            height: 'calc(100% - 48px)',
            borderRadius: '16px',
            boxShadow: '0px 4px 6px -2px rgba(0, 0, 0, 0.05), 0px 10px 15px -3px rgba(0, 0, 0, 0.1)'
          },
          '& .MuiBackdrop-root': {
            backgroundColor: 'rgba(0, 0, 0, 0.3)'
          }
        }}
      >
        <div style={{ 
          display: 'flex', 
          flexDirection: 'column', 
          gap: '16px',
          height: '100%',
          overflow: 'hidden'
        }}>
          <Typography variant="p" sx={{ fontWeight: 600, fontSize: '18px', color: '#111827', marginBottom: '-8px' }}>
            {isEdit ? "Modify Signal" : "Add Signal"}
          </Typography>
          <Typography variant="p" sx={{ color: '#6B7280', fontSize: '14px', fontWeight: 400 }}>
            {isEdit ? "Modify the scoring signal for this interview" : "Add a new scoring signal for this interview"}
          </Typography>
          
          {/* Scrollable content container */}
          <Box sx={{ 
            flex: 1, 
            overflowY: 'auto',
            display: 'flex',
            flexDirection: 'column',
            gap: '16px',
            paddingBottom: '0px'
          }}>
            {errorMsgs.length > 0 && (
              <Box
                sx={{
                  padding: '12px',
                  backgroundColor: '#FEF2F2',
                  borderRadius: '8px',
                  border: '1px solid #FEE2E2'
                }}
              >
                {errorMsgs.map((msg, idx) => (
                  <Typography
                    key={idx}
                    variant="caption"
                    sx={{
                      color: '#DC2626',
                      display: 'block',
                      fontSize: '14px',
                      '&:not(:last-child)': {
                        marginBottom: '4px'
                      }
                    }}
                  >
                    {msg}
                  </Typography>
                ))}
              </Box>
            )}        
    
            <div className="d-flex flex-col" style={{ marginBottom: '-16px' }}>
              <FormField
                label="What is your signal called?"
                required
                fullWidth
                placeholder="Enter signal name"
                value={signalName}
                onChange={(e) => onSignalNameChange(e.target.value)}
              />
            </div>
    
            <div className="d-flex flex-col">
              <FormField
                label={`Criteria for "Meets Expectations" score (3)`}
                tooltip
                tooltipTitle={<>
                  <p className="m-auto">
                  Candidates are evaluated against signal criteria on a scale of 1 to 4. This is the criteria for the 'Meets Expectations' level of the signal or a passing score of 3 out of 4.
                  </p>
                </>}
                required
                multiline
                rows={errorMsgs && errorMsgs.length > 0 ? 8 : 12}
                placeholder="Enter criteria description"
                value={criteria}
                onChange={(e) => onCriteriaChange(e.target.value)}
              />
            </div>
  
            <>
              <Typography variant="p" sx={{ color: '#6B7280', fontSize: '14px', fontWeight: 500, marginTop: '-12px' }}>
                Associated questions<span style={{ color: '#DC2626' }}>*</span>
              </Typography>
              <Box sx={{ 
                display: 'flex', 
                flexDirection: 'column',
                gap: '12px'
              }}>
                {questions.map((question, index) => (
                  <Box 
                    key={index}
                    sx={{
                      backgroundColor: '#F9FAFB',
                      padding: '12px 16px',
                      borderRadius: '8px',
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center'
                    }}
                  >
                    <Typography sx={{ color: '#111827', fontSize: '14px' }}>
                      {question.question}
                    </Typography>
                    <Checkbox
                      checked={selectedQuestions.includes(question)}
                      onChange={(e) => {
                        if (e.target.checked) {
                          setSelectedQuestions([...selectedQuestions, question]);
                        } else {
                          setSelectedQuestions(selectedQuestions.filter(q => q !== question));
                        }
                      }}
                      size="small"
                      sx={{
                        color: '#000000',
                        '&.Mui-checked': {
                          color: '#000000'
                        }
                      }}
                    />
                  </Box>
                ))}
              </Box>
            </>
          </Box>
  
          <Box sx={{ 
            display: 'flex', 
            justifyContent: 'flex-end', 
            gap: 2,
            position: 'sticky',
            bottom: 0,
            left: 0,
            right: 0,
            padding: '16px 0',
            backgroundColor: '#FFFFFF',
            marginTop: 'auto'
          }}>
            <Button
              variant="outlined"
              onClick={() => {
                onClose();
              }}
              sx={{
                color: '#6B7280',
                borderColor: '#E5E7EB', 
                textTransform: 'none',
                borderRadius: '6px',
                '&:hover': {
                  borderColor: '#D1D5DB',
                  backgroundColor: '#F9FAFB'
                }
              }}
            >
              Cancel
            </Button>
            <Button
              sx={{
                bgcolor: 'black',
                color: 'white', 
                textTransform: 'none',
                borderRadius: '6px',
                boxShadow: 'none',
                '&:hover': {
                  bgcolor: '#333333',
                  color: 'white',
                  boxShadow: 'none'
                }
              }}
              variant="contained"
              onClick={() => {
                const saved = saveScoring(isEdit);
                if (saved) {
                  onClose();
                }
              }}
            >
            {isEdit ? 'Save Changes (⌘ + Enter)' : 'Add Signal (⌘ + Enter)'}
            </Button>
          </Box>
        </div>
      </Drawer>
    );
  };

export default ScoringDrawer;