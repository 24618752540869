import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import { Alert, Container, Grid, CircularProgress, Tabs, Tab } from "@mui/material";
import {
  Table,
  TableContainer,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  IconButton,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import Button from "@mui/material/Button";

import DragHandleIcon from "@mui/icons-material/DragHandle";
import NavigateBefore from "@mui/icons-material/NavigateBefore";
import NavigateNext from "@mui/icons-material/NavigateNext";
import ClearIcon from "@mui/icons-material/Clear";

import EditIconNewSvg from "../assets/svg/EditIconNew.svg";
import DeleteIconNewSvg from "../assets/svg/DeleteIconNew.svg";
import UploadFileIcon from '../assets/svg/UploadFileIcon.svg'
import InterviewDetailsGraphic from '../assets/svg/InterviewDetailsGraphic.svg'
import { CSS } from "@dnd-kit/utilities";
import {
  DndContext,
  KeyboardSensor,
  MouseSensor,
  TouchSensor,
  closestCenter,
  useSensor,
  useSensors,
} from "@dnd-kit/core";
import { restrictToVerticalAxis } from "@dnd-kit/modifiers";
import {
  arrayMove,
  SortableContext,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";

// needed for row & cell level scope DnD setup
import { useSortable } from "@dnd-kit/sortable";
import { useAuth0 } from "@auth0/auth0-react";
import pdfToText from "react-pdftotext";

import FormField from "../components/common/FormField";
import TooltipCustom from "../components/common/TooltipCustom";

import api from "../api/api";

const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});

const RowDragHandleCell = ({ rowId }) => {
  const { attributes, listeners } = useSortable({
    id: rowId,
  });

  console.log(rowId);

  console.log(attributes, listeners);

  return (
    <IconButton {...attributes} {...listeners}>
      <DragHandleIcon />
    </IconButton>
  );
};

function CreateInterviewJD({
  setCreationFlow,
  setActiveStep,
  setAddQuestions,
  setAddScoring,
  setAddOutputs,
  setShowAddQuestionInput,
  setRoleBackground,
  roleBackground,
  showInterviewSetup,
  headerText,
  label,
  sx,
  fullWidth,
  btnText
}) {
  const navigate = useNavigate();
  const { user } = useAuth0();
  const sensors = useSensors(
    useSensor(MouseSensor, {}),
    useSensor(TouchSensor, {}),
    useSensor(KeyboardSensor, {})
  );

  const [interview, setInterview] = useState({});
  const [activeSubStep, setActiveSubStep] = useState(0);
  const [jobDescriptionText, setJobDescriptionText] = useState("");
  const [file, setFile] = useState("");
  const [fileText, setFileText] = useState("");
  const [maxInterviewLength, setMaxInterviewLength] = useState(10);

  const [submitting, setSubmitting] = useState(false);

  const [snackbarMsg, setSnackbarMsg] = useState("");
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [showUpload, setShowUpload] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  // Questions page
  const [currentQuestion, setCurrentQuestion] = useState({
    text: "",
    numFollowUps: 0,
    editIndex: -1,
  });
  const [
    showQuestionDeletionConfirmation,
    setShowQuestionDeletionConfirmation,
  ] = useState(false);
  const [questionDeletionIndex, setQuestionDeletionIndex] = useState(-1);
  const [submittingIndex, setSubmittingIndex] = useState(0);

  const messages = [
    'Coming up with good questions...',
    'Generating a strong scorecard...',
    'Calibrating difficulty levels...',
    'Optimizing interview flow...',
    'Crafting follow-up questions...',
    'Fine-tuning time allocations...',
    'Building evaluation criteria...',
    'Personalizing for your role...',
    'Polishing question phrasing...',
    'Setting up scoring rubrics...',
    'Almost done...',
    'Creating your interview...'
  ];
  useEffect(() => {
    const interval = setInterval(() => {
      setSubmittingIndex((prevIndex) => (prevIndex + 1) % messages.length);
    }, 1500);

    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    api
      .getInterview(interview.id, user.email)
      .then((response) => {
        setInterview(response.data);
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);

  const handleFileUpload = (e) => {
    if (e.target.files && e.target.files[0]) {
      const file = e.target.files[0];
      setFile(file);
      processFile(file);
    }
  };

  const processFile = (file) => {
    if (file.type === "application/pdf") {
      pdfToText(file)
        .then((text) => {
          setFileText(text);
        })
        .catch((error) => console.error("Failed to extract text from pdf"));
    } else {
      // text file
      var reader = new FileReader();

      if (file.type.match(/text.*/)) {
        reader.onload = function (event) {
          setFileText(event.target.result);
        };
      } else {
        console.log("Failed to read a non-text file.");
      }
      reader.readAsText(file);
    }
  };

  const clearFile = () => {
    setFile("");
    setFileText("");
  };

  function uuidv4() {
    return "10000000-1000-4000-8000-100000000000".replace(/[018]/g, (c) =>
      (
        +c ^
        (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (+c / 4)))
      ).toString(16)
    );
  }

  const handleDragEnd = (event) => {
    console.log("handleDragEnd");
    const { active, over } = event;

    if (active.id !== over.id) {
      let updatedInterview = interview;

      const oldIndex = interview.questions.findIndex(
        (question) => question.id === active.id
      );
      const newIndex = interview.questions.findIndex(
        (question) => question.id === over.id
      );
      updatedInterview = {
        ...interview,
        questions: arrayMove(interview.questions, oldIndex, newIndex),
      };

      api
        .updateInterview(interview._id, updatedInterview)
        .then((response) => {
          setInterview(response.data);
          setCurrentQuestion({
            text: "",
            numFollowUps: 1,
            editIndex: -1,
          });
          setOpenSnackbar(true);
          setSnackbarMsg("Questions have been updated");
        })
        .catch((error) => {
          console.error("Failed to update interview questions:", error);
        });
    }
  };

  const previousSubStep = () => {
    setActiveStep(activeSubStep - 1);
    if (activeSubStep == 0) navigate("/create-interview");
  };
  const nextSubStep = () => {
    setActiveStep(activeSubStep + 1);
  };

  const handleSubmitJD = () => {
    if (!jobDescriptionText && !fileText) {
      setErrorMessage("Job description is required. Either upload a file or write the job description text.");
      return;
    }

    if (jobDescriptionText && fileText) {
      setErrorMessage("Please only provide either a job description text OR upload a file, not both.");
      return;
    }

    const finalText = jobDescriptionText || fileText;

    setErrorMessage("");
    const payload = {
      context: finalText,
      context_type: "job_description",
      total_time_length: maxInterviewLength * 60 // convert to seconds
    };

    console.log(payload)
    
    setSubmitting(true);
    api
      .generateInterviewQuestions(payload)
      .then((response) => {
        setSubmitting(false);
        console.log("generated interview questions");
        console.log(response);

        setRoleBackground({
          ...roleBackground,
          interviewTitle: response.data.details.interview_title,
          jobTitle: response.data.details.job_title,
        });
        const questionsWithId = response.data.questions.map((question) => {
          return { ...question, question: question.question, id: uuidv4() };
        });
        
        setAddQuestions({
          questions: questionsWithId,
          currentQuestion: {
            question: "What has been the highlight of your role?",
            time_limit: 30,
            editIndex: -1,
          },
        });
        setAddScoring({
          currentSignal: {
            signal: "",
            scale: 4,
            '1PointCriteria': "",
            '2PointCriteria': "", 
            '3PointCriteria': "",
            '4PointCriteria': "",
            editIndex: -1
          },
          signals: response.data.scorecard.map((signal) => {
            return {
              signal: signal.signal,
              scale: 4,
              '1PointCriteria': signal.point_criteria_1,
              '2PointCriteria': signal.point_criteria_2, 
              '3PointCriteria': signal.point_criteria_3,
              '4PointCriteria': signal.point_criteria_4
            };
          }),
        });
        setAddOutputs({
          currentOutput: { extraction_instructions: '', data_type: '', key: '' },
          editIndex: -1,          
          outputs: response.data.structured_outputs.map((output) => {
            return {
              key: output.key,
              extraction_instructions: output.extraction_instructions,
              data_type: output.data_type
            };
          }),
        });

        setActiveStep((prevStep) => prevStep + 1); // set question flow back to original self create (just with newly generate q's)
      })
      .catch((error) => {
        setSubmitting(false);
        console.error("Failed to generate interview questions:", error);
      });
  };

  const renderUploadStep = () => {
    return (
      <div
        className="mx-auto"
        style={{ display: "flex", width: "100%", paddingRight: "0px" }}
      >
        {/* Left Column */}
        <div className="d-flex flex-col" style={{ width: "50%", paddingRight: "40px", paddingTop: '40px', paddingBottom: '40px', height: '100%' }}>
          {/* Back Button */}
          {showInterviewSetup && (
            <div className="d-flex flex-row" style={{ marginBottom: "24px" }}>
              <button
                disabled={submitting}
                className="btn-main small d-flex gray outlined my-auto"
                onClick={() => setCreationFlow('')}
              >
                <NavigateBefore fontSize="14px" className="color-heading m-auto" />
              </button>
              <h5 className={submitting ? 'inactive' : ''} style={{ margin: "auto 8px", lineHeight: "24px", fontWeight: 600 }}>
                Back to interview setup
              </h5>              
            </div>
          )}

          <h3 style={{ marginTop: 0, marginBottom: "24px", fontSize: '20px', fontWeight: 600 }}>
            Upload or write candidate job description
          </h3>

          {/* Toggle Buttons */}
          <div className="d-flex flex-row" style={{ marginBottom: "24px" }}>
            <Tabs
              value={showUpload ? 1 : 0}
              onChange={(e, newValue) => setShowUpload(newValue === 1)}
              sx={{
                '& .MuiTabs-indicator': {
                  display: 'none'
                },
                backgroundColor: '#f5f5f5',
                padding: '4px',
                borderRadius: '8px'
              }}
            >
              <Tab 
                label="Write job description"
                sx={{
                  textTransform: 'none',
                  borderRadius: '6px',
                  color: '#000',
                  '&.Mui-selected': {
                    color: '#000',
                    backgroundColor: '#fff',
                    border: '1px solid #9BAECC',
                    boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)',
                    borderRadius: '8px'
                  },
                  minHeight: '36px'
                }}
              />
              <Tab
                label="Upload job description" 
                sx={{
                  textTransform: 'none', 
                  borderRadius: '6px',
                  color: '#000',
                  '&.Mui-selected': {
                    color: '#000',
                    backgroundColor: '#fff',
                    border: '1px solid #9BAECC',
                    boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)',
                    borderRadius: '8px'
                  },
                  minHeight: '36px'
                }}
              />
            </Tabs>
          </div>

          {/* Write or Upload Content */}
          {!showUpload ? (
            <FormField
              label={label ? label : "Job Description"}
              placeholder="Paste job description here..."
              value={jobDescriptionText}
              multiline
              minRows={6}
              onChange={(e) => {
                setJobDescriptionText(e.target.value);
              }}
              onKeyDown={(e) => {
                if ((e.metaKey || e.ctrlKey) && e.key === 'Enter') {
                  handleSubmitJD();
                }
              }}
              style={{
                overflow: 'auto',
                maxHeight: '400px'
              }}
            />
          ) : (
            <TooltipCustom
              title={
                <>
                  <p className="m-auto">Supports .pdf, .txt files.</p>
                </>
              }
            >
              <Button
                className="btn-upload d-flex flex-col"
                sx={{
                  height: "fit-content", 
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                  padding: "32px",
                  backgroundColor: "#F8F8F8 !important",
                  boxShadow: "none",
                  border: "0.5px solid #E0E0E0 !important",
                  textAlign: "center",
                  minHeight: "240px",
                  gap: "16px"
                }}
                component="label"
                role={undefined}
                variant="contained"
                tabIndex={-1}
              >
                <img src={UploadFileIcon} style={{ width: '33px', height: '32px' }} />

                <p className="section-heading" style={{margin: "0", maxWidth: "400px"}}>
                  Upload job description or any past interview notes/information
                  on the role
                </p>

                <VisuallyHiddenInput
                  type="file"
                  onChange={handleFileUpload}
                  accept="application/pdf,.txt"
                />
                
                <div className="btn-main no-hover">Upload file</div>
                {file && (
                  <div className="btn-main no-hover" onClick={clearFile}>
                    Clear file
                  </div>
                )}
              </Button>
            </TooltipCustom>
          )}
          <br/><br/>
          <div style={{ position: 'relative'}}>
            <FormField
              required
              label="Max Interview Length"
              type="number"
              value={maxInterviewLength}
              onChange={(e) => {setMaxInterviewLength(e.target.value)}}
              onKeyDown={(e) => {
                if ((e.metaKey || e.ctrlKey) && e.key === 'Enter') {
                  handleSubmitJD();
                }
              }}
            />
            <div style={{
              position: 'absolute',
              right: '12px',
              top: '54%',
              transform: 'translateY(-50%)',
              color: '#999',
              pointerEvents: 'none'
            }}>
              minutes
            </div>        
          </div>
          <button
            className="btn-main"
            style={{
              width: "100%",
              marginTop: '25px',
              backgroundColor: submitting ? "#333333" : "black",
              color: "white", 
              padding: "12px",
              fontSize: "16px",
              fontWeight: "600",
              height: "50px",
              border: "none",
              borderRadius: "8px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              gap: "8px",
              cursor: submitting ? "not-allowed" : "pointer"
            }}
            onClick={handleSubmitJD}
            disabled={submitting}
            onSubmit={(e) => {
              e.preventDefault();
              if ((e.metaKey || e.ctrlKey) && e.key === 'Enter') {
                handleSubmitJD();
              }
            }}
          >
            {submitting ? (
              <>
                <CircularProgress size={20} sx={{color: "white"}} />
                <span style={{display: 'flex', flexDirection: 'column', alignItems: 'center', gap: '4px'}}>
                  {messages[submittingIndex]}
                </span>
              </>
            ) : (
              <>
                Next Step
                <span style={{fontSize: '12px', opacity: 0.7, marginLeft: '4px'}}>
                  {!showUpload && `(${navigator.platform.includes('Mac') ? '⌘' : 'Ctrl'} + Enter)`}
                </span>
              </>
            )}
          </button>
          {errorMessage && (
            <Alert 
              severity="error"
              sx={{
                marginTop: '10px',
                '& .MuiAlert-message': {
                  fontSize: '14px',
                  fontWeight: 500
                }
              }}
            >
              {errorMessage}
            </Alert>
          )}
        </div>

        {/* Right Column */}
        <div className="d-flex flex-col" style={{ flex: 1, justifyContent: 'center', alignItems: 'center', overflow: 'hidden', marginTop: '80px' }}>
          <img src={InterviewDetailsGraphic} alt="Interview Setup" style={{ 
            width: '100%', 
            height: '100%', 
            objectFit: 'none',
            objectPosition: 'left top'
          }} />
        </div>        
      </div>
    );
  };

  const InterviewDetailDraggableRow = ({
    idx,
    question,
    questions,
    setCurrentQuestion,
    setShowAddQuestionInput,
    setQuestionDeletionIndex,
    setShowQuestionDeletionConfirmation,
  }) => {
    const { transform, transition, setNodeRef, isDragging } = useSortable({
      id: question.id,
    });

    const style = {
      transform: CSS.Transform.toString(transform), //let dnd-kit do its thing
      transition: transition,
      opacity: isDragging ? 0.8 : 1,
      zIndex: isDragging ? 1 : 0,
      position: "relative",
    };

    return (
      <TableRow
        key={question.id}
        ref={setNodeRef}
        sx={{
          transform: CSS.Transform.toString(transform),
          transition: transition,
          opacity: isDragging ? 0.8 : 1,
          zIndex: isDragging ? 1 : 0,
          position: "relative",
          border: "none",
          borderBottom: "1px solid lightgray",
          padding: "0px",
          margin: "0px",
        }}
      >
        <TableCell>
          <RowDragHandleCell rowId={question.id} />
        </TableCell>
        <TableCell
          sx={{
            display: "flex",
            flexDirection: "row",
            border: "none",
            marginTop: "10px",
            fontWeight: 600,
            fontSize: "14px",
          }}
        >
          {question.question}
        </TableCell>
        <TableCell sx={{ padding: "0px", margin: "0px", paddingLeft: "17px" }}>
          {question.follow_ups}
        </TableCell>
        <TableCell sx={{ padding: "0px", margin: "0px", marginLeft: "40px" }}>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              marginTop: "auto",
              marginBottom: "auto",
            }}
          >
            {/* Edit */}
            <IconButton
              onClick={() => {
                setCurrentQuestion({
                  text: question.question,
                  numFollowUps: question.follow_ups,
                  editIndex: idx,
                });
                setShowAddQuestionInput(true);
              }}
              className="drop-shadow-btn"
              style={{
                marginLeft: "15px",
                color: "black",
                fontSize: "14px",
                fontWeight: 500,
                marginRight: "8px",
                borderRadius: "9px",
                border: "0.5px solid lightgray",
                padding: "6px 9px 6px 8px",
              }}
            >
              <img src={EditIconNewSvg} /> Edit
            </IconButton>
            {/* Delete */}
            <IconButton
              disabled={questions.length === 1}
              onClick={() => {
                setQuestionDeletionIndex(idx);
                setShowQuestionDeletionConfirmation(true);
              }}
              className="drop-shadow-btn"
              style={{
                background: "#FFF8F8",
                color: "#F33D3D",
                fontSize: "14px",
                fontWeight: 500,
                marginRight: "4px",
                borderRadius: "9px",
                border: "0.5px solid lightgray",
                padding: "6px 9px 6px 8px",
              }}
            >
              <img src={DeleteIconNewSvg} /> Delete
            </IconButton>
          </div>
        </TableCell>
      </TableRow>
    );
  };

  /** this section is obsolete due to relying on question table
   * framework of the self-created interview questions, just fed with
   * imported questions.
   */
  const renderQuestions = () => {
    return (
      <div>
        {/* Main Content */}
        <div className="section d-flex flex-col">
          <h3 className="section-heading" style={{ marginTop: 0 }}>
            Voice assessment topics
          </h3>
          <p className="color-gray" style={{ margin: 0 }}>
            Asked in order of topics
          </p>
        </div>
        <div className="card">
          {/* TODO: style rest of interview tabs to match consistent action buttons, modals */}
          <div
            className="d-flex flex-row"
            style={{
              padding: "12px 16px",
              borderBottom: "1px solid lightgray",
              marginBottom: 0,
            }}
          >
            <div
              style={{
                backgroundColor: "var(--color-lightgray)",
                borderRadius: "8px",
                fontSize: "14px",
                fontWeight: "600",
                padding: "6px 8px",
              }}
            >
              All Topics ({interview.questions.length})
            </div>
            <button
              className="btn-main"
              style={{
                marginLeft: "auto",
                marginBottom: 0,
                padding: "6px 9.24px 6px 8px",
                fontWeight: 600,
                fontSize: "14px",
              }}
              onClick={() => {
                setShowAddQuestionInput(true);
              }}
            >
              + Add topics
            </button>
          </div>
          <DndContext
            collisionDetection={closestCenter}
            modifiers={[restrictToVerticalAxis]}
            onDragEnd={handleDragEnd}
            sensors={sensors}
          >
            <Grid style={{ marginTop: 0 }}>
              <TableContainer style={{ marginTop: 0 }}>
                <Table style={{ marginTop: 0 }}>
                  <TableHead>
                    <TableRow
                      sx={{
                        "& .MuiTableCell-root": {
                          lineHeight: "20px",
                          backgroundColor: "#FAFAFA",
                          color: "var(--color-heading)",
                          padding: "12px 16px",
                        },
                      }}
                    >
                      <TableCell></TableCell>
                      <TableCell style={{ width: "60%" }}>Topic</TableCell>
                      <TableCell style={{ minWidth: "72px" }}>Depth</TableCell>
                      <TableCell>Quick Actions</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <SortableContext
                      items={interview.questions.map((question, idx) => {
                        return question.id;
                      })}
                      strategy={verticalListSortingStrategy}
                    >
                      {interview.questions.map((question, idx) => (
                        <InterviewDetailDraggableRow
                          idx={idx}
                          question={question}
                          questions={interview.questions}
                          setCurrentQuestion={setCurrentQuestion}
                          setQuestionDeletionIndex={setQuestionDeletionIndex}
                          setShowQuestionDeletionConfirmation={
                            setShowQuestionDeletionConfirmation
                          }
                          setShowAddQuestionInput={setShowAddQuestionInput}
                        />
                      ))}
                    </SortableContext>
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
          </DndContext>
        </div>
      </div>
    );
  };

  const renderActiveSubStepComponent = () => {
    switch (activeSubStep) {
      case 0:
        return renderUploadStep();
      case 1:
        return renderQuestions();
      default:
        return (
          <>
            <CircularProgress />
          </>
        );
    }
  };

  return (
    <Container sx={{ 
      border: '0.5px solid #E0E0E0', 
      borderRadius: '8px', 
      boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)', 
      marginBottom: '25px',
      display: 'flex',
      backgroundColor: 'white',
      width: '100%',
      maxHeight: '800px',
      paddingRight: activeSubStep === 0 ? '0px !important' : '20px',
      paddingTop: activeSubStep === 0 ? '0px !important' : '20px',
      paddingBottom: activeSubStep === 0 ? '0px !important' : '20px'
    }}>     
      {renderActiveSubStepComponent()}
    </Container>
  );
}

export default CreateInterviewJD;
