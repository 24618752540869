import { useState, useEffect } from 'react';
import { Dialog, MenuItem, Select, FormControl, InputLabel, Box, Typography } from '@mui/material';
import FormSelect from '../common/FormSelect';
import api from "../../api/api";

const MoveFolder = ({ onClose, isOpen, folders, currentInterview }) => {
  const [selectedFolder, setSelectedFolder] = useState('');
  const [errMsgs, setErrMsgs] = useState([]);
  
  // Set the current folder as selected when component mounts or currentFolder changes
  // If currentFolder is undefined, select the first folder in the array
  useEffect(() => {
    if (currentInterview && currentInterview.folder) {
      setSelectedFolder(currentInterview.folder);
    }
  }, [currentInterview, folders]);

  const handleSubmit = async () => {
    try {
        const res = await api.updateInterview(currentInterview._id, {
            _id: currentInterview._id,
            folder: {
                _id: selectedFolder._id,
                name: selectedFolder.name
            }
        });
        if (res.status === 200) {
            onClose(selectedFolder);
        } else {
            console.error("Error updating interview:", res.data);
            setErrMsgs(["Failed to move interview to folder"]);
        }
    } catch (error) {
        console.error("Error updating interview:", error);
        setErrMsgs(["Failed to move interview to folder"]);
    }
  };

  const handleClose = () => {
    setSelectedFolder('');
    setErrMsgs([]);
    onClose();
  };

  return (
    <Dialog 
      open={isOpen}
      onClose={handleClose}
      aria-labelledby="move-folder-dialog-title"
      aria-describedby="move-folder-dialog-description"
    >
      <div style={{padding: "24px", width: "400px"}}>
        <h2 style={{fontSize: "18px", fontWeight: "600", marginBottom: "12px", marginTop: "0px"}}>Move interview to folder</h2>
        
        <FormSelect
          label="Select folder"
          value={selectedFolder || ''}
          noBorder
          onChange={(e) => setSelectedFolder(e.target.value)}
          disabled={folders.length === 0}
          fullWidth
          style={{
            marginBottom: '8px',
            fontSize: '14px',
            height: '40px'
          }}
        >
          {folders && folders.length > 0 ? folders.map((folder) => (
            <MenuItem key={folder._id} value={folder} sx={{ fontSize: '14px' }}>
              {folder.name}
            </MenuItem>
          )) : null}
        </FormSelect>

        {errMsgs && errMsgs.length > 0 && (
          <Box
            sx={{
              marginBottom: '16px',
              padding: '12px',
              backgroundColor: '#FEF2F2',
              borderRadius: '8px',
              border: '1px solid #FEE2E2'
            }}
          >
            {errMsgs.map((msg, idx) => (
              <Typography
                key={idx}
                variant="caption"
                sx={{
                  color: '#DC2626',
                  display: 'block',
                  fontSize: '14px',
                  '&:not(:last-child)': {
                    marginBottom: '4px'
                  }
                }}
              >
                {msg}
              </Typography>
            ))}
          </Box>
        )}

        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end', gap: '12px', marginTop: '8px' }}>
          <button
            onClick={handleClose}
            className="btn-main outlined gray"
            style={{ width: 'fit-content' }}
          >
            Cancel
          </button>
          <button
            onClick={handleSubmit}
            className="btn-main"
            style={{ width: 'fit-content' }}
            disabled={folders.length === 0}
          >
            Move to folder
          </button>
        </div>
      </div>
    </Dialog>
  );
};

export default MoveFolder;
