import React, { useEffect, useState, useMemo } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import api from "../api/api";
import { useNavigate } from 'react-router-dom'
import posthog from "posthog-js";
import { Sidebar } from "../components/nav/Sidebar";
import { useLocation } from 'react-router-dom'

/**
 * Sets base layout for dashboard - setting a navbar on top of layout
 * with the main content consisting of the layout component's children,
 * directly under navbar. Handle auth in wrapper.
 */
function BaseLayout({ children }) {
  const location = useLocation()

  const renderSidebar = useMemo(() => {
    // blacklist routes
    const SIDEBAR_BLACKLIST = ['/call-to-apply/', '/public', '/create-interview', '/your-team', '/onboarding'];
  
    return !SIDEBAR_BLACKLIST.some(path => location.pathname.includes(path));
  }, [location])

  const renderChildren = () => {
    return React.Children.map(children, (child) => {
      return React.cloneElement(child)
    })    
  }

  return (
    <>
      <div className="d-flex flex-row" style={{ width: '100%' }}>
        {renderSidebar && (<Sidebar />)}
        <main className="main h-100 d-flex flex-col" style={{ width: '100%' }}>
          {renderChildren()}
        </main>
      </div>
    </>
  );
}

export default BaseLayout;
