import React, { useState, useEffect } from 'react';
import { Box } from '@mui/material';
import { restrictToVerticalAxis } from '@dnd-kit/modifiers';
import {
    DndContext,
    useSensors,
    useSensor,
    MouseSensor,
    TouchSensor,
    KeyboardSensor,
    closestCenter
  } from "@dnd-kit/core";
import { useAuth0 } from '@auth0/auth0-react';
import { useNavigate } from 'react-router-dom';
import { Tabs, Tab } from '@mui/material';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Skeleton, CircularProgress, IconButton, MenuItem, Typography } from '@mui/material';
import ChipTag from "../common/ChipTag";
import { SessionStatus, SessionDecision } from "../../util/constants";
import api from "../../api/api";
import SearchIcon from '@mui/icons-material/Search';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ChatBubbleOutlineIcon from '@mui/icons-material/ChatBubbleOutline';
import { TextField, InputAdornment } from '@mui/material';
import Add from '@mui/icons-material/Add';
import { getColorFromScore, DEFAULT_SCALE, formatTimeSince } from '../../util/interviews'
import CloseIcon from '@mui/icons-material/Close';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import Sort from '@mui/icons-material/Sort';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import homesplash from '../../assets/homesplash.jpg';
import Splash from '../common/Splash';
import MessageDrawer from '../messaging/MessageDrawer';
import TooltipCustom from '../common/TooltipCustom';

const PAGE_SIZE = 50;

const Home = () => {
    const navigate = useNavigate();
    const { user, isAuthenticated, isLoading } = useAuth0();
    const teamId = localStorage.getItem("teamId");
    const sensors = useSensors(
        useSensor(MouseSensor, {}),
        useSensor(TouchSensor, {}),
        useSensor(KeyboardSensor, {})
    );
    const [currentTab, setCurrentTab] = useState("interviews");
    const [filteredSessions, setFilteredSessions] = useState([]);
    const [initSessionsCount, setInitSessionsCount] = useState(null);
    const [totalSessions, setTotalSessions] = useState(0);
    const [isFetchingInitial, setIsFetchingInitial] = useState(true);
    const [isFetchingNew, setIsFetchingNew] = useState(false);
    const [pagination, setPagination] = useState({}); // fields include total, page, totalPages, limit, startIndex, endIndex
    const [page, setPage] = useState(1);
    const [sortBy, setSortBy] = useState('last_status_timestamp');
    const [sortOrder, setSortOrder] = useState('desc');
    const [search, setSearch] = useState('');
    const [name, setName] = useState('');
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    
    // messaging
    const [showMessageDrawer, setShowMessageDrawer] = useState(false);
    const [selectedCandidate, setSelectedCandidate] = useState({});
    const [currentInterviewConfig, setCurrentInterviewConfig] = useState(null);
    const [conversation, setConversation] = useState({});
    const [messages, setMessages] = useState([]);

    const fetchConversation = (candidateId) => {
      api.getConversation({
        candidateId: candidateId
      }).then((response) => {
        console.log('conversation fetch', response.data);
        setConversation(response.data);
        setMessages(response.data.messages);
      }).catch((error) => {
        console.error('Error getting conversation:', error);
        setMessages([]);
        setConversation(null);
      });
    }

    useEffect(() => {
        const fetchInitSessionsCount = async () => {
            try {
                const response = await api.countSessions({ teamId });
                setInitSessionsCount(response.data.count);
            } catch (error) {
                console.error('Error fetching initial sessions count:', error);
                setInitSessionsCount(0);
            }
        };

        fetchInitSessionsCount();
    }, [teamId]);

    useEffect(() => {
        const fetchSessions = async () => {
            try {
                setIsFetchingNew(true);
                const response = await api.searchSessions({ teamId, page, limit: PAGE_SIZE, sortBy, sortOrder, name, startDate, endDate });
                const { sessions, pagination } = response.data;
                setTotalSessions(pagination.total || 0);
                setPagination(pagination || {});
                setFilteredSessions(sessions || []);
                setIsFetchingNew(false);
                setIsFetchingInitial(false);
            } catch (error) {
                console.error('Error fetching sessions:', error);
                setPagination({});
                setFilteredSessions([]);
                setIsFetchingInitial(false);
                setIsFetchingNew(false);
            }
        };

        fetchSessions();
    }, [page, sortBy, sortOrder, name, startDate, endDate]);

    if (isLoading) {
        return (
            <CircularProgress
            sx={{ color: "var(--color-primary)", margin: "auto" }}
            />
        );
    }

    {/* Default screen if team has no interviews yet */}
    if (initSessionsCount === 0) {
        return (
            <Splash
                img={homesplash}
                title="No interviews yet."
                paragraph="Kickstart your hiring process! Create your first interview and start screening candidates today."
            />
        )
    }

    return (
        <DndContext
          collisionDetection={closestCenter}
          modifiers={[restrictToVerticalAxis]}
          sensors={sensors}
        >

        {/* Loading Skeleton */}
        {isFetchingInitial && (
            <Box className="d-flex flex-col" style={{ padding: "24px 32px 16px 32px", width: "100%", marginLeft: "auto" }}>
                <Box className="d-flex flex-col">
                    <Skeleton variant="text" width={50} height={30} />
                    <Skeleton variant="text" width={100} height={18} />
                </Box>
                <Box className="d-flex flex-row" style={{ marginTop: '24px', gap: '12px' }}>
                    <Skeleton variant="text" width={90} height={40} />
                </Box>
                <Skeleton variant="rectangular" width="100%" height={500} style={{ borderRadius: '12px', marginTop: '24px' }}/>
            </Box>
        )}

        {initSessionsCount && !isFetchingInitial && (
            <Box
                    style={{
                    background: "#FAFAFA",
                    width: "100%",
                    }}
                >
            <Box
                style={{
                background: "#FAFAFA",
                width: "100%",
                margin: "auto",
                }}
            >
                {/* Home Header  */}
                <Box
                style={{
                    width: "100%",
                    background: "white",
                    padding: "24px 32px 16px 32px",
                }}
                >
                <div className="d-flex flex-row" style={{ width: "100%" }}>
                    <Box className="d-flex" style={{ background: "white" }}>
                        <Box className="d-flex flex-col">
                        <Box className="d-flex flex-row">
                            <h3
                            className="my-auto"
                            style={{ marginRight: "8px", fontWeight: 600, fontSize: '18px' }}
                            >
                            Home
                            </h3>
                        </Box>
                        <h5
                            className="color-gray my-auto"
                            style={{ fontWeight: 500, marginTop: "8px", fontSize: '14px' }}
                        >
                            {totalSessions} candidates 
                        </h5>
                        </Box>
                    </Box>

                    {/* Add interview button */}
                    <div
                        className="d-flex flex-row"
                        style={{ marginLeft: "auto" }}
                    >
                        <button 
                            className="btn-main d-flex"
                        onClick={() => {
                            navigate('/create-interview')
                        }}
                        >
                            <Add
                            fontSize="14px"
                            className="my-auto"
                            sx={{ marginRight: "2px" }}
                            ></Add>{" "}
                            Add Interview
                        </button>
                    </div>
                    </div>
                </Box>
            </Box>

                {/* Tabs */}
            <Box
                sx={{
                borderBottom: 1,
                borderColor: "divider",
                background: "white",
                }}
            >
                <Tabs
                value={currentTab}
                onChange={(_, value) => setCurrentTab(value)}
                disableRipple
                style={{ padding: "0px 32px", background: "white" }}
                sx={{
                    "& .MuiTabs-indicator": {
                    backgroundColor: "#0D0E14",
                    },
                }}
                >
                    <Tab
                        label="Interviews"
                        value="interviews"
                        disableRipple
                        style={{
                        textTransform: "none",
                        fontSize: "14px",
                        fontWeight: currentTab === "interviews" ? 600 : 500,
                        color: currentTab === "interviews" ? "black" : "darkgray",
                        zIndex: currentTab === "interviews" ? 10 : 0,
                        }}
                    />
                </Tabs>
            </Box>

            <Box
            style={{
                margin: "24px 32px",
                background: "white",
                border: "1px solid lightgray",
                borderRadius: "12px",
            }}
            >

            <div
            className="d-flex flex-row"
            style={{
                padding: "12px 16px",
                borderBottom: "1px solid lightgray",
                marginBottom: 0,
            }}
            >
                <Box sx={{ display: 'flex', alignItems: 'center', marginRight: '8px' }}>
                    <TextField
                        select
                        size="small"
                        defaultValue="newest"
                        SelectProps={{
                            MenuProps: {
                                PaperProps: {
                                    sx: {
                                        borderRadius: '8px',
                                        marginTop: '4px',
                                        boxShadow: '0 2px 8px rgba(0,0,0,0.15)',
                                        '& .MuiMenuItem-root': {
                                            fontSize: '14px',
                                            fontWeight: '500',
                                            letterSpacing: '-0.03px',
                                            paddingTop: '8px',
                                            paddingBottom: '8px',
                                            paddingLeft: '44px',
                                            '&:hover': {
                                                backgroundColor: 'var(--color-primary-bg-hover)'
                                            },
                                            '&.Mui-selected': {
                                                backgroundColor: 'var(--color-primary-bg)',
                                                '&:hover': {
                                                    backgroundColor: 'var(--color-primary-bg-hover)'
                                                }
                                            }
                                        }
                                    }
                                }
                            },
                            startAdornment: (
                                <InputAdornment position="start">
                                    <Sort sx={{ color: 'black', fontSize: 18 }} />
                                </InputAdornment>
                            ),
                            IconComponent: () => null
                        }}
                        sx={{
                            width: '225px',
                            '& .MuiInputBase-input': {
                                fontSize: '14px',
                                fontWeight: '500',
                                letterSpacing: '-0.03px',
                                paddingLeft: '4px',
                                paddingRight: '4px !important'
                            },
                            '& .MuiOutlinedInput-root': {
                                borderRadius: '8px',
                                boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
                                transition: 'box-shadow 0.2s ease-in-out',
                                '&:hover': {
                                    boxShadow: '0 4px 8px rgba(0,0,0,0.15)'
                                },
                                '& .MuiOutlinedInput-notchedOutline': {
                                    borderColor: 'var(--color-midgray)',
                                },
                                '&:hover .MuiOutlinedInput-notchedOutline': {
                                    borderColor: 'var(--color-midgray)',
                                },
                                '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                    borderColor: 'var(--color-midgray)',
                                    borderWidth: '1px',
                                },
                            },
                            '& .MuiSelect-select': {
                                paddingRight: '4px !important'
                            }
                        }}
                        onChange={async (e) => {
                            const value = e.target.value;
                            let newSortBy, newSortOrder;
                            
                            switch(value) {
                                case 'newest':
                                    newSortBy = 'last_status_timestamp';
                                    newSortOrder = 'desc';
                                    break;
                                case 'oldest':
                                    newSortBy = 'last_status_timestamp';
                                    newSortOrder = 'asc';
                                    break;
                                case 'highest':
                                    newSortBy = 'overall_score';
                                    newSortOrder = 'desc';
                                    break;
                                case 'lowest':
                                    newSortBy = 'overall_score';
                                    newSortOrder = 'asc';
                                    break;
                                default:
                                    return;
                            }

                            setSortBy(newSortBy);
                            setSortOrder(newSortOrder);
                            setPage(1);
                        }}
                    >
                        <MenuItem value="newest">Update: Newest to oldest</MenuItem>
                        <MenuItem value="oldest">Update: Oldest to newest</MenuItem>
                        <MenuItem value="highest">Score: Highest to lowest</MenuItem>
                        <MenuItem value="lowest">Score: Lowest to highest</MenuItem>
                    </TextField>
                </Box>

                <Box sx={{ display: 'flex', gap: '8px', alignItems: 'center' }}>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker
                            clearable
                            value={startDate ? dayjs(startDate) : null}
                            slotProps={{
                                textField: {
                                    size: "small",
                                    placeholder: "Start date",
                                },
                                field: {
                                    clearable: true,
                                    onClear: () => {
                                        setStartDate('');
                                        setPage(1);
                                    }
                                },
                                inputAdornment: {
                                    position: 'start',
                                }
                            }}
                            slots={{
                                openPickerIcon: () => <CalendarTodayIcon sx={{ fontSize: 18, color: 'black', marginLeft: '4px' }} />,
                                clearIcon: () => <CloseIcon sx={{ fontSize: 18, color: 'black', marginRight: '4px' }} />,
                            }}
                            sx={{
                                width: '160px',
                                '& .MuiInputBase-root': {
                                    fontSize: '14px',
                                    fontWeight: '500',
                                    borderRadius: '8px',
                                    paddingTop: '1.5px',
                                    paddingBottom: '1.5px',
                                },
                                '& .MuiOutlinedInput-root': {
                                    borderRadius: '8px',
                                    boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
                                    transition: 'box-shadow 0.2s ease-in-out',
                                    '&:hover': {
                                        boxShadow: '0 4px 8px rgba(0,0,0,0.15)'
                                    },
                                    '& .MuiOutlinedInput-notchedOutline': {
                                        borderColor: 'var(--color-midgray)',
                                    },
                                    '&:hover .MuiOutlinedInput-notchedOutline': {
                                        borderColor: 'var(--color-midgray)',
                                    },
                                    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                        borderColor: 'var(--color-midgray)',
                                        borderWidth: '1px',
                                    },
                                },
                                '& .MuiIconButton-root': {
                                    padding: '4px',
                                    '&:hover': {
                                        backgroundColor: 'transparent'
                                    }
                                }
                            }}
                            onChange={(date) => {
                                setPage(1);
                                setStartDate(date ? date.format('YYYY-MM-DD') : '');
                            }}
                            PopperProps={{
                                sx: {
                                    '& .MuiPaper-root': {
                                        boxShadow: '0 4px 8px rgba(0,0,0,0.15)',
                                        borderRadius: '8px',
                                    },
                                    '& .MuiPickersDay-root': {
                                        fontSize: '14px',
                                        '&.Mui-selected': {
                                            backgroundColor: 'var(--color-primary)',
                                            '&:hover': {
                                                backgroundColor: 'var(--color-primary)',
                                            }
                                        }
                                    },
                                    '& .MuiDayCalendar-weekDayLabel': {
                                        fontSize: '14px',
                                    },
                                    '& .MuiPickersCalendarHeader-label': {
                                        fontSize: '14px',
                                        fontWeight: 'bold',
                                    }
                                }
                            }}
                        />

                        <DatePicker
                            value={endDate ? dayjs(endDate) : null}
                            slotProps={{
                                textField: {
                                    size: "small",
                                    placeholder: "End date",
                                },
                                field: {
                                    clearable: true,
                                    onClear: () => {
                                        setEndDate('');
                                        setPage(1);
                                    }
                                },
                                inputAdornment: {
                                    position: 'start'
                                }
                            }}
                            slots={{
                                openPickerIcon: () => <CalendarTodayIcon sx={{ fontSize: 18, color: 'black', marginLeft: '4px' }} />,
                                clearIcon: () => <CloseIcon sx={{ fontSize: 18, color: 'black', marginRight: '4px' }} />,
                            }}
                            sx={{
                                width: '160px',
                                '& .MuiInputBase-root': {
                                    fontSize: '14px',
                                    fontWeight: '500',
                                    borderRadius: '8px',
                                    paddingTop: '1.5px',
                                    paddingBottom: '1.5px',
                                },
                                '& .MuiOutlinedInput-root': {
                                    borderRadius: '8px',
                                    boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
                                    transition: 'box-shadow 0.2s ease-in-out',
                                    '&:hover': {
                                        boxShadow: '0 4px 8px rgba(0,0,0,0.15)'
                                    },
                                    '& .MuiOutlinedInput-notchedOutline': {
                                        borderColor: 'var(--color-midgray)',
                                    },
                                    '&:hover .MuiOutlinedInput-notchedOutline': {
                                        borderColor: 'var(--color-midgray)',
                                    },
                                    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                        borderColor: 'var(--color-midgray)',
                                        borderWidth: '1px',
                                    },
                                },
                                '& .MuiIconButton-root': {
                                    padding: '4px',
                                    '&:hover': {
                                        backgroundColor: 'transparent'
                                    }
                                }
                            }}
                            onChange={(date) => {
                                setPage(1);
                                setEndDate(date ? date.format('YYYY-MM-DD') : '');
                            }}
                            PopperProps={{
                                sx: {
                                    '& .MuiPaper-root': {
                                        boxShadow: '0 4px 8px rgba(0,0,0,0.15)',
                                        borderRadius: '8px',
                                    },
                                    '& .MuiPickersDay-root': {
                                        fontSize: '14px',
                                        '&.Mui-selected': {
                                            backgroundColor: 'var(--color-primary)',
                                            '&:hover': {
                                                backgroundColor: 'var(--color-primary)',
                                            }
                                        }
                                    },
                                    '& .MuiDayCalendar-weekDayLabel': {
                                        fontSize: '14px',
                                    },
                                    '& .MuiPickersCalendarHeader-label': {
                                        fontSize: '14px',
                                        fontWeight: 'bold',
                                    }
                                }
                            }}
                        />
                    </LocalizationProvider>
                </Box>

                {/* Search Bar */}
                <div style={{ display: "flex", justifyContent: "flex-end", alignItems: "center", width: "100%" }}>
                    {/* Search Chip */}
                    {name && (
                        <Box sx={{ display: 'flex', alignItems: 'center', marginRight: '8px' }}>
                            <ChipTag
                                text={name}
                                onDelete={() => {
                                    setName('');
                                    setPage(1);
                                }}
                                deleteIcon={<CloseIcon style={{ fontSize: '16px' }}/>}
                                sx={{
                                    backgroundColor: '#E0E0E0',
                                    color: '#424242',
                                    height: '28px',
                                    '& .MuiChip-deleteIcon': {
                                        color: '#757575',
                                        '&:hover': {
                                            color: '#424242'
                                        },
                                    },
                                    width: 'max-content'
                                }}
                            />
                        </Box>
                    )}
                    <TextField
                    value={search}
                    variant="outlined" 
                    size="small"
                    InputProps={{
                        startAdornment: (
                        <InputAdornment position="start">
                            <IconButton
                                onClick={() => {
                                    setName(search);
                                    setSearch('');
                                    setPage(1);
                                }}
                                edge="start"
                                size="small"
                            >
                                <SearchIcon style={{ color: "black", fontSize: "18px", fontWeight: "bold" }} />
                            </IconButton>
                        </InputAdornment>
                        ),
                    }}
                    onKeyDown={(e) => {
                        if (e.key === 'Enter') {
                            setName(search);
                            setSearch('');
                            setPage(1);
                        }
                    }}
                    placeholder="Search Candidates"
                    sx={{ 
                        width: "200px",
                        '& .MuiInputBase-input': {
                            fontSize: '14px',
                            fontWeight: '500',
                            paddingTop: '10px',
                            paddingBottom: '10px',
                        },
                        '& .MuiOutlinedInput-root': {
                            borderRadius: '8px',
                            boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
                            transition: 'box-shadow 0.2s ease-in-out',
                            '&:hover': {
                                boxShadow: '0 4px 8px rgba(0,0,0,0.15)'
                            },
                            '& .MuiOutlinedInput-notchedOutline': {
                                borderColor: 'var(--color-midgray)',
                            },
                            '&:hover .MuiOutlinedInput-notchedOutline': {
                                borderColor: 'var(--color-midgray)',
                            },
                            '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                borderColor: 'var(--color-midgray)',
                                borderWidth: '1px',
                            },
                        }
                    }}
                    onChange={(e) => {
                        const value = e.target.value.toLowerCase();
                        setSearch(value);
                    }}
                    />
                </div>
            </div>

            <>
            <TableContainer style={{ overflowX: "auto" }}>
                <Table>
                <TableHead>
                    <TableRow
                    sx={{
                        "& .MuiTableCell-root": {
                        lineHeight: "20px",
                        backgroundColor: "#FAFAFA",
                        color: "var(--color-heading)",
                        padding: "12px 16px",
                        },
                    }}
                    >
                    <TableCell sx={{ width: '20%', fontWeight: 600, fontSize: "14px" }}>Candidate</TableCell>
                    <TableCell sx={{ width: '12.5%', fontWeight: 600, fontSize: "14px" }}>Status</TableCell>
                    <TableCell sx={{ width: '12.5%', fontWeight: 600, fontSize: "14px" }}>Updated</TableCell>
                    <TableCell sx={{ width: '12.5%', fontWeight: 600, fontSize: "14px" }}>Decision</TableCell>
                    <TableCell sx={{ width: '12.5%', fontWeight: 600, fontSize: "14px" }}>Score</TableCell>
                    <TableCell sx={{ width: '30%', fontWeight: 600, fontSize: "14px" }}>Interview Config</TableCell>
                    </TableRow>
                </TableHead>
                {isFetchingNew ? (
                    <TableBody>
                        {[...Array(5)].map((_, index) => (
                            <TableRow key={index}>
                                <TableCell><Skeleton variant="text" width="90%" height="30px" /></TableCell>
                                <TableCell><Skeleton variant="text" width="90%" height="30px" /></TableCell>
                                <TableCell><Skeleton variant="text" width="90%" height="30px" /></TableCell>
                                <TableCell><Skeleton variant="text" width="90%" height="30px" /></TableCell>
                                <TableCell><Skeleton variant="text" width="90%" height="30px" /></TableCell>
                                <TableCell><Skeleton variant="text" width="90%" height="30px" /></TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                ) : (
                <TableBody>
                    {filteredSessions.map((session, idx) => {
                    return (
                        <TableRow
                        className={`clickableRow`}
                        key={idx}
                        onClick={() => {
                            navigate(`/interviews/${session.interview_config_id}/sessions/${session._id}`);
                        }}
                        style={{ cursor: "pointer", height: "64px" }}
                        sx={{
                            cursor: 'pointer',
                            '& .MuiTableCell-root': {
                                borderTop: '1px solid transparent',
                                borderBottom: '1px solid #e0e0e0',
                            },
                            '&:hover .MuiTableCell-root': {
                                borderTop: '1px solid var(--color-primary)',
                                borderBottom: '1px solid var(--color-primary)',
                            }
                        }}
                        >
                        <TableCell sx={{ fontWeight: 500, fontSize: "14px" }}>
                            {session.candidate_metadata && (
                            <Box sx={{ display: 'flex', alignItems: 'center', gap: '12px' }}>
                                <Box sx={{
                                    width: '28px',
                                    height: '28px',
                                    minWidth: '28px',
                                    borderRadius: '50%',
                                    backgroundColor: '#000',
                                    color: '#fff',
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    fontSize: '13px',
                                    fontWeight: 500
                                }}>
                                    {((session.candidate_metadata.first_name?.[0] || '').toUpperCase() + (session.candidate_metadata.last_name?.[0] || '').toUpperCase()) || 'NA'}
                                </Box>
                                <Typography noWrap sx={{ maxWidth: '180px', fontSize: '14px', fontWeight: 500 }}>
                                    {(session.candidate_metadata.first_name || '') + " " + (session.candidate_metadata.last_name || '')}
                                </Typography>

                                {/* Bullhorn */}
                                {session.candidate_metadata.bullhorn_candidate_id && (
                                    <div style={{
                                    backgroundColor: "var(--color-bullhorn-bg)",
                                    borderRadius: "4px",
                                    padding: "4px 8px",
                                    fontSize: "14px",
                                    fontFamily: "Inter",
                                    fontWeight: 600,
                                    color: "var(--color-bullhorn)",
                                    display: "flex",
                                    alignItems: "center",
                                    gap: "4px"
                                    }}>
                                    <img 
                                        src={require("../../assets/bullhorn_logo_bull.png")} 
                                        alt="Bullhorn" 
                                        style={{ height: "14px", width: "auto" }} 
                                    />
                                    {session.candidate_metadata.bullhorn_candidate_id}
                                    </div>
                                )}
                                
                                {/* Messaging */}
                                {process.env.REACT_APP_INBOX_TAB_ENABLED === "true" && <div style={{ marginLeft: 'auto' }}>
                                    {(session.candidate_metadata && session.candidate_metadata.phone && session.candidate_metadata.id) && 
                                    <>
                                        <TooltipCustom title="See candidate text conversation">
                                            <IconButton className="color-gray" 
                                                onClick={(e) => {
                                                    e.preventDefault();
                                                    e.stopPropagation();
                                                    setSelectedCandidate(session.candidate_metadata);
                                                    setCurrentInterviewConfig(session.interview_config_id);
                                                    setShowMessageDrawer(true);
                                                
                                                    api.getConversation({
                                                      candidateId: session.candidate_metadata.id
                                                    }).then((response) => {
                                                      setConversation(response.data);
                                                      setMessages(response.data.messages);
                                                    }).catch((error) => {
                                                      console.error('Error getting conversation:', error);
                                                      setMessages([]);
                                                      setConversation(null);
                                                    });
                                                }}>
                                                <ChatBubbleOutlineIcon style={{ fontSize: "16px" }}/>
                                            </IconButton>
                                        </TooltipCustom>
                                    </>
                                    }
                                </div>}
                            </Box>
                            )}
                            {!session.candidate_metadata && (
                            <Box sx={{ display: 'flex', alignItems: 'center', gap: '12px' }}>
                                <Box sx={{
                                    width: '28px',
                                    height: '28px',
                                    borderRadius: '50%',
                                    backgroundColor: '#000',
                                    color: '#fff',
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    fontSize: '13px',
                                    fontWeight: 500
                                }}>
                                    NA
                                </Box>
                                N/A
                            </Box>
                            )}
                        </TableCell>
                        <TableCell>
                            {session.voicemail_detected ? (
                            <ChipTag
                                variant="default"
                                text="Voicemail"
                            />
                            ) : (
                            <>
                                {session.last_status === 
                                SessionStatus.NOT_STARTED && (
                                    session.sent_invite ?
                                        <ChipTag
                                            variant="invited"
                                            text="Invited"
                                        />
                                    :
                                        <ChipTag
                                            variant="not-started"
                                            text="Not Started"
                                        />
                                )}
                                {session.last_status ===
                                SessionStatus.IN_PROGRESS && (
                                <ChipTag
                                    variant="in-progress"
                                    text="In Progress"
                                />
                                )}
                                {session.last_status ===
                                SessionStatus.COMPLETED && (
                                <ChipTag
                                    variant="completed"
                                    text="Completed"
                                />
                                )}
                                {session.last_status ===
                                SessionStatus.INCOMPLETE && (
                                <ChipTag
                                    variant="in-progress"
                                    text="Incomplete"
                                />
                                )}
                            </>
                            )}
                        </TableCell>
                        <TableCell sx={{ fontWeight: 500, fontSize: "14px" }}>
                            {session.last_status_timestamp ? formatTimeSince(session.last_status_timestamp) : 'N/A'}
                        </TableCell>
                        <TableCell>
                            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                {session.overall_decision === SessionDecision.PASS ? (
                                    <ChipTag variant="completed" text="Accept" />
                                ) : session.overall_decision === SessionDecision.FAIL ? (
                                    <ChipTag variant="reject" text="Reject" />
                                ) : session.overall_decision === SessionDecision.UNDECIDED ? (
                                    <ChipTag variant="pending" text="Pending" />
                                ) : (
                                    <ChipTag variant="pending" text="Pending" />
                                )}
                                {session.potentialCheating && (
                                    <ChipTag variant="danger" text="Suspicious" style={{ marginLeft: '8px', backgroundColor: '#FF4D4D', color: 'white' }} />
                                )}
                            </Box>
                        </TableCell>
                        <TableCell>
                            <Box
                                sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    backgroundColor: getColorFromScore(session.overall_score, session.overall_scale || DEFAULT_SCALE),
                                    color: 'white',
                                    width: '32px',
                                    height: '32px',
                                    borderRadius: '4px',
                                    fontSize: '14px',
                                    fontWeight: 'bold'
                                }}
                            >
                                {session.overall_score === -1 ? '-' : (session.overall_score ? session.overall_score.toFixed(1) : '-')}
                            </Box>
                        </TableCell>
                        <TableCell sx={{ fontWeight: 500, fontSize: "14px" }}>
                            <a href={`/interviews/${session.interview_config_id}`} style={{ textDecoration: 'underline', color: 'black' }}>
                                <Typography noWrap sx={{ maxWidth: '240px', fontSize: '14px', fontWeight: 500 }}>
                                    {session.interview_config_title}
                                </Typography>
                            </a>
                        </TableCell>
                        </TableRow>
                    );
                    })}
                </TableBody>
                )}
                </Table>
            </TableContainer>

            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', padding: '20px' }}>
                <button
                    style={{
                        backgroundColor: 'white',
                        border: '1px solid lightgray',
                        borderRadius: '4px',
                        padding: '8px 12px',
                        fontSize: '14px',
                        fontWeight: '500',
                        letterSpacing: '0%',
                        marginRight: '16px',
                        display: 'flex',
                        alignItems: 'center',
                        cursor: 'pointer',
                    }}
                    onClick={() => setPage(page - 1)}
                    disabled={page === 1}
                >
                    <ArrowBackIcon sx={{ fontSize: 14, marginRight: 1 }} /> Previous
                </button>
                
                <span style={{ 
                    fontSize: '14px',
                    fontWeight: '500',
                }}>
                    {pagination.startIndex}-{pagination.endIndex} of {pagination.total}
                </span>

                <button
                    style={{
                        backgroundColor: 'white',
                        border: '1px solid lightgray',
                        borderRadius: '4px',
                        padding: '8px 12px',
                        fontSize: '14px',
                        fontWeight: '500',
                        letterSpacing: '0%',
                        marginLeft: '16px',
                        display: 'flex',
                        alignItems: 'center',
                        cursor: 'pointer',
                    }}
                    onClick={() => setPage(page + 1)}
                    disabled={page >= pagination.totalPages}
                >
                    Next <ArrowBackIcon sx={{ fontSize: 14, marginLeft: 1, transform: 'rotate(180deg)' }} />
                </button>
            </Box>
            </>
                </Box>
            </Box>
        )}
        {/* Message Drawer */}
        {process.env.REACT_APP_INBOX_TAB_ENABLED === "true" && 
            <MessageDrawer
            candidateId={selectedCandidate.id}
            candidateName={selectedCandidate.first_name + " " + selectedCandidate.last_name}
            candidatePhone={selectedCandidate.phone}
            messages={messages}
            open={showMessageDrawer}
            onClose={() => setShowMessageDrawer(false)}
            platform={selectedCandidate.platform}
            interviewConfigId={currentInterviewConfig}
            conversation={conversation}
            refetchConversation={() => fetchConversation(selectedCandidate.id)}
            />
        }

        </DndContext>
    );
}

export default Home;

