import { Box } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { Add } from '@mui/icons-material';

const Splash = ({ img, title, paragraph }) => {
    const navigate = useNavigate();

    return (
        <Box
            sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                height: '100vh',
                width: '100%',
                background: 'var(--color-lightgray)'
            }}
        >
            <Box className="d-flex flex-row align-center justify-start" sx={{ 
                width: "60%", 
                height: "40%",
                boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.05)"
            }}
            >
                <Box className="d-flex flex-col" style={{ 
                    width: "55%", 
                    justifyContent: "center",
                    background: "white",
                    borderRadius: "12px 0px 0px 12px",
                }}
                >
                    <Box
                        sx={{
                            fontSize: "20px",
                            fontFamily: "Inter, sans-serif",
                            fontWeight: "bold",
                            padding: "16px 0px 0px 16px"
                        }}
                    >{title}</Box>
                    <Box
                        sx={{
                            fontSize: "14px",
                            fontFamily: "Inter, sans-serif",
                            fontWeight: "normal",
                            padding: "8px 16px 0px 16px"
                        }}
                    >{paragraph}</Box>
                    <div
                        className="d-flex flex-row"
                        style={{ marginTop: "16px", marginLeft: "16px" }}
                    >
                        <button 
                            className="btn-main d-flex"
                            onClick={() => {
                                navigate('/create-interview')
                            }}
                        >
                            <Add
                                fontSize="14px"
                                className="my-auto"
                            ></Add>{" "}
                            Add Interview
                        </button>
                    </div>
                </Box>
                <Box sx={{ 
                    width: "45%", 
                    background: `url(${img})`,
                    backgroundSize: "cover",
                    backgroundPosition: "center",
                    borderRadius: "0px 12px 12px 0px"
                }}>
                </Box>
            </Box>
        </Box>
    );
};

export default Splash;
