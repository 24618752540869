import React from 'react';
import { Box, Avatar, Chip } from '@mui/material';
import { formatTimeSince } from '../../util/interviews';

const MessageListItem = React.memo(({ conversation, selected, handleClick }) => {

    return (
    <Box className={`d-flex flex-col message-list-item clickable ${selected ? 'message-list-item-selected' : ''}`} onClick={handleClick}>
      <div className="d-flex flex-row" style={{ width: "100%" }}>
        <Avatar className="my-auto" src={conversation.avatar} style={{ width: "32px", height: "32px" }} />
        <Box className="d-flex flex-col" style={{ marginLeft: "10px", flex: 1, minWidth: 0 }}>
          <Box className="d-flex flex-row" style={{ justifyContent: "space-between", width: "100%" }}>
            <h4 className="color-main font-inter my-auto" style={{ fontWeight: 600 }}>
              {conversation.candidate_name}
            </h4>
            {conversation.unreadCount > 0 && <Box style={{ width: "25%", textAlign: "right" }}>
              <Chip label={conversation.unreadCount} size="small" color="primary" style={{ backgroundColor: "var(--color-required)", borderRadius: "6px", height: "20px", width: "fit-content" }} />
            </Box>}
          </Box>
          <Box className="d-flex flex-row" style={{ justifyContent: "space-between", width: "100%", marginTop: "2px" }}>
            <h4 className="color-gray font-inter my-auto" style={{ fontWeight: 400, fontSize: "14px", flex: 3, whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis" }}>
              {conversation.latestMessage ? conversation.latestMessage.body : "..."}
            </h4>
            <Box className="my-auto" style={{ width: "fit-content", textAlign: "right", marginLeft: "8px" }}>
              <h5 className="color-gray font-inter my-auto" style={{ fontWeight: 400 }}>
                  {conversation.latestMessage ? formatTimeSince(conversation.latestMessage.date_created.$date ? conversation.latestMessage.date_created.$date : conversation.latestMessage.date_created) : ""}
              </h5>
            </Box>
          </Box>
        </Box>
      </div>
    </Box>
    );
});

export default MessageListItem; 