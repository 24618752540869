import StartupsIcon from '../../assets/industries/startups.svg';
import RetailIcon from '../../assets/industries/retail.svg';
import StaffingIcon from '../../assets/industries/staffing.svg';
import HealthcareIcon from '../../assets/industries/healthcare.svg';
import CustomerCareIcon from '../../assets/industries/customer-care.svg';
import ScorecardGraphic from '../../assets/svg/scorecard.svg';
import InstacartIcon from '../../assets/svg/InstacartIcon.svg';
import CoinbaseIcon from '../../assets/svg/CoinbaseIcon.svg';
import StripeIcon from '../../assets/svg/StripeIcon.svg';
import OpenAIIcon from '../../assets/svg/OpenAIIcon.svg';
import { instacartSoftwareEngineer, coinbaseProductManager, openaiProductManager, coinbaseSoftwareEngineer, openaiFrontendEngineer, stripeSoftwareEngineer, instacartProductManager, coinbaseSalesRepresentative, instacartSalesRepresentative, coinbaseMarketingManager, instacartMarketingManager, coinbaseCustomerSuccess, instacartCustomerSuccess, coinbaseCustomerServiceRep, instacartCustomerServiceRep, coinbaseCallCenterAgent, instacartCallCenterAgent, coinbaseSupportSpecialist, instacartSupportSpecialist, coinbaseTeamLead, instacartTeamLead, coinbaseQualityAnalyst, instacartQualityAnalyst, coinbaseRecruiter, rampAccountManager, ripplingAccountManager, rampStaffingCoordinator, ripplingStaffingCoordinator, coinbaseTalentAcquisition, instacartTalentAcquisition, coinbaseHrManager, instacartHrManager, newYorkPresbyterianHospitalRegisteredNurse, brighamAndWomensHospitalRegisteredNurse, newYorkPresbyterianHospitalMedicalAssistant, brighamAndWomensHospitalMedicalAssistant, newYorkPresbyterianHospitalPhysician, brighamAndWomensHospitalPhysician, newYorkPresbyterianHospitalHealthcareAdmin, brighamAndWomensHospitalHealthcareAdmin, newYorkPresbyterianHospitalPhysicalTherapist, selectMedicalCenterPhysicalTherapist, stripeProductManager, walmartStoreManager, targetStoreManager, walmartSalesAssociate, targetSalesAssociate, walmartCashier, targetCashier, walmartVisualMerchandiser, targetVisualMerchandiser, walmartInventorySpecialist, targetInventorySpecialist, instacartRecruiter, registeredNurse, medicalAssistant, physician, healthcareAdmin, physicalTherapist, customerServiceRep, callCenterAgent, supportSpecialist, teamLead, qualityAnalyst, administrativeAssistant, projectManager, accountant, salesManager, operationsManager } from './templates';

const industries = [
    { 
      id: 'startups',
      label: 'Startups',
      icon: StartupsIcon,
      roles: [
        { 
          id: 'software-engineer', 
          label: 'Software Engineer', 
          templates: [
            {
              id: 'instacart-software-engineer',
              company: 'Instacart',
              title: 'Software Engineer',
              template: instacartSoftwareEngineer,
              icon: InstacartIcon
            },
            {
              id: 'coinbase-software-engineer',
              company: 'Coinbase',
              title: 'Software Engineer',
              template: coinbaseSoftwareEngineer,
              icon: CoinbaseIcon
            },
            {
              id: 'stripe-software-engineer',
              company: 'Stripe',
              title: 'Software Engineer - Infrastructure',
              template: stripeSoftwareEngineer,
              icon: StripeIcon
            },
            {
              id: 'openai-frontend-engineer',
              company: 'OpenAI',
              title: 'Frontend Engineer',
              template: openaiFrontendEngineer,
              icon: OpenAIIcon
            }
          ] 
        },
        { 
          id: 'product-manager', 
          label: 'Product Manager', 
          templates: [
            {
              id: 'instacart-product-manager',
              company: 'Instacart',
              title: 'Product Manager',
              template: instacartProductManager,
              icon: InstacartIcon
            },
            {
              id: 'coinbase-product-manager',
              company: 'Coinbase',
              title: 'Product Manager',
              template: coinbaseProductManager,
              icon: CoinbaseIcon
            },
            {
              id: 'stripe-product-manager',
              company: 'Stripe',
              title: 'Product Manager',
              template: stripeProductManager,
              icon: StripeIcon
            },
            {
              id: 'openai-product-manager',
              company: 'OpenAI',
              title: 'Product Manager',
              template: openaiProductManager,
              icon: OpenAIIcon
            }
          ] 
        },
        { 
          id: 'sales-representative', 
          label: 'Sales Representative', 
          templates: [
            {
              id: 'instacart-sales-representative',
              company: 'Instacart',
              title: 'Sales Representative',
              template: instacartSalesRepresentative,
              icon: InstacartIcon
            },
            {
              id: 'coinbase-sales-representative',
              company: 'Coinbase',
              title: 'Sales Representative',
              template: coinbaseSalesRepresentative,
              icon: CoinbaseIcon
            },
          ] 
        },
        { 
          id: 'marketing-manager', 
          label: 'Marketing Manager',
          templates: [
            {
              id: 'instacart-marketing-manager',
              company: 'Instacart',
              title: 'Marketing Manager',
              template: instacartMarketingManager,
              icon: InstacartIcon
            },
            {
              id: 'coinbase-marketing-manager',
              company: 'Coinbase',
              title: 'Marketing Manager',
              template: coinbaseMarketingManager,
              icon: CoinbaseIcon
            }
          ] 
        },
        { 
          id: 'customer-success', 
          label: 'Customer Success', 
          templates: [
            {
              id: 'instacart-customer-success',
              company: 'Instacart',
              title: 'Customer Success',
              template: instacartCustomerSuccess,
              icon: InstacartIcon
            },
            {
              id: 'coinbase-customer-success',
              company: 'Coinbase',
              title: 'Customer Success',
              template: coinbaseCustomerSuccess,
              icon: CoinbaseIcon
            },
            
          ] 
        },
        { 
          id: 'other', 
          label: 'Other', 
          templates: [
            {
              id: 'software-engineer',
              label: 'Software Engineer',
              template: 'Software Engineer'
            },
            {
              id: 'product-manager',
              label: 'Product Manager',
              template: 'Product Manager'
            },
            
          ] 
        }
      ]
    },
    {
      id: 'retail',
      label: 'Retail',
      icon: RetailIcon,
      roles: [
        { 
          id: 'store-manager', 
          label: 'Store Manager',
          templates: [
            {
              id: 'walmart-store-manager',
              company: 'Walmart',
              title: 'Store Manager',
              template: walmartStoreManager
            },
            {
              id: 'target-store-manager',
              company: 'Target',
              title: 'Store Manager',
              template: targetStoreManager
            }
          ]
        },
        { 
          id: 'sales-associate', 
          label: 'Sales Associate',
          templates: [
            {
              id: 'walmart-sales-associate',
              company: 'Walmart',
              title: 'Sales Associate',
              template: walmartSalesAssociate
            },
            {
              id: 'target-sales-associate',
              company: 'Target',
              title: 'Sales Associate',
              template: targetSalesAssociate
            }
          ]
        },
        { 
          id: 'cashier', 
          label: 'Cashier',
          templates: [
            {
              id: 'walmart-cashier',
              company: 'Walmart',
              title: 'Cashier',
              template: walmartCashier
            },
            {
              id: 'target-cashier',
              company: 'Target',
              title: 'Cashier',
              template: targetCashier
            }
          ]
        },
        { 
          id: 'visual-merchandiser', 
          label: 'Visual Merchandiser',
          templates: [
            {
              id: 'walmart-visual-merchandiser',
              company: 'Walmart',
              title: 'Visual Merchandiser',
              template: walmartVisualMerchandiser
            },
            {
              id: 'target-visual-merchandiser',
              company: 'Target',
              title: 'Visual Merchandiser',
              template: targetVisualMerchandiser
            }
          ]
        },
        { 
          id: 'inventory-specialist', 
          label: 'Inventory Specialist',
          templates: [
            {
              id: 'walmart-inventory-specialist',
              company: 'Walmart',
              title: 'Inventory Specialist',
              template: walmartInventorySpecialist
            },
            {
              id: 'target-inventory-specialist',
              company: 'Target',
              title: 'Inventory Specialist',
              template: targetInventorySpecialist
            }
          ]
        },
        { 
          id: 'other', 
          label: 'Other',
          templates: [
            {
              id: 'software-engineer',
              label: 'Software Engineer',
              template: 'Software Engineer'
            },
            {
              id: 'product-manager',
              label: 'Product Manager',
              template: 'Product Manager'
            }
          ]
        }
      ]
    },
    {
      id: 'staffing',
      label: 'Staffing',
      icon: StaffingIcon,
      roles: [
        { 
          id: 'recruiter', 
          label: 'Recruiter',
          templates: [
            {
              id: 'coinbase-recruiter',
              company: 'Coinbase',
              title: 'Recruiter',
              template: coinbaseRecruiter
            },
            {
              id: 'instacart-recruiter',
              company: 'Instacart',
              title: 'Recruiter',
              template: instacartRecruiter
            }
          ]
        },
        { 
          id: 'account-manager', 
          label: 'Account Manager',
          templates: [
            {
              id: 'ramp-account-manager',
              company: 'Ramp',
              title: 'Account Manager',
              template: rampAccountManager
            },
            {
              id: 'rippling-account-manager',
              company: 'Rippling',
              title: 'Account Manager',
              template: ripplingAccountManager
            }
          ]
        },
        { 
          id: 'staffing-coordinator', 
          label: 'Staffing Coordinator',
          templates: [
            {
              id: 'ramp-staffing-coordinator',
              company: 'Ramp',
              title: 'Staffing Coordinator',
              template: rampStaffingCoordinator
            },
            {
              id: 'rippling-staffing-coordinator',
              company: 'Rippling',
              title: 'Staffing Coordinator',
              template: ripplingStaffingCoordinator
            }
          ]
        },
        { 
          id: 'talent-acquisition', 
          label: 'Talent Acquisition Specialist',
          templates: [
            {
              id: 'coinbase-talent-acquisition',
              company: 'Coinbase',
              title: 'Talent Acquisition Specialist',
              template: coinbaseTalentAcquisition
            },
            {
              id: 'instacart-talent-acquisition',
              company: 'Instacart',
              title: 'Talent Acquisition Specialist',
              template: instacartTalentAcquisition
            }
          ]
        },
        { 
          id: 'hr-manager', 
          label: 'HR Manager',
          templates: [
            {
              id: 'coinbase-hr-manager',
              company: 'Coinbase',
              title: 'HR Manager',
              template: coinbaseHrManager
            },
            {
              id: 'instacart-hr-manager',
              company: 'Instacart',
              title: 'HR Manager',
              template: instacartHrManager
            }
          ]
        },
        { 
          id: 'other', 
          label: 'Other',
          templates: [
            {
              id: 'hr-manager',
              label: 'HR Manager',
              template: 'HR Manager'
            },
            {
              id: 'recruiter',
              label: 'Recruiter',
              template: 'Recruiter'
            }
          ]
        }
      ]
    },
    {
      id: 'healthcare',
      label: 'Healthcare',
      icon: HealthcareIcon,
      roles: [
        { 
          id: 'registered-nurse', 
          label: 'Registered Nurse',
          templates: [
            {
              id: 'new-york-presbyterian-hospital-registered-nurse',
              company: 'New York Presbyterian Hospital',
              title: 'Registered Nurse',
              template: newYorkPresbyterianHospitalRegisteredNurse
            },
            {
              id: 'brigham-and-womens-hospital-registered-nurse',
              company: 'Brigham and Women\'s Hospital',
              title: 'Registered Nurse',
              template: brighamAndWomensHospitalRegisteredNurse
            }
          ]
        },
        { 
          id: 'medical-assistant', 
          label: 'Medical Assistant',
          templates: [
            {
              id: 'new-york-presbyterian-hospital-medical-assistant',
              company: 'New York Presbyterian Hospital',
              title: 'Medical Assistant',
              template: newYorkPresbyterianHospitalMedicalAssistant
            },
            {
              id: 'brigham-and-womens-hospital-medical-assistant',
              company: 'Brigham and Women\'s Hospital',
              title: 'Medical Assistant',
              template: brighamAndWomensHospitalMedicalAssistant
            }
          ]
        },
        { 
          id: 'physician', 
          label: 'Physician',
          templates: [
            {
              id: 'new-york-presbyterian-hospital-physician',
              company: 'New York Presbyterian Hospital',
              title: 'Physician',
              template: newYorkPresbyterianHospitalPhysician
            },
            {
              id: 'brigham-and-womens-hospital-physician',
              company: 'Brigham and Women\'s Hospital',
              title: 'Physician',
              template: brighamAndWomensHospitalPhysician
            }
          ]
        },
        { 
          id: 'healthcare-admin', 
          label: 'Healthcare Administrator',
          templates: [
            {
              id: 'new-york-presbyterian-hospital-healthcare-admin',
              company: 'New York Presbyterian Hospital',
              title: 'Healthcare Administrator',
              template: newYorkPresbyterianHospitalHealthcareAdmin
            },
            {
              id: 'brigham-and-womens-hospital-healthcare-admin',
              company: 'Brigham and Women\'s Hospital',
              title: 'Healthcare Administrator',
              template: brighamAndWomensHospitalHealthcareAdmin
            }
          ]
        },
        { 
          id: 'physical-therapist', 
          label: 'Physical Therapist',
          templates: [
            {
              id: 'select-medical-center-physical-therapist',
              company: 'Select Medical Center',
              title: 'Physical Therapist',
              template: selectMedicalCenterPhysicalTherapist
            },
            {
              id: 'new-york-presbyterian-hospital-physical-therapist',
              company: 'New York Presbyterian Hospital',
              title: 'Physical Therapist',
              template: newYorkPresbyterianHospitalPhysicalTherapist
            }
          ]
        },
        { 
          id: 'other', 
          label: 'Other',
          templates: [
            {
              id: 'registered-nurse',
              label: 'Registered Nurse',
              template: registeredNurse
            },
            {
              id: 'medical-assistant',
              label: 'Medical Assistant',
              template: medicalAssistant
            },
            {
              id: 'physician',
              label: 'Physician',
              template: physician
            },
            {
              id: 'healthcare-admin',
              label: 'Healthcare Administrator',
              template: healthcareAdmin
            },
            {
              id: 'physical-therapist',
              label: 'Physical Therapist',
              template: physicalTherapist
            }
          ]
        }
      ]
    },
    {
      id: 'customer-care',
      label: 'Customer Care',
      icon: CustomerCareIcon,
      roles: [
        { 
          id: 'customer-service-rep', 
          label: 'Customer Service Representative',
          templates: [
            {
              id: 'coinbase-customer-service-rep',
              company: 'Coinbase',
              title: 'Customer Service Representative',
              template: coinbaseCustomerServiceRep
            },
            {
              id: 'instacart-customer-service-rep',
              company: 'Instacart',
              title: 'Customer Service Representative',
              template: instacartCustomerServiceRep
            }
          ]
        },
        { 
          id: 'call-center-agent', 
          label: 'Call Center Agent',
          templates: [
            {
              id: 'coinbase-call-center-agent',
              company: 'Coinbase',
              title: 'Call Center Agent',
              template: coinbaseCallCenterAgent
            },
            {
              id: 'instacart-call-center-agent',
              company: 'Instacart',
              title: 'Call Center Agent',
              template: instacartCallCenterAgent
            }
          ]
        },
        { 
          id: 'support-specialist', 
          label: 'Support Specialist',
          templates: [
            {
              id: 'coinbase-support-specialist',
              company: 'Coinbase',
              title: 'Support Specialist',
              template: coinbaseSupportSpecialist
            },
            {
              id: 'instacart-support-specialist',
              company: 'Instacart',
              title: 'Support Specialist',
              template: instacartSupportSpecialist
            }
          ]
        },
        { 
          id: 'team-lead', 
          label: 'Team Lead',
          templates: [
            {
              id: 'coinbase-team-lead',
              company: 'Coinbase',
              title: 'Team Lead',
              template: coinbaseTeamLead
            },
            {
              id: 'instacart-team-lead',
              company: 'Instacart',
              title: 'Team Lead',
              template: instacartTeamLead
            }
          ]
        },
        { 
          id: 'quality-analyst', 
          label: 'Quality Analyst',
          templates: [
            {
              id: 'coinbase-quality-analyst',
              company: 'Coinbase',
              title: 'Quality Analyst',
              template: coinbaseQualityAnalyst
            },
            {
              id: 'instacart-quality-analyst',
              company: 'Instacart',
              title: 'Quality Analyst',
              template: instacartQualityAnalyst
            }
          ]
        },
        { 
          id: 'other', 
          label: 'Other',
          templates: [
            {
              id: 'customer-service-rep',
              title: 'Customer Service Representative',
              template: customerServiceRep
            },
            {
              id: 'call-center-agent',
              title: 'Call Center Agent',
              template: callCenterAgent
            },
            {
              id: 'support-specialist',
              title: 'Support Specialist',
              template: supportSpecialist
            },
            {
              id: 'team-lead',
              title: 'Team Lead',
              template: teamLead
            },
            {
              id: 'quality-analyst',
              title: 'Quality Analyst',
              template: qualityAnalyst
            }
          ]
        }
      ]
    },
    {
      id: 'other',
      label: 'Other',
      roles: [
        { 
          id: 'administrative-assistant', 
          label: 'Administrative Assistant',
          templates: [
            {
              id: 'administrative-assistant',
              title: 'Administrative Assistant',
              template: administrativeAssistant
            },
            {
              id: 'project-manager',
              title: 'Project Manager',
              template: projectManager
            },
            {
              id: 'accountant',
              title: 'Accountant',
              template: accountant
            },
            {
              id: 'sales-manager',
              title: 'Sales Manager',
              template: salesManager
            },
            {
              id: 'operations-manager',
              title: 'Operations Manager',
              template: operationsManager
            }
          ]
        }
      ]
    }
  ];

  export default industries;