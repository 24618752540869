import React from 'react';
import { Box } from '@mui/material';
import TrendPill from './TrendPill';

const TrendBox = ({ title, number, percentage, unit, period }) => {
    return (
        <Box
            style={{
                display: 'flex',
                flex: 1,
                flexDirection: 'column',
                alignItems: 'start',
                backgroundColor: 'white',
                borderRadius: '16px',
                padding: '24px',
                height: '100px',
                gap: '8px',
            }}
        >
            <Box
                style={{
                    fontSize: '14px',
                    color: 'var(--color-gray)',
                    fontWeight: '500',
                }}
            >
                {title}
            </Box>
            <Box style={{ 
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                gap: '8px',
            }}>
                <p
                    style={{
                        fontSize: '24px',
                        fontWeight: 700,
                        color: 'black',
                        padding: '0px',
                        margin: '0px',
                    }}
                >{number.toLocaleString()}{unit ? (unit === '%' ? '%' : ` ${unit}`) : ''}
                </p>
                <TrendPill percentage={percentage} period={period} />
            </Box>
        </Box>
    );
};

export default TrendBox;