// startups
const instacartSoftwareEngineer = `
We're transforming the grocery industry

At Instacart, we invite the world to share love through food because we believe everyone should have access to the food they love and more time to enjoy it together. Where others see a simple need for grocery delivery, we see exciting complexity and endless opportunity to serve the varied needs of our community. We work to deliver an essential service that customers rely on to get their groceries and household goods, while also offering safe and flexible earnings opportunities to Instacart Personal Shoppers.

Instacart has become a lifeline for millions of people, and we're building the team to help push our shopping cart forward. If you're ready to do the best work of your life, come join our table.

Instacart is a Flex First team

There's no one-size fits all approach to how we do our best work. Our employees have the flexibility to choose where they do their best work—whether it's from home, an office, or your favorite coffee shop—while staying connected and building community through regular in-person events. Learn more about our flexible approach to where we work.

Overview

 

About Instacart

Instacart is on a mission to give everyone access to the food they love and more time to enjoy it together. Today, Instacart's technology enables millions of users in North America, hundreds of thousands of shoppers, and more than 1,400 retailers across more than 80,000 locations. Our intuitive, simple solutions for retailers, customers, brands and shoppers are transforming how the world shops, eats and lives.

About Instacart Engineering

Groceries delivered to your door in as little as 30 minutes!  Simple, right?  Well, not quite.  From re-routing deliveries during snowstorms, to connecting customers with coupons and deals for their favorite brands, to updating billions of data points flowing through hundreds of systems, to optimizing the complex value balance between 4 key stakeholders (consumers, retailers, brands, and shoppers), our engineering efforts build cutting edge systems and technologies that collective provide a state-of-the-art operating system for the grocery industry.  We tackle big problems in distributed systems, data management, AI / ML including leveraging the best of Generative AI, quantitative optimization, UX and product building, and more - see our Tech Blog for a sample of the kind of exciting engineering problems we tackle on a daily basis.  Building with Instacart Engineering means turning all these deep technical problems into real solutions and features that your friends and family will see and use.

About the Job

 

Design, build, validate, and ship high quality product features with a strong sense of urgency, efficiency, and ownership
Work closely with product managers, designers, data scientists, and peer engineering teams to define project requirements and execution plans
Ensure that system designs including APIs, deployment architecture, and dependency selection result in highly scalable, robust solutions that minimize support costs and improve an outstanding customer experience
Contribute broadly to Instacart's success by exercising your freedom to suggest and drive organization-wide initiatives
Work within a team that manages petabytes of data used by hundreds of people across the company for the core product, machine learning, reporting, analysis, and much more
Design, develop, and maintain highly efficient data pipelines for ingesting, transforming, and delivering data from various sources
Design and build data models and work together with application engineers to ensure the collection of high quality data
About You

 

MINIMUM QUALIFICATIONS

Recent degree in a technology discipline such as Computer Science, Computer Engineering, or Electrical Engineering
2-4 years of work experience post-graduation in a technology field
Experience working with distributed systems
A strong sense of ownership and intellectual curiosity to understand problems in depth
Previous experience building features end to end or publishing your own app
Prior experience with Python, Ruby, React, PostgreSQL, Go
Excellent collaborator who is able to work with teammates to objectively problem-solve
Growth mindset - seeks and is receptive to actionable feedback and growth opportunities
Takes accountability seriously; able to deliver responsibilities on time and on budget
Self-motivated and thrives in a fast-paced environment
Able to work in small teams with significant ownership and autonomy
PREFERRED QUALIFICATIONS

Previous experience in a fast-paced startup environment
Data Engineering experience: designing and implementing data pipelines, optimizing data schemas, ensuring data quality & integrity
Experience with NoSQL databases
Experience with data streaming & big data technologies: Apache Spark, Airflow, Hadoop
`

const coinbaseSoftwareEngineer = `
Senior Software Engineer, Fullstack (Institutional - Markets, Onchain Products)

Ready to be pushed beyond what you think you're capable of?

At Coinbase, our mission is to increase economic freedom in the world. It's a massive, ambitious opportunity that demands the best of us, every day, as we build the emerging onchain platform — and with it, the future global financial system.

To achieve our mission, we're seeking a very specific candidate. We want someone who is passionate about our mission and who believes in the power of crypto and blockchain technology to update the financial system. We want someone who is eager to leave their mark on the world, who relishes the pressure and privilege of working with high caliber colleagues, and who actively seeks feedback to keep leveling up. We want someone who will run towards, not away from, solving the company's hardest problems.

Our work culture is intense and isn't for everyone. But if you want to build the future alongside others who excel in their disciplines and expect the same from you, there's no better place to be.

The Institutional Product Group engineers create and maintain a suite of products catering to institutional customers of all sizes from Hedge Funds (Coinbase Prime) to experienced individual traders (Coinbase Advanced Trade) and everything in between. Additional Products include Prime - Custody Only, Prime Web3 Wallet, Commerce and Exchange. Whether the customer needs are trading, staking, governance, custody, web3 operations, or API integrations, Coinbase Institutional has them covered.

The Onchain Markets team is focused on bringing institutions onchain while solving for the unique challenges that they face—from compliance and custody to settlement and liquidity. We're seeking a Senior Full-Stack Engineer to join our small entrepreneurial team focused on building innovative 0-to-1 Onchain Markets products. Operating as a startup within Coinbase, we're combining the agility of a small team with the resources and reach of a global platform. In this role, you'll contribute to building cutting-edge solutions that span trading, financing, and more, helping to redefine market infrastructure for the institutional world in this new global onchain economy.

What you'll be doing (ie. job duties):

Drive 0-to-1 product development for new Onchain Markets offerings
Develop and maintain highly interactive, responsive, and user friendly frontend applications for onchain products
Design, develop, and optimize smart contracts using Solidity to enable new onchain products and features
Build and integrate backend systems to power the onchain data needs of Onchain Markets products
Rapidly prototype new onchain experiences to gain early feedback
Troubleshoot and debug complex fullstack issues, ensuring performance, stability, reliability, and security.
What we look for in you (ie. job requirements):

5+ years of full-stack engineering experience shipping production features, including onchain products
Expert in modern JavaScript/TypeScript, React, and Next.js
Strong Solidity skills with experience deploying production smart contracts
Track record building secure, high-performance web applications
Deep understanding of blockchain fundamentals and DeFi protocols
Experience with backend development and API design
Proven ability to balance technical excellence with pragmatic delivery
Strong collaboration skills with product, design, and engineering teams
Nice to haves:

Experience building or scaling DeFi protocols
Contributions to open-source blockchain projects
Background in trading systems or financial exchanges
Experience with Golang and cloud infrastructure (AWS)
Understanding of institutional trading workflows
Track record in high-growth crypto or fintech environments
`

const stripeSoftwareEngineer = `
Software Engineer - Infrastructure
Who we are
About Stripe 
Stripe is a financial infrastructure platform for businesses. Millions of companies—from the world's largest enterprises to the most ambitious startups—use Stripe to accept payments, grow their revenue, and accelerate new business opportunities. Our mission is to increase the GDP of the internet, and we have a staggering amount of work ahead. That means you have an unprecedented opportunity to put the global economy within everyone's reach while doing the most important work of your career.

About the organization 
Stripe Infrastructure is responsible for the reliability, scale, performance, and cost of Stripe's systems and the productivity and sentiment of Stripe's people. 

You may work on a wide variety of critical business areas including: 

Core Infrastructure - We're the home for Stripe's critical tier0 infrastructure systems (Compute, Networking, DocumentDB, Distributed Caching and High assurance engineering). We build the foundational platform for Stripe products and services to allow them to operate at scale. We drive reliability, availability, efficiency and scalability of these systems.
Developer Infrastructure - We're responsible for the productivity of all developers at Stripe. Ensure Stripe's engineers have a reliable, fast, and easy-to-use inner dev loop to maximize productivity while building everything from low-latency microservices to large-scale data pipelines and machine learning models
Reliability Insights and Excellence - We build tools and frameworks to introspect and improve reliability across Stripe.
Admin Platform - We empower Stripes to quickly build secure and delightful internal products in service of their users.
Support Experience - We build and improve our 24x7 email, phone, and chat support channels, our Premium Support and Enterprise Services products, our Support Site, and more. This includes how users get help within our products, how they get in touch with us when they have questions, and how our teams use internal tools to answer those questions.
Service Platform - We enable engineering teams at Stripe to easily write and ship useful services that correctly, reliably, and efficiently leverage platform capabilities. 
What you'll do
You will work on projects that span technologies, systems, and processes where you will design, build, test, and ship great code every day.

Responsibilities

Build a great customer experience for internal and external Stripe teams using our infrastructure
Participate in roadmap planning and prioritization
Deliver value through a strong collaborative approach with multiple customers and stakeholders across Stripe
Debug production issues across services and several levels of the stack
Who you are
We're looking for someone who meets the minimum requirements to be considered for the role. If you meet these requirements, you are encouraged to apply. The preferred qualifications are a bonus, not a requirement.

Minimum Requirements 
Strong software engineering skills and a passion for one of the areas above.
Ability to write high quality code (in programming languages like Go, Java, C/C++ etc)
2-20+ years of professional hands-on software development experience, able to write well-factored algorithms and have experience with commonly used data structure and algorithms
Hands-on experience contributing to or building large scale distributed systems
Strong collaboration skills, can work across workstreams within your team and contribute to your peers' success
Customer obsession, ability to articulate and represent customer experience in various forums to drive the right outcome
Have the ability to thrive on a high level of autonomy, responsibility, and think of yourself as entrepreneurial
Preferred Qualifications
Experience optimizing the end to end performance of distributed systems
Focuses on the needs of our users, both internal and external
Holds yourself and others to a high bar when working with production
` 

const openaiFrontendEngineer = `
Front End Software Engineer, Leverage Engineering
Leverage Engineering - San Francisco and New York City

About the Team
The Leverage team is scaling OpenAI with OpenAI. We apply our latest models to real-world problems in order to assist with or automate work across the company—then share what we learn back to the broader product and research teams. We've built an ecosystem of automation products that's applied everywhere from customer operations to workplace to engineering.

We love building products for folks sitting right next to us, and we take the time to add the little big touches that delight. Our goal is to prototype fast, then build for reliable long-term impact. We're constantly looking for the similarities and patterns in different types of work, and focus on building simple, generic patterns that we can apply across many domains.

About the Role

We're looking for an engineer who's passionate about blending product design with new tech and new paradigms. You'll be at the forefront of creating interfaces that aren't just functional but delightful, pushing the boundaries of what's possible with our models. We value engineers who are proactive, product-minded, and who can see the big picture while paying attention to the little details that define great products.

In this role, you will:

Rapidly prototype user experiences that push the envelope of what's possible with AI.

Iterate quickly, turning ideas into tangible products.

Deliver high-quality, beautiful, joy-inducing interfaces that resonate with users.

Collaborate closely with cross-functional teams, understanding their workflows and uncovering opportunities for transformative improvements.

You might thrive in this role if you:

4+ years of professional engineering experience (excluding internships) in relevant roles at tech and product-driven companies

Are highly skilled in TypeScript, React, and other web technologies, and aren't afraid to explore corners of the web spec to get stuff done.

Have a strong interest in design — from typographic layout and interaction design to user story-led creation — and are comfortable with tools like Figma.

Have a startup-y background or love bringing products from concept to reality: the 0 → 1 phase.

Interested in applying AI to the real-world (even if you don't have experience)

Thrive in dynamic environments where ambiguity is the norm and priorities can shift rapidly.

Take ownership of your projects, driving them independently from inception to launch, with a focus on making a real impact.
`

const instacartProductManager = `
We're transforming the grocery industry

At Instacart, we invite the world to share love through food because we believe everyone should have access to the food they love and more time to enjoy it together. Where others see a simple need for grocery delivery, we see exciting complexity and endless opportunity to serve the varied needs of our community. We work to deliver an essential service that customers rely on to get their groceries and household goods, while also offering safe and flexible earnings opportunities to Instacart Personal Shoppers.

Instacart has become a lifeline for millions of people, and we're building the team to help push our shopping cart forward. If you're ready to do the best work of your life, come join our table.

Instacart is a Flex First team

There's no one-size fits all approach to how we do our best work. Our employees have the flexibility to choose where they do their best work-whether it's from home, an office, or your favorite coffee shop-while staying connected and building community through regular in-person events. Learn more about our flexible approach to where we work.

Overview


About the role - In this role you take a leading role shaping Instacart's enterprise eCommerce solutions for retailers - aka how we enable retailers to have Owned & Operated experiences on par with what we offer customers directly through the Instacart App. This area is a company-wide priority, and you will be expected to wear a "GM" hat coordinating efforts across all tech teams, and representing those efforts externally.


About the Team - This role will partner closely with a dedicated team of engineers, designers and data scientists. Beyond the direct team, this role will help drive priorities across all teams at Instacart who develop platform capabilities relevant to our Enterprise solution.


About the Job 

You will win the product strategy for Instacart's enterprise eCommerce solutions.
You will work closely with product teams and retailer facing teams from across the company to define and execute on a roadmap that spans the entire company.
You will be the one of the primary faces of Instacart's enterprise eCommerce solutions with retailers.
You will define processes and incentives that ensure teams across IC are developing Enterprise grade solutions. 
You will directly own key parts of the enterprise eCommerce solution, and work with an XFN team to drive new enterprise sales, NPS and growth for our partners.

About You

Minimum Qualifications

Proven track record of delivering Enterprise products.
Strong product strategy skills - you have crafted product strategies that have spanned multiple teams and which delivered company level impact spanning multiple years.
Experience working directly with external partners as part of product delivery.

Preferred Qualifications

Experience leading product teams or mentoring more junior PMs.
B2C or B2B2C experience, preferably in the eCommerce space.
Experience working in early stage / hyper growth focused companies.
Experience leading processes spanning product teams beyond your own (e.g., roadmapping).
`

const coinbaseProductManager = `
Product Manager I - Consumer

Ready to be pushed beyond what you think you're capable of?

At Coinbase, our mission is to increase economic freedom in the world. It's a massive, ambitious opportunity that demands the best of us, every day, as we build the emerging onchain platform — and with it, the future global financial system.

To achieve our mission, we're seeking a very specific candidate. We want someone who is passionate about our mission and who believes in the power of crypto and blockchain technology to update the financial system. We want someone who is eager to leave their mark on the world, who relishes the pressure and privilege of working with high caliber colleagues, and who actively seeks feedback to keep leveling up. We want someone who will run towards, not away from, solving the company's hardest problems.

Our work culture is intense and isn’t for everyone. But if you want to build the future alongside others who excel in their disciplines and expect the same from you, there’s no better place to be.

Coinbase has built the world's leading compliant cryptocurrency platform serving over 30 million accounts in more than 100 countries. With multiple successful products, and our vocal advocacy for blockchain technology, we have played a major part in mainstream awareness and adoption of cryptocurrency. We are proud to offer an entire suite of products that are helping build the cryptoeconomy, and increase economic freedom around the world.

There are a few things we look for across all hires we make at Coinbase, regardless of role or team. First, we assess whether a candidate demonstrates our values: Clear Communication, Positive Energy, Efficient Execution, and Continuous Learning. Second, we look for signals that a candidate will thrive in a culture like ours, where we default to trust, embrace feedback, disrupt ourselves, and expect sustained high performance because we play as a championship team. Finally, we seek people with the desire and capacity to build and share expertise in the frontier technologies of crypto and blockchain, in whatever way is most relevant to their role.

Read more about our values and culture here.

What You'll Be Doing:

Owns a focused product area and can execute independently from conception to launch
Collaborate cross-functionally with engineering, design and other stakeholders to drive product development
Understands the user journey across internal and external customers. Map user pain points and feedback into long-term strategic roadmaps
Effectively balance and prioritize across different user needs
Align teams on a shared vision and steer execution on said vision
What We Look For In You:

3+ years of product management experience
2 years of experience working cross-functionally with engineering, design, sales, finance, and other stakeholders
1 year of experience taking products from conception to launch
Bachelor's degree or equivalent practical experience
Experience with SQL, experiment design, and being data driven
Aptitude for learning and ability to ramp quickly on new and potentially complicated domains
Demonstrated passion for cryptocurrency, financial markets, and the Coinbase mission
Nice to Have:

Crypto-forward experience, including familiarity with onchain activity such as interacting with Ethereum addresses, using ENS, and engaging with dApps or blockchain-based services."
`   

const stripeProductManager = `

`

const openaiProductManager = `

`

const instacartSalesRepresentative = `

`

const coinbaseSalesRepresentative = `

`

const instacartMarketingManager = `

`

const coinbaseMarketingManager = `

`

const instacartCustomerSuccess = `

`

const coinbaseCustomerSuccess = `

`

const walmartStoreManager = `

`

const targetStoreManager = `

`

const walmartSalesAssociate = `

`

const targetSalesAssociate = `

`

const walmartCashier = `

`

const targetCashier = `

`

const walmartVisualMerchandiser = `

`

const targetVisualMerchandiser = `

`

const walmartInventorySpecialist = `

`

const targetInventorySpecialist = `

`

const coinbaseRecruiter = `

`

const instacartRecruiter = `

`

const rampAccountManager = `

`

const ripplingAccountManager = `

`

const rampStaffingCoordinator = `

`

const ripplingStaffingCoordinator = `

`

const coinbaseTalentAcquisition = `

`

const instacartTalentAcquisition = `

`

const coinbaseHrManager = `

`

const instacartHrManager = `

`

const newYorkPresbyterianHospitalRegisteredNurse = `

`

const brighamAndWomensHospitalRegisteredNurse = `

`

const newYorkPresbyterianHospitalMedicalAssistant = `

`

const brighamAndWomensHospitalMedicalAssistant = `

`

const newYorkPresbyterianHospitalPhysician = `

`

const brighamAndWomensHospitalPhysician = `

`

const newYorkPresbyterianHospitalHealthcareAdmin = `

`

const brighamAndWomensHospitalHealthcareAdmin = `

`

const selectMedicalCenterPhysicalTherapist = `

`

const newYorkPresbyterianHospitalPhysicalTherapist = `

`

const registeredNurse = `

`

const medicalAssistant = `

`

const physician = `

`

const healthcareAdmin = `

`

const physicalTherapist = `

`

const coinbaseCustomerServiceRep = `

`

const instacartCustomerServiceRep = `

`

const coinbaseCallCenterAgent = `

`

const instacartCallCenterAgent = `

`

const coinbaseSupportSpecialist = `

`

const instacartSupportSpecialist = `

`

const coinbaseTeamLead = `

`

const instacartTeamLead = `

`

const coinbaseQualityAnalyst = `

`

const instacartQualityAnalyst = `

`

const customerServiceRep = `

`

const callCenterAgent = `

`

const supportSpecialist = `

`

const teamLead = `

`

const qualityAnalyst = `

`

const administrativeAssistant = `

`

const projectManager = `

`

const accountant = `

`

const salesManager = `

`

const operationsManager = `

`




export { instacartSoftwareEngineer, coinbaseSoftwareEngineer, stripeSoftwareEngineer, openaiFrontendEngineer, instacartProductManager, coinbaseProductManager, stripeProductManager, openaiProductManager, instacartSalesRepresentative, coinbaseSalesRepresentative, instacartMarketingManager, coinbaseMarketingManager, instacartCustomerSuccess, coinbaseCustomerSuccess, walmartStoreManager, targetStoreManager, walmartSalesAssociate, targetSalesAssociate, walmartCashier, targetCashier, walmartVisualMerchandiser, targetVisualMerchandiser, walmartInventorySpecialist, targetInventorySpecialist, coinbaseRecruiter, instacartRecruiter, rampAccountManager, ripplingAccountManager, rampStaffingCoordinator, ripplingStaffingCoordinator, coinbaseTalentAcquisition, instacartTalentAcquisition, coinbaseHrManager, instacartHrManager, newYorkPresbyterianHospitalRegisteredNurse, brighamAndWomensHospitalRegisteredNurse, newYorkPresbyterianHospitalMedicalAssistant, brighamAndWomensHospitalMedicalAssistant, newYorkPresbyterianHospitalPhysician, brighamAndWomensHospitalPhysician, newYorkPresbyterianHospitalHealthcareAdmin, brighamAndWomensHospitalHealthcareAdmin, selectMedicalCenterPhysicalTherapist, newYorkPresbyterianHospitalPhysicalTherapist, registeredNurse, medicalAssistant, physician, healthcareAdmin, physicalTherapist, coinbaseCustomerServiceRep, instacartCustomerServiceRep, coinbaseCallCenterAgent, instacartCallCenterAgent, coinbaseSupportSpecialist, instacartSupportSpecialist, coinbaseTeamLead, instacartTeamLead, coinbaseQualityAnalyst, instacartQualityAnalyst, customerServiceRep, callCenterAgent, supportSpecialist, teamLead, qualityAnalyst, administrativeAssistant, projectManager, accountant, salesManager, operationsManager };