import React from 'react'
import { styled } from '@mui/material/styles';
import Select from '@mui/material/Select';
import TooltipCustom from './TooltipCustom';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

const FormSelect = styled(({ className, label, noLabel, noBorder, required, tooltip, tooltipTitle, ...props }) => (
  <>
    {!noLabel && <h5 className="input-label d-flex" style={{ fontSize: '14px', fontWeight: 500, color: props.labelColor ? props.labelColor : 'var(--color-gray)' }}>
    {label}{required ? <span className="color-required"> *</span> : ''}
      {tooltip ? <>
        <TooltipCustom 
          title={tooltipTitle}
          style={{ color: "var(--color-gray)", marginLeft: '8px', marginTop: 'auto', marginBottom: 'auto', fontSize: '14px' }}>
        </TooltipCustom>
      </> : ''}
    </h5>}
    
    <Select
      {...props}
      variant="outlined"
      IconComponent={KeyboardArrowDownIcon}
      style={{ 
        padding: '8px 12px', 
        marginBottom: '16px', 
        border: noBorder ? '1px solid transparent' : '1px solid lightgray',
        borderRadius: '6px',
        fontSize: '14px',
        height: props.height ? `${props.height}` : '40px'
      }}
      sx={{
        ['& .MuiSelect-select'] : {
          padding: 0,
        },
        '&.Mui-focused': {
          '& .MuiOutlinedInput-notchedOutline': {
            border: '1px solid lightgray',
          }
        },
        '& .MuiSelect-icon': {
          fontSize: '1.2rem',
        }
      }}
      />
  </>
))({});

export default FormSelect