import { useState, useEffect } from 'react';
import { Drawer, Typography, Box, Button, Grid } from '@mui/material';
import FormField from '../common/FormField';
import RadioBtn from '../common/RadioBtn';

const QuestionDrawer = ({ 
    open, 
    onClose, 
    question, 
    onSave,
    isEdit = false,
    errMsgs = []
  }) => {
    const [currentQuestion, setCurrentQuestion] = useState(question || {
      question: '',
      time_limit: 60 // default 1 minute
    })
    useEffect(() => {
      setCurrentQuestion(question);
    }, [question]);
  
    return (
      <Drawer
        anchor="right"
        open={open}
        onClose={() => {
          onClose();
        }}
        onKeyDown={(e) => {
          if ((e.metaKey || e.ctrlKey) && e.key === 'Enter') {
            const saved = onSave(currentQuestion);
            if (saved) {
              onClose();
            }
          }
        }}
        sx={{
          '& .MuiDrawer-paper': {
            width: '450px',
            padding: '24px',
            backgroundColor: '#FFFFFF',
            margin: '24px',
            height: 'calc(100% - 48px)',
            borderRadius: '16px',
            boxShadow: '0px 4px 6px -2px rgba(0, 0, 0, 0.05), 0px 10px 15px -3px rgba(0, 0, 0, 0.1)'
          },
          '& .MuiBackdrop-root': {
            backgroundColor: 'rgba(0, 0, 0, 0.3)'
          }
        }}
      >
        <div>
          <Typography 
            variant="h6" 
            sx={{ 
              fontWeight: 600,
              fontSize: '18px', 
              color: '#111827',
              mb: 0
            }}
          >
        {isEdit ? 'Modify question' : 'Add question'}
          </Typography>
          <Typography 
            variant="subtitle2" 
            sx={{ 
              color: '#6B7280',
              fontSize: '14px',
              mb: 2
            }}
          >
        {isEdit ? 'Modify the question that will be asked during the interview' : 'Add a question that will be asked during the interview'}
          </Typography>
  
          {errMsgs.length > 0 && (
            <Box
              sx={{
                marginBottom: '16px',
                padding: '12px',
                backgroundColor: '#FEF2F2',
                borderRadius: '8px',
                border: '1px solid #FEE2E2'
              }}
            >
              {errMsgs.map((msg, idx) => (
                <Typography
                  key={idx}
                  variant="caption"
                  sx={{
                    color: '#DC2626',
                    display: 'block',
                    fontSize: '14px',
                    '&:not(:last-child)': {
                      marginBottom: '4px'
                    }
                  }}
                >
                  {msg}
                </Typography>
              ))}
            </Box>
          )}        
  
          <div className="d-flex flex-col" style={{ marginBottom: '8px' }}>
            <h5 className="input-label" style={{ fontSize: '14px', fontWeight: 500, color: 'var(--color-gray)' }}>Question</h5>
            <FormField
            multiline
            rows={4}
              placeholder="Enter your question"
              onKeyDown={(e) => {
                if ((e.metaKey || e.ctrlKey) && e.key === 'Enter') {
                  const saved = onSave(currentQuestion);
                  if (saved) {
                    onClose();
                  }
                }
              }}
            value={currentQuestion.question}
            onChange={(e) => setCurrentQuestion({
              ...currentQuestion,
                question: e.target.value
            })}
            />
          </div>
  
          <div className="d-flex flex-col" style={{ marginBottom: '32px' }}>
            <h5 className="input-label" style={{ fontSize: '14px', fontWeight: 500, color: 'var(--color-gray)' }}>Time</h5>
            <Grid container spacing={2}>
              <Grid item xs={12} style={{ paddingTop: '24px' }}>
                <RadioBtn
              value={currentQuestion.time_limit}
                  setValue={(value) => setCurrentQuestion({
                ...currentQuestion,
                    time_limit: value
                  })}
                  expectedValue={15}
                  label="15 seconds"
                  width="100%"
                  description='Best for quick screening questions like "Are you legally authorized to work in the US?" No follow-up questions will be asked.'
                />
              </Grid>
              <Grid item xs={12} style={{ paddingTop: '12px' }}>
                <RadioBtn
                  value={currentQuestion.time_limit}
                  setValue={(value) => setCurrentQuestion({
                    ...currentQuestion,
                    time_limit: value
                  })}
                  expectedValue={60}
                  label="1 minute"
                  width="100%"
                  description='Good for questions requiring a brief explanation, like "What interests you about this role?" or "Describe your experience with Python."'
                />
              </Grid>
              <Grid item xs={12} style={{ paddingTop: '12px' }}>
                <RadioBtn
                  value={currentQuestion.time_limit}
                  setValue={(value) => setCurrentQuestion({
                    ...currentQuestion,
                    time_limit: value
                  })}
                  expectedValue={120}
                  label="2 minutes"
                  width="100%"
                  description='Ideal for questions needing detailed examples, like "Tell me about a time you had to deal with a difficult customer."'
                />
              </Grid>
              <Grid item xs={12} style={{ paddingTop: '12px' }}>
                <RadioBtn
                  value={currentQuestion.time_limit}
                  setValue={(value) => setCurrentQuestion({
                    ...currentQuestion,
                    time_limit: value
                  })}
                  expectedValue={300}
                  label="5 minutes"
                  width="100%"
                  description='Use this for complex questions requiring a deep dive to fully explore, like "Describe how you would design a system to handle high-volume customer support tickets across multiple channels."'
                />
              </Grid>
            </Grid>
          </div>
  
          <Box sx={{ display: 'flex', justifyContent: 'flex-end', gap: 2 }}>
            <Button
              variant="outlined"
          onClick={onClose}
              sx={{
                color: '#6B7280',
                borderColor: '#E5E7EB',
                textTransform: 'none',
                borderRadius: '6px',
                '&:hover': {
                  borderColor: '#D1D5DB',
                  backgroundColor: '#F9FAFB'
                }
              }}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              onClick={() => {
                const saved = onSave(currentQuestion);
                if (saved) {
            onClose();
                }
              }}
              sx={{ 
                bgcolor: 'black',
                color: 'white',
                textTransform: 'none',
                borderRadius: '6px',
                boxShadow: 'none',
                '&:hover': {
                  bgcolor: '#333333',
                  color: 'white',
                  boxShadow: 'none'
                }
              }}
            >
              {isEdit ? 'Save Changes (⌘ + Enter)' : 'Add Question (⌘ + Enter)'}
            </Button>
          </Box>
        </div>
      </Drawer>
    );
};

export default QuestionDrawer;