import { useState } from 'react';
import { Dialog, Typography, Box } from '@mui/material';
import api from "../../api/api";

const RemoveFolder = ({ onClose, isOpen, currentTeam, folder }) => {
  const [errMsgs, setErrMsgs] = useState([]);

  const handleSubmit = async () => {
    try {
      const res = await api.deleteFolder({ team_id: currentTeam._id.$oid, folder_id: folder._id });
      
      if (res.status === 200) {
        onClose(res.data);
      } else {
        setErrMsgs(["Failed to delete folder"]);
      }
    } catch (error) {
      console.error("Error deleting folder:", error);
      setErrMsgs(["Failed to delete folder"]);
    }
  };

  const handleClose = () => {
    setErrMsgs([]);
    onClose();
  };

  return (
    <Dialog 
      open={isOpen}
      onClose={handleClose}
      aria-labelledby="remove-folder-dialog-title"
      aria-describedby="remove-folder-dialog-description"
    >
      <div style={{padding: "24px", width: "400px"}}>
        <h2 style={{fontSize: "18px", fontWeight: "600", marginBottom: "12px", marginTop: "0px"}}>Remove folder</h2>
        
        <Typography
          sx={{
            fontSize: '14px',
            color: 'var(--color-gray)',
            marginBottom: '16px'
          }}
        >
          Deleting this folder will move all interviews out of it. The interviews will still be available under "All Interviews". This action cannot be undone.
        </Typography>

        {errMsgs && errMsgs.length > 0 && (
            <Box
              sx={{
                marginBottom: '16px',
                padding: '12px',
                backgroundColor: '#FEF2F2',
                borderRadius: '8px',
                border: '1px solid #FEE2E2'
              }}
            >
              {errMsgs.map((msg, idx) => (
                <Typography
                  key={idx}
                  variant="caption"
                  sx={{
                    color: '#DC2626',
                    display: 'block',
                    fontSize: '14px',
                    '&:not(:last-child)': {
                      marginBottom: '4px'
                    }
                  }}
                >
                  {msg}
                </Typography>
              ))}
            </Box>
        )} 

        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end', gap: '12px', marginTop: '8px' }}>
          <button
            onClick={handleClose}
            className="btn-main outlined gray"
            style={{ width: 'fit-content' }}
          >
            Cancel
          </button>
          <button
            onClick={handleSubmit}
            className="btn-main danger"
            style={{ width: 'fit-content' }}
          >
            Delete folder
          </button>
        </div>
      </div>
    </Dialog>
  );
};

export default RemoveFolder;
