import { Radio } from "@mui/material";

const RadioBtn = ({ value, setValue, expectedValue, label, width, description }) => {
    return (
      <div className="radio-btn" style={{
        borderRadius: 8,
        width: width || '245px',
        padding: '8px 12px',
        border: value === expectedValue ? '1px solid black' : '1px solid #CDCDCD',
        display: 'flex',
        alignItems: 'center',
        gap: '8px',
      }}
      onClick={() => {
        setValue(expectedValue)
      }}>
        <div style={{ display: 'flex', flexDirection: 'column', gap: '2px' }}>
          <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
            <Radio
              checked={value === expectedValue}
              onChange={() => {setValue(expectedValue)}}
              value={expectedValue}
              size="small"
              sx={{
                padding: 0,
                color: '#CDCDCD',
                '&.Mui-checked': {
                  color: 'black',
                },
              }}
            />
            <span style={{ display: 'inline-block', fontSize: '14px', fontWeight: 500 }}>
              {label}
            </span>
          </div>
          {description && (
            <div style={{ paddingLeft: '28px' }}>
              <span style={{ display: 'inline-block', fontSize: '13px', fontWeight: 400, color: '#666666' }}>
                {description}
              </span>
            </div>
          )}
        </div>
      </div>
    )
  }

  export default RadioBtn;