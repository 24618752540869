import { useState, useEffect } from 'react';
import { Drawer, Typography, Box, Button, Grid, Select, MenuItem } from '@mui/material';
import FormField from '../common/FormField';

const OutputDrawer = ({ 
  open, 
  onClose, 
  onKeyDown,
  onChangeKey,
  onChangeInstructions,
  onChangeType,
  output, 
  onSave,
  isEdit = false,
  errMsgs = []
}) => {
  return (
    <Drawer
      anchor="right"
      open={open}
      onClose={() => {
        onClose();
      }}
      onKeyDown={onKeyDown}
      sx={{
        '& .MuiDrawer-paper': {
          width: '450px',
          padding: '24px',
          backgroundColor: '#FFFFFF',
          margin: '24px',
          height: 'calc(100% - 48px)',
          borderRadius: '16px',
          boxShadow: '0px 4px 6px -2px rgba(0, 0, 0, 0.05), 0px 10px 15px -3px rgba(0, 0, 0, 0.1)'
        },
        '& .MuiBackdrop-root': {
          backgroundColor: 'rgba(0, 0, 0, 0.3)'
        }
      }}
    >             
      <div>
        <Typography 
          variant="h6" 
          sx={{ 
            fontWeight: 600,
            fontSize: '18px', 
            color: '#111827',
            mb: 1
          }}
        >
          {isEdit ? 'Edit structured output' : 'Add structured output'}
        </Typography>
        <Typography style={{ fontSize: '13px', color: 'gray', margin: 0}}>
          Extract structured information from the interview.
        </Typography>
        
        {errMsgs.length > 0 && (
          <Box
            sx={{
              padding: '12px',
              marginTop: '16px',
              backgroundColor: '#FEF2F2',
              borderRadius: '8px',
              border: '1px solid #FEE2E2'
            }}
          >
            {errMsgs.map((msg, idx) => (
              <Typography
                key={idx}
                variant="caption"
                sx={{
                  color: '#DC2626',
                  display: 'block',
                  fontSize: '14px',
                  '&:not(:last-child)': {
                    marginBottom: '4px'
                  }
                }}
              >
                {msg}
              </Typography>
            ))}
          </Box>
        )}                      
        
        <Grid container>
          <Grid item xs={12} style={{ marginTop: '24px' }}>
            <div className="d-flex flex-col" style={{ marginBottom: '16px' }}>
              <h5 className="input-label" style={{ fontSize: '14px', fontWeight: 500, color: 'var(--color-gray)' }}>Output Key</h5>
              <FormField
                fullWidth
                placeholder="e.g. years of experience"
                value={output && output.key ? output.key : ''}
                onChange={(e) => onChangeKey(e.target.value)}
                sx={{
                  borderRadius: '8px',
                  backgroundColor: '#F9FAFB',
                  '& .MuiOutlinedInput-notchedOutline': {
                    borderColor: '#E5E7EB'
                  },
                  '&:hover .MuiOutlinedInput-notchedOutline': {
                    borderColor: '#D1D5DB'
                  },
                  '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                    borderColor: '#2563EB'
                  }
                }}
              />
            </div>

            <div className="d-flex flex-col" style={{ marginBottom: '16px' }}>
              <h5 className="input-label" style={{ fontSize: '14px', fontWeight: 500, color: 'var(--color-gray)' }}>Extraction Instructions</h5>
              <FormField
                multiline
                rows={8}
                placeholder="Describe how to extract this information from the interview"
                value={output && output.extraction_instructions ? output.extraction_instructions : ''}
                onChange={(e) => onChangeInstructions(e.target.value)}
                sx={{
                  borderRadius: '8px',
                  backgroundColor: '#F9FAFB',
                  '& .MuiOutlinedInput-notchedOutline': {
                    borderColor: '#E5E7EB'
                  },
                  '&:hover .MuiOutlinedInput-notchedOutline': {
                    borderColor: '#D1D5DB'
                  },
                  '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                    borderColor: '#2563EB'
                  }
                }}
              />
            </div>

            <div className="d-flex flex-col">
              <h5 className="input-label" style={{ fontSize: '14px', fontWeight: 500, color: 'var(--color-gray)' }}>Data Type</h5>
              <Select
                value={output && output.data_type ? output.data_type : ''}
                onChange={(e) => onChangeType(e.target.value)}
                sx={{
                  borderRadius: '8px',
                  backgroundColor: '#F9FAFB',
                  '& .MuiOutlinedInput-notchedOutline': {
                    borderColor: '#E5E7EB'
                  },
                  '&:hover .MuiOutlinedInput-notchedOutline': {
                    borderColor: '#D1D5DB'
                  },
                  '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                    borderColor: '#2563EB'
                  }
                }}
              >
                <MenuItem value="text">Text</MenuItem>
                <MenuItem value="list">List</MenuItem>
                <MenuItem value="number">Number</MenuItem>
                <MenuItem value="boolean">True/False</MenuItem>
              </Select>
            </div>
          </Grid>
        </Grid>
        
        <div className="d-flex flex-row" style={{ width: '100%', marginTop: '24px', gap: '12px', justifyContent: 'flex-end' }}>
          <Button
            variant="outlined"
            onClick={onClose}
            sx={{
              color: '#6B7280',
              borderColor: '#E5E7EB',
              textTransform: 'none',
              borderRadius: '6px',
              '&:hover': {
                borderColor: '#6B7280',
                backgroundColor: '#F9FAFB'
              }
            }}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            onClick={() => onSave()}
            sx={{
              bgcolor: 'black',
              color: 'white',
              textTransform: 'none', 
              borderRadius: '6px',
              boxShadow: 'none',
              '&:hover': {
                bgcolor: '#333333',
                color: 'white',
                boxShadow: 'none'
              }
            }}
          >
            {isEdit ? 'Save Changes (⌘ + Enter)' : 'Add Output (⌘ + Enter)'}
          </Button>
        </div>
      </div>
    </Drawer>
  );
};

export default OutputDrawer;