import React from 'react';

const MessageVariable = React.memo(({ variable }) => {

    return (
        <span className="message-variable">
          &#123;{variable}&#125;
        </span>
    );
});

export default MessageVariable; 