import React, { useState, useEffect, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Avatar, Skeleton, Divider } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import UnfoldMore from '@mui/icons-material/UnfoldMore';
import MoreVert from '@mui/icons-material/MoreVert';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import ChatBubbleOutlineIcon from '@mui/icons-material/ChatBubbleOutline';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';

import MessageBubble from '../components/messaging/MessageBubble';
import MessageListItem from '../components/messaging/MessageListItem';
import MessageEvent from '../components/messaging/MessageEvent';
import FormField from '../components/common/FormField';
import TooltipCustom from '../components/common/TooltipCustom';

import api from "../api/api";

const Inbox = () => {
    const navigate = useNavigate();
    const [isFetchingInitial, setIsFetchingInitial] = useState(true);   
    const [conversations, setConversations] = useState([]);
    const [selectedConversationId, setSelectedConversationId] = useState(null);
    const [currentSort, setCurrentSort] = useState('newest');

    const [sendMessage, setSendMessage] = useState('');
    const [sendingMessage, setSendingMessage] = useState(false);

    const teamId = localStorage.getItem("teamId");
    

    const currentConversation = useMemo(() => {
        return selectedConversationId ? conversations.find(conversation => conversation._id === selectedConversationId) : null;
    }, [conversations, selectedConversationId]);

    const refetchConversation = () => {
        api.getConversation({
            candidateId: currentConversation.candidate_id
        }).then(res => {
            const updatedConversations = conversations.map(conversation => {
                if (conversation.candidate_id === currentConversation.candidate_id) {
                    let updatedConversation = res.data;
                    // Update latest message if messages exist
                    if (res.data.messages.length > 0) {
                        updatedConversation.latestMessage = res.data.messages[res.data.messages.length - 1];
                    } else {
                        updatedConversation.latestMessage = null;
                    }
                    return updatedConversation;
                }
                return conversation;
            });
            setConversations(updatedConversations);
        });
    }


    // fetch conversations, set first one as selected if exists
    useEffect(() => {
        api.getConversations({ teamId }).then(res => {
            let conversations = res.data.conversations.reverse();
            for (let i = 0; i < conversations.length; i++) {
              if (conversations[i].messages.length > 0) {
                conversations[i].latestMessage = conversations[i].messages[conversations[i].messages.length - 1];
              } else {
                conversations[i].latestMessage = null;
              }
            }
            
            setConversations(conversations);
            if (conversations.length > 0) {
                setSelectedConversationId(conversations[0]._id);
            }
            setIsFetchingInitial(false);
        }).catch(err => {
            console.error(err);
            setIsFetchingInitial(false);
        });
    }, [teamId]);

    return (
      <Box className="d-flex flex-row" style={{ height: "100%", width: "100%" }}>
        <Box className="d-flex flex-col" style={{ width: "318px", minWidth: "318px", borderRight: "1px solid #E0E0E0" }}>
          {/* Header */}
          <div className="d-flex flex-col" style={{ padding: "24px", paddingBottom: "0px" }}>
            <Box className="d-flex flex-row" style={{ width: "100%" }}>
              <h3 className="my-auto" style={{ marginRight: "8px" }}>
                All Messages
              </h3>
              {/* <IconButton style={{ marginLeft: "auto", border: "1px solid #E0E0E0", borderRadius: "8px", padding: "4px" }}>
                <AddIcon style={{ fontSize: "20px" }} />
              </IconButton> */}
            </Box>

            {/* <Box className="d-flex flex-row" style={{ width: "100%", marginTop: "8px" }}>
              <h4 className="color-gray font-inter my-auto" style={{ fontSize: "14px", fontWeight: 500, marginRight: "4px" }}>Sort:</h4>
                <Select
                  value={currentSort}
                  onChange={(e) => setCurrentSort(e.target.value)}
                  sx={{
                    borderRadius: '8px',
                    backgroundColor: '#F9FAFB',
                    fontWeight: 600,
                    fontSize: '14px',
                    '& .MuiOutlinedInput-input': {
                      padding: '4px',
                      marginTop: '2px',
                    },
                    '& .MuiOutlinedInput-notchedOutline': {
                      borderColor: '#E5E7EB',
                      border: 'none',
                    },
                    '& .MuiSelect-icon': {
                      display: 'none'
                    }
                  }}
                >
                  <MenuItem value={'newest'}>Newest</MenuItem>
                </Select>
                <UnfoldMore className="color-gray my-auto" style={{ zIndex: '1',marginLeft: "-28px", fontSize: '16px' }} />
            </Box> */}
          </div>

          {/* Conversations List */}
          <Box className="d-flex flex-col" style={{ overflowY: "auto", height: "100%", padding: '4px' }}>
            {/* Loading Skeleton */}
            {isFetchingInitial && (
              <div className="d-flex flex-col">
                <Box className="d-flex flex-row" style={{ padding: "12px", width: "100%", marginLeft: "auto" }}>
                    <Skeleton variant="text" width={44} height={72} style={{ borderRadius: '50%', marginTop: '-10px' }}/>
                    <Box className="d-flex flex-col" style={{ marginLeft: '12px' }}>
                        <Skeleton variant="text" width={120} height={30} />
                        <Skeleton variant="text" width={150} height={20} />
                    </Box>
                </Box>
                <Box className="d-flex flex-row" style={{ padding: "12px", width: "100%", marginLeft: "auto" }}>
                    <Skeleton variant="text" width={44} height={72} style={{ borderRadius: '50%', marginTop: '-10px' }}/>
                    <Box className="d-flex flex-col" style={{ marginLeft: '12px' }}>
                        <Skeleton variant="text" width={120} height={30} />
                        <Skeleton variant="text" width={150} height={20} />
                    </Box>
                </Box>
                <Box className="d-flex flex-row" style={{ padding: "12px", width: "100%", marginLeft: "auto" }}>
                    <Skeleton variant="text" width={44} height={72} style={{ borderRadius: '50%', marginTop: '-10px' }}/>
                    <Box className="d-flex flex-col" style={{ marginLeft: '12px' }}>
                        <Skeleton variant="text" width={120} height={30} />
                        <Skeleton variant="text" width={150} height={20} />
                    </Box>
                </Box>
              </div>
            )}
            {conversations.length > 0 && conversations.map((conversation, idx) => (
              <MessageListItem key={idx} conversation={conversation} selected={conversation._id === selectedConversationId} handleClick={() => setSelectedConversationId(conversation._id)} />
            ))}
            {!isFetchingInitial && conversations.length === 0 && (
              <Box className="d-flex flex-col" style={{ padding: "20px" }}>
                <p className="color-gray font-inter my-auto" style={{ fontSize: "14px" }}>No conversations found.</p>
              </Box>
            )}
          </Box>
        </Box>
        {/* Main */}
        <Box className="d-flex flex-col" style={{ width: "100%", height: "100%" }}>
          {/* Header */}
          {selectedConversationId && (
            <Box className="d-flex flex-row" style={{ padding: "16px 24px", borderBottom: "1px solid #E0E0E0" }}>
              <div className={`d-flex flex-row ${currentConversation.interview_config_id && currentConversation.session_id ? 'clickable' : ''}`} onClick={() => {
                if (currentConversation.interview_config_id && currentConversation.session_id)
                  navigate(`/interviews/${currentConversation.interview_config_id}/sessions/${currentConversation.session_id}`);
              }}>
                <Avatar className="my-auto" src={currentConversation.avatar} style={{ width: "32px", height: "32px" }}/>
                <h3 className="my-auto" style={{ fontWeight: 600, marginLeft: "12px" }}>{currentConversation.candidate_name}</h3>
              </div>
              <span className='color-gray font-inter my-auto' style={{ marginLeft: '16px', fontSize: '14px', fontWeight: 400 }}>{currentConversation.to_number}</span>
              <TooltipCustom title={`Conversation platform: ${currentConversation.platform}`} >
                <span className='color-gray font-inter my-auto' style={{ marginLeft: '16px', fontSize: '14px', fontWeight: 400 }}>
                  {currentConversation.platform === "WhatsApp" ? <WhatsAppIcon style={{ fontSize: '18px', marginRight: '8px', marginBottom: '-4px' }} /> : <ChatBubbleOutlineIcon style={{ fontSize: '18px', marginRight: '8px', marginBottom: '-4px' }} />} 
                </span>
              </TooltipCustom>

              {/* <IconButton style={{ marginLeft: "auto", border: "1px solid #E0E0E0", borderRadius: "8px", padding: "8px" }}>
                <MoreVert style={{ fontSize: "18px" }} />
              </IconButton> */}
            </Box>
          )}

          {/* Messages */}
          <Box className="d-flex flex-col" style={{ padding: "24px", height: "calc(100vh - 74px)", overflowY: "auto" }}>
            {currentConversation && currentConversation.messages.map((message, idx) => (
              <MessageBubble key={idx} message={message.body} role={message.direction.includes('outbound') ? 'sent' : 'received'} speaker={message.direction.includes('outbound') ? 'Wayfaster' : currentConversation.candidate_name} time={message.date_created.$date ? message.date_created.$date : message.date_created} />
            ))}
            {/* Message event for interview completion */}
            {/* <MessageEvent
              candidateName={currentConversation ? currentConversation.candidate_name : ""}
              action={"took the Software Engineering role interview"}
              time={new Date()}
              buttonText={"View Interview"}
              handleButton={() => {}}
            /> */}
          </Box>

          
          {/* Conversation started */}
          {currentConversation != null && currentConversation._id && <div className="d-flex flex-col" style={{ marginTop: 'auto', padding: '16px' }}>
            <Divider sx={{ margin: '16px -16px', }} />
            <FormField
              label=""
              multiline
              sx={{
                '& .MuiInputBase-root': {
                  height: 'fit-content',
                  paddingRight: '40px',
                },
              }}
              placeholder="Type a message..."
              value={sendMessage}
              onChange={(e) => {
                setSendMessage(e.target.value);
              }}
            />
            <button className="btn-main" variant="contained" color="primary" style={{ marginLeft: 'auto', width: 'fit-content' }} 
              disabled={sendMessage.length === 0 || sendingMessage}
              onClick={() => {
                setSendingMessage(true);
                api.sendTextMessage({
                  message: sendMessage,
                  conversationId: currentConversation._id,
                }).then((response) => {
                  console.log(response);
                  setSendMessage('');
                  refetchConversation();
                }).catch((err) => {
                  console.log(err);
                }).finally(() => {
                  setSendingMessage(false);
                });
              }}>{sendingMessage ? 'Sending...' : 'Send text'} <ArrowForwardIcon style={{ fontSize: '18px', marginLeft: '8px' }} /></button>
          </div>}
        </Box>
      </Box>
    );
}

export default Inbox;

