import React, { useState } from "react";

import { Container } from "@mui/material";
import DocumentScannerIcon from "@mui/icons-material/DocumentScanner";
import TextsmsIcon from "@mui/icons-material/Textsms";
import BoltIcon from "@mui/icons-material/Bolt";
import ChipTag from "../components/common/ChipTag";

import CreateFromJobDescriptionIcon from "../assets/svg/CreateFromJobDescriptionIcon.svg";
import CreateFromJobDescriptionOnHoverIcon from "../assets/svg/CreateFromJobDescriptionOnHoverIcon.svg";

import ChooseTemplateIcon from "../assets/svg/ChooseTemplateIcon.svg";
import ChooseTemplateOnHoverIcon from "../assets/svg/ChooseTemplateOnHoverIcon.svg";

import CreateYourOwnIcon from "../assets/svg/CreateYourOwnIcon.svg";
import CreateYourOwnOnHoverIcon from "../assets/svg/CreateYourOwnOnHoverIcon.svg";

import { AppRegistration } from "@mui/icons-material";

function CreateInterviewHome({ setCreationFlow }) {

  const [chooseTemplateIcon, setChooseTemplateIcon] = useState(ChooseTemplateIcon);
  const [createFromJobDescriptionIcon, setCreateFromJobDescriptionIcon] = useState(CreateFromJobDescriptionIcon);
  const [createYourOwnIcon, setCreateYourOwnIcon] = useState(CreateYourOwnIcon);

  return (
    <Container sx={{ 
      border: '0.5px solid #E0E0E0', 
      borderRadius: '8px', 
      boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)', 
      marginBottom: '25px',
      display: 'flex',
      width: '100%',
      maxHeight: '800px',
      backgroundColor: 'white'
    }}>     
      <div style={{ width: "100%", display: "flex", flexDirection: "column" }}>
        <h2
          className="section"
          style={{ marginTop: "10%", fontSize: '16px', marginLeft: '105px', fontWeight: '500' }}
        >
          Choose how to set up the interview
        </h2>         
        <div
          className="d-flex flex-row"
        >
          <div
            className="card clickable"
            onClick={() => setCreationFlow("jd")}
            style={{ marginLeft: "105px", marginRight: "24px", padding: "24px", width: "380px", height: '196px' }}
            onMouseOver={() => setCreateFromJobDescriptionIcon(CreateFromJobDescriptionOnHoverIcon)}
            onMouseOut={() => setCreateFromJobDescriptionIcon(CreateFromJobDescriptionIcon)}
          >
            <img 
              src={createFromJobDescriptionIcon} 
              alt="Create from job description" 
              style={{ 
                width: '28px', 
                height: '28px', 
                marginBottom: '25px' }} />
            <h3 className="section-heading" style={{ fontSize: '14px', fontWeight: '600' }}>Upload from job description</h3>
            <p className="section color-gray" style={{ fontSize: '13px', fontWeight: '400' }}>
              Import a job description and create personalized questions tailored to the role and job requirements.
            </p>
            {/* <ChipTag  
              className="m-auto"
              variant="default"
              text="Recommended"
              style={{ marginLeft: "-2px", marginTop: "-8px" }}
            /> */}
          </div>

          {/* <div
            className="card w-33 clickable"
            onClick={() => setCreationFlow("template")}
            style={{ marginRight: "24px", padding: "24px", width: "290px", height: '196px' }}
            onMouseOver={() => setChooseTemplateIcon(ChooseTemplateOnHoverIcon)}
            onMouseOut={() => setChooseTemplateIcon(ChooseTemplateIcon)}
          >
            <img 
              src={chooseTemplateIcon}
              alt="Choose template" 
              style={{ 
                width: '28px', 
                height: '28px', 
                marginBottom: '25px'
              }} 
            />
            <h3 className="section-heading" style={{ fontSize: '14px', fontWeight: '600' }}>Choose template</h3>
            <p className="section color-gray" style={{ fontSize: '13px', fontWeight: '400' }}>
              Select from a variety of templates with pre-set questions designed for different roles such as designers, developers, and more.
            </p>
          </div> */}

          <div
            className="card clickable"
            onClick={() => setCreationFlow("self")}
            style={{ marginRight: "24px", padding: "24px", width: "380px", height: '196px' }}
            onMouseOver={() => setCreateYourOwnIcon(CreateYourOwnOnHoverIcon)}
            onMouseOut={() => setCreateYourOwnIcon(CreateYourOwnIcon)}
          >
            <img 
              src={createYourOwnIcon} 
              alt="Create your own" 
              style={{ 
                width: '28px', 
                height: '28px', 
                marginBottom: '25px'}} />
            <h3 className="section-heading" style={{ fontSize: '14px', fontWeight: '600' }}>Create your own</h3>
            <p className="section color-gray" style={{ fontSize: '13px', fontWeight: '400' }}>
              Build a completely unique interview from scratch to perfectly suit your specific needs and preferences.
            </p>
          </div>
        </div>
      </div>
    </Container>
  );
}

export default CreateInterviewHome;
