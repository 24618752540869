import React from 'react'
import { styled } from '@mui/material/styles';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';

import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

/**
 * Custom styled tooltip that uses underlying MUI Tooltip. Add children
 * to component to attach Tooltip onto element, otherwise attaches
 * on placeholder element.
 */

const placeholder = <InfoOutlinedIcon fontSize='small' />   

const TooltipCustom = styled(({ className, ...props }) => (
  <>
    <Tooltip {...props} classes={{ popper: className }} placement='top'>
      {props.children ? props.children : placeholder }
    </Tooltip>
  </>
))({
  [`& .${tooltipClasses.tooltip}`]: {
    maxWidth: 500,
    fontSize: 14,
    fontWeight: 400,
    fontFamily: 'Inter',
    padding: 15,
    backgroundColor: 'var(--color-empty)',
    color: 'var(--color-main)'
  },
});

export default TooltipCustom