import React from 'react';
import { Box } from '@mui/material';
import ArrowUpward from '@mui/icons-material/ArrowUpward';
import ArrowDownward from '@mui/icons-material/ArrowDownward';
import TooltipCustom from '../common/TooltipCustom';

const TrendPill = ({ percentage, period }) => {
    if (percentage === 0) {
        return null;
    }

    let tooltipContent = "Compared to last "
    if (period === "1W") {
        tooltipContent += "week"
    } else if (period === "1M") {
        tooltipContent += "month"
    } else if (period === "1Y") {
        tooltipContent += "year"
    } else if (period === "YTD") {
        tooltipContent += "year to date"
    } else {
        tooltipContent = ""
    }

    return (
        <TooltipCustom
            title={tooltipContent}
        >
            <Box
                style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    backgroundColor: percentage > 0 ? 'var(--color-completed-bg)' : 'var(--color-reject-bg)',
                    color: percentage > 0 ? 'var(--color-completed)' : 'var(--color-reject)',
                    fontWeight: '500',
                    borderRadius: '8px',
                    padding: '4px 8px',
                    fontSize: '14px',
                }}
            >
                {
                    percentage > 0 ? 
                        <ArrowUpward style={{color: 'var(--color-completed)', transform: 'rotate(45deg)', fontSize: '18px' }} /> : 
                        <ArrowDownward style={{color: 'var(--color-reject)', transform: 'rotate(-45deg)', fontSize: '18px'}} />
                }
                {percentage > 0 ? `${percentage}%` : `${-1 * percentage}%`}
            </Box>
        </TooltipCustom>
        
    );
};

export default TrendPill;
