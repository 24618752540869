import { withAuthenticationRequired } from "@auth0/auth0-react";
import React from "react";
import { CircularProgress } from "@mui/material";

export const AuthenticationGuard = ({ component }) => {
  const Component = withAuthenticationRequired(component, {
    onRedirecting: () => (
      <div>
        <CircularProgress sx={{ color: 'var(--color-primary)', margin: 'auto' }}/>
      </div>
    ),
  });

  return <Component />;
};