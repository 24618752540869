import React, { useState, useRef } from "react";
import { Avatar, Grow, Paper, MenuList, MenuItem } from "@mui/material";
import { Dialog, DialogTitle, DialogContent, DialogActions } from "@mui/material";
import { TableContainer, Table, TableRow, TableHead, TableBody, TableCell } from "@mui/material";
import { ClickAwayListener } from "@mui/material";
import { PlayCircle, UnfoldMore } from "@mui/icons-material";
import TooltipCustom from "../common/TooltipCustom";

function LanguageSelector({ currentLanguage, languages, dialog, clicked, mini }) {
  const [voiceOpen, setVoiceOpen] = useState(false);
  const [audio, setAudio] = useState(null);
  const voiceRef = useRef(null);

  const handleShowVoice = () => {
    setVoiceOpen(true);
  };

  return (
    <>
      {mini ? 
        <TooltipCustom
          title={
            <>
              <p className="m-auto">
                Click to toggle interview language. Currently set to <b>{currentLanguage}</b>.
              </p>
            </>
          }
        >
          <Avatar
            ref={voiceRef}
            className="my-auto clickable"
            onClick={handleShowVoice}
            src={languages[currentLanguage].language_flag}
            sx={{
              color: "black",
              width: 24,
              height: 24,
              borderRadius: "50%",
              textTransform: "capitalize",
              fontSize: "16px",
              marginLeft: "4px",
              marginRight: "4px",
            }}
          >
          </Avatar>
        </TooltipCustom>
      :
        <div className="d-flex flex-row" style={{ marginLeft: "auto", width: '100%' }}>
        <button
          className="btn-main gray outlined d-flex flex-row"
          style={{ height: "40px", borderBottom: '1px solid lightgray !important', borderRadius: '0px !important;', flex: 1 }}
          ref={voiceRef}
          onClick={handleShowVoice}
        >
            <Avatar
            className="my-auto"
            src={languages[currentLanguage].language_flag}
            sx={{
              color: "black",
              width: 22,
              height: 16,
              borderRadius: "20%",
              textTransform: "capitalize",
              fontSize: "16px",
              marginRight: "12px",
            }}
          >
          </Avatar>          
          <div className="d-flex flex-col my-auto" style={{ textAlign: "start" }}>
            <h6
              className="color-main my-auto"
              style={{ marginRight: "4px", fontSize: "14px", lineHeight: '16px', fontWeight: 400 }}
            >
              {currentLanguage}
            </h6>
          </div>
          {/* <ExpandMoreIcon className="my-auto color-main" fontSize="20px" style={{ marginLeft: 'auto' }} /> */}
          <UnfoldMore className="color-gray my-auto" style={{ marginLeft: 'auto', fontSize: '16px' }} />
        </button>
      </div>}

      <Dialog open={voiceOpen} maxWidth="md">
          <DialogTitle style={{ fontSize: '20px', fontWeight: 600 }}>Languages</DialogTitle>
          <DialogContent>
            <Paper style={{ width: '30vw', boxShadow: 'none' }}>
              <TableContainer style={{ overflowX: 'auto' }}>
                  <Table>
                    <TableBody>
                      {Object.keys(languages).map((language) => {
                        let languageName = languages[language].language_name;
                        let languageFlag = languages[language].language_flag;
                        return (
                          <TableRow sx={{
                            backgroundColor: currentLanguage == languageName ? 'var(--color-midgray)' : '',
                            '&:hover': {
                              backgroundColor: 'var(--color-midgray)'
                            }
                          }}
                            key={languageName}
                            onClick={() => {
                              clicked(languageName);
                              setVoiceOpen(false);
                            }}
                            style={{ cursor: 'pointer' }}
                          >
                            <TableCell
                              sx={{
                                padding: '12px 16px',
                                borderBottom: 'none',
                                borderRadius: '8px'
                              }}
                            >
                              <div className="d-flex flex-row">
                                <Avatar
                                  className="my-auto"
                                  src={languageFlag}
                                  sx={{
                                    color: "black",
                                    width: 22,
                                    height: 16,
                                    textTransform: "capitalize",
                                    fontSize: "16px",
                                    marginRight: "12px",
                                    borderRadius: '20%'
                                  }}
                                >
                                </Avatar>          
                                <div className="d-flex flex-col my-auto" style={{ textAlign: "start" }}>
                                  <h6
                                    className="color-main my-auto"
                                    style={{ marginRight: "4px", fontSize: "14px", fontWeight: 400}}
                                  >
                                    {languageName}
                                  </h6>
                                </div>
                              </div>
                            </TableCell>
                          </TableRow>
                        )
                      })}
                    </TableBody>
                  </Table>
              </TableContainer>
            </Paper>
          </DialogContent>
          <DialogActions sx={{ padding: '0px 8px' }}>
              <button className="btn-main" style={{ marginRight: '16px', marginBottom: '16px' }} onClick={() => setVoiceOpen(false)}>
                Close
              </button>
          </DialogActions>
      </Dialog>
    </>
  );
}

export default LanguageSelector;
